import React, { useState, useRef, useEffect } from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
 
import { DayPicker } from "react-day-picker";

import Box from "@mui/material/Box";

import "react-day-picker/dist/style.css";
import "./AppDatePicker.scss";
import { FormControl, TextField } from "@mui/material";

const AppDatePicker = ({ label, id, keyName, value,handleChange,props }) => {
  const [hover, setHover] = useState(false);

  const containerRef = useRef(null) as any;
  const calendarRef = useRef(null) as any;

  const [selected, setSelected] = React.useState<Date>();
  const today = new Date();

  useEffect(() => {   
   handleChange(selected,props)
   		// eslint-disable-next-line react-hooks/exhaustive-deps
}, [selected]);

useEffect(() => {   
   if(selected === undefined && value){
    const formatDate = new Date(value)
    setSelected(formatDate)
   } 
 }, [selected,value]);

  return (
    <div id="datePicker">
    <div id="dateBox">
      <div
        style={{ display: "flex" }}
        onClick={() => setHover(!hover)}
        onKeyDown={(e) => {
          if (e.key === "Enter") setHover(!hover);
        }}
        tabIndex={0}
        role="button"
        aria-expanded={hover}
        aria-label="Select date"
        ref={containerRef}
      >
        
          <FormControl fullWidth key={`${keyName}-t`}> 
            <TextField
              className="formTextField"
              label={label}
              variant="standard"
              value={value}
              id={id}
              key={keyName}
            />
          </FormControl>
       
          <CalendarTodayIcon
            fontSize="small"
            id="calendarIcon"
            sx={{ marginTop: "2px", marginLeft: "10px" }}
            onMouseOver={() => setHover(true)}
          />
    
      </div>
      {hover && (
        <Box
          className="date-head"  
          ref={calendarRef}
        >
          <div className="calendar-container" onMouseLeave={(e) => setHover(false)}   >
            <DayPicker  
              mode="single"
              selected={selected}
              onSelect={setSelected}
              footer=''
              disabled={{ before: today }} 
            />
          </div>
        </Box>
      )}
    </div></div>
  );
};

export default AppDatePicker;
