import { Box } from "@mui/material";

import SPForm from "../../../components/SellerProfile/Form/SPForm";
import { useSellerProfile } from "../SellerProfile";
import "./EditMySettings.scss";
import settingsForm from "../../../components/SellerProfile/Form/json/mySettingsFormFields.json";
import SPFormButton from "../../../components/SellerProfile/Form/SPFormButton";

import {
  SpAPIgetUserProfile,
  SpAPIRequestAccess,
  SpAPIUpdateUserProfile,
} from "../APIs/MySettingsAPI";
import { useEffect, useState } from "react";
import { doFormErrorMsgs, isEmpty } from "../APIs/Helpers";
import { SpAPIgetCompanyProfile } from "../APIs/CompanyProfileAPI";
import { SpAPIgetAllUsers } from "../APIs/SellerProfileAPI";

function EditAdminUserSettings() {
  const { ...context } = useSellerProfile() as any;

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestLinks, setRequestLinks] = useState({});
  const requestAccess = (access) => {
    const requestAccessAPIval = {
      additional_access_id: access?.id,
    };

    SpAPIRequestAccess(context, requestAccessAPIval)
      .then((resp) => {
        if (resp.status === 200) {
           
          setRequestLinks({ ...requestLinks, [access.id]: `Requested` });
        } else {
          console.log("error requesting access", resp);
          setRequestLinks({ ...requestLinks, [access.id]: context.spPageText.errorRequestAccess });
        }
      })
      .catch((error) => {
       
        doFormErrorMsgs(error, context.setErrorMsg, context.spPageText);
      });
  };

  const getUserProfile = () =>
    SpAPIgetUserProfile(context)
      .then((resp) => {
      
        //Disable notifications and display can't opt out msg when applicable
        if (resp?.role_id === "USR001") {
          settingsForm["notifications"]["showNote"] = true;
          Object.keys(settingsForm["notifications"]["options"]).forEach(
            (key) => {
              settingsForm["notifications"]["options"][key]["disabled"] = true;
            }
          );
          Object.keys(settingsForm["addtional_access_id"]["options"]).forEach(
            (key) => {
              settingsForm["addtional_access_id"]["options"][key]["disabled"] =
                true;
            }
          );
        } else {
          settingsForm["notifications"]["showNote"] = false;
        }
        //Show hide Requested/Request Access next to Access (Jira/Sandbox) where applicable
        const accessIds = ["ADA001", "ADA002"];
        const tempRequestLinks = {};
        accessIds.forEach((id) => {
          if (
            resp?.addtional_access_request?.includes(id) &&
            !resp?.addtional_access_id?.includes(id)
          ) {
            //NOTE TYPO IN API
            settingsForm["addtional_access_id"]["options"][id]["notes"] =
            context.spPageText.requested;
          } else if (
            !resp?.addtional_access_id?.includes(id) //NOTE TYPO IN API
           ) {
            settingsForm["addtional_access_id"]["options"][id][
              "clickableNotes"
            ] = true;
            tempRequestLinks[
              id
            ] = `<a class='accessRequest'>${context.spPageText.requestAccess}</a>`;
          }
        });
        setRequestLinks(tempRequestLinks);
        setFormData(resp);
      })
      .catch((error) => {
      
        doFormErrorMsgs(error, context.setErrorMsg, context.spPageText);
      });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
 

    if (isEmpty(context.compProfile)) {
      SpAPIgetCompanyProfile(
      context
      )
        .then(() => {
          getUserProfile();
        })
        .catch((error) => {
          console.log("Error getting company profile in User Settings", error);
        });
    } else {
      getUserProfile();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFormFields = async (formName) => {
    setLoading(true);


    SpAPIUpdateUserProfile(context)
      .then((resp) => {
        if (resp.status === 200) {
          getUserProfile().then(() => {
            SpAPIgetAllUsers(context)
              .then(() => {
                setLoading(false);
                context.setShowSuccessPopUp(context.spPageText.changesSaved);
              })
              .catch((error) =>
                console.log(
                  "error updating user info after my settings edit",
                  error
                )
              );
          });
        } else {
          setLoading(false);
          displayErrorByType(resp);
          console.log("Error updating user settings", resp);
        }
      })
      .catch((error) => {
        setLoading(false);
        displayErrorByType(error);
        console.log("Error updating user settings", error);
      });
  };

  return !isEmpty(formData) ? (
    <Box>
      <form>
        <SPForm
          cols="single"
          compName="mySettings"
          formFields={settingsForm}
          formName="editMySettings"
          user_id={context.user_id}
          formData={formData}
          key="mySettings"
          showLoading={loading}
          handleClickableNote={requestAccess}
          clickableNoteContent={requestLinks}
        />
        <div className="mySettings">
          {!context.formLoading && (
            <SPFormButton
              label={context.spPageText.saveChanges}
              compName="updateUserSettings"
              formName="editMySettings"
              handleClick={() => submitFormFields("editMySettings")}
              showLoading={loading}
            />
          )}
        </div>
      </form>
    </Box>
  ) : (
    <></>
  );
}

export default EditAdminUserSettings;
function displayErrorByType(resp: any) {
  throw new Error("Function not implemented.");
}
