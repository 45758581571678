import { showAPIerror } from "./Helpers";
import { sllrProfAxios } from "./axiosDefault";

const getSellerAndUserIDs = (states, setStates, { ...context }) => {
  const getDefaultCoProfile = async () => await sllrProfAxios.get("");

  return getDefaultCoProfile()
    .then((res) => {
      states.forEach(async (api: string, i) => {
        setStates[i](res.data[api]);
      });
      return res;
    })
    .catch((error) => showAPIerror(error, context, "getSellerAndUserIDs"));
};

export default getSellerAndUserIDs;
