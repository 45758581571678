import { showAPIerror } from "./Helpers";
import { sllrProfAxios } from "./axiosDefault";

const SpAPIgetUserProfile = async ({...context}) => {
  const getUserProfile = async () =>
    await sllrProfAxios.get(`/user-details/${context.user_id}`);
  return await getUserProfile()
    .then((res) => {
     
        const finalData = res.data[0] ? res.data[0] : res.data;

        const updatedFormattedData = { ...finalData };
        updatedFormattedData["notifications"] =
          finalData?.notification_type_id?.split(",") || [];
  
        updatedFormattedData["preferences"] =
          updatedFormattedData?.preferences_type_id?.split(",") || [];
        return updatedFormattedData;
      
    })
    .catch((error) =>  showAPIerror(error, context, "SpAPIgetUserProfile") );
};

const SpAPIRequestAccess = async ({...context}, access) => {
  const requestAccess = async () =>
    await sllrProfAxios.post(`/user-additional-access/${context.user_id}`, access);
  return await requestAccess()
    .then((res) =>res)
    .catch((error) =>  showAPIerror(error, context, "SpAPIRequestAccess") );
};

const SpAPIUpdateUserProfile = async (context) => {
  const putUserUpdates = async () =>
    await sllrProfAxios.put(`/user-details/${context.user_id}`, formattedUpdates);
  const returnFields = [
    "user_first_name",
    "user_last_name",
    "user_phone",
    "notifications",
    "preferences",
    "preferred_language_type",
  ];

  const formattedUpdates = {};
  const getValByObjKey = (field, obj) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "object" && obj[key] !== null && key !== field) {
        getValByObjKey(field, obj[key]);
      } else if (key === field) {
        formattedUpdates[key] = obj[key].content;
      }
    });
  };

  returnFields.forEach((retKey) => {
    getValByObjKey(retKey, context.formUpdates["editMySettings"]);
  });
  formattedUpdates["user_opted_preferences"] = formattedUpdates["preferences"];
  formattedUpdates["user_opted_notifications"] = context.isAdmin
    ? []
    : formattedUpdates["notifications"]; //Admins can't have their notifications changed; API expects empty array for them

  delete formattedUpdates["notifications"];
  delete formattedUpdates["preferences"];

  return await putUserUpdates()
    .then((res) =>res)
    .catch((error) =>  showAPIerror(error, context, "SpAPIUpdateUserProfile") );
};

export { SpAPIgetUserProfile, SpAPIUpdateUserProfile, SpAPIRequestAccess };
