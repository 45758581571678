import './AppDialog.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DialogData from '../../models/AppInterface';
import useHandleAnchorClick from '../../hooks/useHandleAnchorClick';

interface Props {
  data: DialogData,
  open: boolean,
  onClose: Function| any,
  disableClose?: boolean
}

const AppDialog = ({data, open, onClose, disableClose}: Props) => {
  const handleAnchorClick = useHandleAnchorClick();
  return (
    <Dialog
      className="app-dialog"
      disableEscapeKeyDown
      onClose={!disableClose ? onClose : null}
      open={open}
      maxWidth="lg"
    >
      <DialogTitle>
        <div>
          {data.icon && data.icon}
          <h2 className="dialog-title">{data.title}</h2>
        </div>
      </DialogTitle>
      
      <DialogContent className="dialog-content">
        <p id= "app-dialog-description" dangerouslySetInnerHTML={{ __html: data.description}} onClick={handleAnchorClick}/>
        {data.support && <p className="dialog-content__support" dangerouslySetInnerHTML={{ __html: data.support}} onClick={handleAnchorClick} />}
      </DialogContent>

      <DialogActions>
        <div className="c-dialog-actions">
          {(data.handleAction || data.actionLabel) && (
            <Button className="btn-dialog--white" onClick={() => data.handleAction()}>
              <span>{data.actionLabel}</span>
            </Button>
          )}
          {data.handleSecondaryAction && (
            <Button className="btn-dialog--black" onClick={() => data.handleSecondaryAction()}>​​​
              <span>{data.secondaryActionLabel}</span>
            </Button>
          )}
        </div>
      </DialogActions> 
    </Dialog>
  );
};

export default AppDialog;
