import { useMediaQuery, useTheme } from "@mui/material";
import "./Banner.scss";
import banner from "../../../assets/images/gettingstarted/banner.png"

const Banner = ({data}) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <> <section  className={` getting-started-banner-container ${sm  && "mobileGSbanner"}`} aria-labelledby="gettingStartedTitle">
       <div className="bannerCopy" id="gettingStartedTitle"> <h1>{data?.title}</h1>
        <p>{data?.paragraph}</p></div>
        <div className="bannerImg"> 
        <img src={banner} alt="Getting Started banner"/></div>
      </section>
    {/* Below is a crazy hack b/c of way api nav menu works, can't get banner to edge unless absolute, but that causes a bad responsiveness, so duplicating menu and hiding as a sizer for now... FIX when API nav gets reworked to go on top */}
      <section  className={`hiddenBanner getting-started-banner-container ${sm  && "mobileGSbanner"}`} aria-labelledby="gettingStartedTitle">
      <div className="bannerCopy" id="gettingStartedTitle"> <h1>{data?.title}</h1>
       <p>{data?.paragraph}</p></div>
       <div className="bannerImg"> 
       <img src={banner} alt="Getting Started banner"/></div>
     </section></>
  );
};

export default Banner;
