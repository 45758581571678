import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Radio,
  RadioGroup,
  Autocomplete,
  Box
} from '@mui/material';
import { useSellerProfile } from '../../../pages/SellerProfile/SellerProfile';

import { useEffect, useRef, useState } from 'react';
import './SPForm.scss';
import AppDatePicker from '../../AppFilterDate/AppDatePicker/AppDatePicker';
import { format } from 'date-fns';
import { validateInput } from '../../../pages/util/validateFormInput';
import GetLabels from '../../../pages/SellerProfile/GetLabels';
import {
  populateFormFields,
  checkRequiredContent,
  checkParentRequiredChecked,
  updateParentContent,
  // getAllExistingEmails,
  updateNameKeys,
  isEmailExists,
  getAllUsersBasedOnEmail,
  checkEmailExistsInList
} from './formHelpers';
import useScrollPosition from '../../../hooks/useScrollPosition';

function SPForm({ ...props }) {
  const formRef = useRef(null) as any;
  const initialRef = useRef(null) as any;
  const { ...context } = useSellerProfile() as any;
  const scrollPosition = useScrollPosition();

  const [fieldsWithError, setFieldsWithError] = useState<string[]>([]);
  const [enteredExistingEmail, setEnteredExistingEmail] = useState<string[]>(
    []
  );
  const disableForm = props.disableForm ? props.disableForm === 1 : false;
  const [emailsList, setEmailsList] = useState<string[]>([]);
  const [emailExistsError, setEmailExistsError] = useState<boolean>(true);
  //util...

  useEffect(() => {
    //clear out any old unsaved changes on load...
    let data = context.formUpdates;
    data[props.formName] = {};

    context.setFormUpdates({ ...context.formUpdates, [props.formName]: {} });

    const doPrePopForm = populateFormFields({ ...props }, context.isFrench);

    context.setFormUpdates({
      ...context.formUpdates,
      [props.formName]: doPrePopForm.data
    });
    setFieldsWithError(doPrePopForm.errors);
    context.setErrorMsg('');

    context.setDisabledBttn({
      ...context.disabledBttn,
      [props.formName]: true
    });
    const scrollRef = formRef.current ? formRef.current : initialRef.current;
    const formsToScrollFor = [
      'editUserProfile',
      'editNotifications',
      'editAPIcreds'
    ];
    if (formsToScrollFor.includes(props.formName)) {
      scrollRef.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.formName === 'addNotificationRecip' ||
      props.formName === 'addUser'
    ) {
      let emailExistsAlready;

      if (emailsList?.length) {
        let email =
          props.formName === 'addUser'
            ? context.formUpdates[props.formName]['user_email']['content']
            : context.formUpdates[props.formName].inputFields.options[
                'user_email'
              ];

        emailsList?.forEach((e) => {
          if (e['label'] === email)
            emailExistsAlready = {
              user_first_name: e['user_first_name'],
              user_last_name: e['user_last_name'],
              user_email: e['user_email'],
              type:
                props.formName === 'addNotificationRecip'
                  ? 'userFromUserList'
                  : 'userFromNotifList'
            };
        });
      }

      if (emailExistsAlready) {
        let field = 'user_email';
        let getCurrErrors: string[] = [...fieldsWithError];
        const updateNameTypes = ['content'];
        const disableNameFields =
          emailExistsAlready?.type === 'userFromUserList' ? true : false;
        setEnteredExistingEmail([field]);
        disableNameFields && updateNameTypes.push('disable');

        updateNameKeys(
          field,
          context.formUpdates[props.formName],
          emailExistsAlready,
          updateNameTypes,
          getCurrErrors
        );
      }
    }
    // eslint-disable-next-line
  }, [emailsList]);

  useEffect(() => {
    if (
      props.formName === 'addNotificationRecip' ||
      props.formName === 'addUser'
    ) {
      if (context.formUpdates[props.formName]) {
        let field = 'user_email';
        let getCurrErrors: string[] = [...fieldsWithError];
        let curr =
          props.formName === 'addUser'
            ? context.formUpdates[props.formName][field]
            : context.formUpdates[props.formName]?.inputFields?.options[field];

        try {
          let emailExistsAlready;

          if (emailsList?.length) {
            let email =
              props.formName === 'addUser'
                ? context.formUpdates[props.formName]['user_email']['content']
                : context.formUpdates[props.formName].inputFields.options
                    .user_email.content;

            emailsList?.forEach((e) => {
              if (e['label'] === email)
                emailExistsAlready = {
                  user_first_name: e['user_first_name'],
                  user_last_name: e['user_last_name'],
                  user_email: e['user_email'],
                  type:
                    props.formName === 'addNotificationRecip'
                      ? 'userFromUserList'
                      : 'userFromNotifList'
                };
            });
          }

          if (emailExistsAlready) {
            let field = 'user_email';
            const updateNameTypes = ['content'];
            const disableNameFields =
              emailExistsAlready?.type === 'userFromUserList' ? true : false;
            setEnteredExistingEmail([field]);
            disableNameFields && updateNameTypes.push('disable');

            getCurrErrors = updateNameKeys(
              field,
              context.formUpdates[props.formName],
              emailExistsAlready,
              updateNameTypes,
              getCurrErrors
            );
          } else {
            if (
              props.formName === 'addNotificationRecip' ||
              props.formName === 'addUser'
            ) {
              curr.errorMsg =
                props.formName === 'addUser'
                  ? context.spPageText.emailExists
                  : context.spPageText.notifEmailExists;
              curr.error = true;

              !getCurrErrors.includes(field) && getCurrErrors.push(field);
            } else {
              getCurrErrors = getCurrErrors.filter((f) => f !== field); //THIS SHOULD ONLY be removed from error list WHEN AUTO FILLING AS CONVENINCE, BUT not on Notif if exists on notif users already
            }
          }

          setFieldsWithError([...getCurrErrors]);
          context.setFormUpdates({
            ...context.formUpdates,
            [props.formName]: context.formUpdates[props.formName]
          });
          context.setDisabledBttn({
            ...context.disabledBttn,
            [props.formName]: getCurrErrors.length > 0
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    // eslint-disable-next-line
  }, [emailExistsError]);

  const [showHideSelect, setShowHideSelect] = useState([{ key: false, value: ""}])

  const handleShowHideSelect = (label) => {
    const res = showHideSelect.map(v => {
      if(v.value === label && v.key === true) {
        return {
          key: false,
          value: label
        }
      } 
      if(v.value === label && v.key === false) {
        return {
          key: true,
          value: label
        }
      } 
      if(v.value !== label) {
        return {
          key: true,
          value: label
        }
      } else {
        return v
      }
    })
    setShowHideSelect(res)
  }

  useEffect(() => {
    if(scrollPosition) {
      setShowHideSelect([{ key: false, value: ""}])
    }
  }, [scrollPosition]);

  const handleChange = async (e: any, curr, field, isAutoComplete = false) => {
    try {
      //Reset errors
      let getCurrErrors: string[] = [...fieldsWithError]; //use because state not updated immediately
      curr.errorMsg = '';
      curr.error = false;
      let update: string;

      //Set content
      if (curr.hasOwnProperty('checked')) {
        curr['checked'] = !curr['checked'];

        if (curr.hasOwnProperty('parent')) {
          updateParentContent(curr.parent, context.formUpdates[props.formName]);
        }
      }
      if (isAutoComplete) {
        update = e.target?.innerText || e.target?.value;
      } else {
        update = e.target?.value || e.target?.checked || '';
      }
      curr['content'] = update;

      // Get any existing Emails from User List matching the entered email
      let checkEmailExists, emailExistsAlready;

      if (
        field.includes('email') &&
        update.length > 0 &&
        (props.formName === 'addNotificationRecip' ||
          props.formName === 'addUser')
      ) {
        var flag;
        if (props.formName === 'addUser') flag = 'users';
        else if (props.formName === 'addNotificationRecip') flag = 'notifs';

        await getAllUsersBasedOnEmail(update, context, flag, setEmailsList);

        let validEmail = validateInput(curr, context.isFrench);

        if (validEmail.error) {
          checkEmailExists = false;
        } else {
          emailExistsAlready = checkEmailExistsInList(
            update,
            context,
            props.formName === 'addNotificationRecip' ? 'notifs' : 'users'
          );

          if (emailExistsAlready) {
            checkEmailExists = emailExistsAlready;
          } else if (!emailExistsAlready && update.length > 0) {
            await isEmailExists(
              update,
              context,
              setEmailExistsError,
              emailExistsError
            );

            checkEmailExists = false;
          }
        }
      }

      if (checkEmailExists) {
        setEnteredExistingEmail([field]);
        const disableNameFields =
          emailExistsAlready?.type === 'userFromUserList' ? true : false;

        if (
          (props.formName === 'addNotificationRecip' &&
            !emailExistsAlready?.type) ||
          (props.formName === 'addUser' && !emailExistsAlready?.type)
        ) {
          curr.errorMsg =
            props.formName === 'addUser'
              ? context.spPageText.emailExists
              : context.spPageText.notifEmailExists;
          curr.error = true;

          !getCurrErrors.includes(field) && getCurrErrors.push(field);
        } else {
          getCurrErrors = getCurrErrors.filter((f) => f !== field); //THIS SHOULD ONLY be removed from error list WHEN AUTO FILLING AS CONVENINCE, BUT not on Notif if exists on notif users already
        }
        const updateNameTypes = ['content'];
        disableNameFields && updateNameTypes.push('disable');

        getCurrErrors = updateNameKeys(
          field,
          context.formUpdates[props.formName],
          emailExistsAlready,
          updateNameTypes,
          getCurrErrors
        );
      } else {
        //Clear out name fields if just changed email from existing to non-existing
        field.includes('email') &&
          enteredExistingEmail.includes(field) &&
          (getCurrErrors = updateNameKeys(
            field,
            context.formUpdates[props.formName],
            {},
            ['content', 'enable'],
            getCurrErrors
          ));
        setEnteredExistingEmail(
          enteredExistingEmail.filter((f) => f !== field)
        );

        //Check for Required errors
        let isError = false;

        if (curr.parent) {
          //Is a grouped set of Required fields (i.e. checkbox or radio group)
          if (!curr['checked'] && !curr['allowEmpty']) {
            const somethingChecked = checkParentRequiredChecked(
              curr.parent,
              context.formUpdates[props.formName]
            );

            if (!somethingChecked) {
              !getCurrErrors.includes(curr.parent) &&
                getCurrErrors.push(curr.parent);
              isError = true;
            } else {
              getCurrErrors = getCurrErrors.filter((f) => f !== curr.parent);
            }
          } else {
            getCurrErrors = getCurrErrors.filter((f) => f !== curr.parent);
          }
        } else {
          //Standard Required field
          isError = checkRequiredContent(update, curr);

          const useAsField = curr.parent ? curr.parent : field;

          if (isError) {
            !getCurrErrors.includes(field) && getCurrErrors.push(field);
          } else if (fieldsWithError.includes(useAsField)) {
            getCurrErrors = getCurrErrors.filter((f) => f !== useAsField);
          }
        }
        curr['error'] = isError;
        const useLabel = GetLabels(curr['label'], context.spPageText);
        const getLabelForError = (label: string) =>
          label.length > 30 ? `${label.slice(0, 30)}...` : label;

        curr['errorMsg'] = isError
          ? ` ${getLabelForError(useLabel)} ${context.spPageText.isRequired}`
          : '';

        //Do field validation
        if (curr['validate'] !== undefined && !isError) {
          const doValidation = validateInput(curr, context.isFrench);

          isError = doValidation.error;

          if (isError) {
            curr['error'] = isError;
            curr['errorMsg'] = doValidation.msg;

            if (!getCurrErrors.includes(field)) {
              getCurrErrors.push(field);
            }
          }
        }
      }
      //Set updates
      console.log(context.formUpdates, props.formName);
      console.log('Final Errors', getCurrErrors);
      setFieldsWithError([...getCurrErrors]);
      context.setFormUpdates({
        ...context.formUpdates,
        [props.formName]: context.formUpdates[props.formName]
      });

      context.setDisabledBttn({
        ...context.disabledBttn,
        [props.formName]: getCurrErrors.length > 0
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalChange = (val, calProps) => {
    if (val && val instanceof Date && calProps?.content) {
      //compare old/new to prevent firing unintentionally enabling submit button
      const oldDate = format(new Date(calProps.content), 'PP');
      const newDate = format(new Date(val), 'PP');
      if (oldDate !== newDate) {
        calProps.content = newDate;
        context.setFormUpdates({ ...context.formUpdates });
        context.setDisabledBttn({
          ...context.disabledBttn,
          [props.formName]: fieldsWithError.length > 0
        });
      }
    }
  };

  //******** PAGE LAYOUT functions
  const getLabel = (curr) => {
    //get label and if required, add *
    const required =
      curr['required'] && curr['required'] === 'true' ? ' *' : '';

    return `${GetLabels(curr['label'], context.spPageText)}${required}`;
  };
  const checkSpecialInstructions = (field, type) => {
    //for fields with a note next to them...
    const hideNote =
      (field?.hasOwnProperty('showNote') && field.showNote === false) || false;

    if (field?.hasOwnProperty('notes') && type === 'span' && !hideNote) {
      return (
        <div className="groupSpecInst">
          {GetLabels(field['notes'], context.spPageText)}
        </div>
      );
    } else if (
      field?.hasOwnProperty('clickableNotes') &&
      type === 'none' &&
      !hideNote
    ) {
      return (
        <span
          onClick={() => props.handleClickableNote(field) || null}
          className="fieldNote clickableNote"
          dangerouslySetInnerHTML={{
            __html: GetLabels(
              props.clickableNoteContent[field.id],
              context.spPageText
            )
          }}
        ></span>
      );
    } else if (field?.hasOwnProperty('notes') && type === 'h4' && !hideNote) {
      return <h4>{GetLabels(field['notes'], context.spPageText)}</h4>;
    } else if (field?.hasOwnProperty('notes') && type === 'none' && !hideNote) {
      return (
        <span
          className="fieldNote"
          dangerouslySetInnerHTML={{
            __html: GetLabels(field['notes'], context.spPageText)
          }}
        ></span>
      );
    }
  };
  const checkDisplayFieldText = (curr) => {
    if (curr.fieldText) {
      return {
        '&::after': {
          content: `"${context.spPageText[curr.fieldText]}"`,
          position: 'absolute',
          bottom: '.5em',
          right: 0,
          fontSize: '13px'
        }
      };
    }
  };
  let x = 0;
  const createField = (curr, field, addLabeledBy = '') => {
    x++;
    const colStyle = props.cols === 'single' ? 'singleCol' : 'doubleCol';

    switch (curr['type']) {
      case 'autocomplete':
        return (
          <FormControl
            fullWidth
            key={`${props.formName}${curr['label']}-${x}`}
            className={`spFormField ${colStyle}`}
          >
            <Autocomplete
              freeSolo
              id="combo-box-demo"
              options={emailsList}
              onChange={(e) => handleChange(e, curr, field, true)}
              renderInput={(params) => (
                <TextField
                  className={`formTextField ${props.compName}`}
                  label={getLabel(curr)}
                  key={`${props.formName}${curr['label']}-${x}-${x}`}
                  name={curr['label']}
                  variant="standard"
                  autoComplete="off"
                  error={curr['error'] === true}
                  value={curr['content'] || ''}
                  onChange={(e) => handleChange(e, curr, field, true)}
                  helperText={curr['error'] === true && curr['errorMsg']}
                  {...params}
                  disabled={
                    curr[`disabled${props.formName}`] === 'true' ||
                    curr.disabled ||
                    curr.mandatory ||
                    disableForm ||
                    false
                  }
                />
              )}
            />
          </FormControl>
        );
      case 'text':
        curr['content'] = curr['content'] === 'null' ? '' : curr['content']; //hide null from db in form
        return (
          <FormControl
            fullWidth
            key={`${props.formName}${curr['label']}-${x}`}
            className={`spFormField ${colStyle}`}
          >
            <TextField
              className={`formTextField ${props.compName} ${
                curr.fieldText ? 'fieldText' : ''
              }`}
              label={getLabel(curr)}
              key={`${props.formName}${curr['label']}-${x}-${x}`}
              name={curr['label']}
              variant="standard"
              id={`${props.formName}${curr['label']}-${x}`}
              value={curr['content'] || ''}
              onChange={(e) => handleChange(e, curr, field)}
              error={curr['error'] === true}
              helperText={curr['error'] === true && curr['errorMsg']}
              disabled={
                curr[`disabled${props.formName}`] === 'true' ||
                curr.disabled ||
                curr.mandatory ||
                disableForm ||
                false
              }
              sx={checkDisplayFieldText(curr) || {}}
            />
            {curr.example && curr.example !== 'null' && (
              <div className={`formTextField ${props.compName} span`}>
                <span>{GetLabels(curr.example, context.spPageText)}</span>
              </div>
            )}
          </FormControl>
        );
      case 'select':
        return (
          <FormControl
            fullWidth
            key={`${props.formName}${curr['label']}-${x}`}
            className={`spFormField ${colStyle} selectList`}
          >
            <InputLabel id={`${props.formName}-inPutlabel-${x}`}>
              {getLabel(curr)}
            </InputLabel>
            <Select
              labelId={`${props.formName}-inPutlabel-${x}`}
              id={`${props.formName}${curr['label']}-${curr['content']}`}
              variant="standard"
              label={getLabel(curr)}
              name={curr['label']}
              multiple={curr['multiple'] || false}
              value={
                curr['multiple'] ? curr['content'] || [] : curr['content'] || ''
              }
              onChange={(e) => handleChange(e, curr, field)}
              open={showHideSelect[0].value === curr['label'] ? showHideSelect[0].key : false}
              onOpen={() => handleShowHideSelect(curr['label'])}
              onClose={() => handleShowHideSelect(curr['label'])}
              MenuProps={{
                disableScrollLock: true
              }}
            >
              {curr['options'] &&
                curr['options'].map((option, i) => {
                  const display = curr['translateValue']
                    ? GetLabels(option, context.spPageText)
                    : option;
                  return (
                    <MenuItem
                      value={option.replace('spPageText.', '')}
                      key={`${props.formName}${option.replaceAll(
                        ' ',
                        ''
                      )}-${i}`}
                    >
                      {display}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        );

      case 'checkbox':
        const currValue =
          curr['content'] === '' ? false : curr['content'] === 'true';

        const content = checkSpecialInstructions(curr, 'none');
        return (
          <div
            key={`${props.formName}${curr['label']}-${x}`}
            className="spCheckbox"
          >
            <FormControlLabel
              control={
                <Checkbox
                  name={curr['name']}
                  value={currValue || ''}
                  checked={curr['checked'] || false}
                  className={`spFormField`}
                  sx={{ display: 'inline-block' }}
                  onClick={(e) => handleChange(e, curr, field)}
                  disabled={curr['disabled'] || disableForm || false}
                />
              }
              label={getLabel(curr)}
            />
            {content}
          </div>
        );
      case 'radioOption':
        return (
          <FormControlLabel
            key={`${props.formName}${curr['label']}-${x}`}
            value={curr['name']}
            sx={{ display: 'grid' }}
            control={<Radio />}
            label={getLabel(curr)}
            onChange={(e) => handleChange(e, curr, field)}
          />
        );

      case 'calendar':
        return (
          <AppDatePicker
            key={`${props.formName}${curr['label']}-${x}`}
            label={getLabel(curr)}
            id={`${props.formName}dates-${x}`}
            keyName="dates"
            value={curr['content'] || ''}
            handleChange={handleCalChange}
            props={curr}
          />
        );
    }
  };
  let closestGroupParent = '';
  const displayLowestLevelFields = (curr, field) => {
    if (
      curr['type'] === 'group' ||
      curr['type'] === 'subgroup' ||
      curr['parentArray'] === true
    ) {
      closestGroupParent = field;
      const groupFields = curr['options'];
      const getGroupField =
        groupFields &&
        Object.keys(groupFields).map((opField) => {
          return displayLowestLevelFields(groupFields[opField], opField);
        });
      if (curr['type'] === 'subgroup') {
        return (
          <div
            className="formSubgroup"
            key={`${props.formName}${curr['label']}`}
          >
            <h5>{GetLabels(curr['label'], context.spPageText)}</h5>
            {checkSpecialInstructions(curr, 'span')}
            {getGroupField}
          </div>
        );
      } else {
        return getGroupField;
      }
    } else if (curr['type'] === 'radio') {
      const getRadioField = Object.keys(curr['options']).map((opField) => {
        return createField(curr['options'][opField], field);
      });

      return (
        <FormControl>
          <legend
            id={`${props.formName}${curr['name']}-${x}`}
            className="formLabel"
            aria-label={getLabel(curr)}
          >
            {getLabel(curr)}
          </legend>
          <RadioGroup
            name={curr['name']}
            className={`spFormField ${props.compName}`}
            id={`${props.formName}-radio-buttons-group-${x}`}
            aria-labelledby={`${props.formName}${curr['name']}-${x}`}
          >
            {getRadioField}
          </RadioGroup>
        </FormControl>
      );
    } else {
      let addLabeledBy = '';
      if (curr['type'] === 'checkbox') {
        addLabeledBy = closestGroupParent;
      }
      return createField(curr, field, addLabeledBy);
    }
  };
  return !context.formLoading && context.formUpdates[props.formName] ? (
    <div
      ref={formRef}
      className={`spForm ${props.compName ? props.compName : ''}`}
      key={props.formName}
    >
      {Object.keys(context.formUpdates[props.formName]).map((field) => {
        const isHeadOrSubhead =
          context.formUpdates[props.formName][field]['label'] &&
          (context.formUpdates[props.formName][field]['type'] === 'group' ||
            context.formUpdates[props.formName][field]['type'] === 'subgroup');
        return (
          <div
            key={`${props.formName}${field}-t`}
            className={`${isHeadOrSubhead ? 'spFormGroup' : ''} ${
              props.compName ? props.compName : ''
            } sp-${context.formUpdates[props.formName][field]['type']}`}
            aria-label={getLabel(context.formUpdates[props.formName][field])}
          >
            {isHeadOrSubhead && (
              <h3>{getLabel(context.formUpdates[props.formName][field])}</h3>
            )}
            {isHeadOrSubhead &&
              checkSpecialInstructions(
                context.formUpdates[props.formName][field],
                'h4'
              )}
            {displayLowestLevelFields(
              context.formUpdates[props.formName][field],
              field
            )}
          </div>
        );
      })}
    </div>
  ) : (
    <Box sx={{ height: '50vh' }} ref={initialRef}></Box>
  );
}

export default SPForm;
