import * as React from "react";
import {
  ClickAwayListener,
  ListItemButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";

import "./AppSignedUser.scss";
import { LangContext } from "../../../pages/App/App";
import { useContext } from "react";

export default function AppSignedUser({
  userName,
  coName,
  logout,
}) {
  const langContext = useContext(LangContext);
  const isFrench = langContext.selectedLanguage === "fr";
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null);
  const open = Boolean(anchorEl);

  const signinBtnRef = React.useRef(null) as any;

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

const isSignedIn = userName !== "";
  const handleOptionChange = () => {
    logout();
    setAnchorEl(null);
    signinBtnRef.current.focus();
  };
  const navigate = useNavigate();
  const id = open ? "simple-popper" : undefined;

  return (
    <>
      {md && (
        <div className="userSignInMenu">
          <Button
            ref={signinBtnRef}
            role="button"
            aria-expanded={open}
            aria-label={
              isSignedIn
                ? userName
                : isFrench
                ? "Ouvrir une session"
                : "Sign In"
            }
            disableRipple
            onClick={(event) => {
              setAnchorEl(anchorEl ? null : event.currentTarget);
              !isSignedIn && navigate(`${langContext.selectedLanguage}/seller-profile`);
            }}
            sx={{
              //   padding: "0px 30px",
              border: "16px solid transparent",
              marginRight: "15px",
              color: "white",
              height: "auto",
              width: "fit-content",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 400,
              "&:focus-visible": {
                backgroundClip: "padding-box",
                borderRadius: "10px",
                border: "16px solid transparent",
                outline: "4px solid #f8f8f8",
              },
            }}
           
          >
            {isSignedIn ? userName : isFrench ?  "Ouvrir une session" : "Sign in" }
            {isSignedIn && <KeyboardArrowDownIcon />}
          </Button>
          {isSignedIn && (
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement="bottom"
              disablePortal={true} 
              modifiers={[
                {
                  name: "arrow",
                  enabled: true,
                  options: {
                    element: arrowRef,
                  },
                },
              ]}
              style={{
                zIndex: 999,
              }}
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Paper className="userInfo-popper-content">
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                          navigate(`${langContext.selectedLanguage}/seller-profile`);
                        }}
                        tabIndex={0}
                        onKeyDown={(e) => e.key === "Enter" &&  navigate(`${langContext.selectedLanguage}/seller-profile`)} 
                        className="signMenuBtn"
                        disableRipple
                        role="button"
                      >
                        <ListItemText
                          primary={
                            isFrench
                              ? `Consulter le profil de ${coName}`
                              : `View ${coName} Profile`
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider
                      variant="middle"
                      style={{ padding: "5px" }}
                      aria-hidden="true"
                    />
                    <ListItem disablePadding>
                      <ListItemButton
                        className="signMenuBtn"
                        disableRipple
                        onClick={() => handleOptionChange()}
                        onKeyDown={(event) => {
                          if (event.key === "Tab" && !event.shiftKey) {
                            setAnchorEl(null);
                          }else if(event.key === "Enter"){
                            handleOptionChange()
                          }
                        }}
                      >
                        <ListItemText
                          primary={isFrench ? "Déconnexion" : "Sign Out"}
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                  <div className="language-popper-arrow" ref={setArrowRef} />
                </Paper>
              </ClickAwayListener>
            </Popper>
          )}
        </div>
      )}
    </>
  );
}
