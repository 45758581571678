import './SuccessPopUp.scss';
import { Box } from '@mui/material';
import { useSellerProfile } from '../../../pages/SellerProfile/SellerProfile';
import CheckIcon from '@mui/icons-material/Check';

const SuccessPopUp = ({ topPx }) => {
  const { setShowSuccessPopUp, showSuccessPopUp } = useSellerProfile() as any;
  window.scrollTo({ top: 0, behavior: 'smooth' });

  const closeSuccessPopUp = () => {
    setShowSuccessPopUp('');
  };

  setTimeout(() => {
    setShowSuccessPopUp('');
  }, 4000);

  return (
    //AODA NOTE - successful submission message is read through SPFormButton
    <div className="successCloseZone" onClick={() => closeSuccessPopUp()}>
      <Box className="successPopUp" style={{ top: topPx }}>
        <CheckIcon className="successCheckIcon" />
        {showSuccessPopUp}
      </Box>
    </div>
  );
};

export default SuccessPopUp;
