import * as React from 'react';
import { subSellerAxiosDefault } from './axiosDefault';

export const getFormData = async (fr: boolean, { ...context }) => {
  const { setGDSOptions, setBusinessModelOptions } = context;

  try {
    const resp = await subSellerAxiosDefault.get('/sub-seller-business-type');

    if (resp) {
      console.log(resp?.data.data);
      setBusinessModelOptions(
        resp?.data.data.map((item) => {
          if (fr) {
            item.business_type = item.business_type_fr;
          } else {
            item.business_type = item.business_type_en;
          }
          delete item.business_type_fr;
          delete item.business_type_en;
          return item;
        })
      );
    }
  } catch (error) {
    console.log(error);
  }

  try {
    const resp = await subSellerAxiosDefault.get('/seller-aggregator');

    if (resp) {
      setGDSOptions(resp?.data.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const submitForm = async (
  data: any,
  setFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const resp = await subSellerAxiosDefault.post(
      '/sub-seller-registration',
      data
    );

    if (resp) {
      setFormSubmitted(true);
    }
  } catch (error) {
    console.log(error);
  }
};
