import { useState, useEffect } from "react";
import { format, utcToZonedTime } from 'date-fns-tz';

const includeTime = (url, isHourly) => {
	const date = new Date();
	const timeZone = "America/Toronto";
	const localTime = utcToZonedTime(date, 'America/Toronto');

	const dateString = format(localTime, 'yyyy-MM-dd', { timeZone });
	const timeString = format(localTime, 'HH:mm', { timeZone });

	return `${url}?name=All&dateTime=${dateString}%20${timeString}:00&${isHourly ? 'hours=24' : 'days=90'}` 
}

const useFetchStatusAPI = (url, options, version = "Gold", services) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);


	useEffect(() => {
		const isHourly = url.includes("hourly") 
		const dataString = isHourly ? `hourly${version}` : `daily${version}`;
		
		const fetchData = async () => {
			setLoading(true);
			const updatedURL = includeTime(url, isHourly)
			try {
				const response = await fetch(updatedURL, options);
				let responseData = await response.json();
				
				let updatedResponseData = {...responseData}; 

				if (isHourly) {
					updatedResponseData.Status = updatedResponseData.Status.map(item => {
						if (item.data && item.data[0] && Object.keys(item.data[0]).length === 25) {
							const { [Object.keys(item.data[0])[0]]: _, ...rest } = item.data[0]; 
							item.data[0] = rest; 
						}
						return item;
					});
				}
				
				let expiration = new Date();
				
				const serviceOrder = Object.keys(services);
				const sortedData = updatedResponseData.Status.sort(
					(a, b) => serviceOrder.indexOf(a.API) - serviceOrder.indexOf(b.API)
				);

				// eslint-disable-next-line no-unused-expressions
				isHourly
					? expiration.setMinutes(expiration.getMinutes() + 5)
					: expiration.setMinutes(expiration.getMinutes() + 60);

				sessionStorage.setItem(
					dataString,
					JSON.stringify({
						data: sortedData,
						expiration: expiration.getTime(),
					})
				);

				setData(sortedData);
			} catch (err) {
				console.log(err.message);
			}
			setLoading(false);
		};

		const storedData = JSON.parse(sessionStorage.getItem(dataString) || "null");

		// eslint-disable-next-line no-unused-expressions
		storedData && new Date().getTime() < storedData.expiration
			? (setData(storedData.data), setLoading(false))
			: fetchData();

		let intervalTime = isHourly ? 5 * 60 * 1000 : 60 * 60 * 1000;
		const interval = setInterval(fetchData, intervalTime);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url, version]);

	return { data, loading };
};

export default useFetchStatusAPI;
