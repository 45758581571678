import './PointOfSaleCoverage.scss';
import {
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Button
} from '@mui/material';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from 'react-simple-maps';
import { geoCentroid } from 'd3-geo';
import { feature } from 'topojson-client';
import {
  ISLANDS_AND_TERRITORIES,
  SMALL_COUNTRY_CODES
} from '../../constants/posCoverage';
import { LangContext } from '../../pages/App/App';
import { MARKET_TYPE } from '../../constants/common';
import { useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AppAutocomplete from '../AppAutocomplete/AppAutocomplete';
import AppTooltip from '../AppTooltip/AppTooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Markets from './Markets/Markets';
import RemoveIcon from '@mui/icons-material/Remove';
import worldmap from '../../assets/worldmap.json';

const PointOfSaleCoverage = ({ data }) => {
  const langContext = useContext(LangContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [center, setCenter] = useState<[number, number]>([0, 0]);
  const [countryCodeMapping, setCountryCodeMapping] = useState([] as any);
  const [countries, setCountries] = useState([]);
  const [geoData, setGeoData] = useState(null as any);
  const [searchedMarketCode, setSearchedMarketCode] = useState<string | null>(
    ''
  );
  const [searchValue, setSearchValue] = useState<string | null>('');
  const [supportedMarkets, setSupportedMarkets] = useState(
    data.supportedMarkets.market_types
      .map((marketType) => marketType.markets)
      .flat()
      .sort((a, b) => a.name.localeCompare(b.name))
  );
  const [upcomingMarkets, setUpcomingMarkets] = useState(
    data.upcomingMarkets.markets.sort((a, b) => a.name.localeCompare(b.name))
  );
  const [zoomLevel, setZoomLevel] = useState(1);

  const MAP_HEIGHT = 420;
  const MAP_WIDTH = 800;
  const MAX_ZOOM = 80;

  const supportedMarketCodes = data.supportedMarkets.market_types
    .map((marketType) => marketType.markets)
    .flat()
    .map((m) => m.code);
  const upcomingMarketCodes = data.upcomingMarkets.markets.map((m) => m.code);

  const getCountryArea = async (countryCode: string) => {
    try {
      const response = await fetch(
        `https://restcountries.com/v3.1/alpha/${countryCode}`
      );
      const responseJson = await response.json();
      if (responseJson && responseJson.length > 0) {
        return responseJson[0].area;
      } else {
        console.error('Country not found or no area data available');
      }
    } catch (error) {
      console.error('Error fetching country area:', error);
    }
  };

  const getCountryCode = (countryName, countries, defaultValue = '') => {
    for (let country of countries) {
      const code = Object.keys(country)[0];
      if (country[code].toLowerCase() === countryName.toLowerCase()) {
        return code;
      }
    }
    return defaultValue;
  };

  const getSubtitleByCountryCode = (marketTypes, countryCode: string) => {
    for (const item of marketTypes) {
      for (const market of item.markets) {
        if (market.code === countryCode) {
          return item.title;
        }
      }
    }
    return 'Country code not found';
  };

  const getZoomLevel = (area: number | null) => {
    if (!area) {
      return 1;
    }
    switch (true) {
      case area > 15000000:
        return 2.5;
      case area > 5000000:
        return 3;
      case area > 2000000:
        return 4;
      case area > 1500000:
        return 5;
      case area > 1000000:
        return 6;
      case area > 750000:
        return 7;
      case area > 250000:
        return 8;
      case area > 150000:
        return 9;
      case area > 100000:
        return 10;
      case area > 50000:
        return 11;
      case area > 25000:
        return 12;
      case area > 15000:
        return 13;
      case area > 10000:
        return 14;
      case area > 7500:
        return 20;
      case area > 5000:
        return 26;
      case area > 2500:
        return 32;
      case area > 1000:
        return 38;
      case area > 750:
        return 50;
      case area > 500:
        return 62;
      case area > 1:
        return MAX_ZOOM;
      default:
        return 1;
    }
  };

  const handleOnChangeAutocomplete = async (event) => {
    const value = event.target.textContent;
    const countryCode = getCountryCode(value, countryCodeMapping);
    const countryArea = await getCountryArea(countryCode);
    setSearchValue(value);
    setZoomLevel(getZoomLevel(countryArea));

    const updateMarkets = (markets, value) => {
      return markets
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((m) => {
          const nameToCheck =
            m.code === 'IND'
              ? m.name.split(' ')[0].toLowerCase()
              : m.name.toLowerCase();
          return nameToCheck.includes(value.toLowerCase());
        });
    };

    if (!value) {
      const allSupportedMarkets = data.supportedMarkets.market_types.flatMap(
        (marketType) => marketType.markets
      );

      setSupportedMarkets(updateMarkets(allSupportedMarkets, ''));
      setUpcomingMarkets(updateMarkets(data.upcomingMarkets.markets, ''));
      setSearchedMarketCode('');
      setCenter([0, 0]);
      setZoomLevel(1);
    } else {
      const allSupportedMarkets = data.supportedMarkets.market_types.flatMap(
        (marketType) => marketType.markets
      );

      setSupportedMarkets(updateMarkets(allSupportedMarkets, value));
      setUpcomingMarkets(updateMarkets(data.upcomingMarkets.markets, value));

      const countryFeature = geoData.features.find(
        (feature) => feature.properties.countryCode === countryCode
      );

      if (countryFeature) {
        const centroid = geoCentroid(countryFeature);
        setCenter([centroid[0], centroid[1] - 15]);
        if (countryCode === 'CAN' || countryCode === 'GRL') {
          setCenter([centroid[0], centroid[1] - 15]);
        } else if (countryCode === 'RUS') {
          setCenter([centroid[0], centroid[1] - 25]);
        } else if (countryCode === 'KIR') {
          setCenter([centroid[0] + 10, centroid[1] + 0.5]);
        } else {
          setCenter(centroid);
        }
      }

      setSearchedMarketCode(countryCode);
    }
  };

  const handleZoomIn = () => {
    if (zoomLevel < MAX_ZOOM) {
      setZoomLevel((prevZoomLevel) => prevZoomLevel + 1);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 1) {
      setZoomLevel((prevZoomLevel) => prevZoomLevel - 1);
    }
  };

  const renderSearchText = () => {
    if (supportedMarkets.length === 0 && upcomingMarkets.length === 0) {
      return (
        <Typography className="label-search">{data.search_text}</Typography>
      );
    }

    const isSupportedMarket = supportedMarkets.length !== 0;
    const marketData = isSupportedMarket
      ? {
          ...data.supportedMarkets,
          markets: data.supportedMarkets.market_types
            .map((marketType) => marketType.markets)
            .flat()
        }
      : data.upcomingMarkets;
    const market = marketData.markets.find((m) => {
      const nameToCompare = m.code === 'IND' ? m.name.split(' ')[0] : m.name;
      return nameToCompare.toLowerCase() === searchValue?.toLowerCase();
    });

    return (
      <Typography className="label-search">
        {searchValue} {marketData.search_text} ({marketData.supporting_text}{' '}
        {market?.tooltip}).
        {isSupportedMarket && (
          <Box alignItems="center" className="container-text" display="flex">
            <CheckCircleIcon
              aria-label={
                langContext.selectedLanguage === 'fr' ? 'succès' : 'success'
              }
              className="icon-success"
              style={{ color: 'green' }}
            />
            <Typography className="label-text">
              {getSubtitleByCountryCode(marketData.market_types, market.code)}
            </Typography>
          </Box>
        )}
      </Typography>
    );
  };

  const renderTooltipTitle = (geo) => {
    const countryCode = geo.properties.countryCode;
    const countryName =
      langContext.selectedLanguage === 'fr'
        ? (countryCodeMapping.find((m) => Object.keys(m)[0] === countryCode) ||
            {})[countryCode]
        : geo.properties.name;

    let market: any = null;
    let supportingText = data.unsupported_text;

    if (supportedMarketCodes.includes(countryCode)) {
      market = supportedMarkets.find((m) => m.code === countryCode);
      supportingText = data.supportedMarkets.supporting_text;
    } else if (upcomingMarketCodes.includes(countryCode)) {
      market = upcomingMarkets.find((m) => m.code === countryCode);
      supportingText = data.upcomingMarkets.supporting_text;
    }

    return (
      <Box textAlign="center">
        <Typography className="bold fs-12">{countryName}</Typography>
        <Typography className="capitalize fs-12">
          {supportingText}
          {market && `: ${market.tooltip}`}
        </Typography>
      </Box>
    );
  };

  const styleFunction = (countryCode: string) => {
    const baseStyles = {
      fillColor: '#C1C5C8',
      strokeColor: '#FFFFFF',
      strokeWidth: '0.4px'
    };

    if (searchedMarketCode === countryCode) {
      baseStyles.fillColor = '#0074AD';
    } else if (!searchedMarketCode) {
      if (supportedMarketCodes.includes(countryCode)) {
        baseStyles.fillColor = '#93B99D';
      } else if (upcomingMarketCodes.includes(countryCode)) {
        baseStyles.fillColor = '#FFDDB5';
      }
    }

    switch (true) {
      case zoomLevel >= 20:
        baseStyles.strokeWidth = '0.05px';
        break;
      case zoomLevel >= 8:
        baseStyles.strokeWidth = '0.15px';
        break;
      case zoomLevel >= 7:
        baseStyles.strokeWidth = '0.20px';
        break;
      case zoomLevel >= 6:
        baseStyles.strokeWidth = '0.25px';
        break;
      case zoomLevel >= 5:
        baseStyles.strokeWidth = '0.30px';
        break;
    }

    if (SMALL_COUNTRY_CODES.includes(countryCode) || countryCode === 'UKR') {
      baseStyles.strokeWidth = '0.01px';
    }

    if (countryCode === 'MAR') {
      baseStyles.strokeWidth = '0px';
    }

    const style = {
      default: {
        fill: baseStyles.fillColor,
        outline: 'none',
        stroke: baseStyles.strokeColor,
        strokeWidth: baseStyles.strokeWidth
      },
      hover: {
        fill: baseStyles.fillColor,
        outline: 'none',
        stroke: baseStyles.strokeColor,
        strokeWidth: baseStyles.strokeWidth
      },
      pressed: {
        fill: baseStyles.fillColor,
        outline: 'none',
        stroke: baseStyles.strokeColor,
        strokeWidth: baseStyles.strokeWidth
      }
    };

    return style;
  };

  useEffect(() => {
    if (countries.length) return;

    const fetchCountries = async () => {
      fetch('https://restcountries.com/v3.1/all?fields=name,cca3')
        .then((response) => response.json())
        .then((data) => {
          const filteredData = data.filter(
            (d) => !ISLANDS_AND_TERRITORIES.includes(d.cca3)
          );
          const palestine = filteredData.find(
            (country) => country.cca3 === 'PSE'
          );
          palestine.name.common = 'West Bank & Gaza';
          setCountries(filteredData.map((d) => d.name.common).sort());
          setCountryCodeMapping(
            filteredData.map((d) => ({ [d.cca3]: d.name.common }))
          );
        });
    };

    const fetchCountriesFR = async () => {
      fetch('https://restcountries.com/v3.1/all?fields=translations,cca3')
        .then((response) => response.json())
        .then((data) => {
          const filteredData = data.filter(
            (d) => !ISLANDS_AND_TERRITORIES.includes(d.cca3)
          );
          const frenchNames = filteredData
            .filter(
              (country) => country.translations && country.translations.fra
            )
            .map((country) =>
              country.translations.fra.common === 'Swaziland'
                ? 'Eswatini'
                : country.translations.fra.common === 'Palestine'
                ? 'Cisjordanie et Gaza'
                : country.translations.fra.common
            );
          setCountries(frenchNames.sort());
          setCountryCodeMapping(
            filteredData.map((d) => ({
              [d.cca3]:
                d.translations.fra.common === 'Swaziland'
                  ? 'Eswatini'
                  : d.translations.fra.common === 'Palestine'
                  ? 'Cisjordanie et Gaza'
                  : d.translations.fra.common
            }))
          );
        });
    };

    const geoJsonData = feature(worldmap, worldmap.objects.countries);
    setGeoData(geoJsonData);

    if (langContext.selectedLanguage === 'fr') {
      fetchCountriesFR();
    } else {
      fetchCountries();
    }
  }, [countries.length, langContext.selectedLanguage]);

  return (
    <Box className="supported-markets">
      <AppAutocomplete
        itemList={countries}
        label={data.search_label}
        value={searchValue}
        width={isMobile ? '100%' : '343px'}
        onChange={handleOnChangeAutocomplete}
      />
      {searchValue !== '' && renderSearchText()}
      <ComposableMap
        height={MAP_HEIGHT}
        projectionConfig={{ scale: 160 }}
        width={MAP_WIDTH}
      >
        <ZoomableGroup
          center={center}
          maxZoom={MAX_ZOOM}
          translateExtent={[
            [0, 0],
            [MAP_WIDTH, MAP_HEIGHT]
          ]}
          zoom={zoomLevel}
        >
          <Geographies geography={worldmap}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <AppTooltip
                  key={geo.rsmKey}
                  followCursor
                  title={renderTooltipTitle(geo)}
                >
                  <g>
                    <Geography
                      geography={geo}
                      style={styleFunction(geo.properties.countryCode)}
                    />
                  </g>
                </AppTooltip>
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <Box className="zoom-controls">
        <Button className="zoom-button" onClick={handleZoomIn}>
          <AddIcon fontSize="small" />
        </Button>
        <Button className="zoom-button" onClick={handleZoomOut}>
          <RemoveIcon fontSize="small" />
        </Button>
      </Box>
      {searchValue === '' && (
        <>
          {data.supportedMarkets.market_types.map((marketType, i) => {
            const key = `marketType-${i}`;
            return (
              <Markets
                key={key}
                markets={marketType.markets.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
                type={MARKET_TYPE.supported}
                tooltipSupportingText={data.supportedMarkets.supporting_text}
                subtitle={marketType.title}
                title={i === 0 ? data.supportedMarkets.title : undefined}
              />
            );
          })}
          <Markets
            markets={upcomingMarkets}
            type={MARKET_TYPE.upcoming}
            tooltipSupportingText={data.upcomingMarkets.supporting_text}
            title={data.upcomingMarkets.title}
          />
        </>
      )}
    </Box>
  );
};

export default PointOfSaleCoverage;
