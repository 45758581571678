export const validateInput = (curr, isFrench = false) => {

  //Note validate and display error is 2 separate functions b/c w/ button disabled only want to check, not show error
  let returnVal: any = false;
  let msg: string = "";
  if(Array.isArray(curr["content"])) {
    if(curr["content"].length === 0) {
      return {error: true, msg: isFrench ? "Ce champ est obligatoire" : "This field is required"};
    }else{
      return {error: false, msg: ""};
    }
  }
  let content = curr["content"]?.trim();
  if (curr['validate'] === 'text') {
    if(curr["content"].length === 0) {
      return {error: true, msg: isFrench ? "Ce champ est obligatoire" : "This field is required"};
    }
  } else if (curr['validate'] === 'iata') {
    if(!/^[0-9]{8}$/.test(curr["content"])) {
      return {error: true, msg: isFrench ? "Le numéro IATA doit être composé de 8 chiffres" : "The IATA number should consist of 8-digits"};
    }
  }
//Un utilisateur est déjà associé à cette adresse électronique.
//Veuillez entrer une adresse électronique valide (les adresses personnelles ne sont pas autorisées).
//Veuillez saisir un numéro de téléphone valide.
  if (curr["content"]?.length && curr["validate"]) {
    if (curr["validate"] === "email") {
      let re = /\S+@\S+\.\S+/;
      returnVal = !re.test(content);

      const blacklist = [
        "@gmail.",
        "@yahoo.",
        "@hotmail.",
        "@aol.",
        "@msn.",
        "@outlook.",
        "@mail.",
        "@protonmail.",
        "@zoho.",
        "@icloud.",
        "@gmx.",
        "@yandex.",
        "@fastmail.",
        "@tutanota.",
        "@hushmail.",
        "@runbox.",
        "@inbox.",
        "@zimbra.",
        "@sapo.pt",
        "@walla.",
      ];

      blacklist.forEach((b) => {
        if (content.includes(b)) {
          returnVal = true;
        }
      });
      if (returnVal) {
        msg = isFrench ? "Veuillez entrer un email valide (emails personnels non autorisés)" : "Please enter a valid email (personal emails not allowed)";
      }
    } else if (curr["validate"] === "phone") {
      let re = /^[0-9+(][0-9 \-().]{7,14}$/;
      //Allows 7 - 32 numbers and + . () - and spaces only
      returnVal = !re.test(content);
      if (returnVal) {
        msg = isFrench ? "Veuillez entrer un numéro de téléphone valide" :"Please enter a valid phone number";
      }
    } else if (curr["validate"] === "url") {
      let re =
        /^[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
      returnVal = !re.test(content);
      if (returnVal) {
        msg = isFrench ? "Veuillez entrer une URL valide" : "Please enter a valid url";
      }
    } else if (curr["validate"] === "alpha") {
      let re = /^[a-zA-Z.,\- ]*$/;
      returnVal = !re.test(content);
      if (returnVal) {
        msg = isFrench ? "Ce champ ne peut contenir que des lettres" : `This field can only contain letters`;
      }
    } else if (curr["validate"] === "alphanumeric") {
      let re = /^[a-zA-Z0-9,. ]*$/;
      returnVal = !re.test(content);
      if (returnVal) {
        msg = isFrench ? "Ce champ ne peut contenir que des lettres et des chiffres" :`This field can only contain letters and numbers`;
      }
    } else if (curr["validate"] === "numeric") {
      let re = /^[0-9., ]*$/;
      returnVal = !re.test(content);
      if (returnVal) {
        msg = isFrench ? "Ce champ ne peut contenir que des chiffres" :`This field can only contain numbers`;
      }
    } else if (curr["validate"] === "numericpercent") {
      let re = /^[0-9%:,.]*$/;
      returnVal = !re.test(content);
      if (returnVal) {
        msg = isFrench ? "Ce champ ne peut contenir que des chiffres, des virgules et les caractères % et :" :`This field can only contain numbers, commas and the characters % and : `;
      }
    } 
  }
  return { error: returnVal, msg: msg };
};
