import './ConfirmationModal.scss';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ComputerImage from "../../assets/images/ComputerImage.png";
import ConfirmationIcon from "../../assets/images/ConfirmationIcon.png";
import NextIcon from "../../assets/images/NextIcon.png"; 



const ConfirmationModal = ({showModal, handleCloseConfirmation, data}) => {
 
  // const handleOpen = () => setOpen(true);
 // const handleClose = () => setOpen(false);

 const handleClose = () => handleCloseConfirmation();
console.log(data)
  return (
   data &&  <div> 
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='confirmationModal'
      >
        <Box className='confirmationModalContainer' >
          <Box className="confirmationModalImage">
            <img
              src={ComputerImage}
              alt="Support"
            />
          </Box>
          <Box className="confirmationModalContent">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className='submitionDetailContainer'>
                <img
                  className='confirmationIcon'
                  src={ConfirmationIcon}
                  alt="Confirmation Icon"
                />
                <h1 className='confirmationHeading'>{data.confirmation.header}</h1>
              </div>
              <p className='confirmationContent'>{data.confirmation.subheader}</p>
            </Typography>
            <hr className='divider'></hr>
            <div id="modal-modal-description">
              <div className='nextStepContainer'>
                <img
                  className='nextStepIcon'
                  src={NextIcon}
                  alt="Next Icon"
                />
                <h1 className='nextStepHeading'>{data.confirmation.nextStepsHeader}</h1>
              </div>
              <p className='nextStepContent'>{data.confirmation.nextStepsText}</p>
            </div>
          </Box>
          <Button onClick={handleClose} className='confirmButton'>{data.confirmation.buttonText}</Button>
        </Box>
      </Modal>
    </div>
  );
}

export default ConfirmationModal;
