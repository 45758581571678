import { Box } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ConnectionOptionsSideBanner from "../ConnectionOptionsSideBanner/ConnectionOptionsSideBanner";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";
import "./directIntegrationTab.scss";
import { LangContext } from "../../pages/App/App";

const DirectIntegrationTab = ({jsonData}) => {
  const navigate = useNavigate();
  const langContext = useContext(LangContext);
  const handleAnchorClick = useHandleAnchorClick();

  useEffect(()=>{
  let button = document.querySelector("#gettingStartedbtn");
  button?.addEventListener("click",()=>window.location.href=`/${langContext.selectedLanguage}/api/gettingstarted/gettingstarted`)
  return ()=>{
    button?.removeEventListener("click",()=>navigate(`/${langContext.selectedLanguage}/api/gettingstarted/gettingstarted`));
  }
  // eslint-disable-next-line
  },[])

  return (
    <Box className="directIntgContainer">
      <Box  className="directIntgContent">
        <Box dangerouslySetInnerHTML={{ __html: jsonData["direct-integration"]}} onClick={handleAnchorClick}/>
      </Box>
      <Box>
        <ConnectionOptionsSideBanner data={jsonData["direct-integration-banner"]}/>
      </Box>
    </Box>
  );
};

export default DirectIntegrationTab;
