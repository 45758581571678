import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./EditNotifications.scss";
import SPForm from "../../Form/SPForm";
import editNotificationsForm from "../../Form/json/editNotificationsFormFields.json";
import { useSellerProfile } from "../../../../pages/SellerProfile/SellerProfile";
import SPFormButton from "../../Form/SPFormButton";
import {
  doFormErrorMsgs,
  getUserInfoById,
  isEmpty,
} from "../../../../pages/SellerProfile/APIs/Helpers";
import GetLabels from "../../../../pages/SellerProfile/GetLabels";
import { SpApiEditNotifs } from "../../../../pages/SellerProfile/APIs/NotificationsAPI";

export const EditNotifications = ({ email, curr_user_id, hasMandatory }) => {
  const {
   ...context
  } = useSellerProfile() as any;

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const formData = getUserInfoById(context.notificationRecipients, curr_user_id);

  useEffect(() => {
    const notification_type = context.isFrench
      ? "notification_type_fr"
      : "notification_type_en";
      context.notificationTypes.forEach((notif, i) => {
      editNotificationsForm.notifications.options[notif.notification_type_id][
        "label"
      ] = notif[notification_type] || "";
      i === context.notificationTypes.length - 1 && context.setFormLoading(false);
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isFrench]);

  const submitFormFields = async (formName) => {
    setSubmitting(true);
    const getNotificationUpdates: string[] = [];
    const getCheckedNotifs = context.formUpdates[formName]?.notifications?.options;
    Object.keys(getCheckedNotifs).forEach((notif) => {
      getCheckedNotifs[notif]["checked"] &&
        getNotificationUpdates.push(getCheckedNotifs[notif].id);
    });
    await SpApiEditNotifs(
      curr_user_id,
      getNotificationUpdates,
      context
    )
      .then((resp) => {
        setSubmitting(false);
        if (resp?.status === 200) {
       
          context.setNotificationRecipients(resp.data);
          const currUser = resp.data.find((u) => u.user_id === curr_user_id);
          const stillHasNotifs = currUser && currUser.notifications.length > 0;
          const useSuccessMsg = stillHasNotifs
            ? `spPageText.${formName}Success`
            : `spPageText.${formName}RemovedSuccess`;
          const successMsg = GetLabels(useSuccessMsg, context.spPageText);

          context.setModalType("");
          context.setShowSuccessPopUp(successMsg);
        } else {
   
          doFormErrorMsgs(resp, context.setErrorMsg, context.spPageText);
        }
      })
      .catch((error) => {
       
        doFormErrorMsgs(error, context.setErrorMsg, context.spPageText);
        console.log("Error updating user notificaitons", error);
      });
  };
  const disableForm = formData?.notificationsMandatory;
  return !isEmpty(context.notificationTypes) && !loading ? (
    <Box className="editUserCardPopupContainer">
      <Box className="editUserCardPopupBody">
        <Typography className="emailDescription">
          {context.spPageText.notifSentTo} <span className="emailAddress">{email}</span>
        </Typography>
        {hasMandatory && (
          <Typography sx={{ marginTop: "10px" }}>
            {context.spPageText.mandatoryNotif}
          </Typography>
        )}
        <SPForm
          cols="single"
          compName="editUserCardPopupForm"
          formFields={editNotificationsForm}
          user_id={curr_user_id}
          formData={formData}
          formName="editNotifications"
          key="editNotificationsForm"
          userList={context.notificationRecipients}
          disableForm={disableForm}
        />
        {!context.formLoading && (
          <SPFormButton
            label={context.spPageText.saveUpdates}
            compName="updateUserSettings"
            formName="editNotifications"
            handleClick={() => submitFormFields("editNotifications")}
            showLoading={submitting}
          />
        )}
      </Box>
    </Box>
  ) : (
    <></>
  );
};
