import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import "./AppTabs.scss";
import "../../pages/Api/Api.scss"; 
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material";

import { LangContext } from "../../pages/App/App";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const theme = createTheme({
  palette: {
    secondary: {
      main: "#005078",
    },
  },
});

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  
  return (
     <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
           {children} 
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  content,
  handleClick,
  currLinks,
  tabs,
  getLabel,
}) {
  const langContext = useContext(LangContext);
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    currLinks[2] = tabs[newValue];
    setValue(newValue);
    handleClick(currLinks);
  };
  useEffect(() => {
    if (currLinks.length > 2) {
      //deal with tab changes from side nav
      handleTabChange(null, tabs.indexOf(currLinks[2]));
    } else if (value !== 0) {
      //reset tabs on page change
      handleTabChange(null, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLinks]);

  return (
    <ThemeProvider theme={theme}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: '40px', }} id="tabBox">
       
        <div className="apiTabsBG"></div>
     
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label={langContext.selectedLanguage === "fr" ? "exemple d'onglets de base" : "basic tabs example"}
            indicatorColor="secondary"
            textColor="secondary"
        
            component="div"
            sx={{width: '100%'}}
          >
            {[0, 1, 2, 3, 4].map((x) => {
              const label = getLabel(tabs[x]);
              return (
                <Tab
                  label={label}
                  {...a11yProps(x)}
                  key={`label${x}`}
                  className="apiTabLabel"
                />
              );
            })}
          </Tabs>
        </Box>
        {[0, 1, 2, 3, 4].map((x) => <div key={`$value}-${x}`}  className="tabContent"><TabPanel value={value} index={x}>
        {content}
        </TabPanel></div>)} 
     
    </ThemeProvider>
  );
}
