import './AppRoadMap.scss';
import { LangContext } from '../../pages/App/App';
import { useContext, useEffect } from 'react';
import Status from '../../assets/images/Shape.png';
import useHandleAnchorClick from '../../hooks/useHandleAnchorClick';

const quartersRef: any[] = [];

const AppRoadMap = ({ data }) => {
  const langContext = useContext(LangContext);
  const ariaLabel =
    langContext.selectedLanguage === 'fr' ? `Déployé` : `Released`;
  const handleAnchorClick = useHandleAnchorClick();

  // to get current quarter of year
  let today = new Date();
  const currQuarter = Math.floor((today.getMonth() + 3) / 3);

  // scrolling the current quarter into view
  useEffect(() => {
    if (quartersRef.length >= currQuarter) {
      const el = quartersRef[quartersRef.length - 1].getBoundingClientRect();
      const mobile = el.width > 300 ? el.top - 250 : el.top - 300;

      window.screen.width > 900
        ? window.scrollBy(0, el.top - 300)
        : window.scrollBy(0, mobile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currQuarter, quartersRef]);

  const quarters: any = [];
  data?.forEach(
    (t) => !quarters.includes(t['quarter']) && quarters.push(t.quarter)
  );
  let i = 0;

  const updateQuarterRefs = (el) => {
    if (quartersRef.length === quarters.length - 1) {
      quartersRef.push(el);
    } else {
      quartersRef.push(el);
    }
  };
  return (
    <div>
      {data?.map((item) => {
        i++;
        const detailsLink =
          item.releaseNotesLink &&
          item.releaseNotesLink
            .toLowerCase()
            .replaceAll('–', '')
            .replaceAll(' ', '-')
            .replaceAll(')', '')
            .replaceAll('(', '')
            .replaceAll('/', '');
        return (
          <div key={`Q${item.quarter}-${item.year}-R${i}`}>
            {(item.quarter.includes('Q') ||
              item.quarter.includes('T') ||
              item.quarter.includes('H') ||
              item.quarter.includes('S')) && (
              <div
                className={`roadMapContainer ${item.quarter} ${item.year}`}
                ref={(el) => item.roadmapId && updateQuarterRefs(el)}
              >
                {item.roadmapId && (
                  <div
                    className={`year ${
                      item.roadmapId.toString() !== '1' && 'sectionSpacer'
                    } ${item.quarter}`}
                    aria-label={`you are currently in ${item.quarter}`}
                  >
                    {item.quarter}
                    <span aria-hidden="true">-</span>
                    {item.year}
                  </div>
                )}
                <div className={`roadMapBody ${item.quarter}`}>
                  <span className={`circle ${item.quarter}`}>
                    <span className={`innerCirlce ${item.quarter}`}></span>
                  </span>
                  <div className="content">
                    <div className="bookingStatus quarters">
                      <div className="status">
                        {item?.checkbox_image === 'Yes' && (
                          <img
                            src={Status}
                            alt="release notes icon"
                            aria-label={ariaLabel}
                            className="statusIcon"
                          ></img>
                        )}
                        <h2 className="title Q1">
                          {' '}
                          <span>{item.paymentType}</span>{' '}
                        </h2>
                      </div>
                      <div
                        className={`option Q1 ${
                          item.bookingType ===
                            'PRIME BOOKING and POST-BOOKING' && 'bookingBtn'
                        }`}
                      >
                        {item.bookingType}
                      </div>
                    </div>
                    {item?.releaseNotesStatus === 'True' && (
                      <a
                        href={`/${langContext.selectedLanguage}/api/releasenotes?${detailsLink}`}
                        rel="noopner"
                        aria-label="click on link to open latest release notes"
                        className="link"
                      >
                        {langContext.selectedLanguage === 'fr'
                          ? 'Note de mise à jour'
                          : 'Release Note'}
                      </a>
                    )}
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: item?.paymentDescription
                          ? item.paymentDescription
                          : ''
                      }}
                      onClick={handleAnchorClick}
                    ></div>
                  </div>
                </div>
              </div>
            )}

            {item.quarter === 'Upcoming Enhancements' && (
              <div className="roadMapContainer upcomingEnhancements">
                {item.quarter === 'Upcoming Enhancements' && item.roadmapId && (
                  <div
                    className="year upcomingEnhancements sectionSpacer"
                    aria-label="you are currently in upcomingEnhancements"
                  >
                    {langContext.selectedLanguage === 'fr'
                      ? 'À venir'
                      : 'Upcoming'}{' '}
                  </div>
                )}
                <div className="roadMapBody upcomingEnhancements">
                  <span className="circle upcomingEnhancements">
                    <span className="innerCirlce upcomingEnhancements"></span>
                  </span>
                  <div className="content">
                    <div className="bookingStatus upcomingEnhancements">
                      <div className="status upcomingEnhancements">
                        <h2 className="title upcomingEnhancements">
                          {' '}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.paymentType
                            }}
                          ></div>{' '}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default AppRoadMap;
