import "./BenefitsAdditionalContent.scss";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";
const BenefitsAdditionalContent = ({data}) => {
	const handleAnchorClick = useHandleAnchorClick();
	return (
		<section className="additional-content" aria-labelledby="additional-content-id">
			<h2 className="benefits-additn-content-title" id="additional-content-id">{data.title}</h2>
			<div className="additional-content-inner">
				{data.items.map((content,i) => {
					return (
						<article className="additional-content-item" key={`ben2-${i}`}>
							<img src={process.env.PUBLIC_URL + content.img} alt={content.alt} aria-hidden={true} />
							<div className="additional-content-text">
								<span className="additional-content-title">
									{content.title}
								</span>
								{content.subTitle && (
									<span className="additional-content-subTitle">
										{content.subTitle}
									</span>
								)}
								{content.caption && (
									<span className="additional-content-caption" dangerouslySetInnerHTML={{ __html: content.caption }} onClick={handleAnchorClick}>
									</span>
								)}
							</div>
						</article>
					);
				})}
			</div>
		</section>
	);
};

export default BenefitsAdditionalContent;
