import { useContext } from "react";
import { LangContext } from "../../pages/App/App";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import "./Home.scss"; 
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import desktopViewTable from "../../components/TableTemplates/DesktopViewTable";
import mobileViewTable from "../../components/TableTemplates/MobileViewTable";
import AppVideo from "../../components/AppVideo/AppVideo";
import { useNavigate } from "react-router-dom";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";
const parser = new DOMParser();


const Home = ({ data, logoHeight }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const content = data && data["ndc-program.home"];
  const navigate = useNavigate();
  const handleAnchorClick = useHandleAnchorClick();
  const langContext = useContext(LangContext);
  const getTableContent = (table) => {
    if (isMobile) {
      //check for colspan, in which case replace with equiv <td> for each as required
      const tableHTML = parser.parseFromString(table, "text/html");

      let tdList = tableHTML.querySelectorAll("td");
      for (let i = 0; i < tdList.length; i++) {
        let item = tdList[i];
        if (item.colSpan && item.colSpan === 3) {
          //colspan = 3 means regular colspan
          let tdString = "";
          for (let i = 1; i <= 3; i++) {
            tdString = `${tdString}<td>${item.innerHTML}</td>`;
          }
          table = table.replaceAll(
            `<td colspan='3'>${item.innerHTML}</td>`,
            tdString
          );
        }
        //now convert all subsections of tables into their own tables
      }

      return mobileViewTable(table, "NDC Home Table", handleAnchorClick);
    } else {
      return desktopViewTable(table, "ndcHomeTable", "NDC Home Table");
    }
  };
 const goToConnTab = (tab) => {
   window.scrollTo({ top: 0, behavior: "smooth" });
 
    navigate(`/${langContext.selectedLanguage}/ndc-program/connection-options/${tab}`);
 };

  return (
    content && (
      <div style={{marginTop:`${logoHeight}px`}}>
        <div className={`homeBGimg ${isMobile && "homeMobile"}`}></div>
        <main className={`homeBody ${isMobile && "homeMobile"}`}>
       
          <section className={`headerBox ${isMobile && "homeMobile"}`} aria-labelledby="homePageHeader">
            <h1 id="homePageHeader" aria-hidden="true">{content.headerBox.heading}</h1>
            <p>{content.headerBox.subHeading}</p>
            <Button variant="contained" onClick={()=> navigate(`/${langContext.selectedLanguage}${content.headerBox.button.link}`)} onKeyDown={()=> navigate(`/${langContext.selectedLanguage}${content.headerBox.button.link}`)}>
              
              {content.headerBox.button.text}
            </Button>
          </section>
          <div className="headerSection" aria-labelledby="distributionCapabilties">
          <div className={`homeBGcolor ${isMobile && "homeMobile"}`}></div>
          <h2
            className="mainHeader"
            dangerouslySetInnerHTML={{ __html: content.mainHeader }}
            id="distributionCapabilties"
          ></h2>
          <div className="mainSubHeader">{content.mainSubHead}</div>
          <div className="homeInfoBoxes">
            {Object.keys(content.infoBoxes).map((c, i) => (
              <div
                className={`homeInfoBox ${isMobile && "homeInfoBoxMobile"} capabilitiesBox`}
                key={`info-${c}-${i}`}
                onClick={()=>{ window.scrollTo({ top: 0, behavior: "smooth" }); navigate(`/${langContext.selectedLanguage}${content.infoBoxes[c].link}`)}}
                onKeyDown={()=> {window.scrollTo({ top: 0, behavior: "smooth" }); navigate(`/${langContext.selectedLanguage}${content.infoBoxes[c].link}`)}}
                role="button"
              >
               
               <img
                      src={`/images/discover/${content.infoBoxes[c].image}.png`}
                      alt={content.infoBoxes[c]["header"]}
                      className="infoBoxImage"
                    />
              
                <div className="infoBoxHeader">
         
                  {content.infoBoxes[c].header}
                </div>
                <ChevronRightIcon
                  fontSize="medium"
                  sx={{
                    color: "black",
                    display: "inline-block",
                    position: "absolute",
                    top: "35px",
                    right: "10px"
                  }}
                />
                <ul>
                  {content.infoBoxes[c].content.map((cItem, i) => (
                    <li key={`${c}-${i}`}>{cItem}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          </div>
          <div className="keyFeatures" aria-labelledby="keyFeaturesID">
            <h2
              className="mainHeader"
              dangerouslySetInnerHTML={{ __html: content.keyFeatures.header }}
              id="keyFeaturesID"
            ></h2>
            <div
              className="mainSubHeader"
              dangerouslySetInnerHTML={{ __html: content.keyFeatures.bodyText }}
            ></div>
            {getTableContent(content.keyFeatures.table)}
          </div>
          <div className="homeVideoBox" aria-labelledby="newProgramSection">
            <h2
              className="mainHeader"
              dangerouslySetInnerHTML={{ __html: content.video.header }}
              id="newProgramSection"
            ></h2>
            <AppVideo src={content.video.src} />
          </div>
          <div className="exploreOptions" aria-labelledby="exploreOptions">
            <h2
              className="mainHeader"
              dangerouslySetInnerHTML={{ __html: content.explore.header }}
              id="exploreOptions"
            ></h2>
            <div
              className="mainSubHeader"
              dangerouslySetInnerHTML={{ __html: content.explore.subHeading }}
            ></div>
            <div className="exploreLinks">
              {Object.keys(content.explore.links).map((c, i) => (
                <div className="exploreLink" key={`exp-${c}`} 
                onClick={()=>goToConnTab(content.explore.links[c].link)}  
                onKeyDown={()=>goToConnTab(content.explore.links[c].link)} role="button">
                  <div className="exploreIconBG">
                    <img
                      src={`/images/discover/${content.explore.links[c]["image"]}.png`}
                      alt={content.explore.links[c]["text"]}
                      className="exploreIcons"
                    />
                  </div>
                  <div className={`exploreText ${isMobile && "exploreTextMobile"}`}>
                    {content.explore.links[c]["text"]}
                  </div>
                </div>
              ))}
            </div>
            <div className="exploreCards">
            {Object.keys(content.explore.infoBoxes).map((ib, i) => (
              <div
                className={`homeInfoBox ${isMobile && "homeInfoBoxMobile"}`}
                key={`expCard-${ib}`}
              >
                <img
                  src={`/images/discover/${content.explore.infoBoxes[ib]["image"]}.jpeg`}
                  alt={content.explore.infoBoxes[ib]["header"]}
                  className="exploreInfoBoxImg"
                />
                <div className="infoBoxHeader">
                  {content.explore.infoBoxes[ib]["header"]}
                </div> 
                {content.explore.infoBoxes[ib]["subheader"] &&  <div className="infoBoxSubhead">
                  {content.explore.infoBoxes[ib]["subheader"]}
                </div> }
                <div className="infoBoxExploreIcons">
                  {content.explore.infoBoxes[ib]["links"].map((ibl, i) => {
                    return (
                      <div className="exploreIconBG" key={`infoExpIcon-${ibl}`}  onClick={()=>goToConnTab(content.explore.links[ibl].link)}  onKeyDown={()=>goToConnTab(content.explore.links[ibl].link)} role="button"><img
                          src={`/images/discover/${content.explore.links[ibl]["image"]}.png`}
                          alt={content.explore.links[ibl]["text"]}
                          className="exploreIcons"
                        /> 
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}</div>
          </div>
          <section className="newsBox" aria-labelledby="newBoxSection">
            <h2
              className="mainHeader"
              dangerouslySetInnerHTML={{ __html: content.news.header }}
              id="newBoxSection"
            ></h2>
            <img
              src={`/images/discover/${content.news.image}.jpg`}
              alt="NDC News"
              className="newsImage"
            />
            <div className={`newsReleases ${isMobile && "newsReleasesMobile"}`}>
             <ul>
              {Object.keys(content.news.releases).map((r, i) => {
                return (
                  <li className="newsRelease" key={`news-${r}`}>
                    <p>{content.news.releases[r]["header"]}</p>
                    <p><a href={content.news.releases[r]["link"]} target="_blank" rel="noreferrer">{content.news.releases[r]["text"]}</a></p>
                  </li>
                );
              })}
              </ul>
            </div>
          </section>
        </main>
      </div>
    )
  );
};

export default Home;
