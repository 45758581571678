import * as React from 'react';
import { Box } from "@mui/material"
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Fade from "@mui/material/Fade";
import "./ConfirmationAlert.scss";

export function ConfirmationCloseAlert({
    message,
    showAlert,
    closeAlert
}) {
    return (
        showAlert && <Box className='alertContainer'>
            <Alert icon={<CheckIcon fontSize="inherit" />}  className='alertMessageContainer' onClose={closeAlert}>
               <div className='alertMessage'>{message}</div>
            </Alert>
        </Box>
    );
}


export const ConfirmationFadeAlert = ({
    message,
    showAlert,
    fadeTimeout = 5000
}) => {
    const [alertVisibility, setAlertVisibility] = React.useState(true);
    return (
        showAlert && <Fade
            in={alertVisibility}
            addEndListener={() => {
                setTimeout(() => {
                    setAlertVisibility(false)
                }, fadeTimeout);
            }}
        >
            <Box className='alertContainer'>
                <Alert icon={<CheckIcon fontSize="inherit" />} className='alertMessageContainer'>
                    <div className='alertMessage'>{message}</div>
                </Alert>
            </Box>
        </Fade>
    )
};
