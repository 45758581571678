import './NdcCapabilities.scss';
import {
  Box,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useContext, useState } from 'react';
import { LangContext } from '../../App/App';
import DirectIntegrationTable from '../../../components/DirectIntegrationTable/DirectIntegrationTable';
import DiscoverHeader from '../../../components/DiscoverHeader/DiscoverHeader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import TechnologyPartnersTable from '../../../components/TechnologyPartnersTable/TechnologyPartnersTable';

const NdcCapabilities = ({ ...props }) => {
  const langContext = useContext(LangContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [activeTabIndex, setActiveTabIndex] = useState<number>(props.showTab);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const header = props.data['capabilities_header'][0];
  const open = Boolean(anchorEl);
  const tabsLabel =
    langContext.selectedLanguage === 'fr'
      ? ['Intégration directe', 'Partenaires technologiques']
      : ['Direct Integration', 'Technology Partners'];

  const urls = ['direct-integration', 'technology-partners'];

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index: number) => {
    setActiveTabIndex(index);
    setAnchorEl(null);
    window.history.replaceState(
      null,
      urls[index],
      `/${langContext.selectedLanguage}/ndc-program/ndc-capabilities/${urls[index]}`
    );
  };

  return (
    <>
      <div className="connection-options-container capabilities-container">
        <DiscoverHeader
          breadcrumb={header.breadcrumb}
          dropdown={true}
          image="ndcCapabilitiesImage.png"
          paragraph={header.subtitle}
          title={header.title}
        />
        <div className="connection-options-body-container">
          {isDesktop ? (
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginX: '76px',
                marginTop: '10px'
              }}
            >
              <Tabs
                aria-label="Connection Options Tabs"
                className="connectionTabs"
                value={activeTabIndex}
              >
                {tabsLabel.map((label, index) => (
                  <Tab
                    key={`${label}-${index}`}
                    label={label}
                    onClick={() => handleMenuItemClick(index)}
                    value={index}
                  />
                ))}
              </Tabs>
            </Box>
          ) : (
            <Box className="selectDropdown">
              <List
                aria-label={
                  langContext.selectedLanguage === 'fr'
                    ? "Réglages de l'appareil"
                    : 'Device settings'
                }
                component="nav"
                sx={{ bgcolor: 'background.paper', margin: '0 auto' }}
              >
                <ListItem
                  aria-controls="lock-menu"
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="listbox"
                  aria-label={tabsLabel[activeTabIndex]}
                  id="lock-button"
                  onClick={handleClickListItem}
                >
                  <ListItemText primary={tabsLabel[activeTabIndex]} />
                  <KeyboardArrowDownIcon />
                </ListItem>
              </List>
              <Menu
                anchorEl={anchorEl}
                id="lock-menu"
                MenuListProps={{
                  'aria-labelledby': 'lock-button',
                  role: 'listbox'
                }}
                onClose={handleClose}
                open={open}
              >
                {tabsLabel.map((option: string, index: number) => (
                  <MenuItem
                    aria-label={`${tabsLabel[activeTabIndex]}-item`}
                    disabled={index === activeTabIndex}
                    key={option}
                    onClick={() => handleMenuItemClick(index)}
                    role="button"
                    selected={index === activeTabIndex}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          {
            [
              <DirectIntegrationTable
                data={props.data['capabilities_direct_integration'][0]}
              />,
              <TechnologyPartnersTable
                data={props.data['capabilities_technology_partner'][0]}
              />
            ][activeTabIndex]
          }
        </div>
      </div>
    </>
  );
};

export default NdcCapabilities;
