export const getFormData = async (fr) => {
  let [pos, posOther] = [[], []];
  let [businessModel, businessModelIDs, customerTypes, customerTypeIDs, apiSchemas] = [[], {}, [], {},[]];

  try {
    const response = await fetch(
      process.env.REACT_APP_SELLER_REGISTRATION_PROFILE_URL,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_SELLER_REGISTRATION_API_KEY,
        },
        redirect: "follow",
      }
    );
    const responseData = await response.json(); 
    responseData?.data?.posType.forEach(({support, pays, country, pos_code}) => {
      const posString = `${fr ? pays : country} ${pos_code}`;
      support === "supported" ? pos.push(posString) : posOther.push(posString);
    });   

    responseData?.data?.businessModel.forEach((bm) =>
      fr
        ? (businessModel.push(bm.business_model_type_fr),
          (businessModelIDs[bm.business_model_type_fr] = bm.business_model_id))
        : (businessModel.push(bm.business_model_type),
          (businessModelIDs[bm.business_model_type] = bm.business_model_id))
    );

    responseData?.data?.customerTypes.forEach((ct) =>
      fr
        ? (customerTypes.push(ct.customer_type_fr),
          (customerTypeIDs[ct.customer_type_fr] = ct.customer_type_id))
        : (customerTypes.push(ct.customer_type),
          (customerTypeIDs[ct.customer_type] = ct.customer_type_id))
    );
    responseData?.data?.apiSchema.forEach((schema) => apiSchemas.push(schema.api_schema_version));

  } catch (err) {
    console.log("get formdata err:",err.message);
  }
  return {pos, posOther, businessModel, businessModelIDs, customerTypes, customerTypeIDs,apiSchemas};
};


const updatePOS = (postData) => {
  const pos = 
        postData["pos_preference"] && postData["pos_preference"].length !== 0 
        ? postData["pos_preference"].map((pos) => pos.split(" ").pop()) 
        : []
  const posOther = 
        postData["pos_preference_other"] && postData["pos_preference_other"].length !== 0 
        ? postData["pos_preference_other"].map((pos) => pos.split(" ").pop()) 
        : []
  const posPreference = pos.length || posOther.length ? [...pos, ...posOther] : ["None"];
  return {
    "pos_preference": posPreference,
  }
}

export const submitFormData = async (postData, businessModelIDs, customerTypeIDs) => { 
  const data = {
      ...postData,
      ...(postData["implementation_start_date"] && { "implementation_start_date" : new Date(postData["implementation_start_date"]).toISOString()}),
      ...(updatePOS(postData)),
      ...(postData["api_schema_version"] && { "api_schema_version" : [postData["api_schema_version"]]}),
      ...(postData["customer_type"] && { "customer_type" : customerTypeIDs[postData["customer_type"]]}),
      ...(postData["business_model_type"] && { "business_model_type" : businessModelIDs[postData["business_model_type"]]}),
      "pos_enabled": "1",
    };

  data["pos_preference_other"] && delete data["pos_preference_other"]; 
  try {
    const response = await fetch(
      process.env.REACT_APP_SELLER_REGISTRATION_URL,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_SELLER_REGISTRATION_API_KEY,
        },
        body: JSON.stringify(data),
        redirect: "follow",
        credentials: "include",
      }
    );
    const responseData = await response.json();
    return responseData?.status || false;
    } catch (err) {
      console.log("submit formdata err:",err.message);
      return false;
  }


}

