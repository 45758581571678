import AppDropDownBar from "../AppDropDownBar/AppDropDownBar";

interface BookingFlowProps {
  htmlStr: string;
  links: string[];
  keySuffix: string;
  handleAnchorClick?: any;
}

const bookingFlowParser = (props: BookingFlowProps) => {
  
          //Special formatting for booking flow - this adds app dropdown bars WITHIN a parent app dropdown bar
          const re = new RegExp("<div><h3>", "g");
          var regex = re,
            result: string[] | null;
          let subAppBars: string[] = [];
          let titles: string[] = [];
          const rmvWSPhtmlStr = props.htmlStr.replace(new RegExp('>[ ]+<', 'g'), '><');//Remove whitespace BETWEEN tags

          let cutString = rmvWSPhtmlStr.replace('<div class="booking_flow">', ""); //clean up at parent <div>
          let currentString = "";
          let title = "";

          const getEndPosition = (string, subString, index) => {
            return string.split(subString, index).join(subString).length;
          };

          while ((result = regex.exec(rmvWSPhtmlStr))) {
            if (result.hasOwnProperty("index")) {
              const endIndex = getEndPosition(cutString, "<div><h3>", 2);
              if (endIndex > 0) {
                currentString = cutString.substring(0, endIndex);
                cutString = cutString.substring(endIndex);
              } else {
                //last one
                currentString = cutString.substring(result["index"]);
                currentString = currentString.substring(
                  0,
                  currentString.length - 1
                ); //clean off trailing closing </div>
              }

              title = currentString.substring(
                currentString.indexOf("<div><h3>"),
                currentString.indexOf("</h3>") + 5
              );
              currentString = currentString.replace(title, "");
              //splice out title
              title = title
                .replace("<div><h3>", "")
                .replace("</h3>", "")
                .replace("</div>", "");

              if (currentString !== "") {
                subAppBars.push(currentString);
                titles.push(title);
              }
            }
          }

          return  <div  className="bookingFlowParent" key={`${props.keySuffix}-par-appbar`}>
              {subAppBars.map((sub, i) => <AppDropDownBar
                    title={`Workflow Scenarios ${titles[i]}`} //'Workflow Scenarios' used to ID special HTML ONLY - I.e. NO translation needed -is removed in AppDropDownBar
                    key={`${props.keySuffix}-${i}-appbar`}
                    keyID={`${props.keySuffix}-${i}`}
                    className={`apiRefPg ${props.links[1]} ${
                      props.links.length > 2 ? props.links[2] : ""
                    }`}
                  >
                    <div
                      key={`${props.keySuffix}-bookingflow-${i}-`}
                      dangerouslySetInnerHTML={{ __html: sub }}
                      onClick={props.handleAnchorClick}
                    ></div>
                  </AppDropDownBar>)}
            </div>
   
};

export default bookingFlowParser;
