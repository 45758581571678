import './DetailsDialog.scss';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Field, Statuses } from '../../../../../models/knownIssues';
import { KeyboardEvent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import StatusBadge from '../../StatusBadge/StatusBadge';

interface Props {
  issueId: string;
  fields: Field[];
  open: boolean;
  status: string;
  statuses: Statuses;
  handleOnClose: () => void;
  handleOnKeyDown: (event: KeyboardEvent<SVGSVGElement>) => void;
}

const DetailsDialog = ({
  issueId,
  fields,
  open,
  status,
  statuses,
  handleOnClose,
  handleOnKeyDown
}: Props) => {
  return (
    <Dialog
      className="details-dialog"
      disableEscapeKeyDown
      onClose={handleOnClose}
      open={open ?? false}
      maxWidth="lg"
    >
      <DialogTitle className="dialog-title">
        <div className="container-title">
          <h1 className="title">{issueId}</h1>
          <StatusBadge status={status} statuses={statuses} />
        </div>
        <CloseIcon
          className="icon-close"
          tabIndex={0}
          onClick={handleOnClose}
          onKeyDown={(e) => handleOnKeyDown(e)}
        />
      </DialogTitle>

      <DialogContent className="dialog-content">
        {fields.map((field, index) => {
          return (
            <div key={index}>
              <b>{field.header}</b>
              <div>{field.value}</div>
            </div>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default DetailsDialog;
