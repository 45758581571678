import './AppFooter.scss';
import { LangContext } from '../../pages/App/App';
import { Link, useLocation } from 'react-router-dom';
import { useAdobeAnalytics } from '../../providers/AdobeAnalytics';
import { useCallback, useContext, useEffect } from 'react';
import { useSkipLink } from '../../providers/SkipLinkProvider';
import acLogo from '../../assets/images/ac_logo-reversed.svg';
import IATALogo from '../../assets/images/IATA-logo.png';
import skytrax from '../../assets/images/ui-skytrax.png';
import skytraxFr from '../../assets/images/ui-skytrax-fr.svg';
import starLogo from '../../assets/images/ac_star.svg';
import starLogoFr from '../../assets/images/ac_star_fr_rgb_hor.svg';
import useWindowSize from '../../assets/customHooks/useWindowSize';

const currentYear = new Date().getFullYear();
const AppFooterBottomText = `© ${currentYear} Air Canada`;
let AppFooterTopData: any = [];
let selectedLang = '';

const AppFooter = ({ data }) => {
  const langContext = useContext(LangContext);
  selectedLang = langContext.selectedLanguage;

  const location = useLocation();
  const { width } = useWindowSize();

  const isSubseller = !location.pathname.toLowerCase().includes('subseller');

  const getFooterData = useCallback(() => {
    if (!data) return;

    const nav = data?.navigation;
    const footerItems = [nav?.filter((x) => x.link.menu_name === 'footer')];

    footerItems[0]?.forEach((itm) => {
      let linksArr: any = [];

      if (itm?.link?.hasOwnProperty('map_to_menu')) {
        //This footer menu is same as what's in mainnav, so map it/use main nav content
        const mappedNav = nav?.filter(
          (x) => x.link.menu_name === itm.link.map_to_menu
        );
        mappedNav.forEach((map) => {
          if (
            map.link?.children_name !== 'support' &&
            map.link?.children_name !== 'api_info'
          ) {
            //Support and API Info from main nav are there own nav columns, so ignore here...
            linksArr[map.link.weight - 1] = {
              title: map.link.title,
              link: map.link.url
            };
          }
        });
      } else {
        //This footer menu is unique (not in main nav as well)
        itm.link.subtree.forEach(
          (map) =>
            (linksArr[map.link.weight - 1] = {
              title: map.link.title,
              link: map.link.url
            })
        );
      }

      AppFooterTopData[itm?.link?.weight - 1] = {
        mainTitle: itm?.link?.title,
        links: linksArr
      };
    });
  }, [data]);

  useEffect(() => {
    getFooterData();
  }, [getFooterData]);

  return (
    <footer className="footer">
      {width! > 940 ? (
        <>
          <FooterLogos width={width} />
          {isSubseller && <FooterLinks width={width} />}
        </>
      ) : (
        <>
          {isSubseller && <FooterLinks width={width} />}
          <FooterLogos width={width} />
        </>
      )}
      <div className="footerBottom">
        {width! < 768 && <div></div>}
        <span>{AppFooterBottomText}</span>
      </div>
    </footer>
  );
};

const FooterLinks = ({ width }) => {
  const { adobeAnalyticsSectionClick, adobeSiteInteraction } =
    useAdobeAnalytics();
  const { setSkipLinks } = useSkipLink() as any;
  const desktop = width > 1200;

  const skipLinkRef = useCallback(
    (node) => {
      if (node !== null) {
        setSkipLinks((prev) => {
          const index = prev.findIndex((item) => item.id === 3);
          return [
            ...prev.slice(0, index),
            { ...prev[index], ref: node },
            ...prev.slice(index + 1)
          ];
        });
      }
    },
    [setSkipLinks]
  );

  return (
    <div className="footerLinkSection">
      <div className="footerLinkSectionInnerContainer">
        {AppFooterTopData.map((data, index) => {
          let linkSectionStyle = {} as React.CSSProperties;
          if (index !== 0) {
            linkSectionStyle['--padding'] = desktop
              ? selectedLang !== 'fr'
                ? '40px'
                : '20px'
              : '0px';
          }
          if (selectedLang === 'fr' && !desktop) {
            linkSectionStyle['minWidth'] =
              width < 768 ? '150px' : width < 1200 ? '220px' : '200px';
          }

          return (
            <div
              key={`${index}-top-heading`}
              className="linkSection"
              style={linkSectionStyle}
            >
              <div className="linkSectionContainer">
                <p
                  style={{
                    color: '#E3E3E3',
                    fontSize: '1.17em',
                    fontWeight: '700'
                  }}
                >
                  {data.mainTitle.toUpperCase()}
                </p>
              </div>
              <ul>
                {data.links.map((link, i) => (
                  <li
                    key={`${i}-bottom-link`}
                    onClick={(e) => {
                      adobeAnalyticsSectionClick('footer nav');
                      (link.title === 'Sandbox' ||
                        link.title === 'Bac à sable') &&
                        adobeSiteInteraction('sandbox-footer');
                    }}
                  >
                    {link.link.charAt(0) !== '/' ? (
                      <a href={link.link} target="_blank" rel="noreferrer">
                        {link.title}
                      </a>
                    ) : (
                      <Link
                        to={`/${selectedLang}${link.link}`}
                        {...(link.title === 'Benefits' && { ref: skipLinkRef })}
                      >
                        {link.title}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FooterLogos = ({ width }) => {
  const dividerStyle = {
    height: '40px',
    borderRight: '1px solid #fff',
    margin: '0 10px'
  };

  return (
    <div className="footerLogos">
      <div className="footerLogoContainer">
        <div className="leftContainer">
          <img
            src={IATALogo}
            alt="IATA"
            aria-label="Air Canada is a member of IATA, the International Air Transport Association"
            style={{ width: '86px' }}
          />
          <img
            src={selectedLang === 'fr' ? skytraxFr : skytrax}
            alt="Skytrax"
            aria-label="Best Airline Staff in Canada and North America 2021 awarded by Skytrax"
            className="skytrax"
          />
          {width > 940 && <span style={dividerStyle}></span>}
          <span className={`text ${selectedLang === 'fr' ? 'fr' : ''}`}>
            {selectedLang === 'fr'
              ? 'Meilleur personnel au sol et à bord au Canada et en Amérique du Nord'
              : 'Best Airline Staff in Canada and North America'}
          </span>
        </div>
        <div className="rightContainer">
          <img
            src={selectedLang === 'fr' ? starLogoFr : starLogo}
            alt="Star Alliance"
            aria-label="Air Canada is a member of Star Alliance"
            className="starLogo "
          />
          {width > 940 && (
            <span style={{ ...dividerStyle, height: '20px' }}></span>
          )}
          <img src={acLogo} alt="Air Canada" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
