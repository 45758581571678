import { useEffect, useState } from 'react';
import { useSellerProfile } from '../SellerProfile';
import { Box, Button, Divider, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SPFormButton from '../../../components/SellerProfile/Form/SPFormButton';
import SPForm from '../../../components/SellerProfile/Form/SPForm';
import { ConfirmationFadeAlert } from '../../../components/SellerProfile/ConfirmationAlert/ConfirmationAlert';
import recipientForm from '../../../components/SellerProfile/Form/json/recipientFormFields.json';
import FilterNotificationRecipients from '../../../components/SellerProfile/FilterNotificationRecipients/FilterNotificationRecipients';
import './NotificationsRecipients.scss';
import {
  SpAPIgetAllNotifs,
  SpAPIgetNotifTypes,
  SpApiAddNotifs
} from '../APIs/NotificationsAPI';

import GetLabels from '../GetLabels';
import { doFormErrorMsgs, getUserEmailList, isEmpty } from '../APIs/Helpers';

function NotificationsRecipients() {
  const [loaded, setLoaded] = useState(false);
  const [emailList, setEmailList] = useState(false);
  const [showAddRecipient, setShowAddRecipient] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [clearForm, setClearForm] = useState({});
  const showAlert = false;

  const dividerStyle = {
    background: '#EFEFEF',
    marginBlock: '12px'
  };
  const { ...context } = useSellerProfile() as any;

  useEffect(() => {
    setClearForm({}); //to force API loaded notification types to update on lang change

    SpAPIgetNotifTypes(context.setNotificationTypes)
      .then((resp) => {
        if (resp.status === 200) {
          const notifTypes = resp.data.notifications;
          const notification_type = context.isFrench
            ? 'notification_type_fr'
            : 'notification_type_en';

          notifTypes.forEach((notif) => {
            recipientForm.notifications.options[notif.notification_type_id][
              'label'
            ] = notif[notification_type];
          });
        } else {
          doFormErrorMsgs(
            'error getting notification types',
            context.setErrorMsg,
            context.spPageText
          );
          console.log('error getting notification types');
        }
        return resp;
      })
      .then(() => {
        SpAPIgetAllNotifs(context)
          .then(() => {
            const emailList: any = getUserEmailList(context.users);
            setEmailList(emailList);

            setClearForm({ ...recipientForm });
            emailList.length && setLoaded(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
          })
          .catch((error) => {
            doFormErrorMsgs(error, context.setErrorMsg, context.spPageText);
            console.log('notif recip err', error);
          });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isFrench]);
  const submitFormFields = async (formName) => {
    setLoading(true);
    const successMsg = GetLabels(
      `spPageText.${formName}Success`,
      context.spPageText
    );

    const newNotifInfo = context.formUpdates[formName]?.inputFields?.options;
    const newNotifRecip = {
      user_email: newNotifInfo?.user_email?.content,
      user_first_name: newNotifInfo?.user_first_name?.content,
      user_last_name: newNotifInfo?.user_last_name?.content
    };
    const getCheckedNotifs =
      context.formUpdates[formName]?.notifications?.options;
    const notifArray: any = [];
    Object.keys(getCheckedNotifs).forEach((notif) => {
      getCheckedNotifs[notif].checked && notifArray.push(notif);
    });
    newNotifRecip['notifications'] = notifArray;
    await SpApiAddNotifs(newNotifRecip, context)
      .then(() => {
        setLoading(false);
        context.setModalType('');
        context.setShowSuccessPopUp(successMsg);
        if (
          !context.onboardingStatus.notification &&
          context.onboardingStatus?.completed === 2
        ) {
          context.setOnboardCompletePopupDetails({
            show: true,
            navigate: false,
            close: true
          });
        }
        setShowAddRecipient(false);
      })
      .catch((error) => {
        setLoading(false);
        const errMsg = error.err_code
          ? `error${error.err_code}`
          : 'errorGeneral';
        window.scrollTo({ top: 200, behavior: 'smooth' });
        context.setErrorMsg(context.spPageText[errMsg]);
        console.log(
          'Error adding new Notif Recip:',
          error?.response?.data?.err_code
        );
      });
  };
  return loaded && !isEmpty(clearForm) ? (
    <Box className="addNewRecipientBox">
      <Divider sx={dividerStyle} />
      <Button
        variant="text"
        onClick={() => {
          setShowAddRecipient(!showAddRecipient);
          context.setFormLoading(false);
        }}
        className="addNewRecipientBtn"
      >
        <Typography>{context.spPageText.addNewRecipient}</Typography>
        {showAddRecipient ? <ExpandLessIcon /> : <ExpandMoreIcon />}{' '}
      </Button>
      <Box>
        {showAddRecipient && (
          <form>
            <SPForm
              cols="single"
              compName="addRecipent"
              formFields={clearForm}
              formName="addNotificationRecip"
              key="addNotificationRecip"
              emailList={emailList}
            />
            <div className="addRecipientButton">
              {!context.formLoading && (
                <SPFormButton
                  label={context.spPageText.addRecip}
                  compName="addRecipent"
                  formName="addNotificationRecip"
                  handleClick={() => submitFormFields('addNotificationRecip')}
                  showLoading={loading}
                />
              )}
            </div>
          </form>
        )}
      </Box>
      <Divider sx={dividerStyle} />

      <FilterNotificationRecipients />

      {showAlert && (
        <ConfirmationFadeAlert
          message={context.spPageText.newRecipAdded}
          showAlert
        />
      )}
    </Box>
  ) : (
    <></>
  );
}

export default NotificationsRecipients;
