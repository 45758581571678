import './AppLoader.scss';
import spinner from '../../assets/images/spinner.svg';

const AppLoader = ({ showText = true }) => {


  return (
    <div className="c-app-loader">
      <div className="c-loader">
        <img src={spinner} alt="loading" className="loader__spinner-img" />
        {showText && <h1>Loading ...</h1>}
      </div>
    </div>
  );
}

export default AppLoader;
