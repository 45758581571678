import { Box, List, ListItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useRef } from "react";
import { LangContext } from "../../pages/App/App";
import { useAdobeAnalytics } from "../../providers/AdobeAnalytics";
//Hook imports
import useOnClickOutside from "../../hooks/useOnClickOutside";
const DeskTopDropDownMenu = ({
  dropDownMenu,
  coords,
  setDropDown,
  activeDropDown,
  keyboard,
  setKeyboard,
  tabRefs,
  categories,
}) => {
  //Refs
  const dropDownRef = useRef<HTMLDivElement>(null);
  const menuItemsRef = useRef<any[]>([]);
  const langContext = useContext(LangContext);
  const { adobeSiteInteraction, adobeAnalyticsSectionClick } =
    useAdobeAnalytics();

  useEffect(() => {
    if (keyboard) {
      dropDownRef?.current?.focus();
      setKeyboard(false);
    }
  }, [keyboard, setKeyboard]);

  useOnClickOutside(dropDownRef, () => {
    setDropDown(false);
  });
  let prevCategory = "";

  let colPos = 0;
  let rowPos = 1;

  const doClickActions = (label) => {
    adobeAnalyticsSectionClick("header nav");
    const isSandbox = label === "Sandbox" || label === "Bac à sable";
    isSandbox && adobeSiteInteraction("sandbox-header");
  };
const doKeyPressActions = (e,path,index)=>{
   
          adobeAnalyticsSectionClick("header nav");
         
          if (e.shiftKey && e.key === "Tab") {
            if (index === 0) {
              e.preventDefault();
              tabRefs.current[activeDropDown].focus();
            }
          }
          if (e.key === "Tab") {
            if (index === dropDownMenu?.menu?.length - 1) {
              if (tabRefs.current.length - 1 !== activeDropDown) {
                e.preventDefault();
                tabRefs.current[activeDropDown + 1].focus();
              } else {
                setDropDown(false);
              }
            }
          }
          if (e.key === "ArrowDown" || e.key === "ArrowRight") {
            e.preventDefault();
            index !== dropDownMenu?.menu?.length - 1 &&
              menuItemsRef.current[index + 1].focus();
          } else if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
            e.preventDefault();
            index !== 0 && menuItemsRef.current[index - 1].focus();
          }
         
}
  const getLink = (path, label,index) =>   path.charAt(0) !== "/" ? (
      <a
        className="dtMenuItem"
        href={path}
        target="_blank"
        rel="noreferrer"
      >
        <Typography>{label}</Typography>
      </a>
    ) : (
      <Link
      className="dtMenuItem"
        to={`/${langContext.selectedLanguage}${path}`}
        onClick={() => doClickActions(label)}
        onKeyDown={(e) => doKeyPressActions(e,path,index)}
      >
        <Typography>{label}</Typography>
      </Link>
    ); 

  return (
    <Box
      className="dropDownMenu"
      sx={{
        top: coords.y + 60,
        left: coords.x,
      }}
      ref={dropDownRef}
      tabIndex={0}
      onMouseLeave={() => setDropDown(false)}
    >
      <span
        className="arrow"
        style={{ "--left": `${coords.width / 2 - 5}px` } as any}
      ></span>
      <List
        role="menu"
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setDropDown(false);
            tabRefs.current[activeDropDown].focus();
          }
        }}
        sx={{ display: "grid" }}
      >
        {dropDownMenu?.menu?.map((subMenu, index) => {
          const lastIndex = dropDownMenu?.menu.length;
          const currCategory =
            categories[subMenu.parentMenuName][subMenu.category];
          const showSubHeader = prevCategory !== currCategory;
          //get grid positions for horiz nav layout
          colPos = showSubHeader ? colPos + 1 : colPos;
          rowPos = !showSubHeader ? rowPos + 1 : 2; //start at row 2 b/c header is always row 1

          prevCategory = currCategory;

          return (
            <React.Fragment key={`box2-${index}`}>
              {showSubHeader && (
                <Box
                  className="mainNavSubHead"
                  sx={{
                    gridRowStart: 1,
                    gridRowEnd: 1,
                    gridColumnStart: colPos,
                    gridColumnEnd: colPos,
                  }}
                >
                  {currCategory}
                </Box>
              )}
              <ListItem
                className="menuItem"
                aria-label={subMenu.label}
                ref={(el) => (menuItemsRef.current[index] = el!)}
                style={{
                  ...(index !== lastIndex - 1 && {
                    borderBottom: "1px solid #DDECF5",
                  }),
                }}
                sx={{
                  gridRowStart: rowPos,
                  gridRowEnd: rowPos,
                  gridColumnStart: colPos,
                  gridColumnEnd: colPos,
                }}
                // 
              >
                {/* //DT menu items */}
                {getLink(subMenu.path, subMenu.label,index)}
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
};
export default DeskTopDropDownMenu;
