import { Box } from "@mui/material";
// import { useSellerProfile } from "../../../pages/SellerProfile/SellerProfile";
import checkIcon from "../../../assets/images/checkIcon.png";
import goldCheckIcon from "../../../assets/images/goldCheck.png";
import prodCheckIcon from "../../../assets/images/prodCheck.png";
import editIcon from "../../../assets/images/editIcon.png";
import PendingIcon from "@mui/icons-material/Pending";
import notificationsGrayIcon from "../../../assets/images/notificationsGrayIcon.png";
import "./SPUserCard.scss";

import { useSellerProfile } from "../../../pages/SellerProfile/SellerProfile";

// import { useSellerProfile } from "../../../pages/SellerProfile/SellerProfile";
import { RemoveUserConfirmation } from "./RemoveUserConfirmation/RemoveUserConfirmation";
import { EditUserProfile } from "./EditUserProfile/EditUserProfile";
import { EditNotifications } from "./EditNotifications/EditNotifications";
import { EditApiCredentials } from "./EditApiCredentials/EditApiCredentials";
import { useEffect, useRef, useState } from "react";
import {
  SpAPIgetIndUser,
  SpApiDeleteUser,
} from "../../../pages/SellerProfile/APIs/SellerProfileAPI";
import { SpAPIgetIndNotifs } from "../../../pages/SellerProfile/APIs/NotificationsAPI";
import { doFormErrorMsgs } from "../../../pages/SellerProfile/APIs/Helpers";
// import { SpAPIgetAllApiCreds } from "../../../pages/SellerProfile/APIs/ApiCredsAPI";

function SPUserCard({ cardType = "", filter = ["", "All"]  }) {
  const {...context
  } = useSellerProfile() as any;
  const popupRef = useRef(null) as any;
  const [curr_user_id, setCurr_user_id] = useState("");

  const [selectedItem, setSelectedItem] = useState(false);
  const [removingUser, setRemovingUser] = useState(false);
  const [modalDataLoaded, setModalDataLoaded] = useState(false);
  const [removeError, setRemoveError] = useState("");

  let userList = cardType === "notifications" ? context.notificationRecipients : context.users;
  const onCloseRemoveConfirmation = async (isConfirmed) => {
    if (isConfirmed) {
      setRemovingUser(true);
      await SpApiDeleteUser(curr_user_id,context)
        .then((resp) => {
          setRemovingUser(false);
          if (resp.status === 200) {
            context.setShowSuccessPopUp(context.spPageText.removeUserSuccess);
            context.setModalType("");
          } else {
            setRemoveError(context.spPageText.errorRemoveUser);
          }
        })
        .catch((error) => {
          setRemovingUser(false);
          console.log("ERROR in remove User", error);
          setRemoveError(context.spPageText.errorRemoveUser);
        });
    } else {
      setRemoveError("");
      context.setModalType("EditUserProfile");
    }
  };

  const onRemoveUserProfile = () => {
    context.setModalType("RemoveUserConfirmation");
    window.scrollBy({
      top: -300,
      left: 0,
      behavior: "smooth",
    });
  };
  const onClickEditButton = async (index, curr_user_id) => {
    setSelectedItem(index);
    setCurr_user_id(curr_user_id);
    setModalDataLoaded(false);
    switch (cardType) {
      case "notifications":
        await SpAPIgetIndNotifs(
          curr_user_id,
          context
        )
          .then((resp: any) => {
            context.setModalType("EditNotifications");
            // setNotificationRecipients(resp);
            setModalDataLoaded(true);
          })
          .catch((error) => {
            doFormErrorMsgs(error, context.setErrorMsg, context.spPageText);
          });

        break;
      case "access":
        await SpAPIgetIndUser(context,curr_user_id)
          .then((resp: any) => {
            context.setModalType("EditUserProfile");
            context.setUsers([...resp]);
            setModalDataLoaded(true);
          })
          .catch((error) => {
            doFormErrorMsgs(error, context.setErrorMsg, context.spPageText);
          });

        break;
      case "env":
        context.setModalType("EditApiCredentials");
        setModalDataLoaded(true);
        break;
      default:
        context.setModalType("");
        setRemoveError("");
    }
  };
  useEffect(() => {
    context.setFilterUsers("");
    popupRef.current &&
      popupRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closePopup = () => {
    context.setModalType("");
    setRemoveError("");
  };

  const checkShowUserType = (user) => {
    if (cardType === "access") {
      return ["USR001", "USR002"].includes(user.role_id);
    }
    if (cardType === "notifications") {
      return user.role === "Contact" || user.role === "Admin";
    }
    if (cardType === "env") {
      return user.role_id === "USR001";
    }
  };
  let countShow = 0;
  return (
    <div>
      {userList.map((user, i) => {
        const showNotifRecip = () => {
          return filter[1] === "All"
            ? true
            : user[filter[0]]?.includes(filter[1]);
        };
        const fullName = `${user.user_first_name} ${user.user_last_name}}`;
        const showUser = fullName
          .toLowerCase()
          .includes(context.filterUsers.toLowerCase());
          showUser && countShow++;
  
        return (
          ((showUser && cardType === "access") ||
            (showUser && showNotifRecip() && cardType === "notifications") ||
            cardType === "env") &&
          checkShowUserType(user) && (
            // Default card structure
            <div
              className="userCardAndPopupContainer"
              key={`${user.lastName}-${i}`}
            >
              <div
                className={`popupClose  ${
                  context.modalType !== "" && "showPopupClose"
                }`}
                onClick={() => closePopup()}
              ></div>
              <Box className="addEditUserCardContainer" key={`${user}-${i}`}>
                <div className="cardDescription">
                  <h2 className="userName">
                    {user.user_first_name} {user.user_last_name}
                  </h2>
                  <p
                    className={
                      user.role_id?.includes("USR001") ? "admin" : "user"
                    }
                  >
                    {user.role_id
                      ? context.spPageText[user.role_id]
                      : context.spPageText[user.role.toLowerCase()]}
                  </p>
                </div>
                {/* USER LIST PAGE  */}
                <div className="cardBody">
                  {user.additional_access_id && cardType === "access" && (
                    <div className="userAccesses">
                      {user.additional_access_id.map((service) => (
                        <div
                          className="accessDescription"
                          key={`${service}-${i}`}
                        >
                          {user.user_approved !== 0 && (
                            <>
                              {" "}
                              <img
                                className="checkIcon"
                                src={checkIcon}
                                alt="Check Icon"
                              />{" "}
                              <p className="service">{context.spPageText[service]}</p>
                            </>
                          )}
                        </div>
                      ))}
                      {user.additional_access_id.length > 0 &&
                        user.user_approved === 0 && (
                          <>
                            <PendingIcon
                              sx={{
                                marginRight: "8px",
                                fontSize: "24px",
                                opacity: "0.8",
                              }}
                            />
                            {context.spPageText.pending}
                          </>
                        )}
                    </div>
                  )}

                  {/* API CREDENTIALS PAGE*/}
                  {cardType === "env" && (
                    <div className="userAccesses">
                      {user.env?.map((service, ii) => (
                        <div
                          className="accessDescription"
                          key={`${service}-${ii}`}
                          id={`${service}`}
                        >
                          {service === "Prod" ? (
                            <img
                              className="checkIcon"
                              src={prodCheckIcon}
                              alt="Check Icon"
                            />
                          ) : service === "Gold" ? (
                            <img
                              className="checkIcon"
                              src={goldCheckIcon}
                              alt="Check Icon"
                            />
                          ) : (
                            <img
                              className="checkIcon"
                              src={checkIcon}
                              alt="Check Icon"
                            />
                          )}
                          <p className="service">{service}</p>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* NOTIFICATIONS PAGE (Note "count" contains an array of  notification IDs*/}
                  {user.count && cardType === "notifications" && context.isAdmin && (
                    <div className="userNotification">
                      <div className="notification">
                        <img
                          className="notificationIcon"
                          src={notificationsGrayIcon}
                          alt="Notification Icon"
                        />
                        <p className="notificationNum">
                          {user.count.split(",").length || 0}/3
                        </p>
                      </div>
                    </div>
                  )}
                  {/* SHOW EDIT BUTTONS ON ALL CARD TYPES when applicable */}
                  {((cardType === "access" && context.isAdmin) ||
                    cardType !== "access") && (
                    <div
                      className="editUser"
                      onClick={() => onClickEditButton(i, user.user_id)}
                      onKeyDown={(e) =>
                        e.key === "Enter" && onClickEditButton(i, user.user_id)
                      }
                      tabIndex={0}
                    >
                      <img
                        className="editIcon"
                        src={editIcon}
                        alt="Edit Icon"
                      />
                    </div>
                  )}
                </div>
              </Box>
              {/* SHOW Edit popups */}
              {modalDataLoaded &&
                context.modalType === "EditNotifications" &&
                selectedItem === i && (
                  <div className="popupContainer" ref={popupRef}>
                    <EditNotifications
                      email={user.user_email}
                      curr_user_id={curr_user_id}
                      hasMandatory={user.notificationsMandatory === 1}
                    />
                  </div>
                )}

              {modalDataLoaded &&
                context.modalType === "EditUserProfile" &&
                selectedItem === i && (
                  <div className="popupContainer" ref={popupRef}>
                    <EditUserProfile
                      onRemove={onRemoveUserProfile}
                      curr_user_id={curr_user_id}
                    />
                  </div>
                )}

              {modalDataLoaded &&
                context.modalType === "EditApiCredentials" &&
                selectedItem === i && (
                  <div className="popupContainer" ref={popupRef}>
                    <EditApiCredentials
                      email={user.user_email}
                      curr_user_id={curr_user_id}
                    />
                  </div>
                )}

              {modalDataLoaded &&
                context.modalType === "RemoveUserConfirmation" &&
                selectedItem === i && (
                  <div className="popupContainer">
                    <RemoveUserConfirmation
                      onClose={onCloseRemoveConfirmation}
                      order={selectedItem}
                      error={removeError}
                      removeSubmission={removingUser}
                    />
                  </div>
                )}
            </div>
          )
        );
      })}
       {countShow === 0 && <div className="noUsersFound" dangerouslySetInnerHTML={{__html:context.spPageText[`noMatchesFound${cardType}`]}}></div>}
      <div className={`forcePageScroll ${context.modalType}`}></div>
    </div>
  );
}

export default SPUserCard;
