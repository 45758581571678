import './SPLandingPage.scss';
import { Box } from '@mui/material';
import { LangContext } from '../../App/App';
import { useContext } from 'react';
import { useSellerProfile } from '../SellerProfile';
import GroupIcon from '../../../assets/images/GroupIcon.png';
import keyIcon from '../../../assets/images/keyIcon.png';
import NotificationsIcon from '../../../assets/images/NotificationsIcon.png';
import settingsIcon from '../../../assets/images/settingsIcon.png';
import SPOnboarding from '../../../components/SellerProfile/OnboardingBox/SPOnboardingBox';
import SPProfileBox from '../../../components/SellerProfile/ProfileBox/SPProfileBox';
import SPStatusBox from '../../../components/SellerProfile/StatusBox/SPStatusBox';
import SPUserCard from '../../../components/SellerProfile/ActivityCard/SPActivityCard';

function SPLandingPage() {
  const { ...context } = useSellerProfile() as any;
  window.scrollTo({ top: 0, behavior: 'smooth' });
  const langContext = useContext(LangContext);
  const isEnglish = langContext.selectedLanguage === 'en';

  const adminActivityCardData = [
    {
      id: 1,
      title: 'activityCard',
      name: context.spPageText.users,
      description: isEnglish ? 'Manage your users' : 'Gérez vos utilisateurs',
      image: GroupIcon,
      lable: 'Group Icon',
      link: '/seller-profile/users'
    },
    {
      id: 2,
      title: 'activityCard',
      name: context.spPageText.apiContact,
      description: isEnglish
        ? 'Manage your API credential stakeholders'
        : 'Gérez les parties prenantes de vos identifiants API',
      image: keyIcon,
      lable: 'Key Icon',
      link: '/seller-profile/api-credentials'
    },
    {
      id: 3,
      title: 'activityCard',
      name: context.spPageText.notifications,
      description: isEnglish
        ? 'Manage your distribution list'
        : 'Gérez votre liste de diffusion',
      lable: 'Notifications Icon',
      image: NotificationsIcon,
      link: '/seller-profile/notifications'
    },
    {
      id: 4,
      title: 'activityCard',
      name: context.spPageText.mySettings,
      description: isEnglish
        ? 'Manage your personnal account settings'
        : 'Gérez les paramètres de votre compte personnel',
      lable: 'Settings Icon',
      image: settingsIcon,
      link: '/seller-profile/user-settings'
    }
  ];

  const userActivityCardData = [adminActivityCardData[3]];

  //const onboardingCompleted = onboardingStatus?.completed === 3
  const userDataForUserType = context.isAdmin
    ? adminActivityCardData
    : userActivityCardData;

  return (
    <main className="spLandingPageContainer">
      <h3>
        {context.isFrench
          ? `${context.spPageText.profile} ${context.coName} `
          : `${context.coName} ${context.spPageText.profile}`}
      </h3>
      {context.isAdmin && <SPOnboarding />}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <SPStatusBox />
        <Box className="profileContainer">
          <SPProfileBox />
          <SPUserCard
            className="userCardsContianer"
            activityCardData={userDataForUserType}
          />
          <Box className="quickLinks">
            {context.spPageText.quickLinks}{' '}
            <a
              href={process.env.REACT_APP_JIRA_URL}
              target="_blank"
              rel="noreferrer"
            >
              Jira{' '}
              <img
                src="/images/icons/openInNewTabIcon.png"
                alt="Open  in new tab"
              ></img>
            </a>
            <a
              href={process.env.REACT_APP_SANDBOX_URL}
              rel="noreferrer"
              target="_blank"
            >
              Sandbox{' '}
              <img
                src="/images/icons/openInNewTabIcon.png"
                alt="Open  in new tab"
              ></img>
            </a>
          </Box>
        </Box>
      </Box>
    </main>
  );
}

export default SPLandingPage;
