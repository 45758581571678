import "./404.scss";

const FourOfour = () => {

  return (
    <>
      <div className="main">
        The page you are looking for cannot be found. Please check the link and
        try again.
      </div>
      <div className="main">
        <i>La page que vous recherchez est introuvable. Veuillez vérifier le lien
        et réessayer.</i>
      </div>
    </>
  );
};

export default FourOfour;
