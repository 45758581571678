import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import ndcTechnicalImage from "../../assets/images/ndcTechnicalImg.jpeg"; 
import acConnexImage from "../../assets/images/acConnexImg.jpeg";
import ndcReservationImage from "../../assets/images/ndcReservation.jpeg";

import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";

import "./SupportCards.scss";

const SupportCards = ({ data }) => {

  const theme = useTheme();
  const handleAnchorClick = useHandleAnchorClick();
  let isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  // image array for the support cards
  let imageArray = [ndcTechnicalImage, acConnexImage, ndcReservationImage];


  const parser = new DOMParser();
  const doc: any = parser.parseFromString(data, "text/html");
  const supportCardArr = Array.from(doc?.querySelectorAll("div"));

  
    

  return (
    <Box className="supportCards">
      {supportCardArr.map((x: any, i) => {
       const content = x.innerHTML.replaceAll('href="http', "target='_blank' href=\"http");
       return <Paper key={`support-card-${i}`}>
          <img
            src={imageArray[i]}
            width="100%"
            height={isDesktop ? "224px" : "110px"}
            alt={`${x.querySelector("h3").innerHTML}`}
          />
            <Box
              className="cardDetails"
              dangerouslySetInnerHTML={{
                __html: content
              }}
              onClick={handleAnchorClick}
            />
           
        </Paper>
})}
    </Box>
  );
};

export default SupportCards;
