import { Box, Button } from "@mui/material";

import "./CompanyProfile.scss";
import userForm from "../../../components/SellerProfile/Form/json/profileFormFields.json";
import SPForm from "../../../components/SellerProfile/Form/SPForm";
import SPFormButton from "../../../components/SellerProfile/Form/SPFormButton";
import { useSellerProfile } from "../SellerProfile";
import { useContext, useEffect, useState } from "react";
import {
  SpAPIUpdateCompanyProfile,
  SpAPIgetCompanyProfile,
} from "../APIs/CompanyProfileAPI";
import { isEmpty, showAPIerror } from "../APIs/Helpers";
import GetLabels from "../GetLabels";
import { useNavigate } from "react-router-dom";
import { LangContext } from "../../App/App";

function CompanyProfile() {
  const { ...context } = useSellerProfile() as any;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let formatFields: any = {};
  const [formathomePgProfileFields, setFormathomePgProfileFields] = useState(
    {}
  );
  const langContext = useContext(LangContext);
  const successMsg = GetLabels(
    `spPageText.editCompanyProfileSuccess`,
    context.spPageText
  );

  const getDefaultSettings = (defaultData) => {
    formatFields = {
      ...userForm,
    };
    //split up supported and non-suppported pos types
    const supportedPos: string[] = [];
    const nonSupportedPos: string[] = [];
    defaultData?.pos_type?.forEach((item) => {
      const country = context.isFrench ? item.pays : item.country;
      item.support === "supported" && supportedPos.push(country);
      item.support === "non-supported" && nonSupportedPos.push(country);
    });

    //Format data recieved from API to match form fields
    const parentFields = ["api_schema", "customer_types", "business_model"];
    const fieldsToFormat = [
      "api_schema_version",
      "customer_type",
      "business_model_type",
      "pos_type",
      "otherPOS",
    ];
    //traverse through form fields object until find the field we want to format
    const traverse = (obj, field, i) => {
      Object.keys(obj).forEach((key) => {
        if (
          typeof obj[key] === "object" &&
          obj[key] !== null &&
          key !== field
        ) {
          traverse(obj[key], field, i);
        } else if (obj.hasOwnProperty(field)) {
          let labelArray: string[] = [];
          if (field === "pos_type" || field === "otherPOS") {
            labelArray = field === "pos_type" ? supportedPos : nonSupportedPos;
          } else {
            const translatedFieldName =
              context.isFrench &&
              (field === "customer_type" || field === "business_model_type")
                ? `${field}_fr`
                : field;
            labelArray = defaultData[parentFields[i]].map(
              (item) => item[translatedFieldName]
            );
          }

          obj[key]["options"] = labelArray;
        }
      });
    };
    fieldsToFormat.forEach((field, i) => {
      traverse(formatFields, field, i);
    });

    setFormathomePgProfileFields(formatFields);
  };

  useEffect(() => {
    SpAPIgetCompanyProfile(context)
      .then((res) => {
        if (res?.defaultData) {
          getDefaultSettings(res?.defaultData);
        } else {
          showAPIerror(res, context, "SpAPIgetAllUsers");
        }
      })
      .catch((res) => showAPIerror(res, context, "SpAPIgetAllUsers"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isFrench]);

  const submitFormFields = async () => {
    setLoading(true);
    await SpAPIUpdateCompanyProfile(context)
      .then(() => {
        setLoading(false);
        context.setShowSuccessPopUp(successMsg);
      })
      .catch((error) => {
        setLoading(false);
        console.log("coprof error", error);
      });
  };

  return !isEmpty(context.compProfile) &&
    !isEmpty(formathomePgProfileFields) ? (
    <Box className="spUpdateUserProfile">
      <SPForm
        formFields={formathomePgProfileFields}
        formData={context.compProfile}
        formName="editCompanyProfile"
      />
      <div className="spUpdateButtons">
        <Button
          variant="text"
          onClick={() => {
            navigate(`/${langContext.selectedLanguage}/seller-profile`);
          }}
          className="cancelBtn"
        >
          {context.spPageText.cancelUpdates}
        </Button>
        <SPFormButton
          handleClick={() => submitFormFields()}
          label={context.spPageText.saveUpdates}
          compName="companyProfile"
          formName="editCompanyProfile"
          key="companyProfile"
          showLoading={loading}
        />
      </div>
    </Box>
  ) : (
    <></>
  );
}

export default CompanyProfile;
