
import SPAddEditUser from "../../../components/SellerProfile/AddUser/SPAddUser";
import "./UserList.scss";
import SPUserCard from "../../../components/SellerProfile/UserCard/SPUserCard";
import UserSearchBar from "../../../components/SellerProfile/UserSearchBar/UserSearchBar";
import { useSellerProfile } from "../SellerProfile";
 

function UserList() {
  const {  ...context } = useSellerProfile() as any; 
 
  return (
    <main className="spUpdateUserList">
 
  {  context.isAdmin &&  <section aria-label="add or edit user">
        <SPAddEditUser />
      </section>}
      <section aria-label="search user">
        <UserSearchBar  />
        <SPUserCard  cardType="access" />
      </section>
    </main> 
  );
}

export default UserList;
