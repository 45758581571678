export const APPROVESELLER = "Approve Seller";
export const REJECTSELLER = "Reject Seller";
export const PLACEONHOLD = "Place On Hold";
export const RESENDACTIVATIONDETAIL = "Resend Activation Details";  
export const DISCOVERY = 'Discovery'

export const STATUSES = {
  "Pending Approval": [APPROVESELLER, REJECTSELLER, PLACEONHOLD],
  "Pending Activation": [RESENDACTIVATIONDETAIL, REJECTSELLER, PLACEONHOLD],
  "Expired Activation": [RESENDACTIVATIONDETAIL, REJECTSELLER, PLACEONHOLD],
  "Rejected Sellers": [APPROVESELLER, PLACEONHOLD],
  "On Hold": [APPROVESELLER, REJECTSELLER],
  "Discovery": [DISCOVERY]
};

export const STATUSMAPPING = {
  "Pending Activation": "Pending activation",
  "Expired Activation": "Expired activation",
  "Pending Approval": "Pending approval",
  "Rejected Sellers": "Rejected",
  "On Hold": "On hold",
  "Discovery": "Active (Discovery)"
};

export const REGISTEREDSELLERMAPPING = ["RALL", "PR", "OH", "APA", "AE", "RJ"];

export const ACTIVESELLERMAPPING = ["AALL", "OB", "BI", "CE"];

export const REQUIREDROLES = ["BU-1", "BU-2", "AC-Admin"];


