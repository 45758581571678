import { useContext } from "react";
import { Box, Divider } from "@mui/material";
import "./realTimeStatusGraph.scss";

import BarGraph from "../BarGraph/BarGraph";
import useFetchStatusAPI from "../../hooks/useFetchStatusAPI";
import AppLoader from "../AppLoader/AppLoader";

import { LangContext } from "../../pages/App/App";

const RealTimeStatusGraph = ({ statusType, services }) => {
	const  langContext  = useContext(LangContext);
	const isFrench = langContext.selectedLanguage !== "en"
	const api = statusType === "Gold" ? "REACT_APP_STATUS_API_GOLD" : "REACT_APP_STATUS_API_PROD";
	const urlType = api + "_HOURLY_URL";

	const options = {
		headers: { "x-api-key": process.env[api] },
	};

	const url = process.env[urlType];

	const { data, loading } = useFetchStatusAPI(url, options, statusType, services);

	const statusObject = {
		UP: isFrench ? "Fonctionnel" : "Operational",
		Orange: "Degraded",
		DOWN: isFrench ? "Service détérioré" : "Degraded",
	};

	return (
		<>
			{!loading ? (
				<Box className="realTimeStatuspage">
					<Box className="cardsContainer">
						{data &&
							data.map((status, i) => {
								const obj = status["data"][0];

								const label =
									status["API"] === "OrderReshopShop" ? "OrderReshop" : status["API"];

								const render =
									(label as string) !== "OrderReshopPrice" &&
									(label as string) !== "OrderChangeFQTV";

								return (
									render && (
										<article
											className="realTimeGraphCard"
											key={i}
											tabIndex={0}
											aria-label={`${label} current status: ${
												statusObject[status["LiveStatus"]]
											}`}
										>
											<div className="cardHeader">
												<img
													src={
														services[
															label === "OrderReshop" ? "OrderReshopShop" : label
														]
													}
													alt={`${label} service icon`}
													style={{ width: "40px" }}
												/>
												<h3 className="serviceTitle">{label.toString() === "OrderRetrieve" ? "OrderView" : label}</h3>
												<span className={`currentStatus status${status["LiveStatus"]}`}>
													{statusObject[status["LiveStatus"]]}
												</span>
											</div>
											<Divider />

											<BarGraph
												service={status["API"]}
												data={obj}
												renderTooltipContent={RealTimeDataTooltip}
												type={"hourly"}
											/>
										</article>
									)
								);
							})}
					</Box>
				</Box>
			) : (
				<AppLoader />
			)}
		</>
	);
};

const RealTimeDataTooltip = (data: any) => {
	const [date, time] = data.date?.split(" ");
	const [hours, minutes] = time?.split(":");
	const finaleDate = `${date} ${hours}:${minutes} - ${hours}:59`;
	return (
		<>
			<div className="tooltip-heading heading-padding">{data.statusTitle}</div>
			<hr />
			<div className="tooltip-eta">
				<span>{finaleDate}</span>
			</div>
		</>
	);
};

export default RealTimeStatusGraph;
