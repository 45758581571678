import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SPForm from "../../Form/SPForm";
import editAPIcredentials from "../../Form/json/editApiCredentialsFormFields.json";
import { useSellerProfile } from "../../../../pages/SellerProfile/SellerProfile";
import SPFormButton from "../../Form/SPFormButton";
import {
  doFormErrorMsgs,
  getUserInfoById,
  isEmpty,
  showAPIerror,
} from "../../../../pages/SellerProfile/APIs/Helpers";
import GetLabels from "../../../../pages/SellerProfile/GetLabels";
import {
  SpAPIgetApiCredTypes,
  SpApiUpdateAPIcreds,
} from "../../../../pages/SellerProfile/APIs/ApiCredsAPI";


export const EditApiCredentials = ({ curr_user_id, email }) => {

  const { ...context } = useSellerProfile() as any;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const updateFormFields = (credTypes) => {
      credTypes.forEach((type) => {
        const credential_type_id = context.isFrench
          ? "credentials_type_fr"
          : "credentials_type_en";
        editAPIcredentials.env.options[type.credentials_type_en] = {
          label: type[credential_type_id],
          type: "checkbox",
          value: type.credential_type_id,
        };
      });
      setLoaded(true)
    };

    if (isEmpty(context.apiCredTypes)) {
      SpAPIgetApiCredTypes(context).then((res) => {    
          context.setApiCredTypes(res.data);  
          updateFormFields(res.data);
      }).catch((res) => showAPIerror(res, context,"SpAPIgetApiCredTypes"));
    } else {
      updateFormFields(context.apiCredTypes);
    }
    context.setFormLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formData = getUserInfoById(context.users, curr_user_id);
  const [loading, setLoading] = useState(false);

  const submitFormFields = async (formName: string) => {
    setLoading(true);
    await SpApiUpdateAPIcreds({ ...context },curr_user_id)
      .then((resp) => {
        setLoading(false);
        const useSuccessMsg =
          resp === "removed"
            ? `spPageText.${formName}RemovedSuccess`
            : `spPageText.${formName}Success`;
        const successMsg = GetLabels(useSuccessMsg, context.spPageText);

        context.setModalType("");
        context.setShowSuccessPopUp(successMsg);
      })
      .catch((error) => {
        setLoading(false);
        doFormErrorMsgs(
          error,
          context.setErrorMsg,
          context.spPageText
        );
        console.log("Error updating user api creds", error);
      });
  };
  return (
  loaded ?  <Box className="editUserCardPopupContainer">
      <Box className="editUserCardPopupBody">
        <Typography className="emailDescription">
          {context.spPageText.email}:{" "}
          <span className="emailAddress">{email}</span>
        </Typography>
        <SPForm
          cols="single"
          compName="editUserCardPopupForm"
          formFields={editAPIcredentials}
          user_id={curr_user_id}
          formData={formData}
          formName="editAPIcreds"
          key="editAPIcredentials"
        />
        {!context.formLoading && (
          <SPFormButton
            label={context.spPageText.saveUpdates}
            compName="updateUserSettings"
            formName="editAPIcreds"
            handleClick={() => submitFormFields("editAPIcreds")}
            showLoading={loading}
          />
        )}
      </Box>
    </Box>:<></>
  );
};
