import { Box, Button } from "@mui/material";
import { useState } from "react";

const AppPwdLogin = ({ setUATpwd, displayError }) => {
  
  const [pwd, setPwd] = useState("");

  const handleChange = (event) => {
    setPwd(event.target.value);
  };

  const handleUpdateUATpwd = () => {
    setUATpwd(pwd);
  };
  return (
    <>
      {" "}
      <Box sx={{ width: "100%", margin: "200px auto", textAlign: "center" }}>
        <input
          type="text"
          maxLength={20}
          placeholder="UAT Password"
          onChange={handleChange}
          value={pwd}
        />
        <Button
          onClick={handleUpdateUATpwd}
          variant="contained"
          sx={{ margin: "0 20px" }}
        >
          Submit
        </Button>
        {displayError === 'incorrect' && (
          <>
            <br />
            <br />
            Incorrect password. Please try again.
          </>
        )}
      </Box>
    </>
  );
};

export default AppPwdLogin;
