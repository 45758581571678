import React, { useContext, useState } from "react";
import "./FaqSection.scss";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  useTheme,
  // InputBase,
  // Toolbar
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import mobileFaqImage from "../../../assets/images/faqImage.jpeg";
import mobileViewTable from "../../../components/TableTemplates/MobileViewTable";
import ComponentTabs from "../../../components/ComponentTabs/ComponentTabs";
import { LangContext } from "../../App/App";
// import SearchIcon from "@mui/icons-material/Search";
import useHandleAnchorClick from "../../../hooks/useHandleAnchorClick";

const FaqSection = ({ data, showTab }) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(showTab);
  const theme = useTheme();
  const langContext = useContext(LangContext);
  let isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const handleAnchorClick = useHandleAnchorClick();

  const tabsLabel =
    langContext.selectedLanguage === "fr"
      ? ['Programme et offre commerciale','Soutien technique et intégration']
      : ['Program & Commercial','Implementation & Technical'];

     const faqLabels=['accordion-body.program',"accordion-body.Technical & Implementation"] 

  if (data) {
    const faq = data?.["accordion.faq"];
    const faqList = data?.["accordion.faq"][faqLabels[activeTabIndex]];
    const parser = new DOMParser();
    const linkContent: any = parser.parseFromString(
      faq.link.title,
      "text/html"
    );
    const linkText = linkContent.body.innerHTML.replaceAll(
      'href="http',
      "target='_blank' href=\"http"
    );

    return (
      <Box className="faqPageContainer">
        {isDesktop ? (
          <Box className="faqImage faqDT">
            <div className="faqDTHdr">
              <h1 className="faqTitleDT">{faq.title}</h1>
              <div className="faqSubTitleDT">{faq.subtitle}</div>
            </div>
          </Box>
        ) : (
          <Box className="faqImage">
            <img
              src={mobileFaqImage}
              alt="Frequently Asked Questions"
            />
          </Box>
        )}
        <ComponentTabs 
        activeIndex={activeTabIndex} 
        setActiveIndex={setActiveTabIndex} 
        tabsLabel={tabsLabel}/>
        <Box className="faqContentContainer">
          {!isDesktop && (
            <>
              <Typography className="faqContainerHeader">
                {faq.title}
              </Typography>
              <Typography className="faqContainerBody">
                {faq.subtitleMobile}
              </Typography>
            </>
          )}{" "}
          {/* <Toolbar className="faqSearchContainer">
          <InputBase className="faqSearchBox" placeholder="Search FAQ" />
          <SearchIcon />
        </Toolbar> */}
           {faqList.map((faqSubList,i)=>{
            return(
              <Box key={`faq-${i}`}>
              <h2>{faqSubList["header"]}</h2>
            { faqSubList["FAQ"].map((item, key) => {
                let containsTable = false;
                let preTable = "";
                let postTable = "";
                let getMobileTable = <></>;
                if (item.content.includes("<table") && !isDesktop) { 
              
                  containsTable = true; 
                  preTable = item.content.slice(0, item.content.indexOf("<table"));
                  const getTableString =item.content.slice(item.content.indexOf("<table"),item.content.indexOf("/table>")+7).replaceAll("<br/>"," ");
                  getMobileTable = mobileViewTable(
                    getTableString,
                    "FAQ Table",
                    handleAnchorClick
                  );
                  postTable = item.content.slice(
                    item.content.indexOf("</table") + 8
                  );  
                }

                return (
                  <Accordion key={key}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel1a-content-${faqSubList["header"].replaceAll(" ","")}-${key}`}
                      id={`panel1a-header-${faqSubList["header"].replaceAll(" ","")}-${key}`}
                    >
                      <Typography>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {!containsTable ? (
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                          onClick={handleAnchorClick}
                          className="accordianSummary"
                        />
                      ) : (
                        <>  <Typography
                        dangerouslySetInnerHTML={{
                          __html: preTable,
                        }}
                        onClick={handleAnchorClick}
                        className="accordianSummary"
                      />
                            {getMobileTable}
                            <Typography
                          dangerouslySetInnerHTML={{
                            __html: postTable,
                          }}
                          onClick={handleAnchorClick}
                          className="accordianSummary"
                        />
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
              </Box>
              )
           })
           }
        </Box>
        <Box className="technicalSupportBanner">
          <div
            dangerouslySetInnerHTML={{
              __html: linkText,
            }}
            onClick={handleAnchorClick}
          />
        </Box>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default FaqSection;
