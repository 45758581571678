const GetLabels = (label: string, spPageText) => {
  
  if (label?.includes("spPageText.")) {
    const fieldName = label?.replace("spPageText.", "");

    if (spPageText.hasOwnProperty(fieldName)) {
      return spPageText[fieldName];
    } else if (spPageText.hasOwnProperty(fieldName.toLowerCase())) {
      return spPageText[fieldName.toLowerCase()];
    } else {
      return `ERROR FINDING LABEL ${fieldName}`;
    }
  } else { 
    return label;
  }
};

export default GetLabels;
