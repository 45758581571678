import './Steps.scss';
import { Box } from '@mui/material';
import {
  Handshake,
  Explore,
  FactCheck,
  Build,
  Verified,
  Tune,
  FlightTakeoff
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../../../pages/App/App';
import { useContext } from 'react';

const Steps = ({ data, navOpen }) => {
  const stepIcons = [
    FactCheck,
    Handshake,
    Explore,
    Build,
    Verified,
    Tune,
    FlightTakeoff
  ];
  const langContext = useContext(LangContext);
  const navigate = useNavigate();
  return (
    data && (
      <>
        <div className={`gettingStartedContainer ${navOpen && 'navOpen'}`}>
          {Object.keys(data.steps).map((s: any, i) => {
            const StepIcon = stepIcons[i % stepIcons.length];
            const step = data.steps[s];
            return (
              <Box className={`steps step${i + 1}`} key={`step${i}`}>
                <div className="stepBadge" aria-hidden="true">
                  {i + 1}
                </div>
                <StepIcon className={`stepIcon icon${i + 1}`} />{' '}
                <div className="stepHeader" aria-describedby="stepDescription">
                  {step.header}
                </div>
                <div dangerouslySetInnerHTML={{ __html: step.copy }} />
                {i === 0 && data.stepBttn && (
                  <div
                    className="stepButton"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      navigate(
                        `/${langContext.selectedLanguage}/seller-registration-form`
                      );
                    }}
                    onKeyDown={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      navigate(
                        `/${langContext.selectedLanguage}/seller-registration-form`
                      );
                    }}
                  >
                    {data.stepBttn}
                  </div>
                )}
              </Box>
            );
          })}
          <section
            className="quickLinksContainer"
            aria-labelledby="drIntgQuickLinksId"
          >
            <div className="quickLinks">
              <h2 id="drIntgQuickLinksId">{data.quickLinks.header}</h2>
              <ul>
                {Object.entries(data.quickLinks.links).map(([k, v]) => (
                  <li key={k}>
                    <a
                      href={
                        data.quickLinks.links[k].url.includes(
                          'https://www.aircanada.com'
                        )
                          ? `${data.quickLinks.links[k].url}`
                          : `/${langContext.selectedLanguage}${data.quickLinks.links[k].url}`
                      }
                      {...(data.quickLinks.links[k].url.includes('http') && {
                        target: '_blank'
                      })}
                    >
                      {data.quickLinks.links[k].text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </section>{' '}
        </div>
      </>
    )
  );
};

export default Steps;
