import { sllrProfAxios } from "./axiosDefault";
import {
  isEmpty,
  showAPIerror,
} from "../../../pages/SellerProfile/APIs/Helpers";
import { format } from "date-fns";

const SpAPIgetCompanyProfile = async ({ ...context }) => {
  const getDefaultCoProfile = async () =>
    await sllrProfAxios.get(`/profile-data`);

  const getCoProfile = async () =>
    await sllrProfAxios.get(`/profile/${context.seller_id}`);
  let defaultDataToReturn = {};

  return await getCoProfile()
    .then((res) => {
      const finalSellerData = res.data.data.seller[0];
      const finalContactData = res.data.data.contact;
      const formatCompProfile = (defaultCoData) => {
        //format countries for api_schema_version
        const getAllPOS = finalSellerData?.pos_preference?.split(",") || [];
        const pos_typeArr: string[] = [];
        const otherPOSArr: string[] = [];

        getAllPOS.forEach((item: any) => {
          if (item.includes("-supported") && !item.includes("non-supported")) {
            pos_typeArr.push(
              item.replace(new RegExp(" ([a-zA-Z_]+)-supported", "g"), "")
            );
          }
          if (item.includes("-non-supported")) {
            otherPOSArr.push(
              item.replace(new RegExp(" ([a-zA-Z_]+)-non-supported", "g"), "")
            );
          }
        });
        if (context.isFrench) {
          const translateCountry = (item, arr, ind) => {
            const findCountry = defaultCoData.pos_type.find(
              (pos) => pos.country === item
            );

            arr[ind] = findCountry.pays;
          };

          pos_typeArr.forEach((item, i) => {
            translateCountry(item, pos_typeArr, i);
          });
          otherPOSArr.forEach((item, i) => {
            translateCountry(item, otherPOSArr, i);
          });
        }
        //format tech and biz contact data (or catch empty)

        const contactFields = [
          "contact_first_name",
          "contact_last_name",
          "contact_phone",
          "contact_email",
          "contact_type",
          "contact_id",
        ];

        const adminContact = finalContactData.filter(
          (contact) =>
            contact["contact_type"] && contact["contact_type"] === "admin"
        );
        const techContact = finalContactData.filter(
          (contact) =>
            contact["contact_type"] &&
            contact["contact_type"] === "technical/operations"
        );

        contactFields.forEach((field) => {
          finalSellerData[`admin_${field}`] = adminContact.length
            ? adminContact[0][field]
            : "";
          finalSellerData[`techOpAdmin_${field}`] = techContact.length
            ? techContact[0][field]
            : "";
        });

        defaultDataToReturn = defaultCoData;
        return {
          ...finalSellerData,
          customer_type_id: context.isFrench
            ? finalSellerData?.customer_type_fr?.toLowerCase()
            : finalSellerData?.customer_type?.toLowerCase(),
          customer_type: context.isFrench
            ? finalSellerData?.customer_type_fr
            : finalSellerData?.customer_type,
          business_model_id: context.isFrench
            ? finalSellerData?.business_model_type_fr?.toLowerCase()
            : finalSellerData?.business_model_type?.toLowerCase(),
          business_model_type: context.isFrench
            ? finalSellerData?.business_model_type_fr
            : finalSellerData?.business_model_type,
          api_schema_version:
            finalSellerData?.api_schema_version?.split(",") || [],
          pos_type: pos_typeArr,
          otherPOS: otherPOSArr,
          implementation_start_date: format(
            new Date(finalSellerData?.implementation_start_date),
            "PP"
          ),
        };
      };

      context.setCoName(finalSellerData.company_name);
      localStorage.setItem("coName", finalSellerData.company_name);

      //Reformat notification data to show in UI
      if (finalSellerData) {
        if (isEmpty(context.defaultCompData)) {
          return getDefaultCoProfile().then((res) => {
            if (res.status === 200) {
              context.setDefaultCompData(res.data);
              return formatCompProfile(res.data);
            }
          });
        } else {
          return formatCompProfile(context.defaultCompData);
        }
      }
    })
    .then((res) => {
      context.setCompProfile(res);
      return res;
    })
    .then((res) => {
      return { coProf: res, defaultData: defaultDataToReturn };
    })
    .catch((res) => showAPIerror(res, context, "SpAPIgetCompanyProfile"));
};

const SpAPIUpdateCompanyProfile = async ({ ...context }) => {
  const formattedUpdates = {};

  const getValByObjKey = (field, obj) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "object" && obj[key] !== null && key !== field) {
        getValByObjKey(field, obj[key]);
      } else if (key === field) {
        formattedUpdates[key] = obj[key].content;
      }
    });

    return formattedUpdates;
  };

  Object.keys(context.compProfile).forEach((origKey) => {
    getValByObjKey(origKey, context.formUpdates["editCompanyProfile"]);
  });

  const updatedPOStype: string[] = [];

  const getPOStype = formattedUpdates["pos_type"];
  const getPOSother = formattedUpdates["otherPOS"];

  context.defaultCompData?.pos_type?.forEach((pos) => {
    const posCountry = context.isFrench ? pos.pays : pos.country;
    getPOStype?.includes(posCountry) && updatedPOStype.push(pos.pos_code);
    getPOSother?.includes(posCountry) && updatedPOStype.push(pos.pos_code);
  });

  formattedUpdates["pos_preference"] = updatedPOStype;

  const requiredUpdateFields = [
    "company_name",
    "company_address",
    "company_website",
    "business_contact_first_name",
    "business_contact_last_name",
    "business_phone_no",
    "business_email",
    "contact",
    "customer_type_id",
    "business_model_id",
    "implementation_start_date",
    "pos_preference",
    "api_schema_version",
  ];
  //Format customer type (switch to code)
  const getCusType = formattedUpdates["customer_type"];
  const translatedCustomerType = context.isFrench
    ? "customer_type_fr"
    : "customer_type";
  context.defaultCompData?.customer_types?.forEach((cType) => {
    getCusType?.includes(cType[translatedCustomerType]) &&
      (formattedUpdates["customer_type_id"] = cType.customer_type_id);
  });
  //Format bizmodel (switch to code)
  const getBizModel = formattedUpdates["business_model_type"];
  const translatedBizModelType = context.isFrench
    ? "business_model_type_fr"
    : "business_model_type";
  context.defaultCompData?.business_model?.forEach((bMod) => {
    getBizModel?.includes(bMod[translatedBizModelType]) &&
      (formattedUpdates["business_model_id"] = bMod.business_model_id);
  });
  //Format contact
  const contactsFields = [
    "contact_first_name",
    "contact_last_name",
    "contact_phone",
    "contact_email",
    "contact_id",
  ];
  const contactsTypes = ["admin", "techOpAdmin"];
  const updatedContacts: any = [];

  contactsTypes.forEach((conType, i) => {
    const updatedContact: any = {};
    contactsFields.forEach((field) => {
      const getContactData =
        field === "contact_id"
          ? context.compProfile[`${conType}_${field}`] //Alpesh says contact ID always remains same even if change contact info
          : formattedUpdates[`${conType}_${field}`];
      updatedContact[`${field}`] = getContactData?.replaceAll("null", "");
    });
    updatedContacts.push(updatedContact);
  });

  formattedUpdates["contact"] = updatedContacts;

  Object.keys(formattedUpdates).forEach((field) => {
    !requiredUpdateFields.includes(field) && delete formattedUpdates[field];
  });

  const formatDate = new Date(
    formattedUpdates["implementation_start_date"]
  ).toISOString();
  formattedUpdates["implementation_start_date"] = formatDate;

  const updateCoProfile = async () =>
    await sllrProfAxios.put(`/profile/${context.seller_id}`, formattedUpdates);

  return await updateCoProfile()
    .then((res) => {
      //Reformat notification data to show in UI

      context.setCoName(formattedUpdates["company_name"]);
      return true;
    })
    .catch((res) => showAPIerror(res, context, "SpAPIUpdateCompanyProfile"));
};
export { SpAPIgetCompanyProfile, SpAPIUpdateCompanyProfile };
