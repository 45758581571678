import * as React from "react";
import { useState, useRef, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { LangContext } from "../../pages/App/App";
import { useContext } from "react";
import DeskTopDropDownMenu from "./DropDownMenu";
import "./AppMenuBar.scss";
import AppMobileSignedUser from "../AppHeader/AppSignedUser/AppMobileSignedUser";

import { useSkipLink } from "../../providers/SkipLinkProvider";
import { useAdobeAnalytics } from "../../providers/AdobeAnalytics";
//Material UI
import {
  Box,
  Tabs,
  Tab,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Popper,
  Paper,
  TextField,
  FormControl,
  InputAdornment,
} from "@mui/material";


//Icon imports
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AirCanadaLogo from "../../assets/images/air_canada_black_logo.svg";
import CheckIcon from "@mui/icons-material/Check";

interface TabsType {
  mobile?: boolean;
  label: string;
  parentMenuName?: string;
  category?: string;
  path?: string;
  menu?: TabsType[];
}

const AppMenuBar = ({ data, setOpenMenu, userName, coName, logout }) => {
  //Main top nav

  const langContext = useContext(LangContext);

  //States
  const [value, setValue] = useState(0);
  const [dropDown, setDropDown] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [keyboard, setKeyboard] = useState(false);
  const [activeDropDown, setActiveDropDown] = useState<Number>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const { setSkipLinks } = useSkipLink();
  const { adobeAnalyticsSectionClick } = useAdobeAnalytics();
  const location = useLocation();
	const isSubseller = !location.pathname.toLowerCase().includes('subseller')


  //Refs
  const dropDownRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<any[]>([]);

  // MUI theme
  const theme = useTheme();

  let tabs: TabsType[] = [];
  let navCategories = {};
  //get nav info

  useEffect(() => {
    const locPath = location.pathname;
    if (locPath.includes("/api/")) {
      setValue(1);
    } else if (locPath.includes("/support")) {
      setValue(2);
    } else {
      setValue(0);
    }
  }, [location.pathname]);

  if (data) {
    const navData = data.navigation;

    const getNavInfo = (item, menuType) => {
      if (item.link.menu_name === menuType && item.link.enabled) {
        return {
          category: item.link.category,
          parentMenuName: item.link.menu_name,
          label: item.link.title,
          path: item.link.url,
        };
      }
    };

    const cleanArray = (arr) =>
      arr.filter((x) => (Object.keys(x).length ? x : false)); //get rid of any empty values in case spots missing in JSON
    navData.forEach((item) => {
      if (item.link.menu_name === "main") {
        if (item.link.has_children) {
          let subNav: TabsType[] = [];
          navData.forEach((sub) => {
            const getSubItem = getNavInfo(sub, item.link.children_name);
            if (getSubItem) subNav[sub.link.weight] = getSubItem;
            // subNav = cleanArray(subNav);
          });

          tabs[item.link.weight] = {
            label: item.link.title,
            menu: subNav,
          };

          navCategories[item.link.children_name] = item.link.categories;
        } else {
          const addItem = getNavInfo(item, "main");
          if (addItem) tabs[item.link.weight] = addItem;
        }
      }
    });

    tabs = cleanArray(tabs);

    tabs.push({
      mobile: true,
      label: "Language",
    });
  } else {
    // subseller
    tabs.push({
      mobile: true,
      label: "Language",
    });
  }

  const skipLinkOne = useCallback((node) => {
    if (node !== null) {
      setSkipLinks((prev) => {
        let index = prev.findIndex((item) => item.id === 0);
        return [
          ...prev.slice(0, index),
          { ...prev[index], ref: node },
          ...prev.slice(index + 1),
        ];
      });
      tabRefs.current[0] = node;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skipLinkTwo = useCallback((node) => {
    if (node !== null) {
      setSkipLinks((prev) => {
        let index = prev.findIndex((item) => item.id === 2);
        return [
          ...prev.slice(0, index),
          { ...prev[index], ref: node },
          ...prev.slice(index + 1),
        ];
      });
      tabRefs.current[3] = node;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dropDownRef.current && dropDown) {
      dropDownRef.current?.focus();
    }
  }, [dropDown]);

  const handleOptionChange = (lang) => {
    const otherLangCode = lang.substring(0, 2).toLowerCase();
    const path = window.location.search
      ? window.location.pathname + window.location.search
      : window.location.pathname;
    const updatedPathname = path.replace(
      `/${langContext.selectedLanguage}/`,
      `/${otherLangCode}/`
    );
    window.location.href = updatedPathname;
  };
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  let prevCategory = "";
  const getLink = (path, label) =>  path.charAt(0) !== "/" ? (
      <a  className="mobileMenuItem" href={path} target="_blank" rel="noreferrer">
         <Typography>{label}</Typography>
      </a>
    ) : (
      <Link
        className="mobileMenuItem"
        to={`/${langContext.selectedLanguage}${path}`}
        onClick={() => setOpenMenu(false)}
      >
        <Typography>{label}</Typography>
      </Link>
    );
 

  return (
    <>
      {/* DESKTOP */}
      {useMediaQuery(theme.breakpoints.up("md")) ? (
        data && <>
        <Box className="menuBarContainerDesktop">
          <Tabs
            value={value}
            className="tabContainer"
            TabIndicatorProps={{
              style: { transition: "none" },
            }}
            role="menubar"
          >
            {tabs.map((tab, index) => {
              if (tab.mobile) {
                return null;
              }
              return (
                <Tab
                  key={index}
                  {...(index === 0 && { id: "menubar" })}
                  label={tab.label}
                  {...(index === 0 && { style: { margin: "0px 5px" } })}
                  disableRipple
                  tabIndex={0}
                  ref={(el) => (tabRefs.current[index] = el)}
                  {...(index === 0 && { ref: skipLinkOne })}
                  {...(index === 3 && { ref: skipLinkTwo })}
                  {...(tab.menu && {
                    icon:
                      dropDown && activeDropDown === index ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      ),
                  })}
                  iconPosition="end"
                  className="tab"
                  onClick={(e) => {
                    adobeAnalyticsSectionClick("header nav");
                    tab.menu && setDropDown(true);
                    setCoords(e.currentTarget.getBoundingClientRect());
                    if (activeDropDown === index) {
                      setActiveDropDown(undefined);
                      setDropDown(false);
                    } else {
                      setActiveDropDown(index);
                    }
                  }}
                  onMouseOver={(e) => {
                    tab.menu &&
                      setCoords(e.currentTarget.getBoundingClientRect());
                    setActiveDropDown(index);
                    tab.menu && setDropDown(true);
                  }}
                  onMouseLeave={(e) => {
                    if (
                      (e.clientY < coords.y + 50 &&
                        e.clientY > coords.y - 50) ||
                      (e.clientX < coords.x + 5 && e.clientX > coords.x - 5)
                    ) {
                      setDropDown(false);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      (e.key === "Tab" && !e.shiftKey) ||
                      e.key === "ArrowDown"
                    ) {
                      dropDown && setKeyboard(true);
                    }
                    if (e.shiftKey && e.key === "Tab" && index !== 0) {
                      e.preventDefault();
                      tabRefs.current[index - 1].focus();
                    }
                  }}
                  onFocus={(e) => {
                    if (!tab.menu && dropDown) {
                      setDropDown(false);
                      setActiveDropDown(undefined);
                    }
                  }}
                  {...(tab.menu && {
                    "aria-expanded": activeDropDown === index,
                  })}
                  role="menuitem"
                />
              );
            })}
          </Tabs>
        </Box>
        {dropDown && (
          <DeskTopDropDownMenu
            dropDownMenu={tabs[activeDropDown as number]}
            coords={coords}
            setDropDown={setDropDown}
            activeDropDown={activeDropDown}
            keyboard={keyboard}
            setKeyboard={setKeyboard}
            tabRefs={tabRefs}
            categories={navCategories}
          />
        )}
      </>
      ) : (
        //MOBILE
        <Box className="menuBarContainerMobile">
          <Box className="mobileHeaderContainer">
            <CloseSharpIcon
              fontSize="medium"
              className="closeIcon"
              onClick={() => setOpenMenu(false)}
            />
            <img src={AirCanadaLogo} alt="Air Canada Logo" />
          </Box>
          {/* <AppSearch data={data}/>   */}
          <Box className="mobileAccordionContainer">
          {isSubseller && <AppMobileSignedUser userName={userName} coName={coName}  logout={logout}/> }
            {tabs.map((tab, index) => {
              return (
                <Accordion
                  disableGutters
                  elevation={0}
                  sx={{
                    "& .MuiAccordionSummary-root": {
                      borderTop: index !== 0 ? "1px solid #E0E4E7" : "none",
                    },
                  }}
                  key={`menubar-acc-${index}`}
                >
                  <AccordionSummary
                    {...(tab.menu && {
                      expandIcon: <ExpandMoreIcon sx={{ color: "#005078" }} />,
                    })}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {tab.label === "Language" ? 
                     
                      <FormControl size="small" className="language">
                        <TextField
                          disabled
                          aria-describedby={id}
                          id="standard-basic"
                          label={
                            langContext.selectedLanguage === "en"
                              ? "Select Language..."
                              : "Sélectionnez la langue"
                          }
                          variant="standard"
                          onClick={handleClick}
                          value={langContext.readableLanguage}
                          sx={{ color: "black" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <KeyboardArrowDownIcon />
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                        <Popper
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          placement="bottom"
                          disablePortal={false}
                          className="language-popper"
                          style={{
                            zIndex: 999,
                            paddingLeft: "30px",
                            width: "100%",
                            paddingRight: "30px",
                          }}
                        >
                          <Paper
                            className="language-paper-content"
                            style={{
                              height: "116px",
                              borderRadius: 0,
                              marginTop: "-2px",
                              border: "2px solid #EFEFEF",
                            }}
                          >
                            <List>
                              <ListItem disablePadding>
                                <ListItemText
                                  primary={langContext.readableLanguage}
                                  style={{
                                    color: "#005078",
                                    paddingLeft: "30px",
                                    paddingTop: "10px",
                                  }}
                                />
                                <ListItemIcon>
                                  <CheckIcon />
                                </ListItemIcon>
                              </ListItem>
                              <Divider
                                variant="middle"
                                style={{
                                  padding: "5px",
                                  margin: "0px 30px 0px 30px",
                                }}
                              />
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() =>
                                    handleOptionChange(
                                      langContext.otherReadableLanguage
                                    )
                                  }
                                >
                                  <ListItemText
                                    primary={langContext.otherReadableLanguage}
                                    style={{
                                      color: "#4A4F55",
                                      paddingLeft: "15px",
                                    }}
                                  />
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </Paper>
                        </Popper>
                      </FormControl>
                     :  
                       <Typography className="accordionTitle">
                        {tab.label}
                      </Typography> 
                    }
                  </AccordionSummary>
                  {tab.menu && (
                    <AccordionDetails>
                      {tab.menu.map((menu, i) => {
                        const currCategory =
                          menu.parentMenuName &&
                          navCategories[menu.parentMenuName][menu.category];
                        const showSubHeader = prevCategory !== currCategory;
                        prevCategory = currCategory;
                        return (
                          <React.Fragment key={`box-${index}-${i}`}>
                           {showSubHeader &&  <div className="mobileSubhead">{prevCategory}</div>}
                            {getLink(menu.path, menu.label)}
                          </React.Fragment>
                        );
                      })}
                    </AccordionDetails>
                  )}
                </Accordion>
              );
            })}
          </Box>
          <div></div>
        </Box>
      )}
    </>
  );
};

export default AppMenuBar;
