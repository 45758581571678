import React, { useState, useEffect } from "react";
import "./StatusFilter.scss";

import { Box, Typography, List, ListItem, Checkbox } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

enum ServiceOptions {
	AirShopping,
	OfferPrice,
	SeatAvailability,
	OrderCreate,
	OrderRetrieve,
	OrderView,
	OrderReshop,
	OrderCancel,
	OrderChange,
}

enum EnvironmentOptions {
	Production,
	Gold,
}

const StatusFilter = ({ setFilter, filterBy = "service" }) => {
	const [checked, setChecked] = useState([]) as any;
	const [hover, setHover] = useState(false);

	const serviceCount = Object.keys(ServiceOptions).length / 2;
	const environmentCount = Object.keys(EnvironmentOptions).length / 2;

	const count = filterBy === "service" ? serviceCount : environmentCount;

	useEffect(() => {
		const checkedNames = checked.map((item) =>
			filterBy === "service" ? ServiceOptions[item] : EnvironmentOptions[item]
		);
		setFilter(checkedNames);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked]);

	return (
		<>
			<div>
				<div
					className="filterContainer"
					style={
						{
							"--color": hover ? "#005078" : "#000",
							"--border": hover ? "#005078" : "#EFEFEF",
							"--width": "100%",
						} as any
					}
					onMouseOver={() => setHover(true)}
					onMouseOut={() => setHover(false)}
					onClick={() => setHover(!hover)}
					onKeyDown={(e) => {
						if (e.key === "Enter") setHover(!hover);
					}}
					tabIndex={0}
					role="button"
					aria-expanded={hover}
					aria-label={filterBy === "service" ? "Filter By Service" : "Filter By Environment"}
				>
					<Typography
						className="label"
						sx={{
							fontWeight: hover ? "600" : "400",
							color: hover ? "#005078" : "#000",
							marginTop: "2px",
						}}
					>
						{filterBy === "service" ? "Service" : "Environment"}
					</Typography>
					<Box className="filterCount">
						{checked.length === count ? "All" : checked.length}
					</Box>
					<FilterListIcon
						sx={{
							marginTop: "2px",
						}}
						color="primary"
					/>
				</div>
				{hover && (
					<Box
						onMouseOver={() => setHover(true)}
						onMouseOut={() => setHover(false)}
						onFocus={() => setHover(true)}
						onBlur={() => setHover(false)}
						sx={{
							width: "220px",
							zIndex: "100",
							position: "absolute",
							backgroundColor: "white",
						}}
					>
						<List className="listContainer">
							{Object.values(filterBy === "service" ? ServiceOptions : EnvironmentOptions)
								.filter((option) => typeof option === "string")
								.map((option, index) => (
									<ListItem
										key={option}
										sx={{
											"& .MuiCheckbox-colorPrimary": {
												color: "#005078",
											},
											"& .MuiCheckbox-colorPrimary.Mui-checked": {
												color: "#005078",
											},
											borderBottom: index !== count - 1 ? "1px solid #DDECF5" : "none",
											padding: "8px 0px",
										}}
									>
										<Checkbox
											checked={checked.includes(index)}
											onChange={(e) => {
												checked.includes(index)
													? setChecked(
															checked
																.filter((item) => item !== index)
																.sort((a, b) => a - b)
													  )
													: setChecked([...checked, index].sort((a, b) => a - b));
											}}
											color="primary"
											tabIndex={0}
											onBlur={(e) => {
												index !== count - 1 && e.stopPropagation();
											}}
											onKeyDown={(e) => {
												if (index === 0 && e.shiftKey && e.key === "Tab") {
													setHover(false);
												}
											}}
										/>
										{option}
									</ListItem>
								))}
						</List>
					</Box>
				)}
			</div>
		</>
	);
};

export default StatusFilter;
