import { getStatus } from './SellerProfileAPI';
import { showAPIerror, sortUsers } from './Helpers';
import { sllrProfAxios } from './axiosDefault';

const SpApiAddNotifs = async (updates, { ...context }) => {
  await getStatus(context);
  const addUserNotif = async () =>
    await sllrProfAxios.post(
      `/notification-list-contact/${context.seller_id}`,
      newRecipient
    );

  const newRecipient = {
    user_first_name: updates.user_first_name,
    user_last_name: updates.user_last_name,
    user_email: updates.user_email,
    user_opted_notifications: updates.notifications
  };
  //ADD NEW NOTIFICATION RECIPIENT
  return await addUserNotif()
    .then((res) => {
      return SpAPIgetAllNotifs(context).then((refreshUserList) => {
        return refreshUserList;
      });
    })
    .catch((error) => showAPIerror(error, context, 'SpApiAddNotifs'));
};

const SpApiEditNotifs = async (curr_user_id, updates, { ...context }) => {
  await getStatus(context);
  const editUserNotif = async () =>
    await sllrProfAxios.put(`/notification-list-contact/${curr_user_id}`, {
      user_opted_notifications: updates
    });
  //refresh list from db on success
  return await editUserNotif()
    .then(() =>
      SpAPIgetAllNotifs(context).then((refreshUserList) => {
        return { data: refreshUserList, status: 200 };
      })
    )
    .catch((error) => showAPIerror(error, context, 'SpApiEditNotifs'));
};

const SpAPIgetAllNotifs = async ({ ...context }) => {
  const getNotificationRecipients = async () =>
    await sllrProfAxios.get(`/notification-list-contacts/${context.seller_id}`);
  return await getNotificationRecipients()
    .then((res) => {
      const newUserInfo = res.data.data;
      newUserInfo.forEach((user) => {
        const removeDuplicates: string[] = []; //Bug in API returning duplicates sometimes
        user.count.split(',').forEach((u) => {
          !removeDuplicates.includes(u) && removeDuplicates.push(u);
        });
        user.notifications = removeDuplicates || [];
        user.count = removeDuplicates.toString();
      });
      const sortedUsers = sortUsers(newUserInfo);

      context.setNotificationRecipients(sortedUsers);
      return sortedUsers;
    })
    .catch((error) => showAPIerror(error, context, 'SpAPIgetAllNotifs'));
};

const SpAPIgetIndNotifs = async (curr_user_id, { ...context }) => {
  const getIndNotifUser = async () =>
    await sllrProfAxios.get(`/notification-list-contact/${curr_user_id}`);
  return await getIndNotifUser()
    .then((res) => {
      const finalData = res.data['notifUsersRows'][0];
      //Reformat notification data to show in UI
      if (finalData) {
        const updatedUsers = context.notificationRecipients.map((user) => {
          const removeDuplicates: string[] = []; //Bug in API returning duplicates sometimes
          finalData.notification_type_id.split(',').forEach((u) => {
            !removeDuplicates.includes(u) && removeDuplicates.push(u);
          });
          return user.user_id === curr_user_id
            ? {
                ...user,
                notifications: removeDuplicates || [],
                notificationsMandatory: finalData.mandatory,
                count: removeDuplicates.toString()
              }
            : user;
        });

        context.setNotificationRecipients(updatedUsers);
        return updatedUsers;
      }
    })
    .catch((error) => showAPIerror(error, context, 'SpAPIgetIndNotifs'));
};

const SpAPIgetNotifTypes = async (setNotificationTypes) => {
  const getNotificationTypes = async () =>
    await sllrProfAxios.get(`/notification-types`);
  return await getNotificationTypes()
    .then((res) => {
      setNotificationTypes(res.data.notifications);
      return res;
    })
    .catch((error) => {
      console.log('err getting notif types', error);
      return error;
    });
};

export {
  SpAPIgetAllNotifs,
  SpApiAddNotifs,
  SpApiEditNotifs,
  SpAPIgetIndNotifs,
  SpAPIgetNotifTypes
};
