import { Box, Button } from "@mui/material";
import { useSellerProfile } from "../../../pages/SellerProfile/SellerProfile";
import { LoadingButton } from "@mui/lab";
import "./SPFormButton.scss";
function SPFormButton({ ...props }) {
  const { errorMsg,showSuccessPopUp } = useSellerProfile() as any;

  const { label } = props;
  const { ...context } = useSellerProfile() as any;

  let setDisabled = context.disabledBttn.hasOwnProperty(props.formName)
    ? context.disabledBttn[props.formName]
    : true;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      {errorMsg !== "" && (
        <Box className="formButtonContainer">
          <output>{errorMsg} </output>
        </Box>
      )}
      {showSuccessPopUp !== "" && (
        <Box className="successScreenReader">
          <output>{errorMsg} </output>
        </Box>
      )}
      {props.showLoading ? (
        <LoadingButton loading className="spButton loading">
          {" "}
          {label}
        </LoadingButton>
      ) : (
        <Button
          className={`spButton ${props.compName}`}
          onClick={() => props.handleClick()}
          disabled={setDisabled}
        >
          {label}
        </Button>
      )}
    </Box>
  );
}

export default SPFormButton;
