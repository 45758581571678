import './AppTooltip.scss';
import { ReactElement, ReactNode } from 'react';
import { Tooltip } from '@mui/material';

interface Props {
  children: ReactElement;
  title: string | ReactNode;
  followCursor?: boolean;
  offsetY?: number;
}

const AppTooltip = ({ children, title, followCursor, offsetY }: Props) => {
  return (
    <Tooltip
      arrow={!followCursor}
      componentsProps={{
        tooltip: {
          className: 'app-tooltip'
        }
      }}
      followCursor={followCursor}
      placement={followCursor ? 'bottom' : 'bottom-end'}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, offsetY ?? 0]
              }
            }
          ]
        }
      }}
      title={title}
    >
      {children}
    </Tooltip>
  );
};

export default AppTooltip;
