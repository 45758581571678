import "./ReleaseNotes.scss";
import { LangContext } from "../../pages/App/App";
import { Typography } from "@mui/material";
import { useState, useEffect, useRef, useContext } from "react";
import AppFilterDate from "../AppFilterDate/AppFilterDate";
import React from "react";
import ReleaseNotesDetail from "./ReleaseNotesDetail/ReleaseNotesDetail";
import StatusFilter from "../StatusFilter/StatusFilter";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";

let language = "English";
const getServiceLink = (serv) => {
  const servVar =
    typeof serv === "string" || serv instanceof String ? serv : serv?.value;
  let serviceName = servVar?.toLowerCase();
  if (serviceName === "orderview" || serviceName === "orderretrieve") {
    serviceName = "orderretrieve/functionality";
  } else if (serviceName !== "orderchange") {
    serviceName = `${serviceName}/functionality`;
  }
  return serviceName;
};

const ReleaseNotes = ({ data, lang }) => {
  language = lang;

  const [detailsContent, setDetailsContent] = useState<any>("");
  const [entries, setEntries] = useState(data?.sort(sortByPubDate)) as any;
  const [filterByDate, setFilterByDate] = useState();
  const [filterByService, setFilterByService] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [nextReleaseDate, setNextReleaseDate] = useState<Date>(new Date());
  const [redirectToSub, setRedirectToSub] = useState<string>("");
  const [showReleaseDetails, setShowReleaseDetails] = useState(false);

  const handleAnchorClick = useHandleAnchorClick();
  const langContext = useContext(LangContext);
  const tableRef = useRef(null) as any;

  //Need to be able to show releases in future by default,
  //so set whatever "next release" date here (i.e. set to latest of Prod or Gold Release Date)
  let endDate = new Date() < nextReleaseDate ? nextReleaseDate : new Date();

  useEffect(() => {
    //update for language switch
    setRedirectToSub(window.location.search ? window.location.search : "");
    const sortedData = data?.sort(sortByPubDate);
    let getLastRlsDate = new Date();
    sortedData?.forEach((d) => {
      d.environment.forEach((e) => {
        const goldDate = new Date(e.gold);
        const prodDate = new Date(e.prod);
        getLastRlsDate =
          prodDate > getLastRlsDate
            ? prodDate
            : goldDate > getLastRlsDate
            ? goldDate
            : getLastRlsDate;
      });
    });

    setNextReleaseDate(getLastRlsDate);
    setEntries(sortedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    //update Release Details content on language switch or direct link to sub

    if (showReleaseDetails || redirectToSub !== "") {
      [...entries].map((entry) =>
        entry.entries.forEach((sub: any) => {
          if (
            sub.detailsRoute.length &&
            cleanDetailsLink(sub.detailsRoute) ===
              redirectToSub.replace("?", "")
          ) {
            sub["envDates"] = entry.environment[0];
            setDetailsContent(sub);
            if (!showReleaseDetails) {
              setShowReleaseDetails(true);
            }
          }
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === tableRef.current) {
          entry.contentRect.width < 838
            ? setIsMobile(true)
            : setIsMobile(false);
        }
      }
    });

    const tableNode = tableRef.current;
    if (tableNode) {
      observer.observe(tableNode);
    }

    return () => {
      if (tableNode) {
        observer.unobserve(tableNode);
      }
    };
  }, [redirectToSub]);

  useEffect(() => {
    filterEntries({
      services: filterByService,
      date: filterByDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByService, filterByDate]);

  const checkIfArray = (item: any) => {
    const itemArray: string[] = [];

    if (Array.isArray(item)) {
      item.forEach((i) => itemArray.push(i.value));
    } else if (typeof item === "object") {
      itemArray.push(item.value);
    } else {
      itemArray.push(item);
    }

    return itemArray;
  };

  const cleanDetailsLink = (link) =>
    link
      .toLowerCase()
      .replaceAll("–", "")
      .replaceAll(" ", "-")
      .replaceAll(")", "")
      .replaceAll("(", "")
      .replaceAll("/", "");

  const filterEntries = (filterCriteria) => {
    const { date } = filterCriteria;
    let { services } = filterCriteria;
    let startDate = new Date("2023-01-01");

    if (date?.length) {
      startDate = date[0];
      endDate = date[1];
    }
    const filteredEntries = data?.filter((entry) => {
      const prodDate = new Date(entry.environment[0].prod);
      const goldDate = new Date(entry.environment[0].gold);
      const matchesStartDate = prodDate >= startDate || goldDate >= startDate;
      const matchesEndDate = prodDate <= endDate || goldDate <= endDate;

      const matchesServices =
        services === null ||
        services.length === 0 ||
        services.some((service) =>
          entry.entries.some((item) => {
            let servicesList = checkIfArray(item.services);
            return servicesList.includes(service);
          })
        );

      return matchesStartDate && matchesEndDate && matchesServices;
    });

    let filteredEntriesWithService = filteredEntries;

    if (services.length !== 0) {
      filteredEntriesWithService = filteredEntries.map((entry) => {
        const entriesWithService = entry.entries.filter((ent) =>
          services.some((service) => {
            let servicesList = checkIfArray(ent.services);
            return servicesList.includes(service);
          })
        );
        return { ...entry, entries: entriesWithService };
      });
    }

    setEntries(filteredEntriesWithService);
  };

  const goToInfoPage = (entry, dates) => {
    delete dates.id;

    entry["envDates"] = dates;
    setDetailsContent(entry);
    setShowReleaseDetails(true);
    window.history.pushState(
      {},
      "",
      `/${langContext.selectedLanguage}/api/releasenotes?${cleanDetailsLink(
        entry.detailsRoute
      )}`
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const servicesLink = (item) => {
    return (
      <div key={`servLink-${item}`}>
        <a
          href={`/${
            langContext.selectedLanguage
          }/api/documentation/${getServiceLink(item)}`}
          style={{ textDecoration: "none", wordBreak: "break-word" }}
        >
          {item}
        </a>
        <br />
      </div>
    );
  };

  const servicesLinkMobile = (item) => {
    return (
      <div key={item}>
        <a
          href={`/${
            langContext.selectedLanguage
          }/api/documentation/${getServiceLink(item)}`}
          style={{ textDecoration: "none" }}
        >
          {item}
        </a>
        <br />
      </div>
    );
  };

  function sortByPubDate(a, b) {
    return a.date === b.date ? 0 : a.date > b.date ? 1 : -1;
  }

  return Array.isArray(entries) ? (
    <div ref={tableRef}>
      {!showReleaseDetails && (
        <div
          style={
            {
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "wrap",
              marginBottom: "5px",
              "--marginRight": isMobile ? "0" : "10px",
            } as any
          }
          className="filter-container"
        >
          <AppFilterDate setFilter={setFilterByDate} futureDefault={endDate} />
          <StatusFilter setFilter={setFilterByService} />
        </div>
      )}
      {isMobile ? (
        !showReleaseDetails ? (
          <div className="mobile-release-notes">
            {[...entries].reverse().map((entry, i) => (
              <div className="mobile-table" key={`t-${i}`}>
                <div className="mobile-row-container">
                  <Typography className="mobile-row-header">
                    {language === "fr" ? "Date de lancement" : "Release Date"}
                  </Typography>
                  {Object.keys(entry.environment[0])
                    .reverse()
                    .map(
                      (environment, i) =>
                        environment !== "id" && (
                          <Chip
                            key={`c-${i}`}
                            type={
                              environment.charAt(0).toUpperCase() +
                              environment.slice(1)
                            }
                            date={entry.environment[0][environment]}
                          />
                        )
                    )}
                </div>
                {entry.entries.map((subEntry, i) => {
                  if (!Array.isArray(subEntry["services"])) {
                    subEntry["services"] = [subEntry["services"]];
                  }

                  return (
                    <div key={i}>
                      <div className="mobile-row-container">
                        <Typography className="mobile-row-header">
                          {language === "fr"
                            ? "Mise à jour & résumé"
                            : "Release & Summary"}
                        </Typography>
                        <div
                          style={{
                            color: "#005078",
                            fontSize: "14px",
                            paddingBottom: "8px",
                            fontWeight: "600",
                            display: "flex",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            goToInfoPage(subEntry, entry.environment[0]);
                          }}
                        >
                          {subEntry.title.trim() !== "none" && subEntry.title}
                          {subEntry.title.trim() !== "" &&
                            subEntry.title.trim() !== "none" && (
                              <img
                                className="rlsNotesLinkIcon"
                                src="/images/icons/linkIcon.png"
                                alt="Link Icon"
                              />
                            )}
                        </div>
                        <Typography
                          className=""
                          dangerouslySetInnerHTML={{ __html: subEntry.summary }}
                          onClick={handleAnchorClick}
                        ></Typography>
                      </div>
                      {subEntry.implementation !== "" && (
                        <div className="mobile-row-container">
                          <Typography className="mobile-row-header">
                            {language === "fr"
                              ? "Exigence de mise en œuvre"
                              : "Implementation Requirement"}
                          </Typography>
                          <Typography
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: subEntry.implementation,
                            }}
                            onClick={handleAnchorClick}
                          ></Typography>
                        </div>
                      )}
                      <div className="mobile-row-container">
                        <Typography className="mobile-row-header">
                          {language === "fr"
                            ? "API touchées"
                            : "API(s) affected"}
                        </Typography>
                        <div>
                          {subEntry.services.map((item, ind) => {
                            let servicesList = checkIfArray(item);

                            return (
                              <React.Fragment key={ind}>
                                {servicesList
                                  .sort()
                                  .map((s) => servicesLinkMobile(s))}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        ) : (
          <ReleaseNotesDetail
            setShowReleaseDetails={setShowReleaseDetails}
            details={detailsContent}
            Chip={Chip}
            lang={language}
            setRedirectToSub={setRedirectToSub}
          />
        )
      ) : !showReleaseDetails ? (
        <table className="release-notes-desktop">
          <colgroup>
            <col style={{ width: "18%" }} />
            <col style={{ width: "50%" }} />
            <col style={{ width: "18%" }} />
            <col style={{ width: "14%" }} />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                {language === "fr" ? "Date de lancement" : "Release Date"}
              </th>
              <th scope="col">
                {language === "fr"
                  ? "Mise à jour & résumé"
                  : "Release & Summary"}
              </th>
              <th scope="col">
                {language === "fr"
                  ? "Exigence de mise en œuvre"
                  : "Implementation Requirement"}
              </th>
              <th scope="col">
                {language === "fr" ? "API touchées" : "API(s) Affected"}
              </th>
            </tr>
          </thead>
          <tbody>
            {[...entries]?.reverse()?.map((items, i) =>
              items.entries.map((entry, j) => {
                if (!Array.isArray(entry["services"])) {
                  entry["services"] = [entry["services"]];
                } else {
                  entry["services"] = entry["services"].sort((a, b) =>
                    a.value > b.value ? 0 : -1
                  );
                }
                if (
                  entry.detailsRoute.length &&
                  cleanDetailsLink(entry.detailsRoute) ===
                    redirectToSub.replace("?", "")
                ) {
                  goToInfoPage(entry, items.environment[0]);
                }
                const isLastRow = items.entries.length - 1 === j;
                const hasMultipleEntries = items?.entries?.length > 1;
                const rowStyle = {
                  verticalAlign: "top",
                  border: hasMultipleEntries && !isLastRow ? "none" : "",
                };
                return (
                  <tr
                    key={`${i}-${j}`}
                    className={i % 2 === 0 ? "odd-row" : "even-row"}
                  >
                    {j === 0 ? (
                      <td style={rowStyle}>
                        {Object.keys(items.environment[0])
                          .reverse()
                          .map(
                            (environment, ind) =>
                              environment !== "id" && (
                                <Chip
                                  key={`${i}-${j}-${ind}`}
                                  type={
                                    environment.charAt(0).toUpperCase() +
                                    environment.slice(1)
                                  }
                                  date={items.environment[0][environment]}
                                />
                              )
                          )}
                      </td>
                    ) : (
                      <td
                        style={{ ...rowStyle, backgroundColor: "inherit" }}
                      ></td>
                    )}

                    <td style={{ verticalAlign: "top" }}>
                      <h2
                        style={{
                          color: "#005078",
                          marginBottom: "8px",
                          fontWeight: "600",
                          display: "flex",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                        onClick={() =>
                          goToInfoPage(entry, items.environment[0])
                        }
                        onKeyDown={(e) =>
                          e.key === "Enter" &&
                          goToInfoPage(entry, items.environment[0])
                        }
                        role="button"
                        tabIndex={0}
                      >
                        {" "}
                        {entry.title.trim() !== "none" && entry.title}
                        {entry.title !== "" &&
                          entry.title.trim() !== "none" && (
                            <img
                              className="rlsNotesLinkIcon"
                              src="/images/icons/linkIcon.png"
                              alt="Link Icon"
                            />
                          )}
                      </h2>
                      <div
                        dangerouslySetInnerHTML={{ __html: entry.summary }}
                        onClick={handleAnchorClick}
                      ></div>
                    </td>
                    <td
                      dangerouslySetInnerHTML={{ __html: entry.implementation }}
                      onClick={handleAnchorClick}
                      style={{ verticalAlign: "top" }}
                    ></td>
                    <td style={{ verticalAlign: "top" }}>
                      {entry.services.sort().map((i, ind) => {
                        const serviceList = checkIfArray(i);
                        return (
                          <React.Fragment key={ind}>
                            {serviceList.map((s) => servicesLink(s))}
                          </React.Fragment>
                        );
                      })}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      ) : (
        <ReleaseNotesDetail
          setShowReleaseDetails={setShowReleaseDetails}
          details={detailsContent}
          Chip={Chip}
          lang={language}
          setRedirectToSub={setRedirectToSub}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

const Chip = ({ type, date }) => {
  const color = {
    Gold: "#a86819",
    Prod: "#00778F",
    None: "#767676",
  };

  const dateFormat = new Date(`${date} 00:00:00`);
  const isDateAvailable = date && date.trim();
  const chipColor = isDateAvailable ? color[type] : color.None;

  const enMonth = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const frMonth = [
    "janv",
    "févr",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "sept",
    "oct",
    "nov",
    "déc",
  ];

  const month =
    language === "fr"
      ? frMonth[dateFormat.getMonth()]
      : enMonth[dateFormat.getMonth()];

  const day =
    dateFormat.getDate().toString().length < 2
      ? `0${dateFormat.getDate()}`
      : dateFormat.getDate();

  const dateString = `${day} ${month} ${dateFormat.getFullYear()}`;
  const releaseDate = isDateAvailable ? dateString : "TBD";
  const chipLabel = `${type} environment release date: ${releaseDate}`;

  return (
    <div className="chip-container" aria-label={chipLabel}>
      <span className="chip-type" style={{ backgroundColor: chipColor }}>
        {type}
      </span>
      <span
        className="chip-date"
        style={{ borderColor: chipColor, marginRight: "20px" }}
      >
        {releaseDate}
      </span>
    </div>
  );
};

export default ReleaseNotes;
