import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import AppDialog from '../AppDialog/AppDialog';
import DialogData from '../../models/AppInterface';
import { useAuthProvider } from '../../providers/AuthProvider';
import { Box } from '@mui/material';
import { useAdobeAnalytics } from '../../providers/AdobeAnalytics';

const AppError = () => {
  const authContext = useAuthProvider();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const { siteErrorStateClick } = useAdobeAnalytics();

  siteErrorStateClick(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleSignout = () => {
    authContext.signOut();
  };

  const errorData: DialogData = {
    icon: (
      <SentimentVeryDissatisfiedIcon sx={{ fontSize: 70, marginTop: '2rem' }} />
    ),
    title: t('dialog.error.title'),
    description: t('dialog.error.description'),
    support: t('dialog.error.support') as string,
    actionLabel: t('dialog.error.buttonText'),
    secondaryActionLabel: t('buttons.logout'),
    handleAction: handleRefresh,
    handleSecondaryAction: handleSignout
  };

  return (
    <AppDialog
      data={errorData}
      disableClose
      onClose={handleClose}
      open={open}
    />
  );
};

const GenericError = () => {
  const { siteErrorStateClick } = useAdobeAnalytics();

  useEffect(() => {
    siteErrorStateClick(true);
    return () => siteErrorStateClick(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box sx={{ textAlign: 'center', fontWeight: 600 }}>
        There was an error retreiving this page. Please contact support.
      </Box>
      <Box sx={{ textAlign: 'center', fontWeight: 600, fontStyle: 'italic' }}>
        Une erreur s'est produite lors de la récupération de cette page.
        Veuillez contacter l'assistance.
      </Box>
    </>
  );
};
export { AppError as default, GenericError };
