import { useState, createContext, useContext } from "react";

export const SkipLinkContext = createContext({
	skipLinks: [] as any,
	setSkipLinks: (skipLinks: any) => {},
	navigationChange: false,
	setNavigationChange: (navigationChange: boolean) => {},
	sideNav: {} as any,
	setSideNav: (sideNav: any) => {},
});

export const SkipLinkProvider = (props) => {
	const [skipLinks, setSkipLinks] = useState([
		{
			id: 0,
			label: "Skip to menu bar",
			ref: null,
		},
		{
			id: 1,
			label: "Skip side navigation",
			ref: null,
		},
		{
			id: 2,
			label: "Skip to support",
			ref: null,
		},
		{
			id: 3,
			label: "Skip to footer",
			ref: null,
		},
	]);

	const [navigationChange, setNavigationChange] = useState(false);

	const [sideNav, setSideNav] = useState({}) as any;

	return (
		<SkipLinkContext.Provider
			value={{
				skipLinks,
				setSkipLinks,
				navigationChange,
				setNavigationChange,
				sideNav,
				setSideNav,
			}}
		>
			{props.children}
		</SkipLinkContext.Provider>
	);
};

export const useSkipLink = () => {
	return useContext(SkipLinkContext);
};
