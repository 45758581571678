import { useState, useEffect, createContext, useContext, memo, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { LangContext } from "../pages/App/App";
import useWindowSize from "../assets/customHooks/useWindowSize";

export const AdobeAnalyticsContext = createContext({
    adobeAnalyticsSectionClick: (section) => {},
    adobeSiteInteraction: (interaction) => {},
    siteErrorStateClick: (siteErrorStatus) => {},
    getPageVariation: (page) => {},
    getSubPageVariation: (page) => {},
    getCurrentPageTitle: (title) => {},
});

const replacements = {
    "api": "api implementation",
    "documentation": "api documentation",
    "faq": "frequently asked questions",
    "knownissues": "known issues",
    "statusMonitoring": "webservice status",
    "seller-registration-form": "ac ndc api registration form",
    "seller-registration": "ac ndc api registration",
    "connection-options": "connection options",
    // "agencyform": "agency learn more form",
    "ndcapis": "ndc apis",
    "releasenotes": "release notes",
    "/latestrelease": "",
    "testdata": "test data",
    "gettingstarted": "getting started",
    "apisorchestration": "api's orchestration",
    "sellersetup": "seller setup",
    "apisetup": "api setup",
    "ndc-program": "ndc program",
    "ndc-capabilities": "ndc capabilities"
};

const pageTitles = ["ac ndc api registration", "ac ndc api registration form", "webservice status"]


export const AdobeAnalyticsProvider = memo((props: { children: React.ReactNode }) => {

    const language = useContext(LangContext);
    const [sectionClick, setSectionClick] = useState<string | null>(null);
    const [siteInteraction, setSiteInteraction] = useState("");
    const [siteErrorStatus, setSiteErrorStatus] = useState<boolean>(false);
    const [pageVariation, setPageVariation] = useState("");
    const [currentPageTitle, setCurrentPageTitle] = useState(null);
    const [subPageVariation, setSubPageVariation] = useState("");
    const [referrer] = useState(document.referrer);
    const analyticsObj = JSON.parse(JSON.stringify(window.digitalDataAC));

    const { width } = useWindowSize();

    const location = useLocation();
    const currentURL = location.pathname;
    const params = location.search;

    const currentURLFull = `${currentURL}/${params}`;

    const adjustURL = Object.keys(replacements).reduce(
        (acc, key) => acc.replace(key, replacements[key]),
        location.pathname
    );
    
    const urlLevelsArray = useMemo(() => {
        let adjustedURL = adjustURL.split("/").slice(1);

        if (adjustedURL[0] !== "ndc") {
            adjustedURL.unshift("ndc");
        }

        if (adjustedURL[1] === "support" && adjustedURL.length === 2) {
            adjustedURL.push("technical support dashboard");
        }

        const includesRegistrationPages = [
            "ac ndc api registration",
            "ac ndc api registration form"
        ].some(page => adjustedURL.includes(page));

        if (includesRegistrationPages) {
            adjustedURL.splice(1, 0, 'ndc program');
        }

        return adjustedURL;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentURL]);

    const getPageName = (levelArray: string[]): string => {
        let pageName = "";
        levelArray.forEach((item: string, index: number) => {
            const exclude = levelArray[2] === "api documentation" && urlLevelsArray.length === 5 && index === levelArray.length - 1;
            pageName += !exclude ? "|" + item : "";
        });
        return pageName.slice(1);
    };

    const getPageTitle = (levelArray: string[]): string => {
        switch (levelArray[2]) {
            case "api documentation":
                    return levelArray[3];
            case "technical support dashboard":
                    return "ndc support";
            default:
                    return levelArray[levelArray.length - 1];
        }
    };

    const viewportWidth = useMemo(() => {
        return width! < 768 ? "small" : (width! >= 768 && width! < 1024) ? "medium" : "large";
    }, [width]);


    const prevSectionClickRef = useRef() as any; 
    const prevCurrentURLFullRef = useRef(currentURLFull);
	const prevCurrentURLRef = useRef(referrer === "" ? null : currentURL ) as any;
    const prevUrlLevelsArrayRef = useRef(urlLevelsArray);
    const prevCurrentPageTitleRef = useRef(currentPageTitle);
    const prevPageVariationRef = useRef(pageVariation);
    const prevSubPageVariationRef = useRef(subPageVariation);
    const prevLanguageRef = useRef(null) as any;
    const prevViewportWidthRef = useRef(null) as any;


	const cleanUpRemoval = (analytics) => {
		if (analytics.length < 2) {
			return analytics;
		}
		const lastObj = analytics[analytics.length - 1];
		const secondLastObj = analytics[analytics.length - 2];

		if(lastObj?.attributes?.pageInfo && secondLastObj?.attributes?.pageInfo) {
			const { previousPageLinkName: lastPageLinkName, ...lastOtherAttributes } = lastObj.attributes.pageInfo;
			const { previousPageLinkName: secondLastPageLinkName, ...secondLastOtherAttributes } = secondLastObj.attributes.pageInfo;
			if (JSON.stringify(lastOtherAttributes) === JSON.stringify(secondLastOtherAttributes)) {
				return analytics.slice(0, -2).concat([lastObj]);
			}
		}
		return analytics;
	}


    const modifyAdobeObject = () =>{
		analyticsObj.events = cleanUpRemoval(analyticsObj.events);
        window.digitalDataAC = analyticsObj;
    }

    //siteInfo
    useEffect(() => {
        const hasDependenciesChanged = 
            prevLanguageRef?.current !== language.selectedLanguage ||
            prevViewportWidthRef?.current !== viewportWidth;

        if (!hasDependenciesChanged && !siteErrorStatus) {
            return;
        }

        if(!siteErrorStatus) {
            //@ts-ignore
            analyticsObj.events.push({
                    attributes: {
                        siteInfo: {
                            siteVersion: "1.0",
                            buildDate: "2022-03-25",
                            environment: "ndc.aircanada.com",
                            language: language.selectedLanguage,
                            name: "ndc",
                            siteEdition: "ca",
                            viewportSize: viewportWidth,
                            webProperty: "ndc.aircanada.com",
                        },
                    }
            });
            modifyAdobeObject();
        }
        prevLanguageRef.current = language.selectedLanguage;
        prevViewportWidthRef.current = viewportWidth;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, viewportWidth]);


    const isValid = (value) =>{
    return value !== null && value !== undefined && value !== "";
    }

    useEffect(() => {
        const hasOtherDependenciesChanged = 
            prevCurrentURLFullRef.current !== currentURLFull ||
            prevCurrentURLRef.current !== currentURL ||
            JSON.stringify(prevUrlLevelsArrayRef.current) !== JSON.stringify(urlLevelsArray) ||
            prevCurrentPageTitleRef.current !== currentPageTitle || 
            prevCurrentPageTitleRef.current !== getPageTitle(urlLevelsArray) ||
            prevPageVariationRef.current !== pageVariation ||
            prevSubPageVariationRef.current !== subPageVariation;

        //if the ONLY change is sectionClick changing to null, then don't push any changes
        if (sectionClick === null && prevSectionClickRef.current !== null && !hasOtherDependenciesChanged) {
            prevSectionClickRef.current = null;
            return;
        }
        const variation = urlLevelsArray[2] === "api documentation" && urlLevelsArray.length === 5  ? urlLevelsArray[4] : pageVariation;
        if (!siteErrorStatus) {
            const updatedSectionClick = sectionClick === prevSectionClickRef.current ? null : sectionClick;
            if ((sectionClick !== null || prevSectionClickRef.current !== null) || (hasOtherDependenciesChanged && !currentPageTitle)) {
                if (urlLevelsArray[2] !== "webservice status" || (isValid(pageVariation) && isValid(subPageVariation))) {
                    if(!(pageTitles.some((title) => urlLevelsArray.includes(title)) ? currentPageTitle : getPageTitle(urlLevelsArray))) return;
                        //@ts-ignore
                        analyticsObj.events.push({
                            eventInfo: {
                                eventName: "ndc-flow",
                                timeStamp: new Date()
                            },
                            attributes: {
                                pageInfo: {
                                        fullUrl: currentURLFull,
                                        pageHierarchy: {
                                            level1: urlLevelsArray[0] ?? null,
                                            level2: urlLevelsArray[1] ?? null,
                                            level3: urlLevelsArray[2] ?? null,
                                            level4: urlLevelsArray[3] ?? null,
                                        },
                                        pageName: getPageName(urlLevelsArray),
                                        pageTitle: pageTitles.some((title) => urlLevelsArray.includes(title)) ? currentPageTitle : getPageTitle(urlLevelsArray),
                                        previousPageLinkName: updatedSectionClick,
                                        timeStamp: `${new Date().toLocaleString("en-US", {
                                            dateStyle: "short",
                                            timeStyle: "short",
                                        })} ${new Date().toLocaleString("en-US", { weekday: "long" })}`,
                                        url: currentURL,
                                        ...(variation && { pageVariation: variation }),
                                        ...(subPageVariation && { subPageVariation }),
                                }
                            },
                        });

                        modifyAdobeObject();
                        setPageVariation("");
                        setSubPageVariation("");
                        setSectionClick(null);

                        // Update the prevSectionClickRef
                        prevSectionClickRef.current = sectionClick;
                        prevSectionClickRef.current = sectionClick;
                        prevCurrentURLFullRef.current = currentURLFull;
                        prevCurrentURLRef.current = currentURL;
                        prevUrlLevelsArrayRef.current = urlLevelsArray;
                        prevCurrentPageTitleRef.current = currentPageTitle;
                        prevPageVariationRef.current = pageVariation;
                        prevSubPageVariationRef.current = subPageVariation;

                }
            }
        }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentURLFull, currentURL, sectionClick, urlLevelsArray, currentPageTitle, pageVariation, subPageVariation]);



    useEffect(() => {
        const isSandbox = siteInteraction.includes("sandbox");
        const isForm = siteInteraction.includes("form");
        //@ts-ignore
        siteInteraction !=="" && analyticsObj.events.push({
            eventInfo: {
                eventName: "ndc-useraction",
                timeStamp: new Date ()
            },
            attributes: {
                siteInteractionInfo: {
                    stepNameL1: "ndc",
                    stepNameL2: isSandbox ? "sandbox" : siteInteraction,
                    stepNameL3: isSandbox ? `navigation source: ${siteInteraction.split("-")[1]}` : isForm ? (urlLevelsArray[2]) : null,
                    stepNameL4: null,
                },
            },
        });
        modifyAdobeObject();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteInteraction]);

    useEffect(() => {
    //@ts-ignore
     siteErrorStatus === true && analyticsObj.events.push({
            eventInfo: {
                eventName: "ndc-error-tracking",
                timeStamp: new Date()
            },
            attributes: {
                errorInfo: {
                    errorCode: "404",
                    errorType: "Page Not Found"
                }
            }
        });
        modifyAdobeObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteErrorStatus]);

    const adobeAnalyticsSectionClick = (section) => {
		  setSectionClick(section);
    };

    const adobeSiteInteraction = (interaction) => {
        setSiteInteraction(interaction);
    };
    const siteErrorStateClick =(errorStatus:boolean)=>{
        setSiteErrorStatus(()=>errorStatus);
    }
    const getPageVariation = (page) => {
        setPageVariation(page);
    }
    const getSubPageVariation = (subPage) => {
        setSubPageVariation(subPage);
    }
    const getCurrentPageTitle = (title) => {
        setCurrentPageTitle(title);
    }

    return (
        <AdobeAnalyticsContext.Provider
            value={{
                adobeAnalyticsSectionClick,
                adobeSiteInteraction,
                siteErrorStateClick,
                getPageVariation,
                getSubPageVariation,
                getCurrentPageTitle
            }}
        >
            {props.children}
        </AdobeAnalyticsContext.Provider>
    );
});

export const useAdobeAnalytics = () => useContext(AdobeAnalyticsContext);

