import { useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';

const useHandleAnchorClick = () => {
//  const navigate = useNavigate();

  const handleAnchorClick = useCallback((e) => {
    const { target } = e;
    
    if (target && (target.tagName === 'A' || target.parentNode.tagName === 'A')) {
      if (target.getAttribute('target') === '_blank' || target.parentNode.getAttribute('target') === '_blank' || target.classList.value.includes('xmlsample') ) return;
      e.preventDefault();

      const href = target.tagName === 'A' ? target.getAttribute('href') : target.parentNode.getAttribute('href');
      href && window.location.assign(href) //navigate(href)
    }
  }, []);

  return handleAnchorClick;
};

export default useHandleAnchorClick;