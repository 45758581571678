import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { LangContext } from "../../App/App";
import { useAdobeAnalytics } from "../../../providers/AdobeAnalytics";
import useHandleAnchorClick from "../../../hooks/useHandleAnchorClick";
import useWindowSize from "../../../assets/customHooks/useWindowSize";

import SupportStatusToggle from "../../../components/SupportStatusToggle/SupportStatusToggle";
import RealTimeStatusGraph from "../../../components/RealTimeStatusGraph/RealTimeStatusGraph";
import HistoricalDataGraph from "../../../components/HistoricalDataGraph/HistoricalDataGraph";

import "./StatusPage.scss";

type Status = {
  color: string;
  label: string;
}[];

const services = {
	AirShopping: "/images/icons/airshopping.png",
	SeatAvailability: "/images/icons/seatavailability.png",
	ServiceList: "/images/icons/servicelist.png",
	OfferPrice: "/images/icons/offerprice.png",
	OrderCreate: "/images/icons/ordercreate.png",
	OrderRetrieve: "/images/icons/orderretrieve.png",
	OrderReshopShop: "/images/icons/orderreshop.png",
	OrderCancel: "/images/icons/ordercancel.png",
	OrderChange: "/images/icons/orderchange.png",
};

const StatusPage = ({ data }) => {
  const [statusType, setStatusType] = useState<string>();	
  const { width } = useWindowSize();
  const [status, setStatus] = useState<Status>();
  const [label, setLabel] = useState<string>();
  const [supportMessage, setSupportMessage] = useState() as any;
  const [jsonData, setJSONData] = useState() as any;
  
  const { getPageVariation, getSubPageVariation, getCurrentPageTitle } = useAdobeAnalytics();
  const handleAnchorClick = useHandleAnchorClick();

  const langContext = useContext(LangContext);

	const isMobile = width! < 850;

	useEffect(() => {
		if (data) {
			const json = data["support.status-monitoring"];
			setJSONData(json);
			setStatus(json.status);
			setLabel(json.headerTitleOne);
			setStatusType(json["section.header"].toggle[0]);
			setSupportMessage(json.supportMessage);
		}
	}, [data]);

	const StatusRendered = React.memo(() => (
		<div className="statusIndicator">
			{status?.map((item, index) => (
				<div className="statusItem" key={index}>
					<div className="statusColor" style={{ backgroundColor: item.color }}></div>
					<label>{item.label}</label>
				</div>
			))}
		</div>
	));

	const handleToggleDataViewClick = () => {
		setLabel(prevLabel => prevLabel === jsonData.headerTitleTwo 
			? jsonData.headerTitleOne 
			: jsonData.headerTitleTwo
		);
	};

	useEffect(() => {
		if(label && statusType){
			getCurrentPageTitle("website status monitoring")
			getSubPageVariation(label)
			getPageVariation(statusType)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [label, statusType])

	return (
		<>
			{jsonData && (
				<Box className="statusPageContainer">
					<Box className="statusContentContainer">
						<SupportStatusToggle statusType={statusType} setStatus={setStatusType} data={jsonData["section.header"]}/>
						<Box className="statusInfoContainer">
							<div className="statusTopInfoContainer">
								<div style={{width: "50%"}}>
									<h3>{label}</h3>
									{!isMobile && <StatusRendered />}
								</div>
								<div className="toggleDataViewContainer">
									<div className="toggleDataView" onClick={handleToggleDataViewClick}>
										{langContext.selectedLanguage === "English" && "Show"} {label === jsonData.headerTitleTwo ? jsonData.headerTitleOne : jsonData.headerTitleTwo}
									</div>
								</div>
							</div>
							{isMobile && (
								<div className="statusSubheading">
									<span dangerouslySetInnerHTML={{ __html: label === jsonData.headerTitleOne 
										? jsonData.subheadings.headingOne 
										: jsonData.subheadings.headingTwo }} 
										onClick={handleAnchorClick}
									/>
									<StatusRendered />
								</div>
							)}
						</Box>
						<Box className="graphContainer">
							{label !== jsonData.headerTitleOne ? (
								<HistoricalDataGraph statusType={statusType} services={services}/>
							) : (
								<RealTimeStatusGraph statusType={statusType} services={services} />
							)}
						</Box>
						<div className="technicalSupportBanner">
							<div className="technicalSupportBannerText"  dangerouslySetInnerHTML={{ __html: supportMessage }} onClick={handleAnchorClick}></div>
						</div>
					</Box>
				</Box>
			)}
		</>
	);
};

export default React.memo(StatusPage);
