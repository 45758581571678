import './SellerProfile.scss';
import { Box } from '@mui/material';
import { LangContext } from '../../pages/App/App';
import { showAPIerror } from './APIs/Helpers';
import { sllrProfAxios } from '../../pages/SellerProfile/APIs/axiosDefault';
import { SpAPIgetAllUsers, getStatus } from './APIs/SellerProfileAPI';
import { useEffect, useState, createContext, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiCredentials from './ApiCredentials/ApiCredentials';
import CompanyProfile from './CompanyProfile/CompanyProfile';
import getSellerAndUserIDs from './APIs/GetSellerUserIDs';
import NotificationsRecipients from './NotificationsRecipients/NotificationsRecipients';
import OnboardingCompleteModal from '../../components/SellerProfile/OnboardingCompleteModal/OnboardingCompleteModal';
import SellerProfileHeaderContainer from '../../components/SellerProfile/SellerProfileHeaderContainer/SellerProfileHeaderContainer';
import SPLandingPage from './LandingPage/SPLandingPage';
import SPQuestionnaire from './Questionnaire/SPQuestionnaire';
import SPUserSettings from './EditMySettings/EditMySettings';
import SuccessPopUp from '../../components/SellerProfile/SuccessPopUp/SuccessPopUp';
import UserList from './UserList/UserList';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const UserContext = createContext({
  apiCreds: {} as any,
  apiCredTypes: {} as any,
  apiStatus: '' as string,
  compProfile: {} as any,
  coName: '' as string,
  defaultCompData: {} as any,
  disabledBttn: {} as any,
  errorMsg: '' as string,
  filterUsers: '' as string,
  formLoading: false as boolean,
  formUpdates: {} as any,
  isAddingUser: false as boolean,
  isAdmin: false as boolean,
  isFrench: false as boolean,
  modalType: '' as string,
  notificationRecipients: {} as any,
  notificationTypes: {} as any,
  notifRecips: {} as any,
  onboardingStatus: {} as any,
  seller_id: '' as string,
  showingUsers: 'true' as string,
  showSuccessPopUp: '' as string,
  onboardCompletePopupDetails: {
    show: false,
    navigate: false,
    close: false
  } as any,
  spPageText: '' as string,
  surveyDetails: {} as any,
  user_id: '' as string,
  users: {} as any,
  setApiCreds: (user: any) => {},
  setApiCredTypes: (filterUsers: any) => {},
  setApiStatus: (showSuccessPopUp: string) => {},
  setCompProfile: (user: any) => {},
  setCoName: (user: string) => {},
  setDefaultCompData: (user: any) => {},
  setDisabledBttn: (user: any) => {},
  setErrorMsg: (filterUsers: string) => {},
  setFilterUsers: (filterUsers: string) => {},
  setFormLoading: (formLoading: boolean) => {},
  setFormUpdates: (user: string) => {},
  setIsAddingUser: (isAddingUser: boolean) => {},
  setModalType: (user: string) => {},
  setNotificationRecipients: (user: any) => {},
  setNotificationTypes: (filterUsers: any) => {},
  setNotifRecips: (user: any) => {},
  setOnboardingStatus: (user: any) => {},
  setShowingUsers: (showingUsers: string) => {},
  setShowSuccessPopUp: (showSuccessPopUp: string) => {},
  setOnboardCompletePopupDetails: (details: any) => {},
  setSurveyDetails: (user: any) => {},
  setUser_id: (user: string) => {},
  setUsers: (user: any) => {}
});

export const useSellerProfile = () => {
  return useContext(UserContext);
};

const SellerProfile = ({ data, coName, setCoName, logoHeight }) => {
  const navigate = useNavigate();

  const [apiCreds, setApiCreds] = useState<object>({});
  const [apiCredTypes, setApiCredTypes] = useState<object>({});
  const [apiStatus, setApiStatus] = useState('Loading...');
  const [compProfile, setCompProfile] = useState<object>({});
  const [defaultCompData, setDefaultCompData] = useState<object>({});
  const [disabledBttn, setDisabledBttn] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const [filterUsers, setFilterUsers] = useState('');
  const [formLoading, setFormLoading] = useState(false); //a bit hacky, but otherwise the button flashes at top before form generates/loads (button is separate component so can easily adjust placement relative to form fields)...
  const [formUpdates, setFormUpdates] = useState({});
  const [isAddingUser, setIsAddingUser] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFrench, setIsFrench] = useState(false);
  const [modalType, setModalType] = useState('');
  const [notificationRecipients, setNotificationRecipients] = useState<object>(
    {}
  );
  const [notificationTypes, setNotificationTypes] = useState<object>({});
  const [notifRecips, setNotifRecips] = useState<object>({});
  const [onboardingStatus, setOnboardingStatus] = useState<object>({});
  const [seller_id, setSeller_id] = useState('');
  const [showingUsers, setShowingUsers] = useState('true');
  const [showSuccessPopUp, setShowSuccessPopUp] = useState('');
  const [onboardCompletePopupDetails, setOnboardCompletePopupDetails] =
    useState({
      show: false,
      navigate: false,
      close: false
    });
  const [spPageText, setSpPageText] = useState(data.dashboard);
  const [surveyDetails, setSurveyDetails] = useState<object>({});
  const [trackSSOattempts, setTrackSSOattempts] = useState(0);
  const [user_id, setUser_id] = useState('');
  const [users, setUsers] = useState<object>({});

  const langContext = useContext(LangContext);
  const location = useLocation();
  const loaded = users && Object.keys(users).length > 0;
  let onPage = location.pathname.split('/').pop();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const pages = {
    notifications: {
      title: spPageText.updateNotificationList,
      component: <NotificationsRecipients />
    },
    users: {
      title: spPageText.updateUserList,
      component: <UserList />
    },
    profile: {
      title: spPageText.updateProfileDetails,
      component: <CompanyProfile />
    },
    questionnaire: {
      title: spPageText.questionnaire,
      component: <SPQuestionnaire />
    },
    'user-settings': {
      title: spPageText.mySettings,
      component: <SPUserSettings />
    },
    'api-credentials': {
      title: spPageText.updateAPICredentials,
      component: <ApiCredentials />
    }
  };

  const context = {
    apiCreds,
    apiCredTypes,
    apiStatus,
    compProfile,
    coName,
    defaultCompData,
    disabledBttn,
    errorMsg,
    filterUsers,
    formLoading,
    formUpdates,
    isAddingUser,
    isAdmin,
    isFrench,
    modalType,
    notificationRecipients,
    notificationTypes,
    notifRecips,
    onboardingStatus,
    seller_id,
    setCoName,
    showingUsers,
    showSuccessPopUp,
    onboardCompletePopupDetails,
    spPageText,
    surveyDetails,
    user_id,
    users,
    setApiCreds,
    setApiCredTypes,
    setApiStatus,
    setCompProfile,
    setDefaultCompData,
    setDisabledBttn,
    setErrorMsg,
    setFilterUsers,
    setFormLoading,
    setFormUpdates,
    setIsAddingUser,
    setModalType,
    setNotificationRecipients,
    setNotificationTypes,
    setNotifRecips,
    setOnboardingStatus,
    setShowingUsers,
    setShowSuccessPopUp,
    setOnboardCompletePopupDetails,
    setSurveyDetails,
    setUser_id,
    setUsers
  };

  useEffect(() => {
    setIsFrench(langContext.selectedLanguage === 'fr');

    //get and set user_id and seller_id
    const getSellerUserIDs = () => {
      if (seller_id === '' && user_id === '') {
        getSellerAndUserIDs(
          ['user_id', 'seller_id'],
          [setUser_id, setSeller_id],
          context
        )
          .then((res) => res)
          .catch((err) => showAPIerror(err, context, 'getSellerUserIDs'));
      }
    };

    if (sllrProfAxios.defaults.headers.common['Authorization'] !== undefined) {
      getSellerUserIDs();
    } else if (trackSSOattempts < 3) {
      setTimeout(() => {
        const track = trackSSOattempts + 1;
        setTrackSSOattempts(track);
        getSellerUserIDs();
      }, 2000);
    } else {
      showAPIerror(
        { response: { status: 403 } },
        context,
        'authorizationHeader'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sllrProfAxios.defaults.headers.common]);

  useEffect(() => {
    if (seller_id !== '') {
      SpAPIgetAllUsers(context)
        .then((res) => {
          setApiStatus('');
          const checkIfAdminUser = () => {
            const currUser = res?.filter((user) => user.user_id === user_id);

            return currUser[0]?.role_id === 'USR001';
          };

          setIsAdmin(checkIfAdminUser());
          res && setUsers(res);
          return checkIfAdminUser;
        })
        .then((checkIfAdminUser) => {
          if (checkIfAdminUser()) {
            getStatus(context);
          }
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            setApiStatus(spPageText.errorSessionExpired);
          } else {
            console.log('err-sp', err);
            setApiStatus(spPageText.errorAPI);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller_id]);

  useEffect(() => {
    setIsFrench(langContext.selectedLanguage === 'fr');
    setSpPageText(data.dashboard);
  }, [langContext.selectedLanguage, data.dashboard]);

  return loaded && apiStatus === '' ? (
    <UserContext.Provider value={{ ...context }}>
      <div
        className="sellerProfile"
        style={{ marginTop: `${logoHeight - (mobile ? 50 : 30)}px` }}
      >
        {onPage === 'seller-profile' && <SPLandingPage />}
        {showSuccessPopUp !== '' && (
          <SuccessPopUp
            topPx={onboardCompletePopupDetails.show ? '120px' : '180px'}
          />
        )}
        <OnboardingCompleteModal
          profileName={context.coName}
          open={onboardCompletePopupDetails.show}
          handleOnClickBtn={() => {
            if (onboardCompletePopupDetails.navigate) {
              setOnboardCompletePopupDetails((prev) => ({
                ...prev,
                show: false
              }));
              navigate(`/${langContext.selectedLanguage}/seller-profile`);
            } else {
              setOnboardCompletePopupDetails((prev) => ({
                ...prev,
                show: false
              }));
            }
          }}
        />
        {onPage !== 'seller-profile' && (
          <SellerProfileHeaderContainer
            title={onPage && pages[onPage]['title']}
            render={() => onPage && pages[onPage]['component']}
          />
        )}
      </div>
    </UserContext.Provider>
  ) : (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
        padding: '50vh 20px',
        fontWeight: 'bold',
        fontSize: '14px'
      }}
      dangerouslySetInnerHTML={{ __html: apiStatus }}
    ></Box>
  );
};

export default SellerProfile;
