import "./BenefitsCoupons2.scss";
import useWindowSize from "../../assets/customHooks/useWindowSize";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";

const BenefitsCoupons = ({ data }) => {
	const size = useWindowSize();
	const isMobile = size.width! < 900;
	const handleAnchorClick = useHandleAnchorClick();
	const mobileTable = (table) => {
		if (size?.width! > 900) return table;

		const parser = new DOMParser();
		const doc = parser.parseFromString(table, "text/html");
		const innerTables = doc.querySelectorAll(".innerTable");

		innerTables.forEach((table) => {
			const countries = Array.from(table.querySelectorAll("td")).map((td) => td.textContent);
			table.innerHTML = `<tbody>${countries
				.map((country) => `<tr><td>${country}</td></tr>`)
				.join("")}</tbody>`;
		});

		return doc.body.innerHTML;
	};

	return (
		<div className="coupon-incentive" aria-labelledby="coupon-incentive-id">
			<article className="coupon-incentive-left">
				<h2 id="coupon-incentive-id">{data.title}</h2>
				<p>{data.paragraph1}</p>
				<p>{data.paragraph2}</p>
				<p><i>{data.paragraph3}</i></p>
				{!isMobile && (
					<img
						className="coupon-incentive-icon"
						src={process.env.PUBLIC_URL + data.image}
						alt="header"
						aria-hidden={true}
					/>
				)}
			</article>
			<div
				className="coupon-incentive-right"
				dangerouslySetInnerHTML={{ __html: mobileTable(data.table) }}
				onClick={handleAnchorClick}
			></div>
			{isMobile && (
				<img
					className="coupon-incentive-icon"
					src={process.env.PUBLIC_URL + data.image}
					alt="header"
				/>
			)}
		</div>
	);
};

export default BenefitsCoupons;
