import './Issue.scss';
import {
  Field,
  Issue as IssueInterface,
  Statuses
} from '../../../../models/knownIssues';
import { Grid, useMediaQuery } from '@mui/material';
import { KeyboardEvent, useState } from 'react';
import { useTheme } from '@mui/system';
import DetailsDialog from './DetailsDialog/DetailsDialog';
import StatusBadge from '../StatusBadge/StatusBadge';

interface Props {
  issue: IssueInterface;
  issuesIndex: number;
  statuses: Statuses;
}

const Issue = ({ issue, issuesIndex, statuses }: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  const colFields = issue.fields
    .filter((field) => field.order !== undefined)
    .sort((a, b) => a.order - b.order);

  const detailFields = issue.fields
    .filter((field) => field.detailsOrder !== undefined)
    .sort((a, b) => (a.detailsOrder ?? 0) - (b.detailsOrder ?? 0));

  const getHeaderClassName = (index: number, link?: string) => {
    let className = 'issue-header';

    if (index !== 0) {
      className = className + ' issue-header--mt';
    }

    if (link) {
      className = className + ' issue-header--link';
    }

    return className;
  };

  const getIssueClassName = () => {
    let className = 'issue';

    if (issuesIndex % 2 !== 0) {
      className = className + ' background-alco-sno issue--pb';
    }

    return className;
  };

  const handleOnClickViewDetails = (field: Field) => {
    if (field.id === 2) {
      setShowDetailsDialog(true);
    }
  };

  const handleOnKeyDownViewDetails = (
    event: KeyboardEvent<HTMLDivElement>,
    field: Field
  ) => {
    if (event.key === 'Enter' && field.id === 2) {
      setShowDetailsDialog(true);
    }
  };

  const handleOnKeyDownCloseViewDetails = (
    event: KeyboardEvent<SVGSVGElement>
  ) => {
    if (event.key === 'Enter') {
      setShowDetailsDialog(false);
    }
  };

  return (
    <>
      <Grid className={getIssueClassName()} container mt={issuesIndex === 0 ? 40 : 0} spacing={2}>
        <Grid
          item
          md={2}
          style={{
            display: isDesktop ? 'block' : 'flex',
            justifyContent: 'space-between'
          }}
          xs={12}
        >
          <h1 className="issue-title">{issue.title}</h1>
          <StatusBadge
            status={issue.status}
            statuses={statuses}
            parentComponentName="Known Issues"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {colFields.slice(0, 3).map((field, index) => (
            <Grid item xs={12} key={index}>
              <div
                className={getHeaderClassName(index, field.link)}
                onClick={() => handleOnClickViewDetails(field)}
                tabIndex={field.id === 2 ? 0 : undefined}
                onKeyDown={(e) => handleOnKeyDownViewDetails(e, field)}
              >
                {field.header}
              </div>
              {field.value && <div>{field.value}</div>}
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          {colFields.slice(3, 6).map((field, index) => (
            <Grid item xs={12} key={index}>
              <div className={getHeaderClassName(index)}>{field.header}</div>
              {field.value && <div>{field.value}</div>}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <DetailsDialog
        issueId={issue.title}
        fields={detailFields}
        open={showDetailsDialog}
        status={issue.status}
        statuses={statuses}
        handleOnClose={() => setShowDetailsDialog(false)}
        handleOnKeyDown={(e) => handleOnKeyDownCloseViewDetails(e)}
      />
    </>
  );
};

export default Issue;
