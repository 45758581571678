import { Box } from "@mui/material";
import Questionnaire from "../SPQuestionnaire/Questionnaire";

import "./SPQuestionnaire.scss";


function SPQuestionnaire() {

  return (
    <Box className="spQuestionnaire">
      <Questionnaire /> 
    </Box>
  );
}

export default SPQuestionnaire;
