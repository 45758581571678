import './OnboardingCompleteModal.scss';
import { Box, Button, Modal, Typography } from '@mui/material';
import Handshake from '../../../assets/images/handshake.svg';
import RectangleBackground from '../../../assets/images/Rectangle906.svg';

const style = {
  bgcolor: 'background.paper',
  border: 'none !important',
  borderRadius: '4px',
  boxShadow: 24,
  left: '50%',
  maxWidth: 598,
  minWidth: 343,
  outline: 'none',
  position: 'absolute' as 'absolute',
  top: '58%',
  transform: 'translate(-50%, -50%)',
  width: '80%'
};

const headerStyle = {
  alignItems: 'center',
  backgroundImage: `url(${RectangleBackground})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  height: '145px',
  justifyContent: 'center'
};

const imageBoxStyle = {
  display: 'ruby-text',
  marginTop: '-13px',
  width: '100%'
};

const lineStyle = {
  backgroundColor: '#B9D9EB',
  height: '2px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '16px',
  width: '28px'
};

const titleStyle = {
  color: '#005078',
  fontSize: '30px',
  fontWeight: 600
};

const OnboardingCompleteModal = ({ profileName, open, handleOnClickBtn }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={headerStyle}>
          <Typography id="modal-modal-title" style={titleStyle}>
            Onboarding Complete
          </Typography>
        </Box>
        <Box style={imageBoxStyle}>
          <img alt="handshake" src={Handshake}></img>
        </Box>
        <Typography sx={{ mt: 3, fontSize: '12px', textAlign: 'center' }}>
          {profileName} is now ready to build.
        </Typography>
        <Typography sx={{ mt: 3, fontSize: '12px', textAlign: 'center' }}>
          Here is what to expect from the build phase:
        </Typography>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{ fontWeight: 600, fontSize: '14px', textAlign: 'center' }}
            >
              Getting your support channels set-up
            </Typography>
            <Box sx={lineStyle} />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{ fontWeight: 600, fontSize: '14px', textAlign: 'center' }}
            >
              Gaining access to our test environment
            </Typography>
            <Box sx={lineStyle} />
          </Box>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              mt: 2,
              textAlign: 'center'
            }}
          >
            Participation in project meetings
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
          <Button className="btn-got-it" onClick={handleOnClickBtn}>
            Got it
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default OnboardingCompleteModal;
