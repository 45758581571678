import { useState, useEffect, useContext } from "react";
import "./SellerRegistration.scss";
import ReviewSubmit from "./sections/reviewAndSubmit";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FormSection from "./sections/formSection";
import { format } from "date-fns";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import {submitFormData, getFormData} from "./util/submitFormData";
import { LangContext } from "../App/App";
import { useNavigate } from "react-router-dom";
import { useAdobeAnalytics } from "../../providers/AdobeAnalytics"
import {validateInput} from "../util/validateFormInput"; 

const SellerRegistration = ({ data }) => {
  const numFormPages = 4;
  const [formPage, setFormPage] = useState(1);
  const [formData, setFormData] = useState<any>(null);
  const [postData, setPostData] = useState<any>({});
  const [disabled, setDisabled] = useState(true);
  const [sections, setSections] = useState<any>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [startForm, setStartForm] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getCurrentPageTitle, adobeSiteInteraction } = useAdobeAnalytics();

  const langContext = useContext(LangContext);

  let formDataTemp = {};

  const initFormData = async () => {
    formDataTemp = { ...data["sections"] };
    let tempSections: string[] = [];

    const {pos,
           posOther, 
           businessModel, 
           businessModelIDs, 
           customerTypes, 
           customerTypeIDs,
           apiSchemas
          } = await getFormData(langContext.selectedLanguage === "fr");

    let sectionKeys = Object.keys(formDataTemp);
     
    sectionKeys.forEach((section) => {
   
 
      if (formDataTemp[section]["subsections"]) {
        tempSections[formDataTemp[section]["weight"] - 1] = section;

        let subsections = Object.keys(formDataTemp[section]["subsections"]);
        subsections.forEach((subsection) => {
          let fields = Object.keys(
            formDataTemp[section]["subsections"][subsection]["fields"]
          );
          fields.forEach((field) => {
            formDataTemp[section]["subsections"][subsection]["fields"][field][
              "content"
            ] = "";
          });
        });
      }
    });

    formDataTemp["impDetails"]["subsections"]["SellerPOS"]["fields"]["wantPOSsupport"]["options"] = pos;
    formDataTemp["impDetails"]["subsections"]["SellerPOS"]["fields"]["posTypes"]["options"] = posOther;
    formDataTemp["impDetails"]["subsections"]["BizModel"]["fields"]["customerType"]["options"] = customerTypes;
    formDataTemp["impDetails"]["subsections"]["BizModel"]["fields"]["chooseBizModel"]["options"] = businessModel;
    formDataTemp["impDetails"]["subsections"]["ImpDetails"]["fields"]["apiSchemaVers"]["options"] = apiSchemas;

    formDataTemp["BusinessModelIDs"] = businessModelIDs;
    formDataTemp["CustomerTypeIDs"] = customerTypeIDs;

    //filter below deals w/ mistakenly empty/missing position weights in JSON
    setSections(tempSections.filter((n) => n));
    setFormData(formDataTemp);
    
  };
  


  const handleSubmit = async () => {
    setIsSubmitting(true);
    const trimmedPostData = {}; 
    Object.keys(postData).forEach((key) =>{ trimmedPostData[key] = (typeof postData[key] === 'string' || postData[key] instanceof String) ?  postData[key]?.toString()?.trim() : postData[key]} )
 
    const response = await submitFormData(trimmedPostData, formData["BusinessModelIDs"], formData["CustomerTypeIDs"]);
   if(response){
     
    setIsSubmitting(false);
      setShowConfirmation(true);
      adobeSiteInteraction("form sent")
      window.scrollTo({ top: 0, behavior: "smooth" });
     // navigate(`/${langContext.selectedLanguage}/seller-registration`);
    } else { 
      setIsSubmitting(false);
      setSubmitError(true);
    }
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/${langContext.selectedLanguage}/seller-registration`);
  };

  const checkError = (curr, sub, field, section) => {
    const doValidate = validateInput(curr);
    const returnVal = doValidate.error;
    let updatedValue = formData;

    updatedValue[section]["subsections"][sub]["fields"][field]["error"] =
      returnVal;

    setFormData(() => ({
      ...formData,
      ...updatedValue,
    }));
  };

  useEffect(() => {
    getCurrentPageTitle("air canada ndc registration");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    data && initFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    formData &&
      Object.entries(formData).forEach(([key]) => {
        if (formData[key]["weight"] === formPage && formPage !== 4) {
          setDisabled(checkBttnDisabled(key));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPage]);

 



  const checkBttnDisabled = (section) => {
    //TODO = update to deal with field text/value length requirements...

    let isDisabled = false;

    let subSectionKeys = Object.keys(formData[section]["subsections"]);

    subSectionKeys.forEach((sub) => {
      if (
        formData[section] &&
        formData[section]["subsections"] &&
        formData[section]["subsections"][sub]
      ) {
        let fields = Object.keys(
          formData[section]["subsections"][sub]["fields"]
        );
        fields.forEach((field) => {
          const curr = formData[section]["subsections"][sub]["fields"][field];
          if (!isDisabled) {
            const isEmpty = Array.isArray(curr["content"]) ? curr["content"].length === 0 : curr["content"].trim() === "";
         
            if (
              (curr["required"] === "true" && isEmpty) ||
              curr["error"] === "true"
            ) {
              isDisabled = true;
            } else if (
              curr["required"] === "true" ||
              (curr["validate"] && curr["content"].trim() !== "")) {
              isDisabled = validateInput(curr).error;
            }
          }
        });
      }
    });

    return isDisabled;
  };
  const [showConditionals, setShowConditionals] = useState<any>([]);

  const handleChange = (e, section, subsection, field) => {
    if(!startForm){
      adobeSiteInteraction("form start")
      setStartForm(true)
    }
    //Handle all field inputs (from formSection.tsx)
    let updatedValue =
      formData[section]["subsections"][subsection]["fields"][field];

    let newContent = "";

    if (e && e.target) {
      if (e.target.type === "checkbox") {
        //toggle check values
        newContent = e.target.value === "true" ? "false" : "true";
      } else {
        newContent = e.target.value;
      }
    } else if (e instanceof Date) {
      const formatDate = new Date(e);
      newContent = format(formatDate, "PP");
    }
    //List conditional fields only showing if "controller" checkbox field checked (TODO: modify for text field filled if needed)
    if (
      updatedValue["conditional"] &&
      updatedValue["conditional"]["type"] === "control"
    ) {
      const updateConditionals = [...showConditionals];
      if (e.target.value === "false") {
        //Note: looking for False here b/c is the pre-toggled state
        updateConditionals.push(updatedValue["conditional"]["field"]);
      } else {
        updateConditionals.splice(
          updateConditionals.indexOf(updatedValue["conditional"]["field"]),
          1
        );
      }
      setShowConditionals(updateConditionals);
    }
    updatedValue["content"] = newContent;

    const updateName =
      e && e?.target?.value
        ? e.target.name
        : field === "whenStart"
        ? "implementation_start_date"
        : null;
    updateName &&
      setPostData(() => ({
        ...postData,
        [updateName]:
          updateName === "implementation_start_date" ? e : e.target.value,
      }));

    setFormData(() => ({
      ...formData,
      ...updatedValue,
    }));

    setDisabled(checkBttnDisabled(section));
  };

  const handleNext = () => {
    setFormPage(formPage + 1);
    setDisabled(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePrev = () => {
    setFormPage(formPage - 1);
    setDisabled(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const showFlowPg = (pg) => setFormPage(pg);
  const allNavKeys = [...sections, "reviewSubmit"];
  return formData && sections.length > 0 && !showConfirmation ? (
    <div id="sellerRegPgContainer" className={`${mobile ? "isMobile" : ""}`}>
      <div id="sellerRegNavFlow">
        <h3>{data["title"]}</h3>
        {Object.entries(allNavKeys).map(([k, v]) => {
          const curr = formData[v];
          const atPg = parseInt(k) + 1;
          return (
            curr["heading"] && (
              <div className="SellerRegSectionIndicatorBttn" key={k}>
                {/* Show completed page check badge */}
                {formPage > curr["weight"] && (
                  <div
                    className={
                      atPg < numFormPages
                        ? "formFlowLineContainer"
                        : "formFlowNoLine"
                    }
                  >
                    <div className={atPg < numFormPages ? "formFlowLine" : ""}>
                      <CheckCircleIcon className="flowChckMrkBadge" />
                    </div>
                  </div>
                )}
                {/* Show page number badge */}
                {formPage <= curr["weight"] && (
                  <div
                    className={
                      atPg < numFormPages
                        ? "formFlowLineContainer"
                        : "formFlowNoLine"
                    }
                  >
                    <div className={atPg < numFormPages ? "formFlowLine" : ""}>
                      <div className={"flowPgBadge"}>{atPg}</div>
                    </div>
                  </div>
                )}
                {/* Show page links (Current or completed : to be completed */}
                {atPg <= formPage || !disabled ? (
                  <span
                    onClick={() => showFlowPg(atPg)}
                    className={
                      formPage === curr["weight"]
                        ? "currPg formPgTitle"
                        : "formPgTitle"
                    }
                  >
                    {curr["heading"]} 
                  </span>
                ) : (
                  <span className="formPgTitle disabled">
                    {curr["heading"]}
                  </span>
                )}
              </div>
            )
          );
        })}
      </div>
      <div id="sellerRegContent">
        {formPage < numFormPages && (
          <>
            <div className={"flowPgBadge badgeHeader"}>{formPage}</div>
            <FormSection
              formData={formData}
              handleChange={handleChange}
              section={sections[formPage - 1]}
              checkError={checkError}
              showConditionals={showConditionals}
              noneText={data.noneText}
            />
          </>
        )}
        {formPage === numFormPages && (
          <ReviewSubmit
            submitButton={data["sections"]["reviewSubmit"]["button"]}
            heading={data["sections"]["reviewSubmit"]["heading"]}
            formData={formData}
            numSections={numFormPages}
            handleSubmit={handleSubmit}
            submitError={submitError}
            isSubmitting={isSubmitting}
          />
        )}
        <div id="formBackNextBttns">
          {formPage > 1 && (
            <Button
              variant="text"
              className="formBackBttn"
              onClick={(e) => handlePrev()}
            >
              <ChevronLeftIcon /> {data.buttons.back}
            </Button>
          )}

          {formPage < numFormPages && (
            <Button
              variant="contained"
              onClick={(e) => handleNext()}
              disabled={disabled}
              className={`formNextBttn ${formPage === 1 && "firstFormPg"}`}
            >
              {data.buttons.next}
            </Button>
          )}
        </div>
      </div>
    </div>
  ) : (
    data && (
      <ConfirmationModal
        showModal={showConfirmation}
        handleCloseConfirmation={handleCloseConfirmation}
        data={data}
      />
    )
  );
};

export default SellerRegistration;
