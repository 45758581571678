import React from "react";
import { Typography } from "@mui/material";
import { GenericError } from "../../components/AppError/AppError";
import "./DesktopAndMobileTables.scss";

const mobileViewTable = (table: string, tableLabel?: string, handleAnchorClick?: any) => {
 
  let header = [] as any;
  let body = [] as any;
  let mainSection = [] as any;
  
  let i = 0;

  const parser = new DOMParser();
  const doc = parser.parseFromString(table, "text/html");
  const tableElement = doc.querySelector("table");

  const headers = tableElement?.querySelectorAll("th");
  headers?.forEach((head) => {
    if (
      !head["className"].includes("subsectionHead") &&
      !head["className"].includes("ignoreMobileTbl") && head['scope'] !== 'row'
    ) {
      header.push(head.innerHTML);
    } 
  });
  let tableContainsSubheads = false;
  const rows = tableElement?.querySelectorAll("tr");
  let subSection = Array(rows?.length || 0).fill(null);
  rows?.forEach((row) => {
    const columns = row.querySelectorAll("td, th");
    columns.forEach((column, i) => {
      if (!column.classList.toString().includes("ignoreMobileTbl")) {
      
        if (
         ( column["nodeName"] === "TD" &&
          !column.classList.toString().includes("subsectionHead")) ||
          ( column["nodeName"] === "TH" &&
          !column.classList.toString().includes("subsectionHead") && column["scope"] === 'row')
        ) {
          body.push(column.outerHTML.trim().replace("&nbsp;", " "));
          subSection.push("");
          mainSection.push("");
        } else if (
        (  column["nodeName"] === "TD" &&
          column.classList.toString().includes("subsectionHead"))||
          ( column["nodeName"] === "TH" &&
          column.classList.toString().includes("subsectionHead") && column["scope"] === 'row')
        ) {
          subSection[body.length] = column.innerHTML
            .trim()
            .replace("&nbsp;", " ");
        } else if (
          column["nodeName"] === "TH" &&
          column.classList.toString().includes("subsectionHead")
        ) {
          mainSection.pop();
          mainSection.push(column.innerHTML.trim().replace("&nbsp;", " "));
        }
      }
    });
  });

  // slicing the tablebody length by header length to make sure dividing the table data
  // equally between header  -- but need to find a better approach for header and data mapping

  if (body?.length && header?.length) {
    let showSubHeadNext = false;
    let nextSubhead = <></>;
    return body.slice(0, body.length / header.length).map((obj, index = i) => {
		 
      if (subSection[i] && subSection[i] !== "") {
        showSubHeadNext = true;
        nextSubhead = (
          <div
            className="mobileViewTableRow  tableWithSubheads containsSubHead"
            key={`subHead-${i}`}
          >
            <div className="subsectionHead" dangerouslySetInnerHTML={{ __html: subSection[i] }} onClick={handleAnchorClick}></div>
          </div>
        );
      } else {
        showSubHeadNext = false;
      }

	  let showMainHeadNext = false;
	  let nextMainhead = <></>;
	  if (mainSection[i] && mainSection[i] !== "") {
        showMainHeadNext = true;
        nextMainhead = (
          <div
            className="mobileViewTableRow  tableWithSubheads containsMainHead"
            key={`mainHead-${i}`}
          >
            <div className="mainsectionHead"  dangerouslySetInnerHTML={{ __html: mainSection[i] }} onClick={handleAnchorClick}></div>
          </div>
        );
      } else {
        showMainHeadNext = false;
      }
      return (
        <React.Fragment  key={`row-${i}`}>
		   {showMainHeadNext && nextMainhead}
          {showSubHeadNext && nextSubhead}
          <div
            className={`mobileViewTableRow ${
              tableContainsSubheads && "tableWithSubheads"
            }`}
          
          >
            {header.map((head, index) => {
              i++;

              return (
                <React.Fragment key={index}>
                  <div className="mobTableRowSubRow">
                    <Typography
                      className="mobileRowHeader"
                      dangerouslySetInnerHTML={{ __html: head }}
                      onClick={handleAnchorClick}
                    ></Typography>
                    <Typography
                      className="mobileRowData"
                      dangerouslySetInnerHTML={{ __html: body[i - 1] }}
                    ></Typography>
                  </div>
                
                </React.Fragment>
              );
            })}
          </div>
        </React.Fragment>
      );
    });
  } else if (header?.length && !body?.length) {
    //this catches Scenarios where all that is returned is <table><th> data, i.e. no <td>

    return (
      <div className="mobileViewTableRow" key={`row-${i}`}>
        {header.map((head, index) => {
          return (
            <div key={index} className="mobTableRowSubRow">
              <Typography
                className="mobileRowHeader"
                dangerouslySetInnerHTML={{ __html: head }}
                onClick={handleAnchorClick}
              ></Typography>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <GenericError />;
  }
};

export default mobileViewTable;
