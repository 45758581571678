export const MARKET_TYPE = {
  supported: 'supported',
  upcoming: 'upcoming',
};

export const PAGES = {
  knownIssues: 'Known Issues'
};

export const STATUSES = {
  en: {
    inProgress: 'In Progress',
    planned: 'Planned',
    resolved: 'Resolved',
    underReview: 'Under Review'
  },
  fr: {
    inProgress: 'En progression',
    planned: 'Planifié',
    resolved: 'Résolu',
    underReview: 'En Révision'
  }
};
