import { ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type InputProps = {
  height: number;
  width: string;
};

const StyledTextField = styled(TextField)<InputProps>(({ height, width }) => ({
  borderBottom: '1px solid #e2e2e2',
  borderRadius: 0,
  height: `${height}rem`,
  width: width,

  '& label': {
    color: '#4A4F55',
    fontSize: '14px',
    marginTop: '-0.5rem'
  },

  '& label.Mui-focused': {
    color: '#4A4F55',
    fontSize: '14px',
    marginTop: '0.5rem'
  },

  '& .MuiInputBase-input': {
    fontSize: '16px'
  },

  '& .MuiFormLabel-filled': {
    color: '#737373',
    fontSize: '14px',
    marginTop: '0.5rem'
  },

  '& .MuiFormHelperText-root': {
    fontSize: '1rem'
  },

  '&:focus-within': {
    borderBottom: '2px solid black'
  }
}));

interface Props {
  label: string;
  width: string;
  height?: number;
  parentField?: TextFieldProps;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const AppText = ({
  label,
  width,
  height,
  value,
  parentField,
  setValue,
  onChange
}: Props) => {
  const inputProps = parentField
    ? { ...parentField.InputProps, disableUnderline: true }
    : { disableUnderline: true };
  const params = parentField ? parentField : {};

  return (
    <StyledTextField
      {...params}
      autoComplete="off"
      height={height ? height : 3.5}
      InputProps={inputProps}
      label={label}
      onChange={(e) => onChange && onChange(e)}
      value={value && value}
      variant="standard"
      width={width}
    />
  );
};

export default AppText;
