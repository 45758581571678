import './StatusBadge.scss';
import { Statuses } from '../../../../models/knownIssues';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

interface Props {
  status: string;
  statuses: Statuses;
  parentComponentName?: string;
}

const StatusBadge = ({ status, statuses, parentComponentName }: Props) => {
  const theme = useTheme();
  let isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const getBadgeClassName = () => {
    let className = 'status-badge';

    switch (status) {
      case statuses.resolved:
        className += ' status-badge--green';
        break;
      case statuses.underReview:
      case statuses.planned:
        className += ' status-badge--blue';
        break;
      case statuses.inProgress:
        className += ' status-badge--tan';
        break;
      default:
        break;
    }

    if (isDesktop && parentComponentName === 'Known Issues') {
      className = className + ' status-badge--mt';
    } else {
      className = className + ' status-badge--mr';
    }

    return className;
  };

  return <div className={getBadgeClassName()}>{status}</div>;
};

export default StatusBadge;
