import React, { useContext, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LangContext } from "../../pages/App/App";
import "./ComponentTabs.scss";

const ComponentTabs = ({ activeIndex, setActiveIndex, tabsLabel }) => {
  const langContext = useContext(LangContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };
  const handleClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const urls = ["commercial", "technical"];
  const handleTabClick = (index: number) => {
    setActiveIndex(index);
    setMenuOpen(false);
    setAnchorEl(null);
    window.history.replaceState(
      null,
      urls[index],
      `/${langContext.selectedLanguage}/support/faq/${urls[index]}`
    );
  };
  useEffect(() => {
    window.addEventListener("scroll", handleClose);
    return () => {
      window.removeEventListener("scroll", handleClose);
    };
  }, []);

  return (
    <>
      {isDesktop ? (
        <Box className="componentTabsContainer">
          <Tabs
            value={activeIndex}
            aria-label="Connection Options Tabs"
            className="connectionTabs"
          >
            {tabsLabel.map((label, index) => (
              <Tab
                key={`${label}-${index}`}
                value={index}
                label={label}
                onClick={() => handleTabClick(index)}
                tabIndex={0}
              />
            ))}
          </Tabs>
        </Box>
      ) : (
        <Box className="selectDropdown" onScroll={() => setAnchorEl(null)}>
          <List
            component="nav"
            aria-label={
              langContext.selectedLanguage === "fr"
                ? "Réglages de l'appareil"
                : "Device settings"
            }
            sx={{ bgcolor: "background.paper", margin: "0 auto" }}
          >
            <ListItem
              id="lock-button"
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-label={tabsLabel[activeIndex]}
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleClickListItem}
            >
              <ListItemText primary={tabsLabel[activeIndex]} />
              <KeyboardArrowDownIcon />
            </ListItem>
          </List>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
          >
            {tabsLabel.map((option: string, index: number) => (
              <MenuItem
                key={option}
                disabled={index === activeIndex}
                selected={index === activeIndex}
                onClick={() => handleTabClick( index)}
                role="button"
                aria-label={`${tabsLabel[activeIndex]}-item`}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </>
  );
};

export default ComponentTabs;
