import { useEffect, useState } from "react";
import { Box, Divider, Link } from "@mui/material";
import "./EditUserProfile.scss";
import SPForm from "../../Form/SPForm";
import editUserProfileForm from "../../Form/json/editUserProfileFormFields.json";
import { useSellerProfile } from "../../../../pages/SellerProfile/SellerProfile";
import SPFormButton from "../../Form/SPFormButton";
import { SpApiUpdateUser } from "../../../../pages/SellerProfile/APIs/SellerProfileAPI";
import GetLabels from "../../../../pages/SellerProfile/GetLabels";
import {getUserInfoById} from "../../../../pages/SellerProfile/APIs/Helpers";
import {
  doFormErrorMsgs
} from "../../../../pages/SellerProfile/APIs/Helpers";
export const EditUserProfile = ({ onRemove, curr_user_id }) => {
  const  { ...context } = useSellerProfile() as any;
  useEffect(() => {
    context.setFormLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [loading, setLoading] = useState(false)
  const formData = getUserInfoById(context.users,curr_user_id)

  const submitFormFields = async (formName) => {
    setLoading(true)
    const successMsg = GetLabels(`spPageText.${formName}Success`, context.spPageText);

    await SpApiUpdateUser(curr_user_id,context)
      .then(() => {
        setLoading(false)
        context.setModalType("");
        context.setShowSuccessPopUp(successMsg);
      })
      .catch((error) => {
        setLoading(false)
        doFormErrorMsgs(error,context.setErrorMsg,context.spPageText,false)
      });
  };

  return (
    <Box className="editUserCardPopupContainer">
      <Box className="editUserCardPopupBody">
        <SPForm
          cols="single"
          compName="editUserCardPopupForm editUser"
          formFields={editUserProfileForm}
          user_id={curr_user_id}
          formData={formData}
          formName="editUserProfile"
          key="editUserProfileForm"
        />
        <div className="editUserButtons">
          <Link onClick={() => onRemove() }   onKeyDown={(e) =>  e.key === "Enter" && onRemove()} className="removeUserButtonDT" tabIndex={0}>
            {context.spPageText.removeUser}
          </Link>
          {!context.formLoading && (
            <SPFormButton
              handleClick={() => submitFormFields("editUserProfile")}
              label={context.spPageText.saveChanges}
              compName="updateUserSettings"
              formName="editUserProfile"
              user_id={curr_user_id}
              showLoading={loading}
            />
          )}
          <Link onClick={() => onRemove()} onKeyDown={(e) =>  e.key === "Enter" && onRemove()} className="removeUserButtonMobile" tabIndex={0}>
            {context.spPageText.removeUser}
          </Link>
        </div>
        {/* </Box> */}
        <Divider className="editUserPopupSizer" sx={{opacity:0}} />
        {/* <Link className="viewNotificationsButton">
          {spPageText.viewNotifications}
        </Link> */}
      </Box>
    </Box>
  );
};
