import React, { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useWindowSize from "../../assets/customHooks/useWindowSize";
import Bar from "./Bar";
import "./BarGraph.scss";

interface BarGraphProps {
	service: string;
	data: {};
	renderTooltipContent: (data: any) => JSX.Element;
	type: string;
}

const BarGraph: React.FC<BarGraphProps> = ({ service, data, renderTooltipContent, type }) => {
	const { width } = useWindowSize();
	const count = Object.keys(data).length;
	const chunkSize = width! < 1100 ? 30 : Object.keys(data).length;
	const initialIndex = count - chunkSize >= 0 ? count - chunkSize : 0;

	const [activeBar, setActiveBar] = useState<number | null>(null);
	const [startIndex, setStartIndex] = useState(type === "daily" ? initialIndex + chunkSize : 0);

	const displayedData =
		type === "daily"
			? Object.entries(data).slice(startIndex - chunkSize, startIndex)
			: Object.entries(data).slice(startIndex, startIndex + chunkSize);

	const toLocal = (date) =>
		date.toLocaleString("en", {
			day: "numeric",
			month: "long",
			year: "numeric",
		});

	const startDate = toLocal(new Date(displayedData[0]?.[0] ?? ""));
	const endDate = toLocal(new Date(displayedData[displayedData.length - 1]?.[0] ?? ""));

	const handleMouseEnter = (index: number) => {
		setActiveBar(index);
	};

	const handleMouseLeave = () => {
		setActiveBar(null);
	};

	const nextChunk = () => {
		if (startIndex + chunkSize <= count) {
			setStartIndex(startIndex + chunkSize);
		}
	};

	const prevChunk = () => {
		if (startIndex - chunkSize >= chunkSize) {
			setStartIndex(startIndex - chunkSize);
		}
	};
	
	return (
		<div>
			{width! < 1100 && type === "daily" && (
				<div className="navigation">
					<KeyboardArrowLeftIcon onClick={prevChunk} aria-label="Previous date range" />
					<span>{`${startDate} - ${endDate}`}</span>
					<KeyboardArrowRightIcon onClick={nextChunk} aria-label="Next date range" />
				</div>
			)}
			<div className="bar-graph-container">
				{displayedData.map(([key, value], i) => {
					const dataSplit = key.split("-").map(part => parseInt(part, 10));
					const date = new Date(dataSplit[0], dataSplit[1] - 1, dataSplit[2]);
					const formattedDate = type === "daily" ? toLocal(date) : key;

					return (
						<Bar
							key={i}
							value={value as string}
							index={i}
							type={type}
							service={service}
							isActive={activeBar === i}
							formattedDate={formattedDate}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
							renderTooltipContent={renderTooltipContent}
						/>
					);
				})}
			</div>
		</div>
	);
};

// TEMP CODE TILL API HAS MORE DATA

const reverseObject = (obj: any) => {
	const reversedEntries = Object.entries(obj).reverse();
	const reversedObject = Object.fromEntries(reversedEntries);
	return reversedObject;
};

const modifyData = (ModifyComponent) => {
	return (props: BarGraphProps) => {
		let newData = reverseObject({ ...props.data });
		const count = Object.keys(newData).length === 90 ? 89 : 90;
		if (props.type === "daily") {
			const desiredLength = count - Object.keys(newData).length;

			const startDate = Object.keys(props.data)[0];
			let currentDate = new Date(startDate);

			for (let i = 0; i < desiredLength + 1; i++) {
				const newDate = new Date(currentDate.valueOf());
				newDate.setDate(newDate.getDate() - i);
				
				const year = newDate.getFullYear();
				const month = String(newDate.getMonth() + 1).padStart(2, '0'); 
				const day = String(newDate.getDate()).padStart(2, '0');

				const localDateString = `${year}-${month}-${day}`;
				
				if (!newData[localDateString]) {
					newData[localDateString] = "undefined";
				}
			}
		}
		const updateHour = (dateTimeString, index) => {
			const date = new Date(dateTimeString);
			date.setHours(date.getHours() - index);

			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, "0");
			const day = String(date.getDate()).padStart(2, "0");
			const hours = String(date.getHours()).padStart(2, "0");
			const minutes = String(date.getMinutes()).padStart(2, "0");

			return `${year}-${month}-${day} ${hours}:${minutes}`;
		};

		if (props.type === "hourly") {
			const desiredLength = 24 - Object.keys(newData).length;
			const startDate = Object.keys(props.data)[0];

			for (let i = 0; i < desiredLength; i++) {
				const dateString = updateHour(startDate, i);
				if (!newData[dateString]) {
					newData[dateString] = "undefined";
				}
			}
		}

		return <ModifyComponent {...props} data={reverseObject(newData)} />;
	};
};

export default modifyData(BarGraph);
