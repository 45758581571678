import './KnownIssues.scss';
import { Box, SelectChangeEvent, useMediaQuery } from '@mui/material';
import { KnownIssues as KnownIssuesInterface } from '../../../models/knownIssues';
import { useState } from 'react';
import { useTheme } from '@mui/system';
import AppAccordion from '../../../components/AppAccordion/AppAccordion';
import AppMultiSelect from '../../../components/AppMultiSelect/AppMultiSelect';
import Issue from './Issue/Issue';
import knownIssuesImage from '../../../assets/images/knownIssuesImg.jpeg';
import useHandleAnchorClick from '../../../hooks/useHandleAnchorClick';

const KnownIssues = ({ data }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedApis, setSelectedApis] = useState<string[]>(['Show all']);

  const knownIssues: KnownIssuesInterface = data.knownIssues;
  const parser = new DOMParser();
  const linkContent: Document = parser.parseFromString(
    knownIssues.link.title,
    'text/html'
  );
  const linkText = linkContent.body.innerHTML.replaceAll(
    'href="http',
    "target='_blank' href=\"http"
  );

  const getFilteredIssues = () => {
    if (selectedApis.includes('Show all')) {
      return knownIssues.issues;
    }
    return knownIssues.issues.filter((issue) =>
      selectedApis.some((api) => issue.fields[0].value?.includes(api))
    );
  };

  const handleAnchorClick = useHandleAnchorClick();

  const handleOnChangeSelect = (
    event: SelectChangeEvent<string[]>,
    list: string[]
  ) => {
    let updatedList: string[] = [...(event.target.value as string[])];
    const selectedItemIndex = updatedList[updatedList.length - 1];
    const selectedItem = list[selectedItemIndex];

    if (selectedItem === 'Show all') {
      setSelectedApis(['Show all']);
      return;
    }

    updatedList = updatedList.filter((item) => item !== 'Show all');

    if (selectedApis.includes(selectedItem)) {
      updatedList = updatedList.filter(
        (item) => item !== selectedItem && item !== selectedItemIndex
      );
    }

    setSelectedApis(
      updatedList.map((item) =>
        isNaN(Number(item)) ? item : list[Number(item)]
      )
    );
  };

  return (
    <Box className="known-issues">
      {isDesktop ? (
        <div className="container-img-desktop">
          <h1 className="title">{knownIssues.title}</h1>
          <h2 className="subtitle">{knownIssues.subtitle}</h2>
        </div>
      ) : (
        <img alt="Known Issues" src={knownIssuesImage} width="100%" />
      )}

      {!isDesktop && <h1 className="title-mobile">{knownIssues.title}</h1>}

      <Box className={isDesktop ? 'container' : 'container container--mobile'}>
        <AppAccordion
          details={knownIssues.accordion.detailParagraphs}
          summary={knownIssues.accordion.summary}
        />

        <AppMultiSelect
          label={knownIssues.affectedApisDropdown.label}
          itemList={knownIssues.affectedApisDropdown.items}
          values={selectedApis}
          width={250}
          handleOnChange={handleOnChangeSelect}
        />

        {getFilteredIssues().map((issue, index) => {
          return (
            <Issue
              key={index}
              issue={issue}
              issuesIndex={index}
              statuses={knownIssues.statuses}
            />
          );
        })}
      </Box>
      <Box className="banner">
        <div
          dangerouslySetInnerHTML={{
            __html: linkText
          }}
          onClick={handleAnchorClick}
        />
      </Box>
    </Box>
  );
};

export default KnownIssues;
