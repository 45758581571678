import "./AppSkipLink.scss";
import { useSkipLink } from "../../providers/SkipLinkProvider";

const AppSkipLink = () => {
	const { skipLinks, sideNav } = useSkipLink() as any;
	const isApiLink:boolean = window.location.pathname.includes('api');
	return (
		<nav className="skipLinks" aria-label="Skip links">
			<ul>
				{skipLinks.filter((text:string,id)=> isApiLink ? true : id!==1).map((link, index) => (
					  <li key={index}>
						<button
							onClick={() => {
								if (link.label.includes("side nav")) {
									 
									!sideNav.isOpen && sideNav.open();
									link.ref.click();
									link.ref.focus();
								} else {
									link.ref.focus();
								}
							}}
						>
							{link.label}
						</button>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default AppSkipLink;
