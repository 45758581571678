import { useState } from "react";
import "./AppCodeSnippet.scss";
import CopyIcon from "../../assets/images/copy-icon.svg";

const AppCodeSnippet = ({ snippet, title }) => {
	const code = snippet.replace("<pre>", "").replace("</pre>", "").trim();
	const [alert, setAlert] = useState("");
	const handleClick = () => {
		navigator.clipboard.writeText(code);
		setAlert("Code snippet copied to clipboard");
	};
	return (
		<div className="codeSnippetContainer">
			<div aria-live="polite" aria-atomic="true" className="sr-only">
				{alert}
			</div>
			<div className="codeSnippetHeaderContainer">
				<div className="xmlBox">XML Snippet</div>
				<div
					className="copyCodeSnippet"
					onClick={handleClick}
					role="button"
					tabIndex={0}
					aria-label={`Copy XML Snippet for ${title}`}
				>
					<img src={CopyIcon} alt="Copy code Icon" aria-hidden="true" />
					<span>Copy Code</span>
				</div>
			</div>
			<pre>{code}</pre>
		</div>
	);
};

export default AppCodeSnippet;
