import { Alert, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

export default function AnnouncementBanner(props: any) {
  const warningRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [close, setClose] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [currentTime, setCurrentTime] = React.useState(new Date());
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());

  React.useEffect(() => {
    const handleResize = () => {
      if (warningRef.current) {
        const computedStyle = window.getComputedStyle(warningRef.current);
        const marginTop = parseFloat(computedStyle.marginTop) || 0;

        props.setWarningHeight(
          warningRef.current && open
            ? warningRef.current?.getBoundingClientRect().height + marginTop
            : 0
        );
      }
    };

    handleResize();

    if (props.content && !close) {
      const isWithinRange = currentTime >= startTime && currentTime <= endTime;

      if (isWithinRange) {
        setContent(props.content[0].text);
        setOpen(true);
        props.setAnnouncementBanner(true);
      } else {
        setContent('');
        setOpen(false);
        props.setAnnouncementBanner(false);
        props.setWarningHeight(0);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

    // eslint-disable-next-line
  }, [currentTime, startTime, endTime, content, props]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'America/New_York'
      };
      const formatter = new Intl.DateTimeFormat('en-US', options);
      const formattedDateTimeString = formatter.format(now);
      const parsedDate = new Date(formattedDateTimeString);

      setCurrentTime(parsedDate);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    if (props.content) {
      setStartTime(new Date(props.content[0].start_date));
      setEndTime(new Date(props.content[0].end_date));
    }
  }, [props]);

  return (
    <>
      {open ? (
        <Alert
          variant="filled"
          severity="info"
          sx={{
            zIndex: '6',
            color: 'black',
            position: 'fixed',
            width: '100%',
            marginTop: '16px',
            borderRadius: '0px',
            backgroundColor: '#FFF9DD',
            paddingY: '16px',
            fontWeight: 400
          }}
          ref={warningRef}
          icon={<ErrorIcon sx={{ color: '#FFCD38' }}></ErrorIcon>}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                props.setWarningHeight(0);
                props.setAnnouncementBanner(false);
                setClose(true);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography
            variant="body1"
            sx={{
              '& > *': {
                margin: 0
              }
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Alert>
      ) : null}
    </>
  );
}
