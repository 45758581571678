import { useState, useEffect, useRef, useCallback, useContext } from "react";
import {LangContext} from "../../pages/App/App";
interface BarProps {
	value: string;
	type: string;
	service: string;
	index: number;
	isActive: boolean;
	formattedDate: string | false;
	onMouseEnter: (index: number) => void;
	onMouseLeave: () => void;
	renderTooltipContent: (data: any) => JSX.Element;
}

const Bar: React.FC<BarProps> = ({
	value,
	index,
	type,
	service,
	isActive,
	formattedDate,
	onMouseEnter,
	onMouseLeave,
	renderTooltipContent,
}) => {
	const [flipped, setFlipped] = useState("right");
	const tooltipRef = useRef<HTMLDivElement>(null);

	const langContext  = useContext(LangContext);
	const isFrench = langContext.selectedLanguage !== "en"

	const barClass = {
		Green: "bar-green",
		Orange: "bar-yellow",
		Red: "bar-red",
		undefined: "bar-gray",
	};

	const statusTitle = {
		Green:
			type === "daily"
				? (isFrench ? "Aucune période d’interruption enregistrée aujourd’hui." : "No downtime recorded on this day.")
				: (isFrench ? "Aucune période d’interruption enregistrée." : "No downtime recorded at this time."),
		Orange: (isFrench ? "Service dégradé en raison de problèmes techniques." : "Service Degraded due to Technical issues."),
		Red: (isFrench ? `Une panne majeure a été signalée: ${service}` : `Major Outage has been observed for ${service}.`),
		undefined: (isFrench ? "Aucune donnée disponible." : "No data available"),
	};

	const checkTooltipPosition = useCallback(() => {
		if (tooltipRef.current) {
			const tooltip = tooltipRef.current.getBoundingClientRect();
			if (tooltip.right + 150 > window.innerWidth) {
				setFlipped("left");
			} else if (tooltip.left < 0) {
				setFlipped("right");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.innerWidth]);

	useEffect(() => {
		checkTooltipPosition();
	}, [isActive, checkTooltipPosition]);

	return (
		<div
			className={`bar ${barClass[value]}`}
			onMouseEnter={() => onMouseEnter(index)}
			onClick={() => onMouseEnter(index)}
			onFocus={() => onMouseEnter(index)}
			onBlur={onMouseLeave}
			onMouseLeave={onMouseLeave}
			tabIndex={0}
			aria-describedby={isActive ? `tooltip-${index}` : undefined}
		>
			{isActive && (
				<div
					className={`tooltip ${flipped === "left" ? "left-tooltip" : "right-tooltip"}`}
					ref={tooltipRef}
					role="tooltip"
					id={`tooltip-${index}`}
				>
					<span
						className={`tooltip-arrow ${flipped === "left" ? "left-arrow" : "right-arrow"}`}
					></span>
					{renderTooltipContent({
						date: formattedDate,
						statusTitle: statusTitle[value],
					})}
				</div>
			)}
		</div>
	);
};

export default Bar;
