import { Box, Button, Divider, Grid, Link} from "@mui/material";
import "./DesktopPlatformTab.scss";
import DesktopPlatformItem from "./DesktopPlatformItem/DesktopPlatformItem";
import ConnectionOptionsSideBanner from "../ConnectionOptionsSideBanner/ConnectionOptionsSideBanner";

const DesktopPlatformTab = ({jsonData}) => {
	const data = jsonData["desktop-platform"]
	return (
		<Box className="desktop-platform-container">
			<Box className="desktop-platform-main-container">
				<div className="desktop-platform-title">{data?.title}</div>
				<div className="desktop-platform-description">{data?.description}</div>
				<Box className="desktop-platform-sub-title-wrapper">
				<div><img className="desktop-platform-img" src={data?.imageUrl} alt={data?.subTitle}/>
					<div className="desktop-platform-sub-title">{data?.subTitle}</div></div>
					<Button className="desktop-platform-register-button" href="https://acconnex.aircanada.com/home/login" target="_blank">{data?.registerButtonText}</Button>
				</Box>
				<Grid container spacing={2}>
					{data?.items.map(item =>
						<DesktopPlatformItem key={item.text} data={item} />
					)}
						<Grid item xs={12} sm={12} md={4} lg={4} className="desktop-platform-login-bar">
					 
						<Button className="desktop-platform-login-button" href="https://acconnex.aircanada.com/home/login">{data?.loginButtonText}</Button>
						</Grid>
				</Grid> 
				<Link className="desktop-platform-guide-link" href={data?.guideLink?.url} target="_blank">{data?.guideLink?.text}</Link>
				
				
				<Divider className="desktop-platform-divider" />
				
				<div className="desktop-platform-tip-text"><strong>{data?.tip?.title}</strong> {data?.tip?.text}</div>
				{/* <Button className="desktop-platform-login-button" href="https://acconnex.aircanada.com/home/login">{data?.loginButtonText}</Button> */}
			</Box>
			<ConnectionOptionsSideBanner data={jsonData["desktop-platform-banner"]} />
		</Box>
	);
};

export default DesktopPlatformTab;