import { useContext } from 'react';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { LangContext } from '../../pages/App/App';
import './DiscoverHeader.scss';

interface Props {
  title: string;
  paragraph: string;
  image: string;
  breadcrumb: string;
  dropdown?: boolean;
  mobile?: boolean;
}

const DiscoverHeader = ({
  title,
  paragraph,
  image,
  breadcrumb,
  dropdown = true
}: Props) => {
  return (
    <div className="discover-header">
      <div className="discover-header-left">
        <CustomBreadcrumbs breadcrumb={breadcrumb} />
        <h1 className="discover-header-title">{title}</h1>
        <p className="discover-header-paragraph">{paragraph}</p>
        {dropdown && <div className="discover-header-dropdown"></div>}
      </div>
      <div className="discover-header-right"></div>
    </div>
  );
};

const CustomBreadcrumbs = ({ breadcrumb }: { breadcrumb: string }) => {
  const langContext = useContext(LangContext);
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className="breadcrumbs"
    >
      <Link
        underline="hover"
        key="1"
        href={
          langContext.selectedLanguage === 'en'
            ? `/ndc-program/home`
            : `/fr/ndc-program/home`
        }
        className="breadcrumbs-link"
      >
        <strong>
          {langContext.selectedLanguage === 'en' ? 'Home' : 'Accueil'}
        </strong>
      </Link>
      <Typography key="2" className="breadcrumbs-title">
        {breadcrumb}
      </Typography>
    </Breadcrumbs>
  );
};

export default DiscoverHeader;
