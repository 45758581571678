import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { validateInput } from '../../util/validateFormInput';
import { getFormData, submitForm } from '../APIs/signup';
import useScrollPosition from '../../../hooks/useScrollPosition';
import { LangContext } from '../../App/App';

const SignUp = ({ data }) => {
  const theme = useTheme();
  const scrollPosition = useScrollPosition();

  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const langContext = React.useContext(LangContext);

  const [formSubmitted, setFormSubmitted] = React.useState(false);

  const [formData, setFormData] = React.useState({
    seller_aggregator_id: '',
    travel_agency_name: '',
    iata_number: '',
    agency_address: '',
    agency_phone_number: '',
    agency_email: '',
    agency_website: '',
    sub_seller_business_type_id: '',
    contact_first_name: '',
    contact_last_name: '',
  });

  const [formValidation, setFormValidation] = React.useState({
    seller_aggregator_id: false,
    travel_agency_name: false,
    iata_number: false,
    agency_address: false,
    agency_phone_number: false,
    agency_email: false,
    agency_website: false,
    sub_seller_business_type_id: false,
    contact_first_name: false,
    contact_last_name: false,
  });

  const [formError, setFormError] = React.useState({
    seller_aggregator_id: false,
    travel_agency_name: false,
    iata_number: false,
    agency_address: false,
    agency_phone_number: false,
    agency_email: false,
    agency_website: false,
    sub_seller_business_type_id: false,
    contact_first_name: false,
    contact_last_name: false,
  });

  const [businessModelOptions, setBusinessModelOptions] = React.useState<
    {
      sub_seller_business_type_id: string;
      business_type: string;
    }[]
  >([]);
  const [GDSOptions, setGDSOptions] = React.useState<
    {
      company_name: string;
      seller_aggregator_id: string;
    }[]
  >([]);

  const context = {
    setBusinessModelOptions,
    setGDSOptions
  };

  React.useEffect(() => {
    getFormData(langContext.selectedLanguage === 'fr', context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showSelect, setShowSelect] = React.useState(false)
  const [showBusinessType, setShowBusinessType] = React.useState(false)

    React.useEffect(() => {
      if(scrollPosition) {
        setShowSelect(false)
        setShowBusinessType(false)
      }
    }, [scrollPosition]);

  const isValidForm = !Object.values(formValidation).every((item) => item);

  const handleFormData = (e: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
    const op = validateInput(
      {
        content: e.target.value,
        validate: e.target.id
      },
      langContext.selectedLanguage === 'fr'
    );
    setFormError((prevData) => ({
      ...prevData,
      [e.target.name]: op.msg
    }));
    setFormValidation((prevData) => ({
      ...prevData,
      [e.target.name]: !op.error
    }));
  };

  const handleSubmit = async () => {
    await submitForm(formData, setFormSubmitted);
  };

  return (
    <Box sx={{ display: 'flex', mt: '1rem' }}>
      {formSubmitted ? (
        <Box
          sx={{
            bgcolor: '#f1f7fa',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '86vh',
            width: '100%'
          }}
        >
          <Paper
            elevation={3}
            sx={{
              py: '2rem',
              px: isMobile ? '1rem' : '5rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '40rem',
              mx: isMobile ? '1rem' : 0
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              mb={3}
              textAlign={'center'}
            >
              {data?.conclude?.title}
            </Typography>
            <Typography fontSize={'.875rem'}>
              {data?.conclude?.subtitle[0]}
            </Typography>

            <Typography fontSize={'.875rem'} textAlign={'center'}>
              {data?.conclude?.subtitle[1]}
            </Typography>
          </Paper>
        </Box>
      ) : (
        <Grid container columnGap={7}>
          <Grid item xs={12} sm={12} md={4} lg={4} bgcolor="#f1f7fa" p={4}>
            <Typography variant="h5" mb={2} fontWeight={600}>
              {data?.sidebar?.title}
            </Typography>
            <Typography
              fontSize={'.875rem'}
              mb={2}
              fontWeight={600}
              color="#d8292f"
            >
              {data?.sidebar?.note}
            </Typography>
            <Typography fontSize={'.875rem'} mb={2}>
              {data?.sidebar?.subtitle[0]}
            </Typography>
            <Typography fontSize={'.875rem'}>
              {data?.sidebar?.subtitle[1]}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={7}
            lg={7}
            pt={4}
            sx={{ ...(isMobile && { mx: 4 }) }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} mb={isMobile ? 5 : 27}>
              <Typography fontWeight={600} fontSize={'20px'} gutterBottom>
                {data?.form?.title}
              </Typography>

              <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
                <InputLabel
                  id="seller_aggregator_id"
                  required
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: '1.25rem',
                    color: '#C1C5C8'
                  }}
                >
                  {data?.form?.field1.data}
                </InputLabel>
                <Select
                  labelId="seller_aggregator_id"
                  required
                  id="text"
                  name="seller_aggregator_id"
                  value={formData.seller_aggregator_id}
                  onChange={handleFormData}
                  sx={{
                    fontSize: '.9rem',
                    fontWeight: 400,
                    lineHeight: '1.25rem',
                    color: '#000',
                    py: '.2rem'
                  }}
                  inputProps={{
                    style: {
                      borderBottom: `1px solid #C1C5C8`
                    }
                  }}
                  open={showSelect}
                  onOpen={() => setShowSelect(true)}
                  onClose={() => setShowSelect(false)}
                  MenuProps={{}}
                >
                  {GDSOptions.map((item) => (
                    <MenuItem
                      key={item.seller_aggregator_id}
                      value={item.seller_aggregator_id}
                    >
                      {item.company_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                variant="standard"
                id="text"
                name="travel_agency_name"
                value={formData.travel_agency_name}
                onChange={handleFormData}
                error={Boolean(formError.travel_agency_name)}
                helperText={formError.travel_agency_name}
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label={data?.form?.field2.data}
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />

              <TextField
                variant="standard"
                id="iata"
                name="iata_number"
                value={formData.iata_number}
                onChange={handleFormData}
                error={Boolean(formError.iata_number)}
                helperText={
                  formError.iata_number || data?.form?.field3.helperText
                }
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label={data?.form?.field3.data}
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />

              <TextField
                variant="standard"
                id="text"
                name="agency_address"
                value={formData.agency_address}
                onChange={handleFormData}
                error={Boolean(formError.agency_address)}
                helperText={formError.agency_address}
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label={data?.form?.field4.data}
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />

              <TextField
                variant="standard"
                id="phone"
                name="agency_phone_number"
                value={formData.agency_phone_number}
                onChange={handleFormData}
                error={Boolean(formError.agency_phone_number)}
                helperText={formError.agency_phone_number}
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label={data?.form?.field5.data}
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />

              <TextField
                variant="standard"
                id="text"
                name="contact_first_name"
                value={formData.contact_first_name}
                onChange={handleFormData}
                error={Boolean(formError.contact_first_name)}
                helperText={formError.contact_first_name}
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label={data?.form?.field10.data}
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />

              <TextField
                variant="standard"
                id="text"
                name="contact_last_name"
                value={formData.contact_last_name}
                onChange={handleFormData}
                error={Boolean(formError.contact_last_name)}
                helperText={formError.contact_last_name}
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label={data?.form?.field11.data}
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />

              <TextField
                variant="standard"
                id="text"
                name="contact_first_name"
                value={formData.contact_first_name}
                onChange={handleFormData}
                error={Boolean(formError.contact_first_name)}
                helperText={formError.contact_first_name}
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label="Contact First Name"
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />

              <TextField
                variant="standard"
                id="text"
                name="contact_last_name"
                value={formData.contact_last_name}
                onChange={handleFormData}
                error={Boolean(formError.contact_last_name)}
                helperText={formError.contact_last_name}
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label="Contact Last Name"
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />

              <TextField
                variant="standard"
                id="email"
                name="agency_email"
                value={formData.agency_email}
                onChange={handleFormData}
                error={Boolean(formError.agency_email)}
                helperText={
                  formError.agency_email || data?.form?.field6.helperText
                }
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label={data?.form?.field6.data}
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />
              <TextField
                variant="standard"
                id="url"
                name="agency_website"
                value={formData.agency_website}
                onChange={handleFormData}
                error={Boolean(formError.agency_website)}
                helperText={formError.agency_website}
                InputLabelProps={{
                  style: { fontSize: '1rem', color: '#C1C5C8' }
                }}
                sx={{ mt: 2.5, width: '100%' }}
                required
                size="small"
                label={data?.form?.field7.data}
                inputProps={{
                  style: {
                    padding: '.4rem 0'
                  }
                }}
              />
              <Typography
                fontWeight={600}
                fontSize='20px'
                mt={5}
                gutterBottom
              >
                {data?.form?.field8.data}
              </Typography>

              <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
                <InputLabel
                  id="sub_seller_business_type_id"
                  required
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: '1.25rem',
                    color: '#C1C5C8'
                  }}
                >
                  {data?.form?.field9.data}
                </InputLabel>
                <Select
                  labelId="sub_seller_business_type_id"
                  required
                  id="text"
                  name="sub_seller_business_type_id"
                  error={formError.sub_seller_business_type_id}
                  value={formData.sub_seller_business_type_id}
                  onChange={handleFormData}
                  sx={{
                    fontSize: '.9rem',
                    fontWeight: 400,
                    lineHeight: '1.25rem',
                    color: '#000',
                    py: '.2rem'
                  }}
                  inputProps={{
                    style: {
                      borderBottom: `1px solid #C1C5C8`
                    }
                  }}
                  open={showBusinessType}
                  onOpen={() => setShowBusinessType(true)}
                  onClose={() => setShowBusinessType(false)}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                >
                  {businessModelOptions.map((item) => (
                    <MenuItem
                      key={item.sub_seller_business_type_id}
                      value={item.sub_seller_business_type_id}
                      // style={getStyles(name, personName, theme)}
                    >
                      {item.business_type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              lg={6}
              mb={isMobile ? 7 : 15}
              alignItems={'end'}
              justifyContent={'center'}
            >
              <Button
                onClick={handleSubmit}
                sx={{
                  borderRadius: 3,
                  height: 'fit-content',
                  width: isMobile ? '100%  ' : '13rem',
                  bgcolor: '#005078',
                  color: theme.palette.primary.contrastText,
                  '&:hover': { backgroundColor: '#005078' },
                  boxShadow: '0 0 1rem #ccc',
                  textTransform: 'none',
                  fontSize: '1rem',
                  ...(isValidForm && {
                    bgcolor: '#D0D0CE',
                    color: '#737373',
                    boxShadow: 'none'
                  })
                }}
                disabled={isValidForm}
              >
                {data?.form?.submit}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SignUp;
