import './SPStatusBox.scss';
import { Box } from '@mui/material';
import { useSellerProfile } from '../../../pages/SellerProfile/SellerProfile';
import TranslateDate from '../../../pages/SellerProfile/TranslateDate';

const SPStatusBox = () => {
  const { ...context } = useSellerProfile() as any;
  const dateValue = context.compProfile?.registration_date || '';
  const date = TranslateDate(dateValue, context.isFrench);

  const isCurrentStatusBuilding = context.isFrench
    ? context.compProfile.status_fr === 'bâtiment'
    : context.compProfile.status_en === 'Building';

  return (
    <Box className="spStatusBox">
      <div className="registerDate">
        <h4>
          {context.spPageText.registered} {date}
        </h4>
      </div>
      <div className="statusContainer">
        <div className="currentStatusBox">
          <h4 className="currentStatusTitle">
            {context.spPageText.currentStatus}
          </h4>

          <p className="currentStatus">
            {context.isFrench
              ? context.compProfile.status_fr
              : context.compProfile.status_en}
          </p>
        </div>
        <div className="nextStatusBox">
          <h4 className="nextStatusTitle">{context.spPageText.nextStatus}</h4>
          {!isCurrentStatusBuilding && (
            <p className="nextStatus">{context.spPageText.building}</p>
          )}
          <p className="nextStatus">{context.spPageText.certified}</p>
        </div>
      </div>
    </Box>
  );
};

export default SPStatusBox;
