const TranslateDate = (dateValue: string, isFrench: boolean) => {
  const enMonth = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const frMonth = [
    "janv",
    "févr",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "sept",
    "oct",
    "nov",
    "déc",
  ];

  const valueAsDate = new Date(dateValue);

  const month = isFrench
    ? frMonth[valueAsDate.getMonth()]
    : enMonth[valueAsDate.getMonth()];
  let day =
    valueAsDate.getDate().toString().length < 2
      ? `0${valueAsDate.getDate()}`
      : valueAsDate.getDate();
  day = day.toString().replace(/^0+/, ""); //remove leading zero
  return isFrench
    ? `${day} ${month} ${valueAsDate.getFullYear()}`
    : `${month} ${day},  ${valueAsDate.getFullYear()}`;
};

export default TranslateDate;
