import { Button} from "@mui/material";//, Checkbox, FormControlLabel 
import "./SellerRegSections.scss";
import { useContext } from "react";
import { LangContext } from "../../App/App";
import { LoadingButton } from "@mui/lab";
//import { useState } from "react"; 

const errorMessage = {
  "English": `Failed to submit. Please try again or <a href="/support">contact support.</a>`,
  "French": `Une erreur système s'est produite, veuillez réessayer, <a href="/support">ou contacter le centre de support.</a>`,
}
 
const ReviewAndSubmit = ({ formData, numSections, handleSubmit, heading, submitButton, submitError,isSubmitting }) => {
  let content = "";
  const langContext = useContext(LangContext);
 // const [termsAccepted, setTermsAccepted] = useState(false); 


  // const handleAcceptCheck = () => {
  //   setTermsAccepted(!termsAccepted);
  // };


  Object.entries(formData).forEach(([key], i) => {
    if (i + 1 < numSections)
      content += `<div class="reviewGrid"><div class="subsectionBox threeColGrid"><h4 class="reviewSubmitSection">${formData[key]["heading"]}</h4></div>`;

    if (formData[key]["subsections"]) {
      let allSubsections = formData[key]["subsections"];

      let subSectionKeys = Object.keys(allSubsections);


      subSectionKeys.forEach((sub) => {
        let fieldKeys = Object.keys(allSubsections[sub]["fields"]);
       
        // content +="<div>"
        if(sub==='AdminContactDetails'){
          content +=`<div class= "oneColGrid sub1">`
        // content += `<div class="subsectionBox threeColGrid"><div class="reviewSubHeadBox">${allSubsections[sub]["label"]}</div></div>`;
        fieldKeys.forEach((field) => {          
            content += `<div class="subsectionBox"><div class='reviewFieldLabel'>${allSubsections[sub]["fields"][field]["label"]}</div><div class='reviewContent'>${allSubsections[sub]["fields"][field]["content"]}</div></div>`
          }); 
          content +=`</div>`
          }
          else if(sub==='BizContactDetails') {
            content +=`<div class= "oneColGrid">`
            // content += `<div class="subsectionBox threeColGrid"><div class="reviewSubHeadBox">${allSubsections[sub]["label"]}</div></div>`;
        fieldKeys.forEach((field) => {
          
            content += `<div class="subsectionBox"><div class='reviewFieldLabel'>${allSubsections[sub]["fields"][field]["label"]}</div><div class='reviewContent'>${allSubsections[sub]["fields"][field]["content"]}</div></div>`
          }); content +=`</div>`
          } 
          else if (sub === "ImpDetails" || sub === "BizModel" || sub === "SellerPOS") {
            content +=`<div class="threeColGrid sub">`
            // content += `<div class="subsectionBox threeColGrid"><div class="reviewSubHeadBox"><h5 class="reviewSubmitSubHead">${allSubsections[sub]["label"]}</h5></div></div>`;
            fieldKeys.forEach((field) => {
             
                content += `<div class="subsectionBox"><div class='reviewFieldLabel'>${allSubsections[sub]["fields"][field]["label"]}</div><div class='reviewContent'>${allSubsections[sub]["fields"][field]["content"]}</div></div>`
              });
              content +=`</div>`           
          }
          else{
            content +="<div class=col>"
            // content += `<div class="subsectionBox threeColGrid"><div class="reviewSubHeadBox"><h5 class="reviewSubmitSubHead">${allSubsections[sub]["label"]}</h5></div></div>`;
            fieldKeys.forEach((field) => {
              
                content += `<div class="subsectionBox threeColGrid"><div class='reviewFieldLabel'>${allSubsections[sub]["fields"][field]["label"]}</div><div class='reviewContent'>${allSubsections[sub]["fields"][field]["content"]}</div></div>`
              });
              content +="</div>"
            }
          //  content += "</div>"
      
      });
    }

    //close grid
    content += `</div>`;
  });
  content += `<div class="subsectionBox threeColGrid"></div>`;
  return (
    formData && content !== "" ?
      <div id="sellerRegFormPg">
        <h3>{heading}</h3>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
        {/* <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => handleAcceptCheck()}
              value={termsAccepted}
              checked={termsAccepted}
            />
          }
          label="I have carefully read, understand and accept the terms and conditions associated with opening an Account."
          sx={{ position: "relative" }}
        /> */}
         {!isSubmitting ? <Button className="submitButton" variant="contained" onClick={(e) => handleSubmit()} disabled={false} sx={{fontWeight: 600}}> 
        {/* disabled={!termsAccepted} */}
          {submitButton}
        </Button>:<LoadingButton loading  className="submitButton" variant="contained" sx={{ color:"#005078",backgroundColor:"#005078"}}>{submitButton}</LoadingButton>}
        {submitError &&  (<div className="submitError" dangerouslySetInnerHTML={{__html : langContext.selectedLanguage === "English" ? errorMessage["English"] : errorMessage["French"]}}></div>) } 
      </div>:<></>
   
  );
};

export default ReviewAndSubmit;
