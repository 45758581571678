import { sllrProfAxios } from "./axiosDefault";
import { addNewUserInfoToUsersList, isEmpty, showAPIerror } from "./Helpers";
import { sortUsers } from "./Helpers";

const SpAPIgetApiCredTypes = async (context) => {
  const getCredTypes = async () => await sllrProfAxios.get(`/credential-types`);
  return await getCredTypes()
    .then((res) => res)
    .catch((error) => showAPIerror(error, context, "SpAPIgetApiCredTypes"));
};

const SpAPIgetAllApiCreds = async ({ ...context }) => {
  let useApiCredTypes = context.apiCredTypes;
  isEmpty(context.apiCredTypes) &&
    (await SpAPIgetApiCredTypes(context)
      .then((res) => {
        context.setApiCredTypes(res.data);
        useApiCredTypes = res.data;
      })
      .catch((err) =>
        showAPIerror(err, context, "SpAPIgetAllApiCreds - get types")
      ));

  return await sllrProfAxios
    .get(`/api-credentials-contacts/${context.seller_id}`)
    .then((res) => {
      const credential_type_id = context.isFrench
        ? "credentials_type_fr"
        : "credentials_type_en";
      const newUserInfo = res.data.data.user;

      newUserInfo.map((user) => {
        if (user.hasOwnProperty("credentials")) {
          const env: string[] = [];
          user.credentials?.forEach((cred) => {
            const info = useApiCredTypes?.find((t) => {
              return t.credentials_type_id === cred.credential_type_id;
            });

            env.push(info[credential_type_id]);
          });
          const sortEnv = env.sort();
          user.env = sortEnv;
          return user;
        } else {
          user.env = [];
          return user;
        }
      });

      const updatedUsers = addNewUserInfoToUsersList(
        context.users,
        newUserInfo
      );

      const sortedUsers = sortUsers(updatedUsers);
      context.setUsers(sortedUsers);
      return res;
    })
    .catch((res) =>
      showAPIerror(res, context, "SpAPIgetAllApiCreds - get usercreds")
    );
};

const SpApiUpdateAPIcreds = async ({ ...context }, curr_user_id) => {
  let useApiCredTypes = context.apiCredTypes;
  const credential_type_id = context.isFrench
    ? "credentials_type_fr"
    : "credentials_type_en";
  isEmpty(context.apiCredTypes) &&
    (await SpAPIgetApiCredTypes(context).then((res) => {
      if (res.status === 200) {
        context.setApiCredTypes(res.data);
        useApiCredTypes = res.data;
      }
    }));
  const sendUpdatesContent: any[] = [];
  useApiCredTypes.forEach((t) => {
    const update = {
      credential_type_id: t.credentials_type_id,
      active: context.formUpdates["editAPIcreds"].env.content.includes(
        t[credential_type_id]
      ),
    };
    sendUpdatesContent.push(update);
  });
  const sendUpdates = { data: sendUpdatesContent };
  //get all User info from DB again to ensure we have the most up to date info
  return await sllrProfAxios
    .put(`/api-credentials-contact/${curr_user_id}`, sendUpdates)
    .then(async () => await SpAPIgetAllApiCreds({ ...context }))
    .catch((error) => showAPIerror(error, context, "SpApiUpdateAPIcreds"));
};

export { SpAPIgetAllApiCreds, SpApiUpdateAPIcreds, SpAPIgetApiCredTypes };
