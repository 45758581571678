import { useMemo } from "react";

import { Box } from "@mui/material";
import useFetchStatusAPI from "../../hooks/useFetchStatusAPI";
import BarGraph from "../BarGraph/BarGraph";
import useWindowSize from "../../assets/customHooks/useWindowSize";
import AppLoader from "../AppLoader/AppLoader";
import "./HistoricalDataGraph.scss";

const HistoricalDataGraph = ({ statusType, services }) => {
	const api = statusType === "Gold" ? "REACT_APP_STATUS_API_GOLD" : "REACT_APP_STATUS_API_PROD";
	const urlType = api + "_DAILY_URL";

	const options = {
		headers: { "x-api-key": process.env[api] },
	};

	const url = process.env[urlType];

	const { width } = useWindowSize();
	const { data, loading } = useFetchStatusAPI(url, options, statusType, services);

	const isMobile = useMemo(() => width! < 1100, [width]);
	const toLocal = (date) =>
		date.toLocaleString("en", {
			day: "numeric",
			month: "long",
			year: "numeric",
		});

	const content = useMemo(() => {
		if (!data) return null;

		return data.map((status) => {
			const label = status["API"] === "OrderReshopShop" ? "OrderReshop" : status["API"];

			if (
				(label as string) === "OrderReshopPrice" ||
				(label as string).includes("AIR") ||
				(label as string) === "null"
			)
				return <></>;
				const count = Object.keys(status["data"][0]).length;
				const chunkSize = width! < 1100 ? 30 : Object.keys(status["data"][0]).length;
				const initialIndex = count - chunkSize >= 0 ? count - chunkSize : 0;
				const startIndex = initialIndex + chunkSize;

				const displayedData = Object.entries(status["data"][0]).slice(startIndex - chunkSize, startIndex);
			 	const startDate = toLocal(new Date(displayedData[0]?.[0] ?? ""));
				const endDate = toLocal(new Date(displayedData[displayedData.length - 1]?.[0] ?? ""));

			return (
				<div key={label}>
					<Box
						className="sub-container"
						tabIndex={0}
						aria-label={`${label} 90 days up time percentage: ${status["UPTime"]}`}
						sx={{
							width: isMobile ? "376px" : "100%",
						}}
					>
						<div className="header-container">
							<div className="header-service">
								<img
									src={services[label === "OrderReshop" ? "OrderReshopShop" : label]}
									alt={label}
									className="service-icon"
								/>
								<span className="service-label">{label.toString() === "OrderRetrieve" ? "OrderView" : label}</span>
							</div>
							{!isMobile && startDate && endDate &&
							<div className="dateAndPercentBar" >
								<div>{startDate +" - " + endDate}</div>
								<div className="percentText">{status["UPTime"]}</div> 
							</div>}
							{/* <span className="percentage">{status["UPTime"]}</span> */}
							<span className="percentage"></span>
						</div>
						<BarGraph
							service={label}
							data={status["data"][0]}
							renderTooltipContent={HistoricalDataTooltip}
							type={"daily"}
						/>
					</Box>
				</div>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isMobile]);

	return (
		<>
			{loading ? (
				<AppLoader />
			) : (
				<div
					className="historical-container"
					role="group"
					aria-label="Historical data graph group"
				>
					{content}
				</div>
			)}
		</>
	);
};

const HistoricalDataTooltip = (data: any) => (
	<>
		<div className="tooltip-date">{data.date}</div>
		<hr />
		<div className="tooltip-text">{data.statusTitle}</div>
	</>
);

export default HistoricalDataGraph;
