import './TechnologyPartnersTable.scss';
import useHandleAnchorClick from '../../hooks/useHandleAnchorClick';
import { useContext } from 'react';
import { LangContext } from '../../pages/App/App';

const TechnologyPartnersTable = ({ data }) => {
  const additionalInformation = data.footer[0].entries;
  const columns = data.columns.split(',');
  const quickLinks = data.footer[1];
  const rows = data.rows.map((row) => sortRow(row));
  const langContext = useContext(LangContext);

  const handleAnchorClick = useHandleAnchorClick();

  const PartnerCell = ({ value }) => (
    <th style={{ width: 'fit-content' }}>
      <img
        src={`/images/technologyPartners/${value
          .toLowerCase()
          .replace(/\s/g, '')}Icon.png`}
        alt={`${value} icon`}
      />
    </th>
  );

  const TableCell = ({ value }) => {
    let content;

    if (value === 'yes') {
      content = (
        <>
          <img src="/images/success-circle-check.png" alt="success icon" />
          <span>{value}</span>
        </>
      );
    } else if (value === '*') {
      content = <b>*</b>;
    } else {
      content = <span>Not supported</span>;
    }

    return <td>{content}</td>;
  };

  function sortRow(row) {
    return {
      partner: row.partner,
      air_shopping: row.air_shopping,
      offer_price: row.offer_price,
      seat_availability: row.seat_availability,
      order_create: row.order_create,
      order_cancel: row.order_cancel,
      order_change: row.order_change,
      service_list: row.service_list,
      gds_content_integration: row.gds_content_integration
    };
  }

  return (
    <div
      className="connection-options-body-content"
      onClick={handleAnchorClick}
    >
      <h3 className="technology-partners-title">{data.title}</h3>
      <h4 className="technology-partners-subtitle">{data.subtitle}</h4>

      <div className="technology-partners-table">
        <table>
          <tbody>
            <tr className="table-header-row">
              {columns.map((column, columnIndex) => (
                <th
                  key={columnIndex}
                  className={columnIndex === 0 ? 'table-row' : undefined}
                >
                  {column}
                </th>
              ))}
            </tr>
            {rows.map((row, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {Object.keys(row).map((key, keyIndex) => {
                    return key === 'partner' ? (
                      <PartnerCell key={keyIndex} value={row[key]} />
                    ) : (
                      <TableCell key={keyIndex} value={row[key]} />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {additionalInformation.map((info, index) => (
        <div key={index}>{info.text}</div>
      ))}

      <div className="technology-partners-links">
        <h4>{quickLinks.title}</h4>
        <ul>
          {quickLinks.links.map((link, index) => {
            return (
              <li key={index}>
                <a href={`/${langContext.selectedLanguage}/api/${link.path}`}>{link.text}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TechnologyPartnersTable;
