import './SPAddUser.scss';
import { Box, Button, Divider, Typography } from '@mui/material';
import { doFormErrorMsgs } from '../../../pages/SellerProfile/APIs/Helpers';
import { SpApiAddUser } from '../../../pages/SellerProfile/APIs/SellerProfileAPI';
import { useEffect, useState } from 'react';
import { useSellerProfile } from '../../../pages/SellerProfile/SellerProfile';
import editForm from '../Form/json/editUserProfileFormFields.json';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GetLabels from '../../../pages/SellerProfile/GetLabels';
import SPForm from '../Form/SPForm';
import SPFormButton from '../Form/SPFormButton';

function AddEditUser() {
  const { ...context } = useSellerProfile() as any;
  const [loading, setLoading] = useState(false);

  const successMsg = GetLabels(`spPageText.addUserSuccess`, context.spPageText);
  const dividerStyle = {
    background: '#EFEFEF',
    marginBlock: '12px',
    marginInline: { xs: '-16px', md: '0px' }
  };

  const submitFormFields = async (formName) => {
    setLoading(true);
    await SpApiAddUser(context)
      .then((res) => {
        if (res.status === 200) {
          context.setIsAddingUser(false);
          context.setFormUpdates({ ...context.formUpdates, [formName]: {} });
          context.setShowSuccessPopUp(successMsg);
          if (
            !context.onboardingStatus.user &&
            context.onboardingStatus?.completed === 2
          ) {
            context.setOnboardCompletePopupDetails({
              show: true,
              navigate: false,
              close: true
            });
          }
        } else {
          doFormErrorMsgs(res, context.setErrorMsg, context.spPageText);
        }
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        const errMsg = res.response?.data?.err_code || 'errorGeneral';
        context.setErrorMsg(context.spPageText[`error${errMsg}`]);
        window.scrollTo({ top: 200, behavior: 'smooth' });
      });
  };

  useEffect(() => {
    context.setFormLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="addEditUserContainer">
      <Divider sx={dividerStyle} />
      <Button
        variant="text"
        onClick={() => {
          context.setIsAddingUser(!context.isAddingUser);
        }}
        className="addNewUserBtn"
      >
        <Typography>{context.spPageText.addNewUser}</Typography>
        {context.isAddingUser ? <ExpandLessIcon /> : <ExpandMoreIcon />}{' '}
      </Button>

      {context.isAddingUser && (
        <Box className="addUserForm">
          <SPForm formFields={editForm} formName="addUser" key="addUserForm" />
          <SPFormButton
            label={context.spPageText.addUser}
            formName="addUser"
            handleClick={() => submitFormFields('addUser')}
            showLoading={loading}
          />
        </Box>
      )}
      <Divider sx={dividerStyle} />
    </Box>
  );
}

export default AddEditUser;
