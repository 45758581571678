const getUserInfoById = (usersList, id) => {
  const user = usersList?.filter(
    (user) => user?.user_id?.toString() === id.toString()
  );
  return user[0] || [];
};

const getUserEmailList = (list) => {
  const emails: any[] = [];
  list?.forEach((user) =>
    Object.keys(user)?.forEach(
      (f) => f?.includes("user_email") && emails.push({ label: user[f] })
    )
  );

  return emails;
};

const sortUsers = (users) => {
  const sortBy = ["role", "role_id", "user_last_name", "user_first_name"];
  return users?.sort((a, b) => {
    let returnVal = 0;
    let sorted = false;
    sortBy.forEach((sort) => {
      if (a[sort]?.toLowerCase() < b[sort]?.toLowerCase() && !sorted) {
        returnVal = -1;
        sorted = true;
      }
      if (a[sort]?.toLowerCase() > b[sort]?.toLowerCase() && !sorted) {
        returnVal = 1;
        sorted = true;
      }
    });
    return returnVal;
  });
};
const addNewUserInfoToUsersList = (existingusers, newusers) => {
  let updatedUsers: {}[] = [];

  existingusers.forEach((user, i) => {
    //update existing users with new info
    const addNewDataToExistingUser = newusers.find(
      ({ user_id }) => user_id === user.user_id
    );

    const userVal: any = addNewDataToExistingUser
      ? { ...user, ...addNewDataToExistingUser }
      : { ...user };

    updatedUsers.push(userVal);
  });

  newusers.forEach((newuser) => {
    //add users that don't exist yet
    const addNewUserToUserList = existingusers.find(
      ({ user_id }) => user_id === newuser.user_id
    );

    !addNewUserToUserList && updatedUsers.push({ ...newuser });
  });

  return updatedUsers;
};

const showAPIerror = (msg, { ...context }, api) => {
  if (context.spPageText[`error${msg.response?.data?.err_code?.toString()}`]) {
    //Is data-type/anticipated error - display in form
    return msg;
  }
  //Is API error, display in center of page
  window.scrollTo({ top: 0, behavior: "smooth" });
  const isFrench = window.location.pathname.includes("/fr/");
  let errText = isFrench
    ? "Une erreur s'est produite. Veuillez réessayer."
    : "An error occurred. Please try again.";
  msg.response?.status === 403 &&
    (errText = isFrench
      ? "Votre session a expiré.<br/> Veuillez vous reconnecter."
      : "Your session has timed out.<br/> Please login again.");

  if (msg.response?.status && msg.response?.status !== 403) {
    errText = isFrench
      ? `Erreur : ${
          msg.response?.data?.Error ||
          msg.response?.data?.error ||
          msg.response.data.err_code ||
          msg.message ||
          "générique"
        }. Veuillez actualiser la page ou contacter le support.`
      : `Error: ${
          msg.response?.data?.Error ||
          msg.response?.data?.error ||
          msg.response.data.err_code ||
          msg.message ||
          "generic"
        }. Please refresh the page or contact support.`;
  }else if (msg.response?.status && msg.response?.status === 403){
    errText = context.spPageText.errorSessionExpired;
  }
  console.log(msg);
  context.setApiStatus(errText);
  console.log(`${api} error - `, msg);
  return false;
};
const doFormErrorMsgs = (error, setErrorMsg, spPageText, scroll = true) => {
  const errMsg = error?.response?.data?.err_code
    ? `error${error.response.data.err_code}`
    : "errorGeneral";
  scroll === true && window.scrollTo({ top: 200, behavior: "smooth" });
  console.log("Error updating user - ", error, errMsg);
  setErrorMsg(spPageText[errMsg]);
};

const isEmpty = (obj) => Object.keys(obj)?.length === 0;

export {
  doFormErrorMsgs,
  addNewUserInfoToUsersList,
  sortUsers,
  isEmpty,
  getUserInfoById,
  getUserEmailList,
  showAPIerror,
};
