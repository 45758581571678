 
import { useContext } from "react";
import { LangContext } from "../../pages/App/App";
import { useSkipLink } from "../../providers/SkipLinkProvider";

import "./NDCapis.scss";

export default function GettingStartedOverview({ handleClick, data }) {
  const { setNavigationChange } = useSkipLink();
  const getData = (json, matchOn, key, returnFirst = false) => {
    if (data) {
      let result = json.filter(
        (x) => x.link[key] === matchOn && x.link.enabled
      );
      if (returnFirst) {
        result = result[0].link;
      }
      return result;
    }
  };

  const langContext = useContext(LangContext);

  const apiNavData = getData(data["navigation"], "api_info", "menu_name");

  const gettingStartedPgMenu = getData(
    data["navigation"],
    "ndcapis_pg",
    "menu_name"
  );

  //BELOW IS GETTING THE HIGH LEVEL Getting Started PAGE navigation info
  const getstartmenu = gettingStartedPgMenu[0]?.link.subtree;

  const getStartSection = getData(getstartmenu, "letsstart", "route", true);
  const getAPIsection = getData(getstartmenu, "allservices", "route", true);


  //BELOW IS GETTING the menu items for each section (I.e. what is used in left hand nav) to iterate through 
  const APIsNavitems = getData(apiNavData, "documentation", "route");
  
  //  const rlsNotesNavitems = apiNavData?.find((item) => item.link.route === "releasenotes");

  const checkRoute = (x) => {
    let route = ["documentation", x.route];
    if (x.default_children) {
      route.push("functionality");
    }
    handleClick(route);
  };

  return (
    <>
    <div className="ndcAPIsContainer">
      <div className="backgroundImg">
        <div className="overviewBGoverlay"></div>
        <h1 className="title">{getStartSection.title}</h1>
      </div>
      <div className="webServices">
        <h2 className="webServicesTitle">{getAPIsection.title}</h2>

        <p className="webServicesSubTitle">{getAPIsection.subtitle}</p>
        <div className="linksContainer">
          {APIsNavitems[0]?.link?.subtree?.map(
            (x) =>
              x.link.show_on_gs_pg_nav && (
                <div
                  role="button"
                  tabIndex={0}
                  key={`${x.link.route}-lc`}
                  onClick={() => {
                    checkRoute( x.link) 
                    setNavigationChange(true)
                  } }
                  className="link"
                  aria-label={ langContext.selectedLanguage === "fr" ? `Lien vers la page de ${x.link.title}` : `Link to ${x.link.title} page`}
                >
                  <div className="icon">
                    <img
                      src={`/images/icons/${x.link.title === "OrderView" ? "orderretrieve" : x.link.route}.png`} //orderview img icon corrupted, can't get it to replace (will only work locally if try)...
                      alt={`${x.link.title === "OrderRetrieve" ? "OrderView" : x.link.title} icon`}
                    ></img>
                  </div>
                  <div className="lable">
                    <p>{x.link.title}</p>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
 
    </div>
    </>
  );
}
