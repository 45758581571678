import { Box } from "@mui/material";
import { useSellerProfile } from "../../../pages/SellerProfile/SellerProfile";
import "./SPProfileCard.scss";
import GetLabels from "../../../pages/SellerProfile/GetLabels";

function SPProfileCard() {
  const { ...context } = useSellerProfile() as any;

 
const contactTypes=["admin","business","techOpAdmin"]
const contacts:any[]=[]
contactTypes.forEach((t)=>{
  const firstName=context.compProfile[`${t}_contact_first_name`]
  const lastName=context.compProfile[`${t}_contact_last_name`] 
  contacts.push({first_name:firstName,last_name:lastName,contactType:`${t}Contact`})
})
 
  return (
    <div>
      {contacts.map((contact, i) => { 
      
          return (
            <Box className="profileCardContainer" key={`${contact}-${i}`}>
              {/* <div className="cardProfile">
                <div className="profilePic">{getInitials(contact?.first_name,contact?.last_name)}</div>
              </div> */}
              <div className="cardBody">
                <div className="cardDescription">
                  <h2 className="userTitle">{GetLabels(`spPageText.${contact?.contactType}`,context.spPageText)}</h2>
                  <p className="userName">{contact?.first_name} {contact?.last_name}</p>
                </div>
              </div>
            </Box>
          );
      
      })}
    </div>
  );
}

export default SPProfileCard;
