import { Box, Grid } from "@mui/material";
import useHandleAnchorClick from "../../../hooks/useHandleAnchorClick";
import "./DesktopPlatformItem.scss";

const DesktopPlatformItem = ({ data }) => {
	const handleAnchorClick = useHandleAnchorClick();
	return (
		<Grid item xs={12} sm={12} md={4} lg={4}>
			<Box className="desktop-platform-item">
				<img className="desktop-platform-item-img" src={data?.imageUrl} alt={data?.text} />
				<Box className="desktop-platform-item-text" dangerouslySetInnerHTML={{ __html: data?.text }} onClick={handleAnchorClick} />
			</Box>
		</Grid>
	);
};

export default DesktopPlatformItem;
