import { useMemo } from "react";
import Banner from "../../../components/GettingStarted/Banner/Banner";
import Steps from "../../../components/GettingStarted/Steps/Steps";
import "./GettingStartedOverview.scss";

const GettingStartedOverview = ({ handleClick, data, navOpen }) => {
  const gettingStarted = useMemo(() => data?.['ndc-program.gettingstarted'], [data]);


  return (
    <div className="getting-started-container">
      <style>{`.apiBodyHeaderBG { display: none !important; }`}</style>
      <Banner data={gettingStarted?.['section.banner']} />
      <Steps data={gettingStarted?.['section.step']} navOpen={navOpen}/>
      {/* <hr style={{marginBlock:"60px", marginInline:"96px"}} />
      <Overview data={gettingStarted?.['section.overview']} />
      <NdcMessage data={gettingStarted?.['section.ndc-message']} />
      <ImplementationFlow data={gettingStarted?.['section.implementation-flow']} /> */}
    </div>
  );
}

export default GettingStartedOverview;