import "./releaseNotesDetail.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import desktopViewTable from "../../TableTemplates/DesktopViewTable";
import mobileViewTable from "../../TableTemplates/MobileViewTable";
import { useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import useHandleAnchorClick from "../../../hooks/useHandleAnchorClick";
import { LangContext } from "../../../pages/App/App";

const ReleaseNotesDetail = ({ details, setShowReleaseDetails, Chip, lang, setRedirectToSub }) => {
 
  const theme = useTheme();
  const size = useMediaQuery(theme.breakpoints.up("md"));
  const handleAnchorClick = useHandleAnchorClick();
  const langContext = useContext(LangContext);
  const isFrench= langContext.selectedLanguage === "fr";

  const apiServices: any = {
    AirShopping: {
      label: "AirShopping",
      src: "/images/icons/airshopping.png",
      href: "/api/documentation/airshopping/functionality",
    },
    OfferPrice: {
      label: "OfferPrice",
      src: "/images/icons/offerprice.png",
      href: "/api/documentation/offerprice/functionality",
    },
    SeatAvailability: {
      label: "SeatAvailability",
      src: "/images/icons/seatavailability.png",
      href: "/api/documentation/seatavailability/functionality",
    },
    ServiceList: {
      label: "ServiceList",
      src: "/images/icons/servicelist.png",
      href: "/api/documentation/servicelist/functionality",
    },
    OrderCreate: {
      label: "OrderCreate",
      src: "/images/icons/ordercreate.png",
      href: "/api/documentation/ordercreate/functionality",
    },
    OrderRetrieve: {
      label: "OrderRetrieve",
      src: "/images/icons/orderretrieve.png",
      href: "/api/documentation/orderretrieve/functionality",
    },
    OrderReshop: {
      label: "OrderReshop",
      src: "/images/icons/orderreshop.png",
      href: "/api/documentation/orderreshop/functionality",
    },
    OrderCancel: {
      label: "OrderCancel",
      src: "/images/icons/ordercancel.png",
      href: "/api/documentation/ordercancel/functionality",
    },
    OrderChange: {
      label: "OrderChange",
      src: "/images/icons/orderchange.png",
      href: "/api/documentation/orderchange",
    },
    OrderView: {
      label: "OrderView",
      src: "/images/icons/orderretrieve.png",
      href: "/api/documentation/orderretrieve/functionality",
    }, 
    OrderChangeNotification: {
      label: "OrderChange<br/>Notification",
      src: "/images/icons/orderchangenotification.png",
      href: "/api/documentation/orderchangenotification",
    },
  };

  let parser = new DOMParser();
  
  const env = process.env["REACT_APP_ENV_LOADED"];

  let url = `https://content-ndc-portal.aircanada.com/s3fs-public/samples/`; //default to prod
  if (env === "DEV" || env === "INT" || env === "CRT") {
    url = `https://content-ndc-portal-int.aircanada.com/s3fs-public/samples/`;
  } else if (env === "BAT" || env === "PREPROD") {
    url = `https://content-ndc-portal-bat.aircanada.com/s3fs-public/samples/`;
  }
  // let body = content.querySelector("body");
  const updatePREcode =  (linkString) => {
    linkString = linkString.replaceAll("\n ", "");

    // eslint-disable-next-line no-control-regex
    const re = new RegExp("<pre>(.|\n|\r\n)*?</pre>", "g"); //(?<=<pre>)((.|\n|\r\n)*?)(?=</pre>)

    var regex = re,
      result: string[] | null;

    while ((result = regex.exec(linkString))) {
      if (result.hasOwnProperty("index")) {
        const origPreCode = result[0];

        const updatedPreCode = origPreCode
          .replace("<pre>", "")
          .replace("</pre>", "")
          .replaceAll("<", "&#60;")
          .replaceAll(">", "&#62;");

        linkString = linkString.replaceAll(
          origPreCode,
          `<pre>${updatedPreCode}</pre>`
        );
    
      }
    }

    //also replace any ENV_SAMPLES href w/ env url
    
    return linkString.replaceAll("ENV_SAMPLES/", url);
  };
  
  details.detailsBody = updatePREcode(details.detailsBody);
  let content = parser.parseFromString(details.detailsBody, "text/html");


  const servicesList: string[] = [];
  if (details?.services && typeof details?.services[0] === "object") {
    //multiple services returned as object w/ value key for title
    details.services.forEach((s) => s?.value && servicesList?.push(s?.value));
  } else {
    details.services?.length > 0 && servicesList.push(details.services);
  }

  let servicesHTML = {};
  let heading = details.title;

  servicesList.forEach((el, i) => {
    servicesHTML[
      el
    ] = `<a href='/${langContext.selectedLanguage}${apiServices[el]?.href}'><div class="affectedApiImg"><img src=${apiServices[el]?.src} alt="" /></div><span>${apiServices[el]?.label}</span></a>`;
  });

  const splitHtml = [] as any;

  const childElements = Array.from(content.body?.children);

  childElements.forEach((element) => {
    const isTable = element.tagName.toLowerCase() === "table";
    if (
      !isTable &&
      splitHtml.length > 0 &&
      !splitHtml[splitHtml.length - 1].startsWith("<table")
    ) {
      splitHtml[splitHtml.length - 1] += element.outerHTML;
    } else {
      // !element.outerHTML.includes("<h2>") &&
      splitHtml.push(isTable ? element.outerHTML : element.outerHTML);
    }
  });

  const getUseCasesContent = (table) =>
    size
      ? desktopViewTable(table, "useCasesTable", "Use Cases Table")
      : mobileViewTable(table, '', handleAnchorClick);


//since xml files are on separate server, need workaround to force download (devops not able to return content-disposition header...)
  //1. get the hrefs from the XML sample files
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef.current) {
 
      const links = contentRef.current.querySelectorAll("a");
      links.forEach((link) => {
        link.addEventListener("click", handleClick);
        link.classList.add("xmlsample")
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef]);
  //2. handle sample xml clicks
  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    const url = (event.target as HTMLAnchorElement).href;
  
    fetchData(url);

  };
  //3. fetch the file and force download
  const fetchData =(url)=>{
    fetch(url)
     .then( res => res.blob() )
     .then( blob => {
      const fileName = url.slice(url.lastIndexOf('/')+1)
      
      const file = window.URL.createObjectURL(blob); 
       var a = document.createElement('a');
       a.href = file;
       a.download = fileName;
       document.body.appendChild(a); // for firefox
       a.click();    
       a.remove(); 
     });
   }


  const updateXMLurl = (linkString) => {
    const re = new RegExp("<a[^>]*>([^<]+)</a>", "g");
    var regex = re,
      result: string[] | null;
    while ((result = regex.exec(linkString))) {
      if (result.hasOwnProperty("index")) {
        const re = /href="([^"]+)"/g; 
        
        const hrefMatch = result[0].match(re) || [""]; 
        let hrefMatchString: any = Array.isArray(hrefMatch)
          ? hrefMatch[0]
          : hrefMatch;
          hrefMatchString = hrefMatchString.replace("href=", "").replaceAll('"',"");


        const updatedAtag = result[0]
          .replace(`>${result[1]}`, ` download>${result[1]}`)
          .replace(
            hrefMatchString,
            `${url}${hrefMatchString}`
          );
        linkString = linkString.replace(result[0], updatedAtag);
      }
    }

    return linkString;
  };



  return (
    <div className="releaseNotesDetailsContainer">
      <div className="releaseDetailsHeader">
        {" "}
        <ArrowBackIosIcon
          className="backArrowIcon"
          onClick={() => {
            setShowReleaseDetails(false);
            setRedirectToSub("")
            window.history.pushState({}, "", `/${langContext.selectedLanguage}/api/releasenotes`);
          }}
          onKeyDown={(e) => {
            if(e.key === "Enter"){
              setShowReleaseDetails(false);
              setRedirectToSub("")
              window.history.pushState({}, "", `/${langContext.selectedLanguage}/api/releasenotes`);
            }
          }}
          tabIndex={0}
          role="button"
        />
        <h2> {heading}</h2>
        <br />
      </div>
      <div className="releaseNotesDetails">
        <div className="releaseNotificationBar">
          {" "}
          <h3>{isFrench ? "Avis de mise à jour" : "Release Notifications"}</h3>
          <p>
            {isFrench
              ? "Vous trouverez ci-dessous les dates prévues des améliorations à nos différents environnements :"
              : "Below are the scheduled dates for enhancements in our different environments:"}
          </p>{" "}
          <div className="notificationDates">
            <Chip type="Gold" date={details.envDates?.gold} />
            <Chip type="Prod" date={details.envDates?.prod} className="prod" />
          </div>
        </div>
        <div className="overviewContainer">
          <h3> {isFrench ? "Aperçu" : "Overview"}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: details.overview || "" }}
            onClick={handleAnchorClick}
            className="releaseNotesDetails"
          />
        </div>

        {splitHtml.map((html, i) =>
          html.includes("<table>") ? (
            <div key={`t-${i}`}>{getUseCasesContent(html)}</div>
          ) : (
            <div
              key={`rn-${i}`}
              dangerouslySetInnerHTML={{ __html: html || "" }}
              onClick={handleAnchorClick}
              className="releaseNotesDetails"
            />
          )
        )}
      </div>
      {details.xmlSampleFiles && (
        <div className="sampleMessage">
          <h3> {isFrench ? "Exemples de messages" : "Sample Message"}</h3>
          <div
            ref={contentRef}
            dangerouslySetInnerHTML={{
              __html: updateXMLurl(details.xmlSampleFiles),
            }}
            onClick={handleAnchorClick}
          />
        </div>
      )}
      {servicesList?.length > 0 && (
        <div className="affectedApiBar">
          <h3>{isFrench ? "API touchées" : "APIs Affected"}</h3>

          <ul>
            {" "}
            {servicesList.map((service) => (
              <li key={service}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: servicesHTML[service] || "",
                  }}
                  onClick={handleAnchorClick}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      {details?.outOfScope?.length && (
        <>
          {" "}
          <h3>{isFrench ? "Hors du champ d’application" : "Out of Scope"}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: details.outOfScope || "" }}
            onClick={handleAnchorClick}
            className="releaseNotesDetails"
          />{" "}
        </>
      )}
       {details?.limitations?.length && (
        <>
          {" "}
          <h3>{isFrench ? "Restrictions" : "Limitations"}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: details.limitations || "" }}
            onClick={handleAnchorClick}
            className="releaseNotesDetails"
          />{" "}
        </>
      )}
    </div>
  );
};

export default ReleaseNotesDetail;
