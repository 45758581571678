import './Questionnaire.scss';
import { Box } from '@mui/material';
import { isEmpty } from '../APIs/Helpers';
import { LangContext } from '../../App/App';
import { SpAPIgetQuestionnaire } from '../APIs/QuestionnaireProfileAPI';
import { SpAPIsubmitQuestionnaire } from '../APIs/QuestionnaireProfileAPI';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSellerProfile } from '../SellerProfile';
import questionnaireForm from '../../../components/SellerProfile/Form/json/questionnaireFormFields.json';
import SPForm from '../../../components/SellerProfile/Form/SPForm';
import SPFormButton from '../../../components/SellerProfile/Form/SPFormButton';

const Questionnaire = () => {
  const { ...context } = useSellerProfile() as any;
  const langContext = useContext(LangContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [updateQuestionnaireForm, setUpdateQuestionnaireForm] = useState({});

  const displayErrorByType = (error) => {
    if (error.response?.data?.err_code === 402) {
      context.setErrorMsg(context.spPageText[`questionnaireAlreadySubmitted`]);
    } else {
      context.setErrorMsg(context.spPageText[`errorSubmittingQuestionnaire`]);
    }
    setLoading(false);
  };

  const submitFormFields = async () => {
    setLoading(true);
    await SpAPIsubmitQuestionnaire({ ...context })
      .then((resp: any) => {
        if (resp.status === 200) {
          setLoading(false);
          context.setShowSuccessPopUp(
            context.spPageText['questionnaireSubmitted']
          );
          if (context.onboardingStatus?.completed === 2) {
            context.setOnboardCompletePopupDetails({
              show: true,
              navigate: true,
              close: false
            });
          } else {
            navigate(`/${langContext.selectedLanguage}/seller-profile`);
          }
        } else {
          displayErrorByType(resp);
        }
      })
      .catch((error) => {
        displayErrorByType(error);
      });
  };

  useEffect(() => {
    setUpdateQuestionnaireForm('');
    const getQuestions = async () =>
      await SpAPIgetQuestionnaire(questionnaireForm, context)
        .then((res) => {
          setUpdateQuestionnaireForm(res);
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error getting questions', error);
          return error;
        });

    getQuestions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isFrench]);

  useEffect(() => {}, []);

  return !isEmpty(updateQuestionnaireForm) ? (
    <Box className="questionnaireBox">
      <form>
        <SPForm
          cols="single"
          compName="questionnaire"
          formFields={updateQuestionnaireForm}
          formName="questionnaire"
          key="questionnaire"
        />
        <div className="questionnaireButton">
          {
            <SPFormButton
              compName="SPQuestionnaire"
              formName="questionnaire"
              label={context.spPageText.submitQuestionnaire}
              showLoading={loading}
              handleClick={() => submitFormFields()}
            />
          }
        </div>
      </form>
    </Box>
  ) : (
    <></>
  );
};

export default Questionnaire;
