import { useEffect, useState } from 'react';

import './FilterNotificationRecipients.scss';
import { InputLabel, MenuItem, FormControl, Select, Box } from '@mui/material';
import { useSellerProfile } from '../../../pages/SellerProfile/SellerProfile';
import SPUserCard from '../UserCard/SPUserCard';
import UserSearchBar from '../UserSearchBar/UserSearchBar';
import useScrollPosition from '../../../hooks/useScrollPosition';

const FilterNotificationRecipients = () => {
  const scrollPosition = useScrollPosition();

  const [showHideSelect, setShowHideSelect] = useState(false)

  useEffect(() => {
    if(scrollPosition) {
      setShowHideSelect(false)
    }
  }, [scrollPosition]);

  const [selectedFilter, setSelectedFilter] = useState([
    'notifications',
    'All'
  ]);
  const resetSelectedFilter = () => {
    setSelectedFilter(['notifications', 'All']);
  };
  const { ...context } = useSellerProfile() as any;
  const notification_type = context.isFrench
    ? 'notification_type_fr'
    : 'notification_type_en';
  const handleFilterChange = (e) => {
    setSelectedFilter(['notifications', e.target.value]);
  };
  const recipientNames: string[] = [];
  context.notificationRecipients?.forEach((user) => {
    recipientNames.push(user.user_first_name);
    recipientNames.push(user.user_last_name);
  });
  let showFilter = true;
  if (context.filterUsers.length > 0) {
    const getFilteredUsers = context.notificationRecipients.filter(
      (user) =>
        context.filterUsers.includes(user.user_first_name) ||
        context.filterUsers.includes(user.user_last_name)
    );
    showFilter = getFilteredUsers.length > 0;
  }

  return (
    <Box className="NewRecipientNotificationBox">
      <UserSearchBar resetSelectedFilter={resetSelectedFilter} />
      {context.showingUsers === 'true' && showFilter && (
        <Box className="notificationBox">
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">
              {context.spPageText.showRecipients}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedFilter[1]}
              label="Show recipients of"
              onChange={handleFilterChange}
              open={showHideSelect}
              onOpen={() => setShowHideSelect(true)}
              onClose={() => setShowHideSelect(false)}
              MenuProps={{
                PaperProps: {
                  style: {
                    width: 1
                  }
                },
                disableScrollLock: true
              }}
            >
              <MenuItem value="All">
                {context.spPageText.allNotifications}
              </MenuItem>
              {context.notificationTypes?.map((type, i) => {
                return (
                  <MenuItem
                    value={type.notification_type_id}
                    key={`notifFilter${i}`}
                    sx={{ whiteSpace: 'normal' }}
                  >
                    {type[notification_type]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      )}

      <Box className="notificationList">
        <SPUserCard cardType="notifications" filter={selectedFilter} />
      </Box>
    </Box>
  );
};

export default FilterNotificationRecipients;
