
import { Box, Typography, useMediaQuery } from "@mui/material";
import desktopSupportImage from "../../assets/images/desktopSupportImage.jpeg";
import SupportCards from "../../components/SupportCards/SupportCards";
import "./Support.scss";
 
import { useTheme } from "@mui/system";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";
 

const Support = ({data}) => {
  
  const theme = useTheme();
  const handleAnchorClick = useHandleAnchorClick();
  let isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  if (data) {
    
  const supportJson = data["basepage.support"];
  const parser = new DOMParser();
  const preCardContent: any = parser.parseFromString(supportJson.body, "text/html");
 
  preCardContent.querySelectorAll("div").forEach(e => e.remove()); //removing card content from HTML, b/c deal w/ separately below...
 
    return (
      <Box className="supportPageContainer">
        <Box className="supportImageContainer">
           <Box className="supportImage">
           {!isDesktop &&
             <img
              src={desktopSupportImage}
              alt="Support"
            /> }
          </Box>
          {isDesktop && (
            <Box className="supportImageText">
              <h1 className="imageTextHeader"> { supportJson.title}</h1>
              <Typography className="imageTextDescription">
             { supportJson.subtitle}
              </Typography>
            </Box>
          )}
        </Box>

        <Box className="supportContentContainer">
          {!isDesktop && (
            <Box>
              <Typography className="mobileImgTextHeader">
              { supportJson.title}
              </Typography>
              <Typography className="mobileImgTextDesc">
              { supportJson.subtitle}
              </Typography>
            </Box>
          )}
          <Typography
            className="supportContainerBody"
            dangerouslySetInnerHTML={{
              __html: preCardContent.body.innerHTML,
            }}
            onClick={handleAnchorClick}
          />
          <SupportCards data={supportJson.body}/>
        </Box>
        <Box className="supportFaqBanner"  dangerouslySetInnerHTML={{ __html: supportJson.footerLink, }} onClick={handleAnchorClick}/>
      </Box>
    );
  }else{
    return <></>
  }
};

export default Support;
