import React from "react";
import PropTypes from "prop-types";
import "./AppVideo.scss"

const YoutubeEmbed = ({ src }) => {
 
 return  <div className="video-responsive">
    <iframe
      width="90vw"
      height="auto"
      max-width="1000px"
      src={`https://www.youtube.com/embed/${src}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
};

YoutubeEmbed.propTypes = {
  src: PropTypes.string.isRequired
};

export default YoutubeEmbed;