import './CountryFlagCard.scss';
import { ReactNode, useEffect, useState } from 'react';
import AppTooltip from '../AppTooltip/AppTooltip';

interface Props {
  code: string;
  name: string;
  tooltip?: string | ReactNode;
}

const CountryFlagCard = ({ code, name, tooltip }: Props) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    async function fetchImage() {
      try {
        const image = await import(`../../assets/images/flags/${code}.png`);
        setImageSrc(image.default);
      } catch (error) {
        console.error('Failed to load image:', error);
      }
    }
    fetchImage();
  }, [code]);

  const countryFlagCard = (
    <div className="country-flag-card">
      <img alt={name} src={imageSrc} />
      <span>{name}</span>
    </div>
  );

  return tooltip ? (
    <AppTooltip title={tooltip} offsetY={-16}>
      {countryFlagCard}
    </AppTooltip>
  ) : (
    countryFlagCard
  );
};

export default CountryFlagCard;
