import './SPActivityCard.scss';
import { Box } from '@mui/material';
import { LangContext } from '../../../pages/App/App';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSellerProfile } from '../../../pages/SellerProfile/SellerProfile';
import arrow from '../../../assets/images/arrow.png';
import copletedCheck from '../../../assets/images/copletedCheck.png';
import GetLabels from '../../../pages/SellerProfile/GetLabels';

function SPActivityCard({ activityCardData, className = '' }) {
  const { ...context } = useSellerProfile() as any;
  const langContext = useContext(LangContext);

  const navigate = useNavigate();
  const handleClick = (link) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/${langContext.selectedLanguage}${link}`);
  };

  return (
    <div className={className}>
      {activityCardData.map((item, i) => {
        const canClick = item?.link !== '' && !item.completed;
        return (
          <Box
            className={`activityCardContainer ${
              canClick && 'activityCardButton'
            }`}
            key={`${item}-${i}`}
            onClick={canClick ? () => handleClick(item.link) : undefined}
            onKeyDown={
              canClick
                ? (e) => e.key === 'Enter' && handleClick(item.link)
                : undefined
            }
            role="button"
            tabIndex={0}
          >
            <div className="cardBody">
              {item.title === 'activityCard' && (
                <img
                  className="icon"
                  src={item.image}
                  alt="Icon"
                  aria-label={item.label}
                />
              )}
              <div className="cardDescription">
                <h2 className="userName">
                  {GetLabels(item.name, context.spPageText)}
                </h2>
                <p className="userDescription">
                  {GetLabels(item.description, context.spPageText)}
                </p>
              </div>
            </div>
            {item.completed ? (
              <div className="completedCheck">
                <img
                  className="cardIcon"
                  src={copletedCheck}
                  alt="Competed Check Icon"
                  aria-label="Completed Check Icon"
                />
              </div>
            ) : (
              <div className="arrow">
                <img
                  className="cardIcon"
                  src={arrow}
                  alt="Arrow Icon"
                  aria-label="Arrow Icon"
                />
              </div>
            )}
          </Box>
        );
      })}
    </div>
  );
}

export default SPActivityCard;
