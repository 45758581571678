import { List, ListItem, ListItemText, Box, Typography } from "@mui/material";
import { LangContext } from "../../../pages/App/App";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

export default function AppMobileSignedUser({userName,coName, logout}) {
 const isSignedIn = userName !== ""; 
 const langContext = useContext(LangContext);
 const isFrench = langContext.selectedLanguage === "fr";
  const navigate = useNavigate();
  return (
    <>
        {!isSignedIn ? (
          <Box
            sx={{fontSize: "16px",
                fontWeight: 600,
                color:"#005078",
                padding:"5px",
                marginLeft: "20px"
            }}
            onClick={() =>  navigate(`/${langContext.selectedLanguage}/seller-profile`)}
          >
           {isFrench ? 'Ouvrir une session' : 'Sign In'}
          </Box>
        ) : (
          <List sx={{paddingLeft:"5px",marginLeft: "20px"}}>
            <Typography sx={{fontSize: "16px",
                fontWeight: 400, marginBottom:"15px"}}>
             {userName}
            </Typography>
            <ListItem disablePadding>
              <ListItemText
                primary={isFrench ? `Consulter le profil de ${coName}` : `View ${coName} Profile`}
                style={{
                  color: "#005078",
                }}
                onClick={()=>  navigate(`/${langContext.selectedLanguage}/seller-profile`)  }
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={isFrench ? "Déconnexion" : "Sign Out"}
                onClick={()=> logout()  }
                style={{
                  color: "#005078",
                }}
              />
            </ListItem>
          </List>
        )}
    </>
  );
}
