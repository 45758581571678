import './Markets.scss';
import { LangContext } from '../../../pages/App/App';
import { MARKET_TYPE } from '../../../constants/common';
import { useContext } from 'react';
import CountryFlagCard from '../../CountryFlagCard/CountryFlagCard';
import SuccessIcon from '../../../assets/images/Shape.png';

interface Props {
  markets: any[];
  tooltipSupportingText: string;
  type: string;
  subtitle?: string;
  title?: string;
}

const Markets = ({
  markets,
  tooltipSupportingText,
  type,
  subtitle,
  title
}: Props) => {
  const langContext = useContext(LangContext);

  return (
    <div className="market">
      {title && (
        <div className="container-header">
          <h3>{title}</h3>
          <div
            className={`square ${
              type === MARKET_TYPE.supported ? 'green' : 'orange'
            }`}
          ></div>
        </div>
      )}
      {subtitle && (
        <div className="container-text">
          <img
            alt="success icon"
            aria-label={
              langContext.selectedLanguage === 'fr' ? 'succès' : 'success'
            }
            className="icon-success"
            src={SuccessIcon}
          ></img>
          <span className="label-text">{subtitle}</span>
        </div>
      )}
      <div className="container container--markets">
        {markets.map((m, i) => {
          const key = `${type}Market-${i}`;
          return (
            <CountryFlagCard
              key={key}
              code={m.code}
              name={m.name}
              tooltip={
                m?.tooltip ? (
                  <>
                    <div className="capitalize">{tooltipSupportingText}:</div>
                    <div>{m.tooltip}</div>
                  </>
                ) : undefined
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default Markets;
