import "./BenefitsNewServicing.scss";
import BenefitsNewServicingItem from "./BenefitsNewServicing/BenefitsNewServicingItem";


const BenefitsNewServicing = ({data}) => {
	return (
		<div className="benefits-new-servicing-container" aria-labelledby="benefits-new-servicing-title-id">
			<h2 className="benefits-new-servicing-title" id="benefits-new-servicing-title-id">{data?.title}</h2>
			{data?.items?.map(item => <BenefitsNewServicingItem key={item.title} data={item} />)}
		</div>
	);
};

export default BenefitsNewServicing;
