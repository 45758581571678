import './AppAutocomplete.scss';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason
} from '@mui/material';
import AppText from '../AppText/AppText';
import { SyntheticEvent } from 'react';

interface Props {
  itemList: string[];
  label: string;
  value: string | null;
  width: string;
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => void;
}

const AppAutocomplete = ({
  itemList,
  label,
  value,
  width,
  onChange
}: Props) => {
  return (
    <Autocomplete
      disablePortal
      forcePopupIcon={false}
      onChange={onChange}
      options={itemList}
      renderInput={(params) => (
        <AppText label={label} width={width} parentField={params} />
      )}
      value={value}
    />
  );
};

export default AppAutocomplete;
