import { InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
 
export const sllrProfAxios = axios.create({
    baseURL: process.env.REACT_APP_API_BASE
});
 
sllrProfAxios.interceptors.request.use(
    async function (config) {
    const instance = window.instance as IPublicClientApplication
        try {
            const res = await instance.acquireTokenSilent({
                redirectUri: process.env.REACT_APP_AZURE_REDIRECT,
                authority: process.env.REACT_APP_AZURE_AUTHORITY,
                scopes: [""],
            });
            config.headers["Authorization"] = `Bearer ${res.idToken}`;
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                await instance.loginRedirect();
            }
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
 
export default sllrProfAxios;