import { Box } from "@mui/material";
import ConnectionOptionsSideBanner from "../ConnectionOptionsSideBanner/ConnectionOptionsSideBanner";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";
import "./technologyPartnersTab.scss";

const TechnologyPartnersTab = ({jsonData}) => {
  const handleAnchorClick = useHandleAnchorClick();
  return (
    <Box className="techPartnersContainer">
      <Box dangerouslySetInnerHTML={{__html:jsonData["technology-partners"]}} sx={{ paddingRight: "10px" }} className="techPartnerContent" onClick={handleAnchorClick}/>
      <Box>
      <ConnectionOptionsSideBanner data={jsonData["technology-partners-banner"]}/>
      </Box>
    </Box>
  );
};

export default TechnologyPartnersTab;