import * as React from 'react';
import logo from '../../assets/images/ac_logo-reversed.svg';
// import NDClogo from "../../assets/images/Rectangle 151.svg";
import {
  AppBar,
  Box,
  ClickAwayListener,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import ndcLogo from '../../assets/images/NewDistributionCapability.svg';
import AppMenuBar from '../AppMenuBar/AppMenuBar';
import { useAdobeAnalytics } from '../../providers/AdobeAnalytics';
import { LangContext } from '../../pages/App/App';
import './AppHeader.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AppSignedUser from './AppSignedUser/AppSignedUser';
import AnnouncementBanner from '../AnnouncementBanner/AnnouncementBanner';

export default function AppHeader({
  data,
  userName,
  coName,
  logout,
  selectedLanguage,
  setSelectedLanguage,
  setHeaderHeight,
  setLogoHeight,
  setAnnouncementBanner
}) {
  const langContext = useContext(LangContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const menuBarRef = React.useRef<HTMLDivElement>(null);
  const [warningHeight, setWarningHeight] = React.useState(0);
  const [menuBarHeight, setMenuBarHeight] = React.useState(0);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    const handleResize = () => {
      if (headerRef.current) {
        const totalHeaderHeight =
          (menuBarRef.current ? menuBarRef.current?.offsetHeight : 0) +
          (warningHeight ? warningHeight : 0);
        // +
        // (headerRef.current ? headerRef.current?.offsetHeight : 0);
        setHeaderHeight(totalHeaderHeight);

        setLogoHeight(
          (menuBarRef.current ? menuBarRef.current?.offsetHeight : 0) +
            (warningHeight ? warningHeight : 0) +
            (headerRef.current ? headerRef.current?.offsetHeight : 0)
        );
      }

      setMenuBarHeight(
        menuBarRef.current ? menuBarRef.current?.offsetHeight : 0
      );
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

    // eslint-disable-next-line
  }, [warningHeight]);

  const isSubseller = !location.pathname.toLowerCase().includes('subseller');

  const { adobeAnalyticsSectionClick, adobeSiteInteraction } =
    useAdobeAnalytics();

  const menuLangRef = React.useRef(null) as any;
  const selectLangRef = React.useRef() as any;

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
      if(!isSubseller) {
        setHeaderHeight(0)
      }
    // eslint-disable-next-line
    }, [isSubseller])

  const handleOptionChange = (lang) => {
    const otherLangCode = lang.substring(0, 2).toLowerCase();
    const path = window.location.search
      ? window.location.pathname + window.location.search
      : window.location.pathname;
    const updatedPathname = path.replace(
      `/${langContext.selectedLanguage}/`,
      `/${otherLangCode}/`
    );

    window.location.href = updatedPathname;
  };

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  const id = open ? 'simple-popper' : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleLogoClick = {
    onClick: () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate(`/${langContext.selectedLanguage}/ndc-program/home`);
      adobeAnalyticsSectionClick('home button');
      adobeSiteInteraction('home button');
    },
    onKeyDown: () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate(`/${langContext.selectedLanguage}/ndc-program/home`);
      adobeAnalyticsSectionClick('home button');
      adobeSiteInteraction('home button');
    }
  };

  return (
    <>
      <AppBar
        className="header"
        position="fixed"
        sx={{ padding: '0 !important' }}
        ref={headerRef}
      >
        {' '}
        <Toolbar sx={{ padding: '0 !important' }}>
          <Box className="header__content" sx={{ padding: 0, width: '100%' }}>
            <div className="logo homeLink">
              {!md && (
                <MenuIcon
                  fontSize="medium"
                  sx={{ marginRight: '10px', cursor: 'pointer' }}
                  onClick={handleMenuClick}
                />
              )}
              <div className="logo__airCanada" {...handleLogoClick}>
                <img
                  alt="Air Canada logo"
                  src={logo}
                  aria-label="return to home page"
                  className={`${sm ? 'mobileAClogo' : ''}`}
                />
              </div>
              {
                <div
                  className={`logo__NDC ${sm ? 'mobileNDClogo' : ''}`}
                  {...handleLogoClick}
                >
                  {/* {md ? logoTextDT : "NDC"} */}
                  {sm ? (
                    <span>NDC</span>
                  ) : langContext.selectedLanguage !== 'fr' ? (
                    <img alt="NDC logo" src={ndcLogo} aria-hidden="true" />
                  ) : (
                    <div className="frLogo">
                      NOUVELLE CAPACITÉ DE DISTRIBUTION
                    </div>
                  )}
                </div>
              }
            </div>
            {md && (
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <div className="search_and_lang-Wrapper">
                  <div className="language">
                    <Button
                      ref={menuLangRef}
                      role="button"
                      aria-expanded={open}
                      aria-label={
                        (langContext.selectedLanguage === 'fr'
                          ? 'sélectionner la langue, la langue actuellement sélectionnée est.'
                          : 'select language, current selected language is. ') +
                        langContext.readableLanguage
                      }
                      disableRipple
                      onClick={(event) => {
                        setAnchorEl(anchorEl ? null : event.currentTarget);
                      }}
                      sx={{
                        padding: '0px 30px',
                        border: '16px solid transparent',

                        '&:focus-visible': {
                          backgroundClip: 'padding-box',
                          borderRadius: '10px',
                          border: '16px solid transparent',
                          outline: '4px solid #f8f8f8'
                        }
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Tab' && open) {
                          event.preventDefault();
                          selectLangRef.current.focus();
                        }
                        if (event.key === 'Tab' && event.shiftKey && open) {
                          setAnchorEl(null);
                        }
                      }}
                    >
                      {langContext.readableLanguage} <KeyboardArrowDownIcon />
                    </Button>
                    <Popper
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      placement="bottom"
                      disablePortal={true}
                      className="language-popper"
                      modifiers={[
                        {
                          name: 'arrow',
                          enabled: true,
                          options: {
                            padding: 5,
                            element: arrowRef
                          }
                        }
                      ]}
                      style={{
                        zIndex: 999,
                        marginRight: '20px'
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Paper className="language-popper-content">
                          <List>
                            <ListItem disablePadding aria-hidden="true">
                              <ListItemText
                                primary={langContext.readableLanguage}
                                style={{
                                  color: '#005078',
                                  paddingLeft: '15px'
                                }}
                              />
                              <ListItemIcon>
                                <CheckIcon />
                              </ListItemIcon>
                            </ListItem>
                            <Divider
                              variant="middle"
                              style={{ padding: '5px' }}
                              aria-hidden="true"
                            />
                            <ListItem disablePadding>
                              <ListItemButton
                                ref={selectLangRef}
                                sx={{
                                  '&:focus-visible': {
                                    backgroundClip: 'padding-box',
                                    borderRadius: '10px',
                                    outline: '4px solid black'
                                  }
                                }}
                                disableRipple
                                onClick={() =>
                                  handleOptionChange(
                                    langContext.otherReadableLanguage
                                  )
                                }
                                onKeyDown={(event) => {
                                  if (event.key === 'Tab' && !event.shiftKey) {
                                    setAnchorEl(null);
                                  }
                                }}
                              >
                                <ListItemText
                                  primary={langContext.otherReadableLanguage}
                                  style={{ color: '#4A4F55' }}
                                />
                              </ListItemButton>
                            </ListItem>
                          </List>
                          <div
                            className="language-popper-arrow"
                            ref={setArrowRef}
                          />
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </div>
                </div>
                {isSubseller && (
                  <AppSignedUser
                    userName={userName}
                    coName={coName}
                    logout={logout}
                  />
                )}
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Second toolbar needed for matui bug on fixed AppBar */}
      {(md || openMenu) && ( //main nav
          <nav
            id="menuBarWrapper"
            aria-label="primary navigation"
            ref={menuBarRef}
          >
            <AppMenuBar
              data={isSubseller ? data : null}
              setOpenMenu={setOpenMenu}
              userName={userName}
              coName={coName}
              logout={logout}
            />
          </nav>
        )}
      {isSubseller && <div style={{ marginTop: `${menuBarHeight}px` }}>
        <AnnouncementBanner
          setWarningHeight={setWarningHeight}
          content={data.announcement_banner_banner}
          setAnnouncementBanner={setAnnouncementBanner}
        ></AnnouncementBanner>
      </div>}
    </>
  );
}
