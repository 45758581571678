import React, { useContext, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import "./AppTabs.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LangContext } from "../../pages/App/App";


export default function SimpleListMenu({
  content,
  handleClick,
  currLinks,
  tabs,
  getLabel,
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const langContext = useContext(LangContext);
  useEffect(() => {
    if (currLinks.length > 2) {
      //deal with tab changes from side nav
      handleMenuItemClick(tabs.indexOf(currLinks[2]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLinks]);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    currLinks[2] = tabs[index];
    handleClick(currLinks);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>  
      <div id="mobNavContainer"> 
        <List
          component="nav"
          aria-label={langContext.selectedLanguage === "fr" ? "Réglages de l'appareil" : "Device settings"}
          sx={{ bgcolor: "background.paper", margin: "0 auto" }}
          className="mobTabsMenu"
        >
          <ListItem
            id="lock-button"
            aria-haspopup="listbox"
            aria-controls="lock-menu"
            aria-label={getLabel(tabs[selectedIndex])}
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickListItem}
          >
            <ListItemText primary={getLabel(tabs[selectedIndex])}/><KeyboardArrowDownIcon  className="mobTabMenuSelectArrow"/>
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
        >
          {tabs.map((option: string, index: number) => (
            <MenuItem
              key={option}
              disabled={index === selectedIndex}
              selected={index === selectedIndex}
              onClick={() => handleMenuItemClick(index)}
              role="button"
              aria-label={`${getLabel(tabs[selectedIndex])}-item`}
            >
              {getLabel(tabs[index])}
            </MenuItem>
          ))}
        </Menu> 
      </div>
      <div className="mobileTabsContent">
        {content}</div>
    </>
  );
}
