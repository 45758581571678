import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";
import "./connectionOptionsSideBanner.scss";

const ConnectionOptionsSideBanner = ({data}) => {
    const handleAnchorClick = useHandleAnchorClick();
    return (
        <div  dangerouslySetInnerHTML= {{__html:data}} onClick={handleAnchorClick}/>
    );
};

export default ConnectionOptionsSideBanner;
