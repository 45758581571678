import React, { useContext, useState } from "react";
import "./ConnectionOptions.scss";
import DiscoverHeader from "../../../components/DiscoverHeader/DiscoverHeader";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DirectIntegrationTab from "../../../components/DirectIntegrationTab/DirectIntegrationTab";
import TechnologyPartnersTab from "../../../components/TechnologyPartnersTab/TechnologyPartnersTab";
import DesktopPlatformTab from "../../../components/DesktopPlatformTab/DesktopPlatformTab";
import { LangContext } from "../../App/App";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import ConnectionFooterBanner from "../../../components/connectionBanner/connectionBanner"; 

const ConnectionOptions = ({ ...props }) => { 
  const urls = ["direct-integration", "technology-partners", "ac-connex"];
  const json = props.data["ndc-program.connection-options"];
  const [activeTabIndex, setActiveTabIndex] = useState<number>(props.showTab);
  const langContext = useContext(LangContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (index: number) => {
    setActiveTabIndex(index);
    setAnchorEl(null);
    window.history.replaceState(null, urls[index],`/${langContext.selectedLanguage}/ndc-program/connection-options/${urls[index]}`)
   // history(`/ndc-program/connection-options/${urls[index]}`);
  };

  const tabsLabel =
    langContext.selectedLanguage === "fr"
      ? ["Intégration directe", "Partenaires technologiques", "AC Connex"]
      : ["Direct Integration", "Technology Partners", "AC Connex"];

  const breadcrumb =
    langContext.selectedLanguage === "fr"
      ? "Options de connexion"
      : "Connection Options";

  return (
    <>
      {json && (
        <main className="connection-options-container">
          <DiscoverHeader
            title={json.title}
            paragraph={json["connection-options-header"]}
            breadcrumb={breadcrumb}
            image="planeConnecImg.png"
            dropdown={true}
          />
          {isDesktop ? (
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                marginX: "96px",
                marginTop: "10px",
              }}
            >
              <nav aria-label="connection-options-tabs">
                <Tabs
                  value={activeTabIndex}
                  aria-label="Connection Options Tabs"
                  className="connectionTabs"
                >
                  {tabsLabel.map((label, index) => (
                    <Tab
                      key={`${label}-${index}`}
                      value={index}
                      label={label}
                      onClick={() => handleMenuItemClick(index)}
                    />
                  ))}
                </Tabs>
              </nav>
            </Box>
          ) : (
            <Box className="selectDropdown">
              <List
                component="nav"
                aria-label={
                  langContext.selectedLanguage === "fr"
                    ? "Réglages de l'appareil"
                    : "Device settings"
                }
                sx={{ bgcolor: "background.paper", margin: "0 auto" }}
              >
                <ListItem
                  id="lock-button"
                  aria-haspopup="listbox"
                  aria-controls="lock-menu"
                  aria-label={tabsLabel[activeTabIndex]}
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickListItem}
                >
                  <ListItemText primary={tabsLabel[activeTabIndex]} />
                  <KeyboardArrowDownIcon />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
              >
                {tabsLabel.map((option: string, index: number) => (
                  <MenuItem
                    key={option}
                    disabled={index === activeTabIndex}
                    selected={index === activeTabIndex}
                    onClick={() => handleMenuItemClick(index)}
                    role="button"
                    aria-label={`${tabsLabel[activeTabIndex]}-item`}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
          {
            [
              <DirectIntegrationTab jsonData={json} />,
              <TechnologyPartnersTab jsonData={json} />,
              <DesktopPlatformTab jsonData={json} />,
            ][activeTabIndex]
          }
          {/* <ConnectionFooterBanner data={props.data} /> */}
        </main>
      )}
    </>
  );
};

export default ConnectionOptions;
