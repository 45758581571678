import  { useEffect } from "react";
import "./Benefits.scss";

import DiscoverHeader from "../../../components/DiscoverHeader/DiscoverHeader";
import BenefitsFeatured from "../../../components/BenefitsFeatured/BenefitsFeatured";
import BenefitsNewServicing from "../../../components/BenefitsNewServicing/BenefitsNewServicing";
import BenefitsAdditionalContent from "../../../components/BenefitsAdditionalContent/BenefitsAdditionalContent";
import BenefitsCoupons from "../../../components/BenefitsCoupons/BenefitsCoupons";
// import ConnectionFooterBanner from "../../../components/connectionBanner/connectionBanner";


 

const Benefits = ({ data }) => {
	const json = data["ndc-program.benefits"];

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const couponRef:any[] = [];

	useEffect(() => {
	 
			if(window.location.search){
				const goto = window.location.search.replace("?","")
		
				if(goto === "coupon"){
						couponRef[0] &&	couponRef[0].scrollIntoView({
							block: "center",
						  });
				}
			  } 
	  }, [couponRef]);
	
	 
	return (
		<>
			{json && (
				<main className="benefits-container">
					<DiscoverHeader {...json["section.header"]} />
					<BenefitsFeatured data={json["section.featured"]} />
					<hr className="divider"/>
					<div className="benefits-ns-ac-container">
						<BenefitsNewServicing data={json["section.servicing"]} />
						<BenefitsAdditionalContent data={json["section.additional"]} />
					</div>
					<div ref={(el) => ( couponRef.push(el))}>
					<BenefitsCoupons data={json["section.coupon"]} 
					/></div>
					{/* <ConnectionFooterBanner data={data}/> */}
				</main>
			)}
		</>
	);
};

export default Benefits;
