import AppCodeSnippet from "../../components/AppCodeSnippet/AppCodeSnippet";
import AppDropDownBar from "../../components/AppDropDownBar/AppDropDownBar";
import AppApiRefTable from "../../components/AppApiRefTable/AppApiRefTable";
import AppWarningBanner from "../../components/AppWarningBanner/AppWarningBanner";
import { GenericError } from "../../components/AppError/AppError";
import bookingFlowParser from "./BookingFlowParser";


interface TabPanelProps {
  setContent: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
  links: string[];
  data: string;
  handleAnchorClick?: any;
}

const AppContentParser = (props: TabPanelProps) => {
  if (props.data && props.links.length) {
    const tableClasses = [
      "requestResponseTable",
      "releaseNotesTable",
      "errorCodesTable",
      "useCasesTable",
    ];

    let finalContent = <></>;

    const checkContentType = (str: string, id: string, title: string) => {
      const htmlStrings: string[] = [];

      //Check for the following special elements
      //(update tagTypeStart and tagTypeEnd as needed)
      const tagTypeStart = ["<table", "<pre>", '<div class="warning-banner"'];
      const tagTypeEnd = ["/table>", "</pre>", "</div>"];

      //Count and record total occurences of special element
      //types and their location in the html string
      const tags = {};
      const indices: number[] = [];

      tagTypeStart.forEach((a) => {
        const re = new RegExp(a, "g");
        var regex = re,
          result: string[] | null;

        while ((result = regex.exec(str))) {
          if (result.hasOwnProperty("index")) {
            indices.push(result["index"]);
            tags[result["index"]] = result[0];
          }
        }
        if (indices.length) {
          indices.sort((a: number, b: number) => a - b);
        }
      });

      if (indices.length) {
        //If there are special elements (i.e. table/pre/warning) parse accordingly...
        indices.forEach((number, index) => {
          /*Make a slice of the html between every instance of special elements, 
         then extract it, checking for standard html in between */
          const getTypeEnd = tagTypeStart.indexOf(tags[number]);
          let sliceToCheck = "";
          if (index === 0 && number > 0) {
            //first is standard html tag so just push it
            htmlStrings.push(str.substring(0, indices[0]));
            //then get what is delineated by index 0
            sliceToCheck = str.substring(indices[0], indices[1]);
          } else if (indices.length === index + 1) {
            //is last html chunk
            sliceToCheck = str.substring(indices[index]);
          } else {
            //all other html chunks
            sliceToCheck = str.substring(indices[index], indices[index + 1]);
          }

          const elemTypeEnd = tagTypeEnd[getTypeEnd];

          //Extract the desired element from the html chunk
          const currElemStr = sliceToCheck.substring(
            0,
            sliceToCheck.indexOf(elemTypeEnd) + elemTypeEnd.length
          );

          htmlStrings.push(currElemStr);

          //Check if had trailing plain html
          if (currElemStr.length < sliceToCheck.length) {
            htmlStrings.push(sliceToCheck.substring(currElemStr.length));
          }
        });
      } else {
        htmlStrings.push(str);
      }

      return parseHTML(
        htmlStrings,
        `${props.links[1]}-${props.links[2]}-${id}`,
        title
      );
    };
    let keySuffix = "";
    for (let i = 0; i < props.links.length; i++) {
      keySuffix += i === 0 ? `${props.links[i]}` : `-${props.links[i]}`;
    }
 
    const parseHTML = (htmlStrings: string[], key: string, label: string) => {
      //For each parsed html element, add it to final content to send back
      return htmlStrings.map((htmlStr: string, ind) => {
        //deal with empty alt tags
        if (htmlStr.includes('alt=""')) {
          htmlStr = htmlStr.replaceAll('alt=""', `alt='${label}'`); //TODO - find a better solution for this...
        }
        //look for tables with specific classes to return as component
        if (htmlStr.includes("<table")) {
          let tblClass = "";
          tableClasses.forEach((tClass) => {
            props.links.forEach((link) =>
              tClass.toLowerCase().includes(link) ? (tblClass = tClass) : null
            );
          });

          finalContent = (
            <AppApiRefTable
              tblStr={htmlStr}
              className={tblClass}
              classLabel={label}
              key={`${key}-appbartbl-${ind}`}
            />
          );
        } else if (htmlStr.includes("<pre")) {
          finalContent = (
            <AppCodeSnippet
              snippet={htmlStr}
              key={`${key}-appbarsnippet-${ind}`}
              title={label}
            />
          );
        } else if (htmlStr.includes('<div class="warning-banner"')) {
          finalContent = (
            <AppWarningBanner
              htmlContent={htmlStr}
              key={`${key}-appbarwarning-${ind}-${label}`}
            />
          );
        } else if (htmlStr.includes('<div class="booking_flow"')) {
          const links = props.links;
      
          finalContent = bookingFlowParser({ htmlStr, links, keySuffix, handleAnchorClick : props.handleAnchorClick});
        } else {
          //basic HTML string
          finalContent = (
            <div
              key={`${keySuffix}-div-bookingflow-${ind}`}
              dangerouslySetInnerHTML={{ __html: htmlStr }}
              onClick={props.handleAnchorClick}
            ></div>
          ); //Not using parser() b/c it converts specialty tags to lowercase which makes react unhappy...
        }
        return finalContent;
      });
    };

    //get the key suffix i.e. -${props.links[1]}-${props.links[2]}`

    const createAcc = (key: string) => {
      let htmlContent = <></>;

      try {
        /*Go through html and look for special element types 
          (i.e. html elements that will get injected into React 
          components as opposed to left as standard html strings.)
          These special elements are set below in tagTypeStart and tagTypeEnd)
          */

        const contentArray = props.data[key]["accordion-body.array"];

        htmlContent = (
          <>
            {contentArray.map((sections: string, i) => {
              //Send this section's html for parsing of special html elements

              let str = sections["content"]
              .trim()
                .replaceAll("<p>&nbsp;</p>", "")
                .replaceAll("</a><a", "</a> &nbsp; <a")
                .replaceAll('href="http', "target='_blank' href=\"http")
                .replaceAll('<td>&nbsp;', "<td>");
              

              const parsedHTML = checkContentType(
                str,
                sections["id"],
                sections["title"]
              );

              //Check if is in an Accordion
              if (sections["collapsable"]) {
                return (
                  <AppDropDownBar
                    title={sections["title"]}
                    key={`${keySuffix}-appbar-${i}`}
                    keyID={`${keySuffix}-${i}`}
                    index={sections["id"]}
                    className={`apiRefPg ${props.links[0]} ${props.links[1]} ${
                      props.links.length > 2 ? props.links[2] : ""
                    }`}
                  >
                    {parsedHTML}
                  </AppDropDownBar>
                );
              } else {
                return (
                  <div
                    key={`${keySuffix}-div-${i}`}
                    className={`apiRefPg ${props.links[0]} ${props.links[1]} ${
                      props.links.length > 2 ? props.links[2] : ""
                    }`}
                  >
                    <h2 className="sectionTitle">{sections["title"]}</h2>
                    {parsedHTML}
                  </div>
                );
              }
            })}
          </>
        );
      } catch (error) {
        console.log("ERROR in app content parser",error);
      
        htmlContent = <GenericError />;
      }

      props.setContent(htmlContent);
    };
    const createBasic = (key: string) => {
      let htmlContent = <></>;
      try {
        const contentTitle = "";

        const contentBody = props.data[key]["body"];

        if (!props.data[key]) {
          console.log("DATA NOT FOUND");
        }
        const contentId = props.data[key]["title"].replace(" ", ""); //TODO Figure out better ID...

        const parsedHTML = checkContentType(
          contentBody,
          contentId,
          contentTitle
        );

        htmlContent = (
          <div
            className={`apiRefPg ${props.links[0]} ${props.links[1]} ${
              props.links.length > 2 ? props.links[2] : ""
            }`}
            key={`${keySuffix}-content`}
          >
            {parsedHTML}
          </div>
        );
      } catch (error) {
        console.log(`ERROR LOADING: ${props.links[0]}`);
        if (props.links[1]) {
          console.log(`Sublink: ${props.links[1]}`);
        }
        if (props.links[2]) {
          console.log(`Sublink 2: ${props.links[2]}`);
        }
        console.log(error);
        htmlContent = <GenericError />;
      }
      props.setContent(htmlContent);
      //  props.setContent(`${useContent} content goes here.`);
    };

    if (props.data[`basepage.api-${keySuffix}`]) {
      //is a plane page

      const key = `basepage.api-${keySuffix}`;

      //create basic page
      createBasic(key);
    } else if (props.data[`accordion.api-${keySuffix}`]) {
      //is a NON API REF accordion  page

      const key = `accordion.api-${keySuffix}`;
      createAcc(key);
      //create accordion page
    } else {
      //content not found
      //display error

      console.log(`ERROR LOADING: ${keySuffix}`);

      props.setContent(<GenericError />);

      return false;
    }
  }
};

export default AppContentParser;
