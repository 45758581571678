import { Grid, Typography } from "@mui/material";
import "./BenefitsNewServicingItem.scss";

const BenefitsNewServicingItem = ({ data }) => {
	return (
		<Grid container className="benefits-new-servicing-item">
			<Grid item className="benefits-new-servicing-item-img">
				<img src={data?.imageUrl} alt=""/>
			</Grid>
			<Grid item className="benefits-new-servicing-item-right">
				<Typography className="benefits-new-servicing-item-title">{data?.title}</Typography>
				<ul className="benefits-new-servicing-item-text-list">
					{data?.texts?.map((text,i) => 
						<li key={`ben1-${i}`} className="benefits-new-servicing-item-text">
							{text}
						</li>
					)}
				</ul>
			</Grid>
		</Grid>
	);
};

export default BenefitsNewServicingItem;
