import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  details: string | string[];
  summary: string;
}
const AppAccordion = ({ details, summary }: Props) => {
  const getAccordionProps = () => {
    return {
      borderBottom: '1px solid #efefef',
      borderRadius: 'none',
      borderTop: '1px solid #efefef',
      boxShadow: 'none'
    };
  };

  const getAccordionSummaryProps = () => {
    return {
      fontSize: '16px',
      fontWeight: '600',
      '&.Mui-expanded': {
        backgroundColor: '#f1f7fa'
      }
    };
  };

  return (
    <Accordion sx={getAccordionProps()}>
      <AccordionSummary
        aria-controls="panel-content"
        expandIcon={
          <ExpandMoreIcon sx={{ color: '#005078', fontSize: '24px' }} />
        }
        id="panel-header"
        sx={getAccordionSummaryProps()}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#f1f7fa' }}>
        {Array.isArray(details)
          ? details.map((d, i) => (
              <div key={i} style={{ marginTop: i !== 0 ? '30px' : '0' }}>
                {d}
              </div>
            ))
          : details}
      </AccordionDetails>
    </Accordion>
  );
};

export default AppAccordion;
