import { Box } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useScrollPosition from '../../hooks/useScrollPosition';
import useWindowSize from "../../assets/customHooks/useWindowSize";

const FrenchDisclaimer = ({ pageType, open }) => {
  const scrollPosition = useScrollPosition()
  const { width } = useWindowSize();
  const baseWidth = width! < 900 ? 45 : 65;

  const notGeneral = {
    position: "absolute",
    top: `${open ? "-75px" : `-${Math.min(85, baseWidth + scrollPosition)}px`}`,
    paddingRight: "50px",
  }
   
  return (
    <>
   <Box sx={{ ...( pageType !== "ndcAPIs" && notGeneral) ,margin: "0 auto 5px auto",  width: "100%"}}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "65px", backgroundColor: "#DDECF5", borderLeft: "1px solid #005078", position: "relative", zIndex: "999" }}>
        <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
            <InfoOutlinedIcon sx={{ marginLeft: "10px", color: "#005078", height: "25px", width: "25px" }} />
            <span style={{ marginLeft: "5px" }}>La documentation technique relative à l'API est fournie par un tiers et n'est disponible qu'en anglais.</span>
        </div>
      </Box>
    </Box>
    </>
  );
};

export default FrenchDisclaimer;
