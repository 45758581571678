import React, { useState, ChangeEventHandler, useEffect, useRef } from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { format, isAfter, isBefore, isValid, parse } from "date-fns";
import {
	DateRange,
	CaptionProps,
	useNavigation,
	DayPicker,
	SelectRangeEventHandler,
} from "react-day-picker";

import Box from "@mui/material/Box";

import "react-day-picker/dist/style.css";
import "./AppFilterDate.scss";

const AppFilterDate = ({ setFilter, futureDefault }) => {

	const [fromValue, setFromValue] = useState<string>("");
	const [toValue, setToValue] = useState<string>("");
	const [selectedRange, setSelectedRange] = useState<DateRange>();
	const [hover, setHover] = useState(false);

	const containerRef = useRef(null) as any;
	const calendarRef = useRef(null) as any;

	useEffect(() => {
		if (selectedRange?.from && selectedRange?.to) {
			setFilter([selectedRange?.from, selectedRange?.to]);
		}
	}, [selectedRange, setFilter]);

	const formatDateInput = (value: string) => {
		return `${value.slice(0, 4)}-${value.slice(5, 7)}-${value.slice(8, 10)}`;
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		setValue: React.Dispatch<React.SetStateAction<string>>,
		setRangeValue: (date: Date) => void
	) => {
		const value = e.target.value.replace(/[^\d]/g, "");
		const formattedValue =
			value.slice(0, 4) +
			(value.length > 4 ? " / " : "") +
			value.slice(4, 6) +
			(value.length > 6 ? " / " : "") +
			value.slice(6);

		setValue(formattedValue);

		const formattedVa = formatDateInput(formattedValue.replace(/ \/ /g, "/"));
		let date = parse(formattedVa, "y-MM-dd", new Date());
		if(date < futureDefault){
		 	date= futureDefault
		}
 
		isValid(date) && setRangeValue(date);
	};

	const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		handleInputChange(e, setFromValue, (date) => {
			selectedRange?.to && isAfter(date, selectedRange.to)
				? setSelectedRange({ from: selectedRange.to, to: date })
				: setSelectedRange({ from: date, to: selectedRange?.to });
		});
	};

	const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		handleInputChange(e, setToValue, (date) => {
			selectedRange?.from && isBefore(date, selectedRange.from)
				? setSelectedRange({ from: date, to: selectedRange.from })
				: setSelectedRange({ from: selectedRange?.from, to: date });
		});
	};

	const selectedDate = () => {
		const formatDate = (date) => format(date, "MMM dd yyyy");

		const from = selectedRange?.from ? formatDate(selectedRange.from) : "Jan 1 2023";
		const today = new Date()
		const endDate = (today < futureDefault) ? futureDefault : today;
		const to = selectedRange?.to ? formatDate(selectedRange.to) : formatDate(endDate);
	 
		 
		return `${from} - ${to}`;
	};

	return (
		<>
			<div
				style={{ display: "flex" }}
				onMouseOver={() => setHover(true)}
				onMouseOut={() => setHover(false)}
				onClick={() => setHover(!hover)}
				onKeyDown={(e) => {
					if (e.key === "Enter") setHover(!hover);
				}}
				tabIndex={0}
				role="button"
				aria-expanded={hover}
				aria-label="Filter by date"
				ref={containerRef}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "space-between",
						padding: "6px 10px",
						width: "100%",
						height: "40px",
						border: "2px solid #EFEFEF",
						borderRadius: "0 5px 5px 0",
						cursor: "pointer",
					}}
				>
					<span>{selectedDate()}</span>
					<CalendarTodayIcon fontSize="small" sx={{ marginTop: "2px", marginLeft: "10px" }} />
				</div>
			</div>
			{hover && (
				<Box
					className="date-head"
					onMouseOver={() => setHover(true)}
					onMouseOut={() => setHover(false)}
					onFocus={() => setHover(true)}
					onBlur={(e) => (!calendarRef.current?.contains(e.relatedTarget) && setHover(false))}
					ref={calendarRef}
				>
					<div className="date-input-container">
						<input
							type="text"
							maxLength={14}
							placeholder="YYYY/MM/DD"
							onChange={handleFromChange}
							className="date-input"
							value={fromValue}
              			aria-label="From date in format ..."
						/>
						<input
							type="text"
							maxLength={14}
							placeholder="YYYY/MM/DD"
							onChange={handleToChange}
							className="date-input"
							value={toValue}
              			aria-label="To date in format ..."
						/>
					</div>
					<div className="calendar-container">
						<CustomDate
							selectedRange={selectedRange}
							setSelectedRange={setSelectedRange}
							setFromValue={setFromValue}
							setToValue={setToValue}
						/>
					</div>
				</Box>
			)}
		</>
	);
};

function CustomDate({ selectedRange, setSelectedRange, setFromValue, setToValue }) {
	const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
		setSelectedRange(range);
		if (range?.from) {
			const date = format(range.from, "y/MM/dd");
			setFromValue(date.replace(/\//g, " / "));
		} else {
			setFromValue("");
		}
		if (range?.to) {
			const date = format(range.to, "y/MM/dd");
			setToValue(date.replace(/\//g, " / "));
		} else {
			setToValue("");
		}
	};

	return (
		<DayPicker
			mode="range"
			selected={selectedRange}
			showOutsideDays
			onSelect={handleRangeSelect}
			components={{
				Caption: CustomCaption,
				Head: CustomHead,
			}}
		/>
	);
}
const CustomHead = () => {
	const week = ["S", "M", "T", "W", "T", "F", "S"];
	return (
		<tbody>
		<tr>
			{week.map((day,i) => (
				<td key={`${day}-${i}`} className="custom-head">{day}</td>
			))}
		</tr></tbody>
	);
};

const CustomCaption = (props: CaptionProps) => {
	const { goToMonth, nextMonth, previousMonth } = useNavigation();
	return (
		<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
			<IconButton
			disabled={!previousMonth}
			onClick={() => previousMonth && goToMonth(previousMonth)}
			aria-label="Previous month"
			>
			<ChevronLeftIcon fontSize="medium" sx={{ color: "black" }} />
			</IconButton>
			<span style={{ fontFamily: "Open Sans", color: "#2A2A2A", fontSize: "20px" }}>
				{format(props.displayMonth, "MMMM yyyy")}
			</span>
			<IconButton
			disabled={!nextMonth}
			onClick={() => nextMonth && goToMonth(nextMonth)}
			aria-label="Next month"
			>
			<ChevronRightIcon fontSize="medium" sx={{ color: "black" }} />
			</IconButton>
		</div>
	);
}

export default AppFilterDate;
