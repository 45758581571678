//from: https://gist.github.com/andywer/800f3f25ce3698e8f8b5f1e79fed5c9c

import React from "react";
import Catch from "./functional-error-boundary";
import "./ErrorBoundary.scss";

type Props = {
  children: React.ReactNode;
};

const ErrorBoundary = Catch(function MyErrorBoundary(
  props: Props,
  error?: Error
) {
  if (error) {
    console.log("****** Error Boundary triggered on:");
    console.log(error.message);
    return (
      <div className="error-screen">
        <div>
          <h3>
            There was an error retreiving this page. <br />
            Please contact support.
          </h3>
        </div>
        <div>
          {" "}
          <h4>
            Une erreur s'est produite lors de la récupération de cette page.
            Veuillez contacter l'assistance.
          </h4>
        </div>
      </div>
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
});

export default ErrorBoundary;
