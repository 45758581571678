import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import mobileViewTable from "../TableTemplates/MobileViewTable";
import desktopViewTable from "../TableTemplates/DesktopViewTable";
import "./AppApiRefTable.scss";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";
interface IReleaseNotesProps {
  tblStr: string;
  className: string;
  classLabel: string;
}
const ApiTable: React.FC<IReleaseNotesProps> = (
  props: IReleaseNotesProps
) => {
  const theme = useTheme();
  const handleAnchorClick = useHandleAnchorClick();
  if(props.className === "errorCodesTable"){

  }
  /*
   * useMediaQuery will return the desktop or mobile view depending
   * on scenario
   * mobileviewTable and desktopViewTable and getting generated in
   * their respective functions by parsing the string table data that
   * is passed as argument
   */
  let getApiTableContent = useMediaQuery(theme.breakpoints.up("md"))
    ? desktopViewTable(props.tblStr, props.className, props.classLabel)
    : (props.className === "errorCodesTable") ? mobileViewTable(props.tblStr,"Error Codes Table", handleAnchorClick) : mobileViewTable(props.tblStr,'' , handleAnchorClick);

    if (props.className === "releaseNotesTable") {
          //placement is a hack due to no cond hook call, fix this...!
          getApiTableContent = mobileViewTable(props.tblStr, '', handleAnchorClick);
       }

  return <div key={`${props.className}-tbl`}>{getApiTableContent}</div>;
};
export default ApiTable;
