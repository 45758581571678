import parser, { HTMLReactParserOptions, Element } from "html-react-parser";
import "./DesktopAndMobileTables.scss";
const desktopViewTable = (
  table: string,
  className: string,
  tableLabel?: string
) => {
  const deskTopOptions: HTMLReactParserOptions = {
    replace: domNode => {
      if (domNode instanceof Element && domNode.name === "table") {
        /* destopViewTable class is used to give a common style to table and
           could be used in the other components to change the style accordingly */
        domNode.attribs.class = className;
        tableLabel && (domNode.attribs.label = tableLabel);
        tableLabel && (domNode.attribs["aria-label"] = tableLabel);
      }
    }
  };
  return parser(table, deskTopOptions);
};
export default desktopViewTable;
