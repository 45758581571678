import "./AppDropDownBar.scss";

import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext } from "react";
import { LangContext } from "../../pages/App/App";
import useHandleAnchorClick
 from "../../hooks/useHandleAnchorClick";
const AppDropDownBar = ({  title = "", keyID = "", index = 1, className = "", children }) => {
	const isWorkFlowTitle = title.includes("Workflow Scenarios");
  const langContext = useContext(LangContext);
  const workFlowTitle =
    isWorkFlowTitle && langContext.selectedLanguage === "fr"
      ? `Scénarios de processus`
      : `Workflow Scenarios`;
	const handleAnchorClick = useHandleAnchorClick();

	 
	return (
		<Accordion
			className={`dropDownBarContainer ${className}`}
			sx={{
				boxShadow: "none",
				"&.MuiAccordion-root:before": {
					position: "static",
				},
			}}
			key={`${keyID}-l1${index}`}
			elevation={0}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: "#005078" }} fontSize="large" />}
				aria-controls={`panel${index}a-content`}
				id={`panel${index}-${title.toLowerCase().replace(/ /g, "-")}-a-header`}
				key={`${keyID}-l2-${index}`}
			>
				<Typography className="dropDownBarTitle" component="h2">{isWorkFlowTitle ?    <div
                    dangerouslySetInnerHTML={{ __html:  `<h3>${workFlowTitle} </h3> <div class='workFlowSubtitle'>${title.replace('Workflow Scenarios',"")}</div>` }} onClick={handleAnchorClick}
                  ></div>  : <div  dangerouslySetInnerHTML={{ __html: title }}  onClick={handleAnchorClick}></div>}</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					"& > :not(style)": { marginTop: "30px" },
				}}
				key={`${keyID}-l3-${index}`}
			>
				{children}
			</AccordionDetails>
		</Accordion>
	);
};

export default AppDropDownBar;
