import { Box, Button, Link, Typography } from "@mui/material"
import "./RemoveUserConfirmation.scss";
import { useSellerProfile } from "../../../../pages/SellerProfile/SellerProfile";
import { LoadingButton } from "@mui/lab";

export const RemoveUserConfirmation = ({  onClose, order, error, removeSubmission }) => {
  const {
    ...context
  } = useSellerProfile() as any;
  //removeUserConfirmationContainer
  //removeUserConfirmationBody
  return   <Box className="editUserCardPopupContainer removeUserConfirmationContainer">
   
      <Box className="editUserCardPopupBody removeUserConfirmationBody">
        <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>
          {context.spPageText.confirmRemove}
        </Typography>
        <Box className="removeUserConfirmationButtons ">
          <Link 
            onClick={() => onClose(false)}
            onKeyDown={(e) =>  e.key === "Enter" && onClose(false)} 
            className="cancelButton"
            tabIndex={0}
          >
          {context.spPageText.cancel}
          </Link>
        
        {  removeSubmission ? <LoadingButton loading   className="removeUserButton loading"> {context.spPageText.removeUser}</LoadingButton>  : <Button
            variant="text"
            onClick={() => onClose(true)}
            onKeyDown={(e) =>  e.key === "Enter" && onClose(true)} 
            className="removeUserButton"
          >
           {context.spPageText.removeUser}
          </Button>}
        </Box>
        {error !== "" && <Box sx={{marginTop: "12px",fontWeight: "bold", color: "#D8292F", width: "auto", maxWidth: "300px", textAlign:"center"}}>{error}</Box>}
      </Box>
    </Box>
 
};