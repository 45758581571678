import { Box, Button, Divider, Typography } from "@mui/material";
// import { useSellerProfile } from "../../../pages/SellerProfile/SellerProfile";
import "./SPProfileBox.scss";
import SPProfileCard from "../ProfileCard/SPProfileCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSellerProfile } from "../../../pages/SellerProfile/SellerProfile";
import homePgProfileFields from "../Form/json/homePageProfileFields.json";
import GetLabels from "../../../pages/SellerProfile/GetLabels";
import TranslateDate from "../../../pages/SellerProfile/TranslateDate";
import { SpAPIgetCompanyProfile } from "../../../pages/SellerProfile/APIs/CompanyProfileAPI";
import { LangContext } from "../../../pages/App/App";
function SPProfileBox() {
  const langContext = useContext(LangContext);
  const {
   ...context
  } = useSellerProfile() as any;
  const navigate = useNavigate();
  const [isExpanded, setExpandedStatus] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
 
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    isExpanded && ref?.current?.focus();
  }, [isExpanded]);

  let expandBtnText = isExpanded
    ? GetLabels("spPageText.hideProfile", context.spPageText)
    : GetLabels("spPageText.expandProfile", context.spPageText);

  useEffect(() => {

    SpAPIgetCompanyProfile(
      context
    ).then(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isFrench]);

  return !isLoading ? (
    <Box className="SPProfileBox">
      <div className="profileDetailsContainer">
        {Object.entries(homePgProfileFields).map(([label, entry], i) => {
          let useLabel: any = "";
          let useValue: any = "";
          if (entry["mainpage"] && label !== "profileCards") {
            if (entry.hasOwnProperty("options")) {
              Object.entries(entry["options"]).forEach(([key, val], i) => {
              
                const currLabel = GetLabels(
                  entry["options"][key]["label"],
                  context.spPageText
                );
                let currValue = context.compProfile[key];
                if (Array.isArray(currValue)) {
                 currValue =currValue.toString().replaceAll(",", ", ")
                }
                useLabel =
                  useLabel === ""
                    ? currLabel
                    : `${GetLabels(useLabel, context.spPageText)} / ${currLabel}`; 
                if (
                  entry["options"][key]["type"] === "date" &&
                  context.compProfile[key]
                ) {
                  const dateValue = GetLabels(context.compProfile[key], context.spPageText);
                  currValue = TranslateDate(dateValue, context.isFrench);
                }
                useValue =
                  useValue === "" ? currValue : `${useValue} / ${currValue}`;
              });
            } else if (entry.hasOwnProperty("label")) {
              useLabel = `${GetLabels(entry["label"], context.spPageText)}`;

              useValue = context.compProfile[label];
            }
            if (Array.isArray(useValue)) {
              useValue = useValue.map((val, i) =>
                i < useValue.length - 1 ? `${val}, ` : val
              );
            }

            return (
              <div
                className="detailsBlock"
                key={`${entry}-${i}`}
                aria-label={label + ". group reads ." + entry}
                tabIndex={0}
              >
                <span className="profileLabel" aria-hidden={true}>
                  {GetLabels(useLabel, context.spPageText)}
                </span>
                <p
                  className={` ${
                    !isExpanded ? "truncateText" : ""
                  } profileDescription`}
                >
                  {useValue}
                </p>
              </div>
            );
          } else {
            return false;
          }
        })}
      </div>
      {isExpanded && (
        <div aria-label="expanded content" tabIndex={0} ref={ref}>
          {" "}
          <Divider sx={{ background: "#DDECF5", marginBlock: "32px" }} />
          <Box
            sx={{
              display: { xs: "flex", md: "grid" },
              gridTemplateColumns: "1fr 1fr",
              flexDirection: { xs: "column" },
            }}
          >
            <SPProfileCard />
            <div className="companyDetails">
              {Object.entries(homePgProfileFields).map(([label, entry], i) => {
                if (!entry["mainpage"] && label !== "profileCards") {
                  return (
                    <div
                      aria-label={label + ". group reads ." + entry}
                      tabIndex={0}
                      key={`${label}-${i}`}
                    >
                      <span className="profileLabel" aria-hidden={true}>
                        {GetLabels(entry["label"], context.spPageText)}
                      </span>
                      <p className="profileDescription">
                        {context.compProfile[label] || ""}
                      </p>
                    </div>
                  );
                } else {
                  return false;
                }
              })}
            </div>
          </Box>
          {context.isAdmin && (
            <Box className="updateProfileBtn">
              <Button onClick={() =>  navigate(`/${langContext.selectedLanguage}/seller-profile/profile`)} style={{textTransform: 'none'}}>
                {GetLabels("spPageText.updateProfileDetails", context.spPageText)}
              </Button>
            </Box>
          )}
        </div>
      )}
      <Divider sx={{ marginTop: "32px" }} />
      <Box className="expandColapBtn">
        <Button onClick={() => setExpandedStatus(!isExpanded)} style={{textTransform: 'none'}}>
          <Typography>{expandBtnText}</Typography>
          {!isExpanded ? (
            <ExpandMoreIcon aria-hidden={true} />
          ) : (
            <ExpandLessIcon aria-hidden={true} />
          )}
        </Button>
      </Box>
    </Box>
  ) : (
    <></>
  );
}

export default SPProfileBox;
