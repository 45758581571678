import { Box, Button } from "@mui/material";
import "./SellerProfileHeaderContainer.scss";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useSellerProfile } from "../../../pages/SellerProfile/SellerProfile";
import { useNavigate } from "react-router-dom";
import { LangContext } from "../../../pages/App/App";
import { useContext } from "react";

const SellerProfileHeaderContainer = ({ ...props }) => {
  const { ...context } = useSellerProfile() as any;
  const navigate = useNavigate(); 
  const langContext = useContext(LangContext);
  return (
    <main className="mainContainer">
      <>
        <Button
          onClick={() =>  navigate(`/${langContext.selectedLanguage}/seller-profile`)}
          className="profileBackButton"
        >
          <KeyboardArrowLeftIcon className="profileBackIcon" />
          {context.isFrench
            ? `${
              context.spPageText.backTo
              } ${context.spPageText.profile?.toLowerCase()} ${context.coName}`
            : `${context.spPageText.backTo} ${context.coName} ${context.spPageText.profile}`}
        </Button>
       
     
      <Box className="headerContainer">
        <h1 className="title">{props.title}</h1>
      </Box>
      <Box className="contentContainer">{props.render()}</Box> </>
    </main>
  );
};

export default SellerProfileHeaderContainer;
