// import ListItem from "@mui/material/ListItem";
import '../../components/AppLoader/AppLoader';
import '../../components/AppRoadMap/AppRoadMap.scss';
import './Api.scss';
import './ApiJSON.scss';
import './GettingStartedOverview/GettingStartedOverview.scss';
import { apiSublevels } from './navInfo';
import { Button, Skeleton } from '@mui/material';
import { LangContext } from '../App/App';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useAdobeAnalytics } from '../../providers/AdobeAnalytics';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { useSkipLink } from '../../providers/SkipLinkProvider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import appContentParser from '../../components/AppContentParser/AppContentParser';
import AppLoader from '../../components/AppLoader/AppLoader';
import AppRoadMap from '../../components/AppRoadMap/AppRoadMap';
import BasicTabs from '../../components/AppTabs/AppTabs';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FrenchDisclaimer from '../../components/FrenchDisclaimer/FrenchDisclaimer';
import GettingStartedOverview from './GettingStartedOverview/GettingStartedOverview';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MobileTabs from '../../components/AppTabs/AppTabsMobile';
import NDCapis from '../../components/NDCapis/NDCapis';
import ReleaseNotes from '../../components/ReleaseNotes/ReleaseNotes';
import PointOfSaleCoverage from '../../components/PointOfSaleCoverage/PointOfSaleCoverage';
import useHandleAnchorClick from '../../hooks/useHandleAnchorClick';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollPosition from '../../hooks/useScrollPosition';
import useWindowSize from '../../assets/customHooks/useWindowSize';

let width = window.innerWidth;
let drawerWidth = width > 500 ? 320 : width - 10; //This is super basic and should be updated...

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open'
})<{
  //MUI code for persistent drawer (I.e. the API Info Navigator)
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }),
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

export default function PersistentDrawerLeft({
  data,
  logoHeight,
  announcementBanner
}) {
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [content, setContent] = useState<ReactNode | null>();
  const [currLabel, setCurrLabel] = useState('');
  const [currLinks, setCurrLinks] = useState<any | null>(null);
  const [nonStanApiRef, setNonStanApiRef] = useState<any | null>([]);
  const [nonStanApiRefSubs, setNonStanApiRefSubs] = useState<any | null>([]);
  const [open, setOpen] = useState(
    !window.location.href.includes('/api/gettingstarted/gettingstarted')
  );
  const [openLinks, setOpenLinks] = useState(['']);
  const [openSections, setOpenSections] = useState(['']);
  const [sectionLinks, setSectionLinks] = useState({});
  const [showAppLoader, setShowAppLoader] = useState<boolean>(true);
  const [subNavs, setSubNavs] = useState(new Map());
  const [userClosedApiNav, setUserClosedApiNav] = useState(false);

  const bodyHeader = useRef(null);
  const theme = useTheme();
  const langContext = useContext(LangContext);
  const mainRef = useRef(null) as any;
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const navType = useNavigationType();
  const scrollPosition = useScrollPosition();
  const size = useWindowSize();
  const url = new URL(document.URL);

  const { adobeAnalyticsSectionClick } = useAdobeAnalytics();
  const { navigationChange, setNavigationChange, setSideNav, setSkipLinks } =
    useSkipLink();

  const handleAnchorClick = useHandleAnchorClick();
  const navigate = useNavigate();

  /* Make API Navigator reponsive */
  if (size.width && size.width < 700 && size.width > 600) {
    drawerWidth = size.width / 2;
  } else if (size.width && size.width < 600) {
    drawerWidth = size.width;
  } else {
    drawerWidth = 320;
  }

  const checkClass = (levels: string[]) => {
    //Check link should be 'active'
    if (currLinks) {
      /*TEMP bug fix for latestrelease link not highlighting in nav... FIX properly (i.e. remove latestrelease as an option) when deal w/ page scrolling*/
      if (currLinks.includes('latestrelease')) {
        const temp = [...levels, 'latestrelease'];
        if (JSON.stringify(temp) === JSON.stringify(currLinks)) {
          return 'apiActive';
        }
      }
      /*end temp bugfix*/
      if (JSON.stringify(levels) === JSON.stringify(currLinks)) {
        return 'apiActive';
      } else if (currLinks.length > levels.length) {
        return levels.every((l, i) => l === currLinks[i])
          ? 'apiChildActive'
          : 'apiInactive';
      }
      return 'apiInactive';
    }
  };

  const checkIfSpecialPg = () => {
    if (
      currLinks &&
      currLinks[0] &&
      currLinks[1] &&
      currLinks[0] === 'documentation' &&
      currLinks[1] === 'ndcapis'
    ) {
      //adds a class that hides header/change pg layout for NDC APIs pg
      return 'documentation introPg';
    } else if (
      currLinks &&
      currLinks[0] &&
      currLinks[1] &&
      currLinks[0] === 'gettingstarted' &&
      currLinks[1] === 'gettingstarted'
    ) {
      //adds a class that hides header/change pg layout for Getting Started pg
      return 'gettingstarted introPg';
    }
    // else if(currLinks &&  currLinks[0] === "releasenotes"){
    //   return 'releasenotesPg'
    // }
  };

  const checkForClosedActiveNav = (section: string, openType) => {
    const pos = openType === openSections ? 0 : 1;
    if (openType && currLinks) {
      if (!openType.includes(section) && currLinks[pos] === section) {
        return 'closedSectionWithActiveChild';
      }
    }
  };

  const checkShowFrDisclaimer = () =>
    currLinks &&
    langContext.selectedLanguage === 'fr' &&
    currLinks &&
    currLinks[0] === 'documentation' &&
    currLinks[1] !== 'ndcapis';

  /*Nav link helper functions*/
  const createNavLink = (path: string[], condClass = '') => {
    const link = path[1];

    return (
      <li
        key={`${path[path.length - 1]}-l-i`}
        onClick={() => handleClick(path)}
        onKeyDown={(event) => handleKeyPress(event, path)}
        className={condClass}
      >
        <ListItemButton
          key={`${link}-l-i-b`}
          {...(link === 'gettingstarted' && { ref: skipLinkRef })}
        >
          <ListItemText
            disableTypography
            key={`${link}-l-i-t`}
            primary={getLinkTitle(path)}
          />
        </ListItemButton>
      </li>
    );
  };

  const getApiDocNav = () => {
    if (data) {
      const apiNavData = getApiNavData();
      return apiNavData?.find((item) => item.link.route === 'documentation');
    }
  };

  const getApiNavData = () =>
    data &&
    data['navigation'].filter(
      (x) =>
        x.link.menu_name === 'api_info' &&
        x.link.enabled &&
        x.link?.show_on_left_nav !== false
    );

  const getAPIReflabels = (labelID: string) =>
    data && getLinkTitle(['documentation', 'airshopping', labelID]);
  //Note: 2nd position above (airshopping) can be any of the API Doc subsections, just trying to get to defaults...

  /*Get body content from JSON */
  const getBodyContent = (links: string[]) => {
    if (data && links.length) {
      if (links[0] === 'releasenotes') {
        setContent(
          <ReleaseNotes
            data={data['release_notes_array']}
            lang={langContext.selectedLanguage}
          />
        ); // release_notes_entries release-notes
      } else if (links[0] === 'documentation' && links[1] === 'ndcapis') {
        //API Documentation > Overview is not a basic JSON page, get component
        setContent(<NDCapis handleClick={handleClick} data={data} />);
      } else if (
        links[0] === 'gettingstarted' &&
        links[1] === 'gettingstarted'
      ) {
        //Getting Started > Getting Started is not a basic JSON page, get component

        setContent(
          <GettingStartedOverview
            handleClick={handleClick}
            data={data}
            navOpen={open}
          />
        );
      } else if (links[0] === 'roadmap') {
        setContent(<AppRoadMap data={data['roadmap_releases']} />);
      } else if (links[0] === 'pointOfSaleCoverage') {
        setContent(<PointOfSaleCoverage data={data['point_of_sale']} />);
      } else {
        appContentParser({ links, data, setContent, handleAnchorClick });
      }
    } else {
      if (!links.length) {
        console.log('No links loaded - Redirect to Getting Started > Intro');
        handleClick(['gettingstarted', 'gettingstarted']);
      } else {
        console.log('No data loaded');
      }
    }
  };

  const getHeaderFontSize = (content: string) => {
    //check if header can fit on one line, if not, make it responsive (can't just wrap because of potential fixed tab menu underneath...)
    const availableHeaderSpace = open
      ? window.innerWidth - 400
      : window.innerWidth - 50;
    const headerLength = content.length;

    let getFS = '';
    if (availableHeaderSpace / headerLength < 12) {
      //Long title, make responsive text
      getFS = 'calc(100% + 1vw)';
    } else {
      getFS = mobile ? '24px' : '28px';
    }
    return getFS;
  };

  const getLinkTitle = (path: string[]) => {
    if (data) {
      const apiNavData = getApiNavData();

      let usePath = [''];

      if (path.length > 1 && path[0] === 'documentation') {
        if (path.length === 2) {
          usePath = path;
        } else {
          //Check for default API Documentation sublevel nav items or custom
          const ApiDocNav = getApiDocNav();
          const checkIfDefaults = ApiDocNav['link']['subtree'].find(
            (x) => x.link.route === path[1]
          );

          if (
            checkIfDefaults.link.has_children &&
            checkIfDefaults.link.default_children
          ) {
            //Is a default nav item
            return ApiDocNav['link']['default_sub_children'][path[2]];
          } else if (checkIfDefaults.link.has_children) {
            //Is a custom item
            const customApiDocSubTitle = checkIfDefaults.link.subtree.find(
              (x) => x.link.route === path[2]
            );
            if (customApiDocSubTitle?.link) {
              return customApiDocSubTitle?.link.title;
            } else {
              return 'Error finding link';
            }
          }
        }
      } else {
        usePath = path;
      }

      let navSteps = '';
      let title = 'Error finding link';
      usePath.forEach((step, i) => {
        const latestLevel = navSteps === '' ? apiNavData : navSteps;
        const updateSteps = latestLevel.find(
          (item) => item.link.route === step
        );
        if (updateSteps) {
          if (i < usePath.length - 1) {
            if (updateSteps['link']['subtree']) {
              navSteps = updateSteps['link']['subtree'];
            }
          } else {
            title = updateSteps['link']['title'];
          }
        }
      });

      return title;
    }
  };

  const getNonStanApiDoc = () => {
    //Most API Documentation links have the same subs (Functionality, Request, etc),
    //but find those that don't and get their sublinks, if applicable
    if (data) {
      const apiDocNav = getApiDocNav();
      const arrNonStanApiRef: string[] = [];
      const temp = { documentation: {} };

      apiDocNav.link.subtree.forEach((x) => {
        if (!x.link.default_children) {
          arrNonStanApiRef.push(x.link.route);
        }
        if (!x.link.default_children && x.link.has_children) {
          const arrNonStanApiRefSubs: string[] = [];
          x.link.subtree.forEach((sub) => {
            arrNonStanApiRefSubs[sub.link.weight - 1] = sub.link.route;
          });
          temp['documentation'][x.link.route] = arrNonStanApiRefSubs;
        }
      });
      setNonStanApiRef(arrNonStanApiRef);
      setNonStanApiRefSubs(temp);
    }
  };

  const getSectionLinks = () => {
    const apiNavData = getApiNavData();
    let sectionLinks = {};

    apiNavData.forEach((item) => {
      if (item.link.has_children) {
        item.link.subtree.forEach((x) => {
          if (x.link.enabled) {
            sectionLinks[item.link.route]
              ? sectionLinks[item.link.route].splice(
                  x.link.weight - 1,
                  0,
                  x.link.route
                )
              : (sectionLinks[item.link.route] = [x.link.route]);
          }
        });
      } else {
        sectionLinks[item.link.route] = [];
      }
    });

    setSectionLinks(sectionLinks);
  };

  const getShowHideBttnTxt = (vs: string) => {
    if (data) {
      const ApiDocNav = getApiDocNav();
      return ApiDocNav['link']['show_hide_button'][vs];
    }
  };

  /*Handle clicks */
  const handleClick = (links: string[], keyboard = false) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (data) {
      if (links.length === 1) {
        trackOpenMenuSections(links, 0);
      } else if (links.length === 2) {
        trackOpenMenuLinks(links, 1);
      }

      setCurrLinks(links);

      getBodyContent(links);

      adobeAnalyticsSectionClick('side nav');

      //update route
      let fullLink = `/${langContext.selectedLanguage}/api`;
      links.map((link) => (fullLink = `${fullLink}/${link}`));
      const url = new URL(document.URL);

      url.pathname !== fullLink && navigate(`${fullLink}`);
      openMenus(links);

      if (mobile) {
        //check for last level nav click, if so, close mobile menu automatically
        const depth = links.length;
        const checkDepth3 = depth === 3;
        const checkDepth2 = depth === 1 && sectionLinks[links[0]]?.length === 0;
        const checkDepth1 =
          depth === 2 &&
          ((links[0] !== 'documentation' && !nonStanApiRefSubs[links[1]]) ||
            links[0] === 'documentation');

        if (checkDepth1 || checkDepth2 || checkDepth3) {
          handleDrawerClose();
        }
      }
      if (keyboard) {
        mainRef?.current?.focus();
      }
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setUserClosedApiNav(false);
    setSideNav({ open: () => handleDrawerOpen(), isOpen: true });
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setUserClosedApiNav(true);
    setSideNav({ open: () => handleDrawerOpen(), isOpen: false });
  };

  const handleDropDownOpen = (links: string[]) => {
    if (links.length === 1) {
      trackOpenMenuSections([links[0]], 0);
    } else {
      trackOpenMenuLinks(links, 1);
    }
  };

  const handleDropDownOpenPress = (event, links: string[]) => {
    if (event.which === 13) {
      handleDropDownOpen(links);
    }
  };

  const handleKeyPress = (event, links: string[]) => {
    if (event.which === 13) {
      handleClick(links, true);
    }
  };

  const handleKeyPressDrawer = (event) => {
    if (event.which === 13) {
      setOpen(!open);
    }
  };

  /*Helper functions for getting all Nav info from JSON */
  const openMenus = (path: string[]) => {
    if (path.length === 1) {
      //is a top section route
      setOpenSections([...openSections, path[0]]);
    } else if (path.length < 4) {
      //is a link or sublink route NOTE: if more route levels added, need to add a case increase for each...
      setOpenSections([...openSections, path[0]]);
      setOpenLinks([...openLinks, path[1]]);
    }
  };

  /*Track open menu sections*/
  const trackOpenMenuLinks = (links: string[], pos: number) => {
    if (openLinks.includes(links[pos])) {
      setOpenLinks(openLinks.filter((e) => e !== links[pos]));
    } else {
      setOpenLinks([...openLinks, links[pos]]);
    }
  };

  const trackOpenMenuSections = (links: string[], pos: number) => {
    if (openSections.includes(links[pos])) {
      setOpenSections(openSections.filter((e) => e !== links[pos]));
    } else {
      setOpenSections([...openSections, links[pos]]);
    }
  };

  const skipLinkRef = useCallback((node) => {
    if (node !== null) {
      setSkipLinks((prev) => {
        let index = prev.findIndex((item) => item.id === 1);
        return [
          ...prev.slice(0, index),
          { ...prev[index], ref: node },
          ...prev.slice(index + 1)
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSideNav({ open: () => handleDrawerOpen(), isOpen: open });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let links = url.pathname.split('/');
    links = links.filter(
      (link) =>
        link !== 'api' && link !== langContext.selectedLanguage && link !== ''
    );
    setCurrLinks(links);
    getBodyContent(links);
    openMenus(links);

    if (
      mobile ||
      window.location.href.includes('/api/gettingstarted/gettingstarted')
    ) {
      //if on mobile OR on Getting Started page close api nav by default
      setOpen(false);
    } else if (!userClosedApiNav) {
      //catch if navigator only closed b/c visited Getting Started pg...
      setOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url.pathname]);

  useEffect(() => {
    //On initial load, get data, loop through JSON for content and nav creation...
    if (data || navType === 'POP') {
      const sublinks = new Map();

      for (const [key, value] of Object.entries(nonStanApiRefSubs)) {
        if (key && value) {
          for (const [key2, value2] of Object.entries(value)) {
            sublinks.set(`${key}.${key2}`, value2);
          }
        }
      }

      setSubNavs(sublinks);
      getSectionLinks();
      getNonStanApiDoc();

      // if (
      //   !userClosedApiNav &&
      //   !window.location.href.includes("/api/gettingstarted/gettingstarted")
      // ) {
      //   setOpen(true);
      // }
      setShowAppLoader(false);
    } else {
      setShowAppLoader(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, navType]);

  /* Set page title */
  useEffect(() => {
    if (currLinks?.length) {
      let header = '';
      if (
        currLinks.length === 3 &&
        currLinks[0] === 'documentation' &&
        apiSublevels.includes(currLinks[2])
      ) {
        //If is API ref sub so only go to 2nd path level (I.e. don't display Functionality, Response, etc)
        header = getLinkTitle([currLinks[0], currLinks[1]]);
      } else {
        header = getLinkTitle(currLinks);
      }

      if (!header.includes('undefined')) {
        setCurrLabel(header);
      }
    }

    document.querySelectorAll('[role="region"]').forEach(function (el) {
      const label =
        el.parentElement?.parentElement?.parentElement?.previousElementSibling
          ?.firstChild?.textContent || '';
      if (!el.ariaLabel) {
        el.ariaLabel = `${label} section`;
      }
    });
    if (navigationChange) {
      setNavigationChange(false);
      const page =
        currLinks.length === 3
          ? `${currLinks[1]} ${currLinks[2]}`
          : currLinks[currLinks.length - 1];
      setAlertMessage(`Page changed to ${page}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currLinks]);

  /*Create API Navigator structure/html*/
  const apiNavData = getApiNavData();
  const isAPIdocPg = currLinks && currLinks[0] === 'documentation';

  const drawer = (
    // Do Section labels/links
    <nav
      id="apiLeftDrawer"
      aria-label={
        langContext.selectedLanguage === 'fr'
          ? 'navigation latérale'
          : 'side bar'
      }
    >
      {apiNavData &&
        apiNavData.map((item) => {
          const section = item.link.route;

          if (
            (isAPIdocPg && section !== 'documentation') ||
            (!isAPIdocPg && section === 'documentation')
          )
            return false; //Now two seperate pages (general/documentation) so hide accordingly...

          if (!sectionLinks[section] || sectionLinks[section].length === 0) {
            //Is a section with no links, so make it the link

            const linkLabel = getLinkTitle([section]);
            const checkActive = checkClass([section]);
            return (
              <div
                role="button"
                className={`${checkActive} apiAccordionStyleShallow`}
                onClick={() => handleClick([section])}
                onKeyDown={(event) => handleKeyPress(event, [section])}
                key={`${section}-sectionbttn`}
                tabIndex={0}
              >
                {linkLabel}
              </div>
            );
          } else {
            //Is a section with at least one level of links, make menu an accordion/dropdown
            return (
              <Accordion
                className="accordionStyle divide"
                disableGutters={true}
                key={`${section}-t-a`}
                expanded={openSections?.includes(section)}
                sx={{
                  '.MuiAccordionSummary-root': {
                    padding: '8px 18px !important',
                    '&:hover': {
                      backgroundColor: 'grey.100'
                    }
                  }
                }}
              >
                <AccordionSummary
                  key={`${section}-t-a-s`}
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => handleDropDownOpen([section])}
                  onKeyDown={(event) =>
                    handleDropDownOpenPress(event, [section])
                  }
                  className={`accordionSummaryStyle ${checkClass([
                    section
                  ])} ${checkForClosedActiveNav(section, openSections)}`}
                  sx={{ fontWeight: 600 }}
                >
                  {getLinkTitle([section])}
                </AccordionSummary>
                <AccordionDetails
                  key={`${section}-a-d`}
                  className="accordionDetailsStyle"
                >
                  <List key={`${section}-l`}>
                    {section === 'releasenotes' && (
                      <li className="rlsNotesQuarter">Q1/2023</li>
                    )}
                    {sectionLinks[section].map((link: string) => {
                      try {
                        const addNavCondClass = `${checkClass([
                          section,
                          link
                        ])} apiLink`;
                        let currSublinks = [''];
                        let condClass = '';

                        if (
                          (section === 'documentation' &&
                            !nonStanApiRef.includes(link)) ||
                          subNavs.get(`${section}.${link}`)
                        ) {
                          //Link has a subnav, so create dropdown (accordion)
                          //NOTE: In 'API Documentation' section most links have same subnavs, others are from custom list

                          currSublinks =
                            section === 'documentation' &&
                            !nonStanApiRef.includes(link)
                              ? apiSublevels
                              : Array.from(subNavs.get(`${section}.${link}`));

                          condClass =
                            section === 'documentation'
                              ? 'apiLink'
                              : 'apiSubnav';

                          return (
                            <li key={`${link}-li`}>
                              <Accordion
                                className="accordionStyle"
                                disableGutters={true}
                                key={`${link}-a`}
                                expanded={openLinks?.includes(link)}
                                sx={{
                                  '.MuiAccordionSummary-root': {
                                    padding: '8px 18px !important',
                                    '&:hover': {
                                      backgroundColor: 'grey.100'
                                    }
                                  }
                                }}
                              >
                                <AccordionSummary
                                  key={`${link}-a-s`}
                                  expandIcon={
                                    <ExpandMoreIcon
                                      key={`${link}-exp`}
                                      onClick={() =>
                                        handleDropDownOpen([section, link])
                                      }
                                      onKeyDown={(event) =>
                                        handleDropDownOpenPress(event, [
                                          section,
                                          link
                                        ])
                                      }
                                    />
                                  }
                                  className={`accordionSummaryStyle ${condClass} ${checkForClosedActiveNav(
                                    link,
                                    openLinks
                                  )}`}
                                  onClick={() => {
                                    handleDropDownOpen([section, link]);
                                  }}
                                  onKeyDown={(event) => {
                                    handleDropDownOpenPress(event, [
                                      section,
                                      link
                                    ]);
                                  }}
                                >
                                  {getLinkTitle([section, link])}
                                </AccordionSummary>
                                <List key={`${link}-k`}>
                                  {currSublinks.map((apiSub) => {
                                    const addSubNavCondClass = `${checkClass([
                                      section,
                                      link,
                                      apiSub
                                    ])} ${condClass} accordionDetailsStyle`;
                                    return createNavLink(
                                      [section, link, apiSub],
                                      addSubNavCondClass
                                    );
                                  })}
                                </List>
                              </Accordion>
                            </li>
                          );
                        } else {
                          //Link has no subnav
                          return createNavLink(
                            [section, link],
                            addNavCondClass
                          );
                        }
                      } catch {
                        console.log(
                          `Error creating left api nav on: ${section} / ${link}`
                        );
                        return false;
                      }
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            );
          }
        })}
    </nav>
  );

  return (
    <div style={{ marginTop: `${logoHeight}px` }}>
      <div aria-live="polite" aria-atomic="true" className="sr-only">
        {alertMessage}
      </div>
      <Box
        sx={{ display: 'flex' }}
        id="apiPage"
        className={`${open ? 'apiNavigatorOpen' : 'apiNavigatorClosed'} ${
          mobile ? 'apiMobile' : ''
        } ${checkIfSpecialPg()}`}
      >
        <CssBaseline />
        <Box id="apiLeftNavWrap">
          <Box
            id="apiNavigatorBttnWrap"
            tabIndex={0}
            sx={{
              // ...(mobile ? { top: '70px' } : { top: `130px` }),
              ...(mobile
                ? { top: `${logoHeight - (announcementBanner ? 35 : 10)}px` }
                : { top: `${logoHeight - (announcementBanner ? 25 : 5)}px` }),
              width: `calc(${drawerWidth}px - 42px)`,
              ...(open && { backgroundColor: 'white' })
            }}
          >
            <Button
              onClick={handleDrawerOpen}
              onKeyDown={handleKeyPressDrawer}
              size="medium"
              sx={{ ...(open && { display: 'none' }), backgroundColor: 'none' }}
              className="apiNavigatorBttns"
            >
              <KeyboardDoubleArrowRightIcon fontSize="medium" />
              {getShowHideBttnTxt('show')}
            </Button>
            <Button
              onClick={handleDrawerClose}
              onKeyDown={handleKeyPressDrawer}
              sx={{ ...(!open && { display: 'none' }) }}
              className="apiNavigatorBttns"
            >
              <KeyboardDoubleArrowLeftIcon fontSize="medium" />
              {getShowHideBttnTxt('hide')}
            </Button>
          </Box>

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box'
              },
              zIndex: 4,
              position: 'relative'
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            {data !== '' ? (
              drawer
            ) : (
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            )}
          </Drawer>
        </Box>

        {/*Display API Doc (standard) Service page (I.e. w/ tabs) - Desktop tabs*/}
        <Main
          open={open}
          className="apiMainContent"
          ref={mainRef}
          aria-label={`${currLabel} ${
            currLinks?.length === 3 ? currLinks[2] : ''
          } ${langContext.selectedLanguage === 'fr' ? 'contenu' : 'content'}`}
          tabIndex={0}
        >
          {currLinks &&
          currLinks[0] === 'documentation' &&
          currLinks.length > 2 &&
          !nonStanApiRefSubs?.documentation.hasOwnProperty(currLinks[2]) ? (
            !mobile ? (
              <>
                {checkShowFrDisclaimer() && (
                  <FrenchDisclaimer pageType="nonTab" open={open} />
                )}
                <Box className="apiMainContentContain apiTabsShowing">
                  {checkShowFrDisclaimer() && (
                    <div
                      style={{
                        height: `${Math.max(0, 77 - scrollPosition)}px`
                      }}
                    ></div>
                  )}
                  <div className="apiBodyHeaderWrap">
                    <div
                      className="apiBodyHeaderBG"
                      style={
                        {
                          '--top': `-${Math.min(128, 20 + scrollPosition)}px`,
                          '--height': `${Math.min(128, 55 + scrollPosition)}px`
                        } as any
                      }
                    ></div>
                    <Box
                      className="apiBodyHeader"
                      sx={{ fontSize: getHeaderFontSize(currLabel) }}
                      ref={bodyHeader}
                      component="h1"
                    >
                      {currLabel}
                    </Box>
                  </div>
                  {showAppLoader && <AppLoader />}
                  <BasicTabs
                    content={content}
                    handleClick={handleClick}
                    currLinks={currLinks}
                    tabs={apiSublevels}
                    getLabel={(labelID: string) => getAPIReflabels(labelID)}
                  />
                </Box>
              </>
            ) : (
              <>
                {checkShowFrDisclaimer() && (
                  <div>
                    <FrenchDisclaimer pageType="nonTab" open={open} />
                  </div>
                )}
                <Box className="apiMainContentContain apiTabsShowing">
                  {/*Display API Doc (standard) Service page (I.e. w/ tabs) - Mobile tabs*/}
                  {checkShowFrDisclaimer() && (
                    <div
                      style={{
                        height: `${Math.max(0, 80 - scrollPosition)}px`
                      }}
                    ></div>
                  )}
                  <div className="apiBodyHeaderWrap">
                    {checkShowFrDisclaimer() ? (
                      <div
                        className="apiBodyHeaderBG"
                        style={
                          {
                            '--top': `-${Math.min(128, 20 + scrollPosition)}px`,
                            '--height': `${Math.min(
                              128,
                              55 + scrollPosition
                            )}px`
                          } as any
                        }
                      ></div>
                    ) : (
                      <div className="apiBodyHeaderBG"></div>
                    )}
                    <Box
                      className={`apiBodyHeader ${
                        checkShowFrDisclaimer() && 'frDisclaimer'
                      }`}
                      sx={{ fontSize: getHeaderFontSize(currLabel) }}
                      ref={bodyHeader}
                      component="h1"
                    >
                      {currLabel}
                    </Box>
                  </div>
                  {showAppLoader && <AppLoader />}
                  <MobileTabs
                    content={content}
                    handleClick={handleClick}
                    currLinks={currLinks}
                    tabs={apiSublevels}
                    getLabel={(labelID: string) => getAPIReflabels(labelID)}
                  />
                </Box>
              </>
            )
          ) : (
            <>
              {checkShowFrDisclaimer() && (
                <div>
                  <FrenchDisclaimer
                    pageType={
                      currLinks && currLinks[1] !== 'ndcapis' ? '' : 'ndcAPIs'
                    }
                    open={open}
                  />
                </div>
              )}
              <Box className="apiMainContentContain">
                {/*Display other API Info  page (I.e. no tabs)*/}
                {checkShowFrDisclaimer() && (
                  <div
                    style={{ height: `${Math.max(0, 100 - scrollPosition)}px` }}
                  ></div>
                )}
                <div className="apiBodyHeaderWrap">
                  {checkShowFrDisclaimer() ? (
                    <div
                      className="apiBodyHeaderBG"
                      style={
                        {
                          '--top': `-${Math.min(128, 40 + scrollPosition)}px`,
                          '--height': `${Math.min(128, 75 + scrollPosition)}px`
                        } as any
                      }
                    ></div>
                  ) : (
                    <div className="apiBodyHeaderBG"></div>
                  )}
                  <Box
                    className={`apiBodyHeader ${
                      checkShowFrDisclaimer() && 'frDisclaimer'
                    }`}
                    ref={bodyHeader}
                    component="h1"
                    sx={{ fontSize: getHeaderFontSize(currLabel) }}
                  >
                    {currLabel}
                  </Box>
                </div>
                {showAppLoader && <AppLoader />}
                {content}
              </Box>
            </>
          )}
        </Main>
      </Box>
    </div>
  );
}
