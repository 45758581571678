import { sllrProfAxios } from './axiosDefault';
import { sortUsers, showAPIerror } from './Helpers';

const getStatus = async ({ ...context }) => {
  return await sllrProfAxios
    .get(`/onboarding-status/${context.seller_id}`)
    .then((res) => {
      const status = res?.data?.status;

      let countCompleted = 0;
      Object.values(status)?.forEach((v: any) => v && countCompleted++);
      status['completed'] = countCompleted;
      context.setOnboardingStatus(status);
      return true;
    })
    .catch((res) => showAPIerror(res, context, 'getStatus'));
};

const getUserListDetailTypes = async () =>
  await sllrProfAxios.get(`/user-list-details`);

const convertAdditionalAccess = (user, getUserDetailTypes) => {
  const addAccessArr: any[] = [];
  getUserDetailTypes['additional_access'].forEach((access: any) => {
    //we need the additional access info in an array, so convert here
    user['additional_access']?.includes(access.additional_access_id) &&
      addAccessArr.push(access.additional_access_id);
  });
  user['additional_access_id'] = addAccessArr;
  return user;
};

const SpAPIgetAllUsers = async ({ ...context }) => {
  await getStatus(context);
  return await sllrProfAxios
    .get(`/user-list-contacts/${context.seller_id}?offset=0`)
    .then((res) => {
      const finalData = res.data['users'];

      return getUserListDetailTypes().then((res) => {
        const getUserDetailTypes: any = res.data;
        finalData.forEach((user) => {
          convertAdditionalAccess(user, getUserDetailTypes);
        });

        const sortedUsers = sortUsers(finalData);
        context.setUsers(sortedUsers);
        return sortedUsers;
      });
    })
    .catch((res) => showAPIerror(res, context, 'SpAPIgetAllUsers'));
};

const SpAPIgetIndUser = async ({ ...context }, curr_user_id = null) => {
  const id = curr_user_id || context.user_id;
  return await sllrProfAxios
    .get(`/user-list-contact/${id}`)
    .then((res) => {
      const finalData = res.data['user'];
      if (finalData) {
        return getUserListDetailTypes().then((res) => {
          const getUserDetailTypes: any = res.data;

          const cleanedFinalData = finalData.map((user) => {
            return convertAdditionalAccess(user, getUserDetailTypes);
          });

          const updatedUsers = context.users.map((user) =>
            user.user_id === id ? { ...user, ...cleanedFinalData[0] } : user
          );
          context.setUsers(updatedUsers);

          return updatedUsers;
        });
      }
    })
    .catch((res) => showAPIerror(res, context, 'SpAPIgetIndUser'));
};

const getFormattedUpdates = (updates) => {
  const formattedUpdates = {};
  Object.keys(updates).forEach((key) => {
    updates[key]['content'] &&
      (formattedUpdates[key] = updates[key]['content']);
  });
  return formattedUpdates;
};

const SpApiAddUser = async ({ ...context }) => {
  const updates = getFormattedUpdates(context.formUpdates['addUser']);

  return await sllrProfAxios
    .post(`/user-list-contact/${context.seller_id}`, updates)
    .then((res) => {
      return SpAPIgetAllUsers(context).then((refreshUserList) => {
        context.setUsers(refreshUserList);
        return res;
      });
    })
    .catch((res) => showAPIerror(res, context, 'SpApiAddUser'));
};

const SpApiUpdateUser = async (curr_user_id, { ...context }) => {
  const updates = getFormattedUpdates(context.formUpdates['editUserProfile']);

  return await sllrProfAxios
    .put(`/user-list-contact/${curr_user_id}`, updates)
    .then((res) =>
      SpAPIgetIndUser(context, curr_user_id).then(() => {
        return res;
      })
    )
    .catch((res) => showAPIerror(res, context, 'SpApiUpdateUser'));
};

const SpApiDeleteUser = async (curr_user_id, { ...context }) => {
  return await sllrProfAxios
    .delete(`/user-list-contact/${curr_user_id}`)
    .then((res) =>
      SpAPIgetAllUsers(context).then(() => {
        return res;
      })
    )
    .catch((res) => showAPIerror(res, context, 'SpApiDeleteUser'));
};

const SpApiEmailCheck = async (email, { ...context }) => {
  return sllrProfAxios.get(
    `/email-check/${context.seller_id}?flag=EC&user_email=${email}`
  );
};

const SpApiEmailListBasedOnEmail = async (email, { ...context }, flag) => {
  return sllrProfAxios.get(
    `/email-check/${context.seller_id}?flag=ED&user_email=${email}${flag==='users'? '&EDFlag=users' :''}`
  );
};

export {
  SpAPIgetAllUsers,
  SpApiUpdateUser,
  SpApiAddUser,
  SpAPIgetIndUser,
  SpApiDeleteUser,
  getStatus,
  SpApiEmailCheck,
  SpApiEmailListBasedOnEmail
};
