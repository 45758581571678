export const ISLANDS_AND_TERRITORIES = [
  "ASM", "AIA", "ATA", "BMU", "BVT", "IOT", "VGB", "BES", "CYM", "CXR", "CCK", "COK", 
  "FLK", "FRO", "PYF", "ATF", "GIB", "GLP", "GUF", "GUM", "GGY", "HMD", "IMN", "JEY", "MAC", "MTQ", 
  "MYT", "FSM", "MSR", "NIU", "NFK", "MNP", "PCN", "COG", "REU", "BLM", "SHN", "MAF", "SPM", "SXM", 
  "SGS", "SJM", "TLS", "TKL", "TCA", "UMI", "VIR", "VAT", "WLF", "ALA", "TUV", "ESH"
];

export const SMALL_COUNTRY_CODES = [
  "ABW", "AND", "ATG", "BHR", "BRB", "BLZ", "BWA", "BRN", "CPV", "COM", "DMA", "SWZ", "FJI", "GMB", "GUY", 
  "HKG", "ISL", "KIR", "LIE", "LSO", "MDV", "MLT", "MHL", "MCO", "MNE", "NRU", "PLW", "WSM", "SMR", 
  "STP", "SYC", "SLB", "SUR", "TON", "TUV", "VUT"
];