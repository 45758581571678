import { showAPIerror } from "./Helpers";
import { getStatus } from "./SellerProfileAPI";
import { sllrProfAxios } from "./axiosDefault";

const doOptions = (apiData, newEntry, parent) => {
  apiData.split("//").forEach((subQ, i) => {
    const subFields = subQ.split("|");
    const subQcontent: any = {};

    subFields.forEach((field) => {
      const finalContent = field.split(":");
      subQcontent[finalContent[0]] = finalContent[1];
    });

    newEntry["options"][subQcontent["survey_option_id"]] = {
      label: subQcontent["survey_options_description"],
      type: "checkbox",
      checked: false,
      parent: parent,
      optional: true,
      id: subQcontent["survey_option_id"],
    };
  });
};
const doSubQuestions = (apiData, parentKey, questionnaireForm) => {
  questionnaireForm[`${parentKey}-SUB`] = { type: "group", options: {} };
  const currEntry = questionnaireForm[`${parentKey}-SUB`]["options"];

  apiData.split("//").forEach((subQ, i) => {
    const subFields = subQ.split("|");
    const subQcontent: any = {};
    subFields.forEach((field) => {
      const finalContent = field.split(":");
      subQcontent[finalContent[0]] = finalContent[1];
    });
    currEntry[subQcontent["survey_sub_question_id"]] = {
      label: subQcontent["survey_sub_question_desc"],
      type: "text",
      required: "false",
      validate: "alphanumeric",
      example:
        subQcontent["additional_info"] !== "null"
          ? subQcontent["additional_info"]
          : "",
    };
  });
};
const SpAPIsubmitQuestionnaire = async ({ ...context }) => {
  const formattedDataParent = { survey_data: {} };
  const formattedData = formattedDataParent.survey_data;
  const answers = {};
  const sub_question = context.isFrench ? "sub_question_fr" : "sub_question_en";

  const formatUpdatesForSubmission = (field, updates) => {
    Object.keys(updates).forEach((key) => {
      if (key === `${field}-SUB`) {
        //get answers for subquestions and deal w/ unique formatting for submission
        const subQs = updates[key]["options"];
        const subQsKeys = Object.keys(subQs);
        subQsKeys.forEach((subkey) => {
          answers[subkey] = updates[key]["options"][subkey].content;
        });
      } else if (key === field) {
        if (updates[key].type === "radio") {
          //get radio/boolean answer
          Object.keys(updates[key].options).forEach((option) => {
            if (updates[key]["options"][option]["checked"] === true) {
              answers[field] = updates[key]["options"][option]["content"];
            }
          });
        } else if (updates[key].options) {
          //get selected options for checkbox answers
          const contentArray: string[] = [];
          Object.keys(updates[key].options).forEach((option) => {
            if (updates[key]["options"][option]["checked"] === true) {
              //add ID of the selected option as answer
              contentArray.push(
                updates[key]["options"][option]["id"].toString()
              );
            }
          });
          answers[field] = contentArray;
        } else {
          //get standard (text) answers and format
          answers[field] = updates[key].content || updates[key].checked;
        }
      } else if (typeof updates[key] === "object" && updates[key] !== null) {
        //wrong key, try next...
        formatUpdatesForSubmission(field, updates[key]);
      }
    });
  };
  Object.keys(context.surveyDetails).forEach((key) => {
    if (context.surveyDetails[key]["survey_question_id"].includes("QUES")) {
      formatUpdatesForSubmission(
        context.surveyDetails[key]["survey_question_id"],
        context.formUpdates["questionnaire"]
      );

      //parse the api data about the sub questions...
      if (context.surveyDetails[key][sub_question] !== null) {
        const subQs = context.surveyDetails[key][sub_question].split("//");

        !formattedData.hasOwnProperty(
          context.surveyDetails[key]["survey_question_id"]
        ) &&
          (formattedData[context.surveyDetails[key]["survey_question_id"]] = {
            sub_question: {},
          });
        const currSubQObj =
          formattedData[context.surveyDetails[key]["survey_question_id"]][
            "sub_question"
          ];

        subQs.forEach((subq) => {
          const subFields = subq.split("|");
          const subQcontent: any = {};
          let subQID = "";
          subFields.forEach((field) => {
            const finalContent = field.split(":");
            if (finalContent[0] === "question_type_id") {
              subQcontent[finalContent[0]] = finalContent[1];
            } else if (finalContent[0] === "optional") {
              subQcontent[finalContent[0]] =
                finalContent[1] === "1" ? true : false;
            } else if (finalContent[0] === "survey_sub_question_id") {
              subQID = finalContent[1];
            }
          });

          subQcontent["answer"] = answers[subQID] || "";
          currSubQObj[subQID] = subQcontent;
        });
      }

      //add standard answer and required question params, so can submit to api

      !formattedData.hasOwnProperty(
        context.surveyDetails[key]["survey_question_id"]
      ) &&
        (formattedData[context.surveyDetails[key]["survey_question_id"]] = {});

      const currQ =
        formattedData[context.surveyDetails[key]["survey_question_id"]];
      currQ["question_type_id"] =
        context.surveyDetails[key]["question_type_id"];

      if (context.surveyDetails[key]["question_type_id"] === "QT005") {
        //radio/boolean answer has different key + capitalize first letter...
        const answer =
          answers[context.surveyDetails[key]["survey_question_id"]];
        currQ["bool_answer"] = answer[0].toUpperCase() + answer.slice(1);
      } else if (context.surveyDetails[key]["question_type_id"] === "QT003") {
        //grouped checknbox, return ID array as 'options'
        currQ["options"] =
          answers[context.surveyDetails[key]["survey_question_id"]];
      } else {
        currQ["answer"] =
          answers[context.surveyDetails[key]["survey_question_id"]] || "";
      }
      currQ["optional"] =
        context.surveyDetails[key]["optional"] === 1 ? true : false;
    }
  });

  return await sllrProfAxios
    .post(`/survey/${context.user_id}`, formattedDataParent)
    .then((res) => {
      getStatus(context)
        .then((res) => res)
        .catch((res) => res);
        return res
    })
    .catch((res) => showAPIerror(res, context, "SpAPIsubmitQuestionnaire"));
};

const SpAPIgetQuestionnaire = async (questionnaireForm, { ...context }) => {
  return await sllrProfAxios
    .get(`/survey-details`)
    .then((res) => {
      context.setSurveyDetails(res.data?.questions);
      const sub_question = context.isFrench
        ? "sub_question_fr"
        : "sub_question_en";
      const survey_question_description = context.isFrench
        ? "survey_question_description_fr"
        : "survey_question_description_en";
      const additional_info = context.isFrench
        ? "additional_info_fr"
        : "additional_info_en";
      const options = context.isFrench ? "options_fr" : "options_en";
      const questions = res.data.questions;

      //parse API data to show in form
      const setValsByObjKey = (apiData, qForm) => {
        const field = apiData["survey_question_id"];

        if (apiData["survey_question_id"]) {
          Object.keys(qForm).forEach((key) => {
            if (
              typeof qForm[key] === "object" &&
              qForm[key] !== null &&
              key !== field
            ) {
              setValsByObjKey(apiData, qForm[key]);
            } else if (key === field) {
              qForm[key]["label"] = apiData[survey_question_description];
              qForm[key]["required"] =
                apiData["optional"] === 1 ? "false" : "true";
              qForm[key]["example"] = context.isFrench
                ? apiData[additional_info]
                : apiData[additional_info];

              if (apiData[options] && apiData[options] !== null) {
                doOptions(apiData[options], qForm[key], key);
                apiData["parentArray"] = true;
                apiData["type"] = "group";
              } else {
                apiData["type"] = "text";
              }
              apiData[sub_question] &&
                apiData[sub_question] !== null &&
                doSubQuestions(apiData[sub_question], key, questionnaireForm);
            }
          });
        }
      };

      questions.forEach((apiQ) => {
        apiQ["survey_question_id"].includes("QUE") &&
          setValsByObjKey(apiQ, questionnaireForm);
      });
      return questionnaireForm || "";
    })
    .catch((res) => showAPIerror(res, context, "SpAPIgetQuestionnaire"));
};

export { SpAPIgetQuestionnaire, SpAPIsubmitQuestionnaire };
