import { Box } from "@mui/material";
import { useSellerProfile } from "../SellerProfile";

import "./ApiCredentials.scss";
import SPUserCard from "../../../components/SellerProfile/UserCard/SPUserCard";
import { useEffect, useState } from "react";
import { SpAPIgetAllApiCreds } from "../../../pages/SellerProfile/APIs/ApiCredsAPI";
import { doFormErrorMsgs } from "../APIs/Helpers";

function ApiCredentials() {
  const { ...context } = useSellerProfile() as any;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    SpAPIgetAllApiCreds({ ...context })
      .then((resp) => {
        setLoaded(true);
        if(resp?.status !== 200){
        
          doFormErrorMsgs(
            resp,
            context.setErrorMsg,
            context.spPageText
          );
        }
         
        
      })
      .catch((error) => {
        doFormErrorMsgs(
          error,
          context.setErrorMsg,
          context.spPageText
        );
        console.log("err-api", error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded ? (
    <Box className="spUpdateApiCredentials">
      <p className="spInstructions">{context.spPageText.selectAdminAPIcreds}</p>
      <SPUserCard cardType="env"/>
    </Box>
  ) : (
    <></>
  );
}

export default ApiCredentials;
