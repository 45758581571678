import './SPOnboardingBox.scss';
import { Box } from '@mui/material';
import { LangContext } from '../../../pages/App/App';
import { useContext, useEffect, useState } from 'react';
import { useSellerProfile } from '../../../pages/SellerProfile/SellerProfile';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SPActivityCard from '../ActivityCard/SPActivityCard';

function SPOnboarding() {
  const { ...context } = useSellerProfile() as any;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const completedCount = context.onboardingStatus?.completed;
  const langContext = useContext(LangContext);
  const isEnglish = langContext.selectedLanguage === 'en';

  const activityCardData = [
    {
      id: 1,
      title: 'registerCard',
      name: context.spPageText.regUsers,
      description: isEnglish
        ? 'Add new users to your profile'
        : 'Ajoutez de nouveaux utilisateurs à votre profil',
      completed: context.onboardingStatus?.user,
      link: '/seller-profile/users'
    },
    {
      id: 2,
      title: 'registerCard',
      name: context.spPageText.submitQuestionnaire,
      description: isEnglish
        ? 'Provide preliminary technical information for your integration'
        : 'Fournissez des informations techniques préliminaires pour votre intégration',
      completed: context.onboardingStatus?.questionnaire,
      link: '/seller-profile/questionnaire'
    },
    {
      id: 3,
      title: 'registerCard',
      name: context.spPageText.setNotifications,
      description: isEnglish
        ? 'Add recipients to mailing distribution lists'
        : 'Ajoutez des destinataires aux listes de diffusion',
      completed: context.onboardingStatus?.notification,
      link: '/seller-profile/notifications'
    }
  ];

  useEffect(() => {
    setIsExpanded(context.onboardingStatus?.completed !== 3);
  }, [context.onboardingStatus]);

  return (
    <Box className="spOnboarding" onClick={() => setIsExpanded(!isExpanded)}>
      <Box className="onBoardingHeader">
        <h2>{context.spPageText.onSteps}</h2>
        {Array(activityCardData.length)
          .fill(1)
          .map((item, i) => {
            return (
              <span
                key={`${item}-${i}`}
                aria-hidden={true}
                className={`stepCircle ${
                  i < completedCount ? '' : 'notCompleted'
                }`}
              />
            );
          })}
        <span
          className="completedStepCount"
          aria-label={
            completedCount +
            ' out of ' +
            activityCardData.length +
            ' steps are completed'
          }
        >
          {completedCount}/{activityCardData.length}
        </span>
        {isExpanded ? (
          <ExpandLessIcon
            className="expandingIcon"
            onClick={() => setIsExpanded(false)}
          />
        ) : (
          <ExpandMoreIcon
            className="expandingIcon"
            onClick={() => setIsExpanded(true)}
          />
        )}
      </Box>
      {isExpanded && (
        <>
          <p className="onBoardingInfoText">
            {context.spPageText.completeInstructions}
          </p>
          <SPActivityCard
            activityCardData={activityCardData}
            className="statusCards"
          />
        </>
      )}
    </Box>
  );
}

export default SPOnboarding;
