import './App.scss';
import { AdobeAnalyticsProvider } from '../../providers/AdobeAnalytics';
import {
  EventType,
  InteractionRequiredAuthError,
  InteractionType
} from '@azure/msal-browser';
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal
} from '@azure/msal-react';
import { REQUIREDROLES } from './constants';
import { Route, Routes, useLocation } from 'react-router-dom';
import { SkipLinkProvider } from '../../providers/SkipLinkProvider';
import { sllrProfAxios } from '../../pages/SellerProfile/APIs/axiosDefault';
import Api from '../../pages/Api/Api';
import AppFooter from '../../components/AppFooter/AppFooter';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppLoader from '../../components/AppLoader/AppLoader';
import AppPwdLogin from '../../components/AppPwdLogin/AppPwdLogin';
import AppSkipLink from '../../components/AppSkipLink/AppSkipLink';
import axios from 'axios';
import Benefits from '../Discover/Benefits/Benefits';
import ConnectionOptions from '../Discover/ConnectionOptions/ConnectionOptions';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import FaqSection from '../../pages/Support/FaqSection/FaqSection';
import FourOfour from '../../pages/404/404';
import Home from '../../pages/Discover/Home';
import KnownIssues from '../../pages/Support/KnownIssues/KnownIssues';
import NdcCapabilities from '../Discover/NdcCapabilities/NdcCapabilities';
import React, { useEffect, useState, createContext } from 'react';
// import Registration from '../Registration/Registration';
import Roadmap from './RoadmapRedirect';
import SellerProfile from '../SellerProfile/SellerProfile';
import SellerRegistration from '../SellerRegistration/SellerRegistration';
import SellerRegStarting from '../../pages/SellerRegistration/SellerRegStarting';
import StatusPage from '../../pages/Support/StatusPage/StatusPage';
import SubsellerSignUp from '../SubSeller/SubsellerSignUp';
import Support from '../../pages/Support/Support';

export interface iLangContext {
  otherReadableLanguage: string;
  readableLanguage: string;
  selectedLanguage: string;
  setSelectedLanguage: any;
}

export const LangContext = createContext<iLangContext>({
  otherReadableLanguage: '',
  readableLanguage: '',
  selectedLanguage: '',
  setSelectedLanguage: (selectedLanguage: string) => {}
});

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [headerHeight, setHeaderHeight] = useState(0);
  const [logoHeight, setLogoHeight] = useState(0);
  const [announcementBanner, setAnnouncementBanner] = useState(false);

  useEffect(() => {}, [headerHeight]);

  //AZURE

  const { instance } = useMsal();

  window.instance = instance;

  const isAuthenticated = useIsAuthenticated();

  const userRolesRef = React.useRef([]);
  const onSellerProfile = location.pathname.includes('/seller-profile');
  const [isAuth, setIsAuth] = useState(!onSellerProfile);
  const [coName, setCoName] = useState(localStorage.getItem('coName') || '');

  const doSetUserName = (name) => {
    if (userName === '' && name !== '' && name !== null) {
      const firstName = name?.split(' ')[0];
      const lastInitial = name?.charAt(name.lastIndexOf(' ') + 1);
      setUserName(`${firstName} ${lastInitial}.`);
    }
  };
  React.useEffect(() => {
    if (!isAuthenticated) {
      instance
        /* @ts-ignore */
        .ssoSilent() //@ts-ignore
        .then((res) => {
          instance.setActiveAccount(res.account);
        })
        .catch((err) => {
          if (err instanceof InteractionRequiredAuthError) {
            instance.loginRedirect();
          }
        });
    }

    const id: any = instance.addEventCallback((event) => {
      instance.getActiveAccount()?.name &&
        doSetUserName(instance.getActiveAccount()?.name);

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        instance.setActiveAccount(event.payload.account);
        userRolesRef.current = event.payload.idTokenClaims.roles;

        if (
          !event.payload.idTokenClaims?.roles?.some((role) =>
            REQUIREDROLES.includes(role)
          )
        ) {
          // if (window.location.pathname !== "/page-not-found") {
          //   window.location.href = "/page-not-found";
          // }
        }

        sllrProfAxios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${event.payload.idToken}`;

        sllrProfAxios.defaults.headers.common['x-api-key'] =
          process.env.REACT_APP_SELLER_PROFILE_API_KEY;
        sllrProfAxios.defaults.headers.common['Content-Type'] =
          'application/json';

        setIsAuth(true);
        doSetUserName(event.payload?.account?.name);
      }
    });

    return () => instance.removeEventCallback(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutAzure = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href =
      'https://login.microsoftonline.com/common/oauth2/logout'; //bypases bug of instance.logout forcing you to choose which account to logout
    // instance.logout();
  };
  //END AZURE

  let defaultLang = 'en';
  if (window.location.href.includes('/fr/')) {
    localStorage.setItem('selectedLanguage', 'fr');
    defaultLang = 'fr';
  }
  const path = window.location.pathname;
  if (
    (path.includes('/roadmap') ||
      path.includes('/feuillederoute') ||
      path.includes('/supportedmarkets')) &&
    !path.includes('/api/')
  ) {
    //vanity url for ndc.aircanada.ca/roadmap or ndc.aircanada.ca/feuillederoute
    if (path.includes('/fr/') || path.includes('/feuillederoute')) {
      window.location.href = `/fr/api/roadmap`;
    } else {
      window.location.href = `/en/api/roadmap`;
    }
  } else if (!path.includes('/en/') && !path.includes('/fr/')) {
    if (localStorage.getItem('selectedLanguage')) {
      window.location.href = `/${localStorage.getItem(
        'selectedLanguage'
      )}${path}`;
    } else {
      window.location.href = `/en${path}`;
    }
  }

  const [selectedLanguage, setSelectedLanguage] = useState<string>(defaultLang);
  const [readableLanguage, setReadableLanguage] = useState<string>(
    defaultLang === 'fr' ? 'Français' : 'English'
  );
  const [otherReadableLanguage, setOtherReadableLanguage] = useState<string>(
    defaultLang === 'fr' ? 'English' : 'Français'
  );
  const [data, setData] = useState('');

  const rlsNotesFileName =
    defaultLang === 'fr' ? 'ReleaseNotesFR.json' : 'ReleaseNotes.json';

  const drupalRoadmapJSON =
    defaultLang === 'fr' ? 'RoadmapFR.json' : 'Roadmap.json';

  // const drupalCapabilitiesJSON =
  //  defaultLang === 'fr' ? 'CapabilitiesFR.json' : 'Capabilities.json';

  // const drupalKnownIssuesJSON =
  //   defaultLang === 'fr' ? 'KnownIssuesFR.json' : 'KnownIssues.json';

  const announcementBannerJSON =
    defaultLang === 'fr'
      ? 'AnnouncementBannerFR.json'
      : 'AnnouncementBanner.json';

  const getVersion = async () => await axios.get(`${jsonEnvUrl}/version.json`);

  const getStoredVersion = () => sessionStorage.getItem('version') || '';

  const getVersionData = (vers) => {
    console.log('Getting Data for Version:', vers);
    const fetchCalls = [axios.get(`/json/drupal-en.json`)]; //Always need to get Eng JSON even on Fr, b/c some content not translated
    const dataFetch = async () => await Promise.all(fetchCalls);
    if (vers === getStoredVersion() || sessionStorage.getItem('jsonData')) {
      fetchCalls.slice(0); //don't need to get Eng JSON again
    }
    if (defaultLang === 'fr') {
      fetchCalls.push(axios.get(`/json/drupal-fr.json`));
    }

    fetchCalls.push(
      axios.get(`${jsonEnvUrl}/${vers}/${announcementBannerJSON}`)
    );
    fetchCalls.push(axios.get(`${jsonEnvUrl}/${vers}/${rlsNotesFileName}`));
    fetchCalls.push(axios.get(`${jsonEnvUrl}/${vers}/${drupalRoadmapJSON}`));
    // fetchCalls.push(
    //  axios.get(`${jsonEnvUrl}/${vers}/${drupalCapabilitiesJSON}`)
    // );
    // fetchCalls.push(
    //   axios.get(`${jsonEnvUrl}/${vers}/${drupalKnownIssuesJSON}`)
    // );

    sessionStorage.setItem(`version`, vers);
    dataFetch()
      .then((resp) => {
        let mergedData = resp[0].data;
        resp.forEach((r) => (mergedData = { ...mergedData, ...r.data }));
        setData(mergedData);

        sessionStorage.setItem(`jsonData`, JSON.stringify(mergedData));
        setLoading(false);
      })
      .catch((err) => {
        console.log('Error getting data:', err);
      });
  };
  const getData = () => {
    getVersion()
      .then((resp) => {
        return resp.data.version;
      })
      .then((vers) => {
        getVersionData(vers);
      })
      .catch((err) => {
        process.env.REACT_APP_ENV_LOADED === 'DEV' &&
          getVersionData('1707251514'); // FOR TESTING ON ONLY...  (port != 3000)
      });
  };

  React.useEffect(() => {
    // set defaults
    console.log('Fetching Data:', defaultLang, isAuth);
    onSellerProfile && isAuth === true && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    setReadableLanguage(selectedLanguage === 'fr' ? 'Français' : 'English');
    setOtherReadableLanguage(
      selectedLanguage === 'fr' ? 'English' : 'Français'
    );
    localStorage.setItem('selectedLanguage', selectedLanguage);
    isAuth === true && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const env = process.env.REACT_APP_ENV_LOADED;
  const jsonEnvExt = env === 'PROD' ? '' : env === 'BAT' ? '-bat' : '-int';
  const jsonEnvUrl = `https://content-ndc-portal${jsonEnvExt}.aircanada.com/content`;

  const [UATpwd, setUATpwd] = useState('');
  const [displayError, setDisplayError] = useState('');

  //if UAT, require pwd
  useEffect(() => {
    if (
      process.env.REACT_APP_UAT_PWD &&
      UATpwd === process.env.REACT_APP_UAT_PWD
    ) {
      localStorage.setItem('uatPWD', UATpwd);
    } else if (UATpwd !== '') {
      setDisplayError('incorrect');
    }
  }, [UATpwd]);

  if (
    env === 'BAT' &&
    process.env.REACT_APP_UAT_PWD &&
    UATpwd !== process.env.REACT_APP_UAT_PWD &&
    localStorage.getItem('uatPWD') !== process.env.REACT_APP_UAT_PWD
  ) {
    return <AppPwdLogin setUATpwd={setUATpwd} displayError={displayError} />;
  }

  const siteContent = (
    <CssBaseline>
      <LangContext.Provider
        value={{
          selectedLanguage,
          setSelectedLanguage,
          readableLanguage,
          otherReadableLanguage
        }}
      >
        <AdobeAnalyticsProvider>
          <SkipLinkProvider>
            <AppSkipLink />

            <div className="App, c-full-page">
              <AppHeader
                data={data}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                userName={userName}
                coName={coName}
                logout={logoutAzure}
                setHeaderHeight={setHeaderHeight}
                setLogoHeight={setLogoHeight}
                setAnnouncementBanner={setAnnouncementBanner}
              />

              {data === '' || loading ? (
                <AppLoader />
              ) : (
                <div
                  className="inline-header-height"
                  style={{ marginTop: `${headerHeight}px` }}
                >
                  <ErrorBoundary>
                    <Routes>
                      <Route path="/:urlLang/*" element={<FourOfour />} />
                      <Route
                        path="/:urlLang/"
                        element={<Home data={data} logoHeight={logoHeight} />}
                      />
                      {/* <Route
                        path="/:urlLang/agencyform"
                        element={
                          <Registration data={data['ndc-program.agencyForm']} />
                        }
                      /> */}
                      <Route
                        path="/:urlLang/ndc-program/home"
                        element={<Home data={data} logoHeight={logoHeight} />}
                      />
                      <Route
                        path="/:urlLang/support"
                        element={<Support data={data} />}
                      />
                      <Route
                        path="/:urlLang/seller-registration"
                        element={
                          <SellerRegStarting
                            data={data['sellerRegistration']}
                          />
                        }
                      />
                      <Route
                        path="/:urlLang/seller-registration-form"
                        element={
                          <SellerRegistration
                            data={data['sellerRegistration']}
                          />
                        }
                      />
                      <Route
                        path="/:urlLang/subseller-registration-form/084af2d8-e1f8-47c3-b4de-f5e0ec0e0414"
                        element={
                          <SubsellerSignUp data={data['subSellerOnboarding']} />
                        }
                      />
                      <Route
                        path="/:urlLang/seller-profile"
                        element={
                          <SellerProfile
                            data={data['sellerProfile']}
                            coName={coName}
                            setCoName={setCoName}
                            logoHeight={logoHeight}
                          />
                        }
                      />
                      <Route
                        path="/:urlLang/seller-profile/:section"
                        element={
                          <SellerProfile
                            data={data['sellerProfile']}
                            coName={coName}
                            setCoName={setCoName}
                            logoHeight={logoHeight}
                          />
                        }
                      />

                      <Route
                        path="/:urlLang/support/faq"
                        element={<FaqSection data={data} showTab={0} />}
                      />
                      <Route
                        path="/:urlLang/support/faq/commercial"
                        element={<FaqSection data={data} showTab={0} />}
                      />
                      <Route
                        path="/:urlLang/support/faq/technical"
                        element={<FaqSection data={data} showTab={1} />}
                      />
                      <Route
                        path="/:urlLang/support/knownIssues"
                        element={<KnownIssues data={data} />}
                      />
                      <Route
                        path="/:urlLang/support/statusMonitoring"
                        element={<StatusPage data={data} />}
                      />
                      <Route
                        path="/:urlLang/support"
                        element={<Support data={data} />}
                      />

                      <Route
                        path="/:urlLang/support/knownIssues"
                        element={<KnownIssues data={data} />}
                      />
                      {/* <Route path="/support/statusMonitoring" element={<StatusPage data={data}/>} />  */}
                      <Route path="/:urlLang/roadmap" element={<Roadmap />} />
                      <Route
                        path="/:urlLang/feuillederoute"
                        element={<Roadmap />}
                      />
                      <Route
                        path="/:urlLang/api/*"
                        element={
                          <Api
                            data={data}
                            logoHeight={logoHeight}
                            announcementBanner={announcementBanner}
                          />
                        }
                      />

                      <Route
                        path="/:urlLang/ndc-program/benefits"
                        element={<Benefits data={data} />}
                      />
                      <Route
                        path="/:urlLang/ndc-program/connection-options"
                        element={<ConnectionOptions data={data} showTab={0} />}
                      />
                      <Route
                        path="/:urlLang/ndc-program/connection-options/direct-integration"
                        element={<ConnectionOptions data={data} showTab={0} />}
                      />
                      <Route
                        path="/:urlLang/ndc-program/connection-options/technology-partners"
                        element={<ConnectionOptions data={data} showTab={1} />}
                      />
                      <Route
                        path="/:urlLang/ndc-program/connection-options/ac-connex"
                        element={<ConnectionOptions data={data} showTab={2} />}
                      />
                      <Route
                        path="/:urlLang/ndc-program/ndc-capabilities"
                        element={<NdcCapabilities data={data} showTab={0} />}
                      />
                      <Route
                      path="/:urlLang/ndc-program/ndc-capabilities/direct-integration"
                      element={<NdcCapabilities data={data} showTab={0} />}
                    />
                    <Route
                      path="/:urlLang/ndc-program/ndc-capabilities/technology-partners"
                      element={<NdcCapabilities data={data} showTab={1} />}
                    />
                    </Routes>
                  </ErrorBoundary>
                </div>
              )}
            </div>
            <AppFooter data={data} />
          </SkipLinkProvider>
        </AdobeAnalyticsProvider>
      </LangContext.Provider>
    </CssBaseline>
  );
  return window.location.href.includes('/fr/') ||
    window.location.href.includes('/en/') ? (
    onSellerProfile ? (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <>{siteContent}</>
      </MsalAuthenticationTemplate>
    ) : (
      <>{siteContent}</>
    )
  ) : (
    <></>
  );
};

export default App;
