import { useState } from "react";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useSellerProfile } from "../../../pages/SellerProfile/SellerProfile";

function UserSearchBar({...props}) {
  const [isBtnSown, setIsBtnShown] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>("");
  const { ...context } = useSellerProfile() as any;

  const onInputChange = (e) => {
 
    setSearchName(e.target.value);
 
    if (e.target.value) {
      setIsBtnShown(true);
    } else {
      setIsBtnShown(false);
    }
  };
  const setFilter = () => { 
    props.resetSelectedFilter && props.resetSelectedFilter()
    context.setFilterUsers(searchName);
   
  };
  const handleEnterPressSearch = (e) => {
  
    if (e.key === "Enter") {
    
      setFilter();
    }
  
  }
  const handleEnterPressClear = (e) => {
  
    if (e.key === "Enter") {
  
      e.stopPropagation(); 
      clearSearch()
    }
  
  }
const clearSearch = () => {
   
    setSearchName("");
    setIsBtnShown(false); 
    context.setFilterUsers("");
  
}
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          marginBlock: "32px",
          height: { md: "66px" },
        }}
        className="addNewUserBtn"
      >
        <TextField
          sx={{
            width: { xs: "100%", md: "311px" },
            marginTop: "auto",
            height: "48px",
          }}
          label={context.spPageText.searchUser}
          variant="standard"
          value={searchName}
          onChange={(e) => onInputChange(e)}
          onKeyDown={(e) => handleEnterPressSearch(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isBtnSown && (
                  <ClearIcon
                    sx={{ marginBottom: "15px", cursor: "pointer" }}
                    onClick={() => clearSearch()}
                    onKeyDown={(e) => {handleEnterPressClear(e)}}
                    tabIndex={0}  
                    role="button"
                  />
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: "black" },
          }}
        />
        {isBtnSown && (
          <Button
            variant="contained"
            tabIndex={0}
            role="button"
            sx={{
              marginLeft: { md: "30px" },
              marginBottom: "2px",
              width: { xs: "100%", md: "183px" },
              height: "48px",
              backgroundColor: "#005078",
              borderRadius: "10px",
              "&:hover": { backgroundColor: "#005078" },
              marginTop: { xs: "16px" },
            }}
            onClick={() =>setFilter()}
          >
            <SearchIcon sx={{ width: "24px", height: "24px" }} />
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default UserSearchBar;
