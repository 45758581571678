import { isEmpty } from '../../../pages/SellerProfile/APIs/Helpers';
import { SpAPIgetAllNotifs } from '../../../pages/SellerProfile/APIs/NotificationsAPI';
import { validateInput } from '../../../pages/util/validateFormInput';
import {
  SpApiEmailCheck,
  SpApiEmailListBasedOnEmail
} from '../../../pages/SellerProfile/APIs/SellerProfileAPI';
import _debounce from 'lodash/debounce';

const populateFormFields = ({ ...props }, isFrench) => {
  const formFields = JSON.parse(JSON.stringify(props.formFields)); //deep copy so can have multiple forms showing at once
  const trackErrors: string[] = [];

  if (props.formData) {
    //Prepopulate form fields
    const formData = props.formData;

    Object.entries(formData).forEach(([key, val]) => {
      prePopulateFormFields(key, val, formFields, trackErrors, isFrench, true);
    });
  } else {
    //Create empty form fields

    Object.entries(formFields).forEach(([key, val]) => {
      createEmptyFormFields(key, formFields, trackErrors);
    });
  }

  return { data: formFields, errors: trackErrors };
};
const checkIsContent = (content) => {
  return (
    content && content?.length > 0 && content !== null && content !== 'null'
  );
};

const updateValByObjKey = (field, obj, val, updateType) =>
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null && key !== field) {
      updateValByObjKey(field, obj[key], val, updateType);
    } else if (key === field) {
      updateType.includes('content') && (obj[key]['content'] = val);
      updateType.includes('disable') && (obj[key]['disabled'] = true);
      updateType.includes('enable') && (obj[key]['disabled'] = false);
    }
  });

const updateNameKeys = (
  field,
  formUpdates,
  updateVals,
  updateType,
  getCurrErrors
) => {
  const firstNameKey = field.replace('email', 'first_name');
  const lastNameKey = field.replace('email', 'last_name');
  
  updateValByObjKey(
    firstNameKey,
    formUpdates,
    updateVals[firstNameKey],
    updateType
  );
  updateValByObjKey(
    lastNameKey,
    formUpdates,
    updateVals[lastNameKey],
    updateType
  );
  let updateCurrErrors: string[] = [];
  if (updateType.includes('enable')) {
    //clearing out a field previously autofilled, so add to errors
    updateCurrErrors = [...getCurrErrors, firstNameKey, lastNameKey];
  } else {
    updateCurrErrors = getCurrErrors.filter(
      (error) => error !== firstNameKey && error !== lastNameKey
    );
  }

  return updateCurrErrors;
};

const getAllExistingEmails = (email, { ...context }) => {
  let exists: any;
  const checkIfExists = (list, listType) => {
    return Object.keys(list).forEach((user) => {
      const existsInUserList = Object.keys(list[user]).find((key) => {
        return key.includes('email') && list[user][key] === email;
      });
      if (existsInUserList) {
        exists = list[user];
        exists['listType'] =
          exists?.listType && Array.isArray(exists.listType)
            ? [...exists?.listType, listType]
            : [listType];
      }
    });
  };
  checkIfExists(context.users, 'regularUser');
  if (isEmpty(context.notificationRecipients)) {
    SpAPIgetAllNotifs(context)
      .then((res) => {
        checkIfExists(res, 'notificationRecip');
      })
      .catch((err) => {
        console.log('ERROR GETTING NOTIF RECIPS', err);
      });
  } else {
    checkIfExists(context.notificationRecipients, 'notificationRecip');
  }

  return exists;
};

const checkEmailExistsInList = (email, context, flag) => {
  try {
    const users = context.users;
    const notifs = context.notificationRecipients;
    let userDetails;

    if (flag === 'users') {
      users.map((e) => {
        if (e.user_email.toLowerCase() === email.toLowerCase()) {
          userDetails = true;
        }
        return null;
      });
    }

    if (flag === 'notifs') {
      notifs?.map((e) => {
        if (e.user_email.toLowerCase() === email.toLowerCase()) {
          userDetails = true;
        }
        return null;
      });
    }

    return userDetails;
  } catch (error) {
    console.error(error);
  }
};

const isEmailExists = _debounce(async (email, context, setErrorFunc, flag) => {
  let emailExists;
  try {
    emailExists = await SpApiEmailCheck(email, context);
    
    if (
      emailExists.status === 200 &&
      emailExists.data.Status === 'Success' &&
      emailExists.data.emailExists === false
    ) {
      return false;
    } else if (
      emailExists.status === 200 &&
      emailExists.data.Status === 'Success' &&
      emailExists.data.emailExists === true
    )
    setErrorFunc(!flag);
      return true;
  } catch (error) {
    console.error(error);
    return true;
  }
}, 1000);

const getAllUsersBasedOnEmail = _debounce(async (email, context, flag, setEmailFunc) => {
  try {
    let emailList = await SpApiEmailListBasedOnEmail(email, context, flag);
    
    if (emailList.data.data.length) {
      emailList.data.data.forEach((e) => (e['label'] = e.user_email));
      setEmailFunc(emailList.data.data);
      return emailList.data.data;
    }
    setEmailFunc([]);
    return null;
  } catch (error) {
    console.error(error);
    setEmailFunc([]);
    return;
  }
}, 800);

const checkForRadioAndCheckbox = (obj, objKey, content) => {
  obj.hasOwnProperty('options') &&
    Object.keys(obj.options).forEach((optionKey) => {
      if (
        obj.options[optionKey].hasOwnProperty('type') &&
        (obj.options[optionKey]['type'] === 'checkbox' ||
          obj.options[optionKey]['type'].includes('radio'))
      ) {
        obj.options[optionKey]['id'] = optionKey;
        obj.options[optionKey]['parent'] = objKey;
        obj.options[optionKey]['checked'] = content?.includes(optionKey);
      }
    });
};

const prePopulateFormFields = (
  dataField,
  content,
  formFields,
  trackErrors,
  isFrench,
  isInitialLoad = false
) => {
  Object.keys(formFields).forEach((key) => {
    if (
      typeof formFields[key] === 'object' &&
      formFields[key] !== null &&
      key !== dataField
    ) {
      prePopulateFormFields(
        dataField,
        content,
        formFields[key],
        trackErrors,
        isFrench
      );
    } else if (key === dataField) {
      let isError = formFields[key]['required'] === 'true' && !content;

      formFields[key]['content'] = formFields[key]['multiple']
        ? content?.length > 0
          ? content
          : []
        : content || '';

      checkForRadioAndCheckbox(formFields[key], key, content);

      if (
        formFields[key]['validate'] &&
        (formFields[key]['required'] === 'true' ||
          checkIsContent(formFields[key]['content']))
      ) {
        const doValidation = validateInput(formFields[key], isFrench);
        if (doValidation.error) {
          !trackErrors.includes(key) && trackErrors.push(key);
          isError = true;
        }
        formFields[key]['errorMsg'] = doValidation.msg;
      } else {
        formFields[key]['errorMsg'] = ''; //Don't show error message for initial empty required field, just highlight red
      }
      formFields[key]['error'] = isInitialLoad ? 'false' : isError;
      isError && !trackErrors.includes(key) && trackErrors.push(key);
      if (formFields[key].hasOwnProperty('options')) {
        prePopulateFormFields(
          dataField,
          content,
          formFields[key]['options'],
          trackErrors,
          isFrench
        );
      }
    }
  });
};

const createEmptyFormFields = (dataField, formFields, trackErrors) => {
  Object.keys(formFields).forEach((key) => {
    if (formFields[key]['required'] === 'true') {
      !trackErrors.includes(key) && trackErrors.push(key);
    }

    if (formFields[key]['options']) {
      createEmptyFormFields(dataField, formFields[key]['options'], trackErrors);
    } else if (
      typeof formFields[key] === 'object' &&
      formFields[key] !== null
    ) {
      formFields[key]['error'] = false;
      formFields[key]['errorMsg'] = '';
    }
  });
};

const updateParentContent = (curr, formFields) => {
  Object.keys(formFields).forEach((key) => {
    if (
      typeof formFields[key] === 'object' &&
      formFields[key] !== null &&
      key !== curr
    ) {
      updateParentContent(curr, formFields[key]);
    } else if (key === curr) {
      formFields[key]['content'] = Object.keys(
        formFields[key]['options']
      ).filter((obj) => formFields[key]['options'][obj]['checked']);
    }
  });
};

const checkParentRequiredChecked = (parent, formFields) => {
  let checked = false;

  const findKey = (parent, formFields) =>
    Object.keys(formFields).forEach((key) => {
      if (
        typeof formFields[key] === 'object' &&
        formFields[key] !== null &&
        key !== parent
      ) {
        findKey(parent, formFields[key]);
      } else if (key === parent) {
        Object.keys(formFields[key]['options']).forEach((opt) => {
          formFields[key]['options'][opt].checked && (checked = true);
        });
      }
    });
  findKey(parent, formFields); //needs to be nested loop or get unexpected results on multilevel form fields...

  return checked;
};

const checkRequiredContent = (update: string, curr) => {
  let isRequiredError = false;
  if (curr['required'] === 'true') {
    if (
      (Array.isArray(update) && update?.length === 0) ||
      (!Array.isArray(update) && update?.trim() === '')
    ) {
      isRequiredError = true;
    }
  }
  return isRequiredError;
};

export {
  populateFormFields,
  checkParentRequiredChecked,
  checkRequiredContent,
  updateParentContent,
  getAllExistingEmails,
  updateValByObjKey,
  updateNameKeys,
  isEmailExists,
  getAllUsersBasedOnEmail,
  checkEmailExistsInList
};
