import './AppMultiSelect.scss';
import { Select, SelectChangeEvent } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import useScrollPosition from '../../hooks/useScrollPosition';
import { useEffect, useState } from 'react';

interface Props {
  itemList: string[];
  label: string;
  values: string[];
  width: number;
  handleOnChange: (event: SelectChangeEvent<string[]>, list: string[]) => void;
}

const AppMultiSelect = ({
  label,
  itemList,
  width,
  values,
  handleOnChange
}: Props) => {
  const scrollPosition = useScrollPosition();

  const [showHideSelect, setShowHideSelect] = useState(false)

  useEffect(() => {
    if(scrollPosition) {
      setShowHideSelect(false)
    }
  }, [scrollPosition]);

  const getFormControlProps = () => {
    return {
      borderBottom: '1px solid #C1C5C8',
      height: '50px',
      width: `${width < 10 ? 10 : width + 1}px`,

      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      },

      '&.Mui-selected': {
        backgroundColor: 'grey',
        color: 'white',

        '&:hover': {
          backgroundColor: 'darkgrey'
        }
      }
    };
  };

  const getSelectDisplayProps = () => {
    return {
      style: {
        fontSize: '16px',
        paddingBottom: '4px',
        paddingLeft: '1px',
        paddingTop: '5px'
      }
    };
  };

  const getSelectLabelProps = () => {
    return {
      fontSize: '14px',
      marginLeft: '1px',
      marginTop: '5px',

      '&.Mui-focused': {
        color: '#737373'
      }
    };
  };

  return (
    <FormControl variant="standard" sx={getFormControlProps()} tabIndex={0}>
      <InputLabel sx={getSelectLabelProps()}>{label}</InputLabel>
      <Select
        disableUnderline
        IconComponent={ArrowDropDownIcon}
        label={label}
        MenuProps={{
          PaperProps: {
            style: {
              borderRadius: '10px',
              maxHeight: 48 * 4.5 + 8,
              width: 250
            }
          },
          disableScrollLock: true
        }}
        multiple
        onChange={(e) => handleOnChange(e, itemList)}
        renderValue={(selected) => selected.join(', ')}
        SelectDisplayProps={getSelectDisplayProps()}
        value={values}
        open={showHideSelect}
        onOpen={() => setShowHideSelect(true)}
        onClose={() => setShowHideSelect(false)}
      >
        {itemList.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={index.toString()}
              style={{
                backgroundColor: values?.indexOf(item) > -1 ? '#F5F5F5' : ''
              }}
            >
              <Checkbox
                sx={{ color: '#005078' }}
                checked={values.indexOf(item) > -1}
              />
              <ListItemText primary={item} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default AppMultiSelect;
