 
import "./SellerRegStarting.scss"; 
import { useContext, useEffect } from 'react'
import { Button, useMediaQuery, useTheme } from "@mui/material"; 
import { useNavigate } from "react-router-dom";
import { useAdobeAnalytics } from "../../providers/AdobeAnalytics"
import { LangContext } from "../../pages/App/App";
import React from "react";

const SellerRegStarting = ({ data }) => {
  const theme = useTheme();
  const langContext = useContext(LangContext);
  const mobile = useMediaQuery(theme.breakpoints.down("md")); 
  const intro = data && data["intro"]; 
  const navigate = useNavigate();
  const { adobeAnalyticsSectionClick, getCurrentPageTitle } = useAdobeAnalytics();

  useEffect(() => {
    getCurrentPageTitle("join the air canada ndc program")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    intro && (
      <div
        id="sellerRegStartPgContainer"
        className={`${mobile ? "isMobile" : ""}`}
      >
        <div id="sellerRegStartLeftPicBox"> 
          <div className="planePic">
           
            <h3> { Object.entries(intro.sidePanelHeader).map(([k,v],i) => {return <React.Fragment key={`${intro.sidePanelHeader[k]}-${i}`}><span>{intro.sidePanelHeader[k]}</span><br /></React.Fragment>})}
       </h3>
          </div>
          
            <div >
              <div className="subhead adjustCenter">
                {intro["infoGraphics"]["header"]}
              </div>
              <div className="subHeadBodyText adjustCenter">
                {intro["infoGraphics"]["body"]}
              </div>
            </div>
         

         
        </div>
        <div id="sellerRegStartContent">
          <h3> {intro["header"]}</h3>
          {intro["subHeader"]}
          {[1, 2, 3].map((x) => (
            <div key={`infoGraphicLinks-${x}`}> 
              <div
                className="regStartBodyHead">
                <div className="regStartSectionBadge badgeHeader">{x}</div> {intro["instructions"][x.toString()]["body"]}
              </div>
              {/* <div className="regStartBodyText">
              {intro["instructions"][x.toString()]["body"]}
              </div> */}
            </div>
          ))}
           <Button className="regLink" variant="contained" onClick={()=> {adobeAnalyticsSectionClick("register now"); navigate(`/${langContext.selectedLanguage}/seller-registration-form`)}} sx={{fontWeight: "600"}}>
           {intro["button"]}
          </Button>
          <div className="regStartBodyEndText">  
          {intro["endContent"]}
          </div>
          {Object.entries(intro["bodyLinks"]).map((x,i) => <Button
            className="regStartBttns"
            href={intro["bodyLinks"][i+1]["link"]}
            target={intro["bodyLinks"][i+1]["target"]}
            key={`bodyBttn-${i}`}
          >
            {intro["bodyLinks"][i+1]["text"]}
          </Button> )}
           
        
        </div>
      </div>
    )
  );
};

export default SellerRegStarting;
