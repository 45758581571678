import "./SellerRegSections.scss";
 import TooltipIcon from "../../../assets/images/alert-circle.png";
import { 
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import AppDatePicker from "../../../components/AppFilterDate/AppDatePicker/AppDatePicker";
import useScrollPosition from "../../../hooks/useScrollPosition";
import { useEffect, useState } from "react";
 
 

const FormSection = ({ formData, handleChange, section, checkError,showConditionals,noneText  }) => {
  const scrollPosition = useScrollPosition();

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
    },
  });

  const handleCalChange = (val, props) => {
    handleChange(val, props[0], props[1], props[2]);
  };

  const [showHideSelect, setShowHideSelect] = useState([{ key: false, value: ""}])

  const handleShowHideSelect = (label) => {
    const res = showHideSelect.map(v => {
      if(v.value === label && v.key === true) {
        return {
          key: false,
          value: label
        }
      } 
      if(v.value === label && v.key === false) {
        return {
          key: true,
          value: label
        }
      } 
      if(v.value !== label) {
        return {
          key: true,
          value: label
        }
      } else {
        return v
      }
    })
    setShowHideSelect(res)
  }

  useEffect(() => {
    if(scrollPosition) {
      setShowHideSelect([{ key: false, value: ""}])
    }
  }, [scrollPosition]);


  const toolTip = (curr) => {
   
    return (
      <Box
        sx={{
          position: "absolute",  
          cursor: "pointer",
          padding: "22px 5px",
          display: 'inline-block'
        }}
      >
        <CustomWidthTooltip 
          title={<div className="toolTipTable" dangerouslySetInnerHTML={{__html:curr["tooltip"]}}></div> }
          placement="right">
          <img
            className="tooltipIcon"
            src={TooltipIcon}
            alt="Tooltip Icon"
            aria-hidden="true"
          />
        </CustomWidthTooltip>
      </Box>
    );
  };
 
  const getLabel = (curr) => {
    const required = curr["required"] === "true" ? " *" : "";
    return `${curr["label"]}${required}`;
  };
  const checkSubLabelLength = (label) => {
    return label.length > 100 ? "longSubLabel" : "";
  }
   
  if (formData[section]?.subsections) {
    let allSubsections = formData[section]?.subsections;
    let subSectionKeys = Object.keys(allSubsections);
    return (
      formData && (
        <div id="sellerRegFormPg">
          <h3>{formData[section]["heading"]}</h3>
          <div className="subSectionGrid">
            {subSectionKeys.map((sub,i) => {
              
              let fieldKeys = Object.keys(allSubsections[sub]["fields"]);
              const sameRow = allSubsections[sub]["weight"] % 2 === 0;
              if (sameRow) {
              }
              
              return (
                <div className="subsectionBox" key={sub}>
                  <h4 className="sellerRegLable">{allSubsections[sub]["label"]}</h4>
                  {allSubsections[sub]["subLabel"] && (
                    <div className={sub==='SellerPOS'?'sublab':`subLabel ${checkSubLabelLength(allSubsections[sub]["subLabel"])}`}>
                      {allSubsections[sub]["subLabel"]}
                    </div>
                  )}
                  {fieldKeys.map((field:string,i2) => {
                    const curr = allSubsections[sub]["fields"][field]; 
             
                    if(curr["conditional"] && curr["conditional"]["type"]==="controlled" && !showConditionals.includes(field)){
                      //only show fields that are conditional on others being filled if applicable
                      return false;
                    }
 
                    switch (curr["type"]) {
                      case "text":
                        return (
                          <FormControl fullWidth key={`${field}-t`}>
                          
                            <TextField
                              className="formTextField"
                              label={getLabel(curr)}
                              name={curr["name"]}
                              variant="standard"
                              value={curr["content"]}
                              id={`${field}-${curr["name"]}`}
                              onChange={(e) =>
                                handleChange(e, section, sub, field)
                              }
                              key={field}
                              error={curr["error"] === true}
                              helperText={curr["error"] && curr["errorMsg"]}
                              inputProps={{
                                onBlur: () => {
                                  checkError(curr, sub, field, section);
                                },
                              }} 
                              autoFocus={i===0 && i2===0}
                            />
                            {(curr["tooltip"] && curr["content"]) === "" &&
                              toolTip(curr)}  
                          </FormControl>
                        );

                      case "select": 
                        return (
                          <FormControl
                            fullWidth
                            key={`${field}-t`}
                            className="selectBox"
                          >
                            <InputLabel
                              id="demo-simple-select-label"
                              key={`${field}-l`}
                            >
                              {getLabel(curr)}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id={field} 
                              variant="standard"
                              value={curr["content"]}
                              label={getLabel(curr)}
                              name={curr["name"]}
                              onChange={(e) =>
                                handleChange(e, section, sub, field)
                              }
                              key={field}  
                              open={showHideSelect[0].value === curr['label'] ? showHideSelect[0].key : false}
                              onOpen={() => handleShowHideSelect(curr['label'])}
                              onClose={() => handleShowHideSelect(curr['label'])}
                              MenuProps={{
                                disableScrollLock: true
                              }}
                            >
                              {curr["options"] &&
                                curr["options"].map((option, i) => {
                                  return (
                                    <MenuItem
                                      value={option}
                                      key={`${field}-${i}`}
                                    >
                                      {option}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            {/* {(curr["tooltip"] && curr["content"]) === "" &&
                              toolTip(curr)} */}
                          </FormControl>
                        );

                        case "selectMultiple": 
                        return (
                          <FormControl
                            fullWidth
                            key={`${field}-t`}
                            className="selectBox"
                          >
                            <InputLabel
                              id="demo-simple-select-label"
                              key={`${field}-l`}
                            >
                              {getLabel(curr)}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id={field}
                              multiple 
                              variant="standard"
                              value={[...curr["content"]]}
                              label={getLabel(curr)}
                              name={curr["name"]}
                              onChange={(e) =>
                                handleChange(e, section, sub, field)
                              }
                              key={field}  
                              open={showHideSelect[0].value === curr['label'] ? showHideSelect[0].key : false}
                              onOpen={() => handleShowHideSelect(curr['label'])}
                              onClose={() => handleShowHideSelect(curr['label'])}
                              MenuProps={{
                                disableScrollLock: true
                              }}
                            >
                              {curr['allowNone'] &&  <MenuItem
                                      value="None"
                                      key={`none-${i}`}
                                    >
                                     {noneText}
                                    </MenuItem>}
                              {curr["options"] &&
                                curr["options"].map((option, i) => {
                                  return (
                                    <MenuItem
                                      value={option}
                                      key={`${field}-${i}`}
                                    >
                                      {option}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            {/* {(curr["tooltip"] && curr["content"]) === "" &&
                              toolTip(curr)} */}
                          </FormControl>
                        );

                      case "checkbox":
                        const currValue =
                          curr["content"] === ""
                            ? false
                            : curr["content"] === "true";
                        return (
                         <div   key={`${field}-c`} > <FormControlLabel
                            control={ 
                                <Checkbox
                                  onChange={(e) =>
                                    handleChange(e, section, sub, field)
                                  }
                                  name={curr["name"]}
                                  value={currValue}
                                  checked={currValue} 
                                  className="formCheckbox"
                                  sx={{display: 'inline-block'}}
                                /> 
                            }
                            label={getLabel(curr)}
                            key={`${field}-c2`}
                          />{curr["tooltip"] && toolTip(curr)}</div>
                        );

                      case "calendar":
                        return (
                         <AppDatePicker
                            key={`${field}-cal`}
                            label={getLabel(curr)}
                            id={field}
                            keyName={field}
                            value={curr["content"]}
                            handleChange={handleCalChange} 
                            props={[section, sub, field]}  
                          />
                        );

                      default:
                      // code block
                    }

                    return true;
                  })}
                </div>
              );
            })}
          </div>{" "}
        </div>
      )
    );
  }
};

export default FormSection;
