import './DirectIntegrationTable.scss';
import { Fragment } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import { LangContext } from '../../pages/App/App';

const DirectIntegrationTable = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const langContext = useContext(LangContext);

  const additionalInformation = data.footer[0].entries;
  const columns = data.columns.split(',');
  const quickLinks = data.footer[1];
  const sections = data.sections;

  const renderDesktopTable = () => {
    return (
      <table className="direct-integration-table">
        <thead>
          <tr>
            {columns.map((header, index) => {
              return (
                <th key={index} className="table-header">
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {sections.map((section, sectionIndex) => {
            return (
              <Fragment key={sectionIndex}>
                <tr>
                  <th className="section-head" colSpan={columns.length}>
                    {section.title}
                  </th>
                </tr>
                {section.subsections.map((subsection, subsectionIndex) => {
                  return (
                    <Fragment key={`${sectionIndex}-${subsectionIndex}`}>
                      {subsection.features.map((feature, featureIndex) => {
                        return (
                          <tr
                            key={`${sectionIndex}-${subsectionIndex}-${featureIndex}`}
                          >
                            {featureIndex === 0 && (
                              <td
                                className="subsection-head"
                                rowSpan={subsection.features.length}
                              >
                                {subsection.title}
                              </td>
                            )}
                            <td>
                              {feature.description}
                              {feature.description_list &&
                                feature.description_list?.every((obj) =>
                                  obj.hasOwnProperty('item')
                                ) && (
                                  <ul>
                                    {feature.description_list.map(
                                      (item, itemIndex) => {
                                        return (
                                          <li key={itemIndex}>{item.item}</li>
                                        );
                                      }
                                    )}
                                  </ul>
                                )}
                            </td>
                            <td className="align-center">
                              {feature.air_canada_ndc_api === 'yes' ? (
                                <img
                                  src="/images/icons/success-circle-check.png"
                                  alt="success-circle-check"
                                />
                              ) : feature.air_canada_ndc_api === 'no' ? (
                                '-'
                              ) : (
                                feature.air_canada_ndc_api
                              )}
                            </td>
                            <td className="align-center">
                              {feature.gds_edifact === 'yes' ? (
                                <img
                                  src="/images/icons/success-circle-check.png"
                                  alt="success-circle-check"
                                />
                              ) : feature.gds_edifact === 'no' ? (
                                '-'
                              ) : (
                                feature.gds_edifact
                              )}
                            </td>
                            <td className="align-center">
                              {feature.ac_connex === 'yes' ? (
                                <img
                                  src="/images/icons/success-circle-check.png"
                                  alt="success-circle-check"
                                />
                              ) : feature.ac_connex === 'no' ? (
                                '-'
                              ) : (
                                feature.ac_connex
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderFooter = () => {
    return (
      <div className="infoAndLinksBar">
        <div>
          {additionalInformation.map((item, index) => {
            const urlPattern = /https?:\/\/[^\s]+/g;
            const splitText = item.text.split(urlPattern);
            const url = item.text.match(urlPattern);

            return (
              <p key={index}>
                {splitText[0]}
                {url && (
                  <a href={url[0]} target="_blank" rel="noopener noreferrer">
                    {url[0]}
                  </a>
                )}
                {splitText[1]}
              </p>
            );
          })}
        </div>
        <div className="quickLinks" style={{ width: '496px' }}>
          <h4
            className="quickLinksTitle"
            style={{ paddingLeft: isMobile ? '0' : '20px' }}
          >
            {quickLinks.title}
          </h4>
          <ul>
            {quickLinks.links.map((link, index) => {
              return (
                <li key={index}>
                  <a href={`/${langContext.selectedLanguage}/api/${link.path}`}>
                    {link.text}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  const renderMobileTable = () => {
    return (
      <div className="direct-integration-table-mobile">
        {sections.map((section, sectionIndex) => {
          return (
            <Fragment key={sectionIndex}>
              <div className="container-header">{section.title}</div>
              {section.subsections.map((subsection, subsectionIndex) => {
                return (
                  <Fragment key={`${sectionIndex}-${subsectionIndex}`}>
                    <div className="container-subheader">
                      {subsection.title}
                    </div>
                    {subsection.features.map((feature, featureIndex) => {
                      return (
                        <div
                          className="subsection"
                          key={`${sectionIndex}-${subsectionIndex}-${featureIndex}`}
                        >
                          {columns.slice(1).map((column, columnIndex) => {
                            return (
                              <div
                                className="feature"
                                key={`${sectionIndex}-${subsectionIndex}-${featureIndex}-${columnIndex}`}
                              >
                                <p className="feature-header">{column}</p>
                                {columnIndex === 0
                                  ? feature.description
                                  : columnIndex === 1
                                  ? feature.air_canada_ndc_api
                                  : feature.gds_edifact}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <div className="connection-options-body-content">
      <h3>{data.title}</h3>
      {isMobile ? renderMobileTable() : renderDesktopTable()}
      {renderFooter()}
    </div>
  );
};

export default DirectIntegrationTable;
