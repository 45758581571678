import { Box, Button, Typography } from "@mui/material";
import "./SupportStatusToggle.scss";

const SupportStatusToggle = ({ statusType, setStatus, data }) => {

	return (
		<Box className="toggleContainer">
			<Box>
				<Typography className="statusHeaderText">{data.title}</Typography>
				<Typography className="statusSubText">
					{data.description}
				</Typography>
			</Box>
			<Box className="toggle-group">
				<Box
					className="toggle"
					sx={{
						transform: `translateX(${statusType === "Production" ? 0 : "100px"})`,
					}}
				/>
				<Button
					variant="text"
					sx={{
						color: statusType === "Production" ? "#ffffff" : "black",
						textTransform: "none",
						fontWeight: 400,
						fontSize: "14px",
					}}
					onClick={() => setStatus("Production")}
				>
					{data.toggle[0]}
				</Button>
				<Button
					variant="text"
					sx={{
						color: statusType === "Gold" ? "#ffffff" : "black",
						textTransform: "none",
						fontWeight: 400,
						fontSize: "14px",
					}}
					onClick={() => setStatus("Gold")}
				>
					{data.toggle[1]}
				</Button>
			</Box>
		</Box>
	);
};

export default SupportStatusToggle;
