import "./AppWarningBanner.scss";

//mui imports
import { useTheme, useMediaQuery } from "@mui/material";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";
interface WarningBannerProps {
	htmlContent: string;
  }
const AppWarningBanner: React.FC<WarningBannerProps> = (
	props: WarningBannerProps
  ) => {
	// MUI theme
	const theme = useTheme();
	const handleAnchorClick = useHandleAnchorClick()
	return (
		<div
			className="warningBannerContainer"
			style={
				{
					"--flex-direction": useMediaQuery(theme.breakpoints.down("md"))
						? "column-reverse"
						: "row",
				} as React.CSSProperties
			}
		>
			<div
				className="warningBannerText"
				style={
					{
						"--margin-top": useMediaQuery(theme.breakpoints.down("md")) ? "0px" : "25px",
					} as React.CSSProperties
				}
			>
				
				<div dangerouslySetInnerHTML={{ __html: props.htmlContent }} onClick={handleAnchorClick}></div>
			</div>
		</div>
	);
};

export default AppWarningBanner;
