import { Grid, Typography } from "@mui/material";
import useHandleAnchorClick from "../../hooks/useHandleAnchorClick";
import "./BenefitsFeatured.scss";

const BenefitsFeatured = ({ data }) => {
	const handleAnchorClick = useHandleAnchorClick();
	return (
		<>
			<div className="benefits-featured-top" aria-labelledby="benefits-feature-title">
				<h2 className="benefits-featured-title" id="benefits-feature-title">{data?.title}</h2>
				<Grid  className="main-container" container>
					{data?.items?.map((item, index) => {
					 
						return (
							<Grid className="benefits-featured-container" item xs={12} sm={6} md={6} lg={4} key={index}>
								<div className="benefits-featured-image"  >
								
									<img src={item.imageUrl} alt=""/>
									<div className="benefitsBG"></div>
								</div>
								<div
									className="benefits-featured-item"
									 
								>
										
									<Typography className="benefits-featured-item-title">
										{item?.title}
									</Typography>
									<Typography className="benefits-featured-item-text" dangerouslySetInnerHTML={{ __html: item?.text }} onClick={handleAnchorClick}>
									</Typography>
								</div>
							</Grid>
						);
					})}
				</Grid>
			</div>
		</>
	);
};
export default BenefitsFeatured;


